import Paper from '@material-ui/core/Paper';
import React, { Component } from "react";
import { connect } from 'react-redux';

class EmptyState extends Component {
	render() {
		return (
			<Paper style={{padding: this.props.padding || 20, minHeight: this.props.minHeight || 250, textAlign: 'center', display: 'flex', alignItems: 'center', borderRadius: 6, boxShadow: 'none', background: this.props.themecolors.tabBgColor, color: this.props.themecoTextColor }}>
              {this.props.children || <h2 style={{width: '100%', color: 'inherit', fontWeight: 'normal'}}>{this.props.text}</h2>}
			</Paper>
		)
	}
}


const mapState = state => ({
	themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(EmptyState);