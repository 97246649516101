import { checkPrivileges } from "../../Helpers";
import instance from "../../axios";

// get Trailers
export const getTrailers = (trailers) => ({
  type: "GET_TRAILERS",
  trailers,
});
export const addTrailers = (trailers) => ({
  type: "ADD_TRAILERS",
  trailers,
});
export const addTrailer = (trailer) => ({
  type: "ADD_TRAILER",
  trailer,
});
export const updateTrailer = (trailer) => ({
  type: "UPDATE_TRAILER",
  trailer,
});
export const removeTrailer = (trailer) => ({
  type: "REMOVE_TRAILER",
  trailer,
});

export const sortTrailers = (trailers) => ({
  type: "SORT_TRAILERS",
  trailers,
});

export const searchTrailer = (trailer) => ({
  type: "SEARCH_TRAILER",
  payload: trailer.trailer,
});

export const isTrailerLoad = (loadTrailer) => ({
  type: "LOAD_TRAILERS",
  loadTrailer,
});

//start trailer
export const fetchTrailers = (dispatch, userInfoFront) => {
  const query = "userId=" + userInfoFront.id + "&all=true";
  // inital fetch call for trailer
  if (checkPrivileges("binder")) {
    instance({
      url: `/api/trailers`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        userId: userInfoFront.id,
        all: true,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((trailers) => {
        // const { data } = trailers
        if (trailers && trailers.length) {
          dispatch(getTrailers(trailers));
        }
        dispatch(isTrailerLoad(false));
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  }
};
//end trailer
