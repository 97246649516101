/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from './TextField'
import SearchField from './SearchField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from './MenuItem'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import Style from 'style-it'
import { connect } from 'react-redux'
import { selectStyles } from '../../Helpers'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(1) * 2
  }
})

class IntegrationReactSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      single: null,
      multi:
        (this.props.multi &&
          this.props.multi.map(o => {
            return { value: o.id, label: o.name || o.label }
          })) ||
        null
    }
  }

  handleChange = name => value => {
    if (value.length && this.props.canAssign) {
      this.setState(
        {
          [name]: value
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.multi)
          }
          if (this.props.returnSelected) {
            this.props.returnSelected(value, 'POST')
          }
        }
      )
    } else {
      if (this.props.canRemove) {
        this.setState(
          {
            [name]: value
          },
          () => {
            if (this.props.onChange) {
              this.props.onChange(this.state.multi)
            }
            if (this.props.returnSelected) {
              this.props.returnSelected(value, 'POST')
            }
          }
        )
      }
    }
  }

  handleDelete (data) {
    let newChips = []
    if (this.state.multi.length && this.props.canRemove) {
      this.state.multi.map(chip => {
        if (data.value !== chip.value) {
          newChips.push(chip)
        } else {
          return null
        }
        return null
      })

      this.setState({ multi: newChips }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.multi)
        }
        if (this.props.returnSelected) {
          this.props.returnSelected([data], 'DELETE')
        }
      })
    }
  }

  render () {
    const { classes, array, themecolors } = this.props

    const suggestions = array.map(suggestion => {
      if (this.state.multi && this.state.multi.length) {
        if (
          !this.state.multi.filter(s => s.value === suggestion.value).length
        ) {
          return {
            value: suggestion.id,
            label: suggestion.name || suggestion.label
          }
        } else {
          return {}
        }
      } else {
        return {
          value: suggestion.id,
          label: suggestion.name || suggestion.label
        }
      }
    })
    let chips

    if (this.state.multi && this.state.multi.length) {
      chips = this.state.multi.map(chip => (
        <Chip
          className='chip-tags'
          key={chip.value}
          label={chip.label}
          variant='outlined'
          onDelete={e => this.handleDelete(chip, e)}
          style={{ marginRight: 5, marginBottom: 5 }}
        />
      ))
    }

    return (
      <div className={classes.root}>
          <div className='custom-select'>
            <NoSsr>
              <Select
                {...this.props}
                classes={classes}
                styles={selectStyles(themecolors)}
                textFieldProps={{
                  label: this.props.label || 'Select multiple',
                  InputLabelProps: {
                    shrink: true
                  }
                }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral0: themecolors.themeDarkColor,
                    neutral5: themecolors.textColor,
                    neutral10: themecolors.textColor,
                    neutral20: themecolors.textColor,
                    neutral30: themecolors.textColor,
                    neutral40: themecolors.textColor,
                    neutral50: themecolors.textColor,
                    neutral60: themecolors.textColor,
                    neutral70: themecolors.textColor,
                    neutral80: themecolors.textColor,
                    neutral90: themecolors.textColor,
                    dangerLight: themecolors.themeDarkColor,
                    danger: themecolors.textColor,
                    primary50: themecolors.themeLightColor,
                    primary25: themecolors.themeLightColor,
                    primary: themecolors.themeLightColor
                  }
                })}
                options={suggestions}
                value={this.state.multi}
                onChange={this.handleChange('multi')}
                placeholder=''
              />
            </NoSsr>
          </div>
      </div>
    )
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  themecolors: state.themeColors
})
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(IntegrationReactSelect)
)
