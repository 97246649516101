import React, { PureComponent } from 'react'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close';
import './index.scss'
import { checkPrivileges, prepareStreeView, setAttributeFormat, setIconOnList } from '../../../Helpers'
import { unsetDeviceId } from './../../../Actions/Devices'
import moment from 'moment'
import ProtocolDialog from '../ProtocolDialog'
import ReactHtmlParser from 'react-html-parser'
import { ReactComponent as FileCopyIcon } from './../../../assets/monitoring/copy.svg'
import { ReactComponent as OtherSensorIcon } from '../../../assets/sensors/sensor.svg'
import { ReactComponent as ElogicIcon } from '../../../assets/nav/algorithm.svg'
import { ReactComponent as AggregateIcon } from '../../../assets/sensors/chip.svg'
import { ReactComponent as MeasurementIcon } from '../../../assets/sensors/centimeter.svg'
import { ReactComponent as DiscreteIcon } from '../../../assets/sensors/discrete.svg'
import { ReactComponent as PlayIcon } from '../../../assets/monitoring/play.svg'
import { ReactComponent as StopIcon } from '../../../assets/monitoring/stop.svg'
import { ReactComponent as IgnitionIcon } from '../../../assets/monitoring/idling.svg'
import { ReactComponent as TowingIcon } from '../../../assets/monitoring/towing.svg'
import { ReactComponent as ParkingIcon } from '../../../assets/monitoring/parking.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/monitoring/question.svg'
import { ReactComponent as OnlineIcon } from "../../../assets/monitoring/online.svg";
import { ReactComponent as OfflineIcon } from "../../../assets/monitoring/wifi-off.svg";
import { ReactComponent as GpsIcon } from "../../../assets/monitoring/gps.svg";
import { ReactComponent as IgnitionSensorIcon } from "../../../assets/monitoring/ignition-01.svg";
import { ReactComponent as BoardPowerIcon } from "../../../assets/monitoring/board-power.svg";
import { ReactComponent as PowerCutIcon } from "../../../assets/monitoring/electric-car-01.svg"; 
import { ReactComponent as TimeIcon } from "../../../assets/monitoring/time.svg"; 
import { ReactComponent as ImmobiliserIcon } from "../../../assets/monitoring/immobiliser.svg"; 
import { ReactComponent as SpeedMeterIcon } from "../../../assets/monitoring/speed-meter.svg"; 
import { ReactComponent as StreetViewIcon } from "../../../assets/nav/street-view.svg";
import { ReactComponent as CarIcon } from "../../../assets/monitoring/car.svg";
import { ReactComponent as PinIcon } from "../../../assets/monitoring/pin.svg";
import { ReactComponent as OpenLinkIcon } from "../../../assets/monitoring/openLink.svg";
import { ReactComponent as MovingIcon } from "../../../assets/monitoring/moving.svg";
import { ReactComponent as GroupIcon } from '../../../assets/nav/group.svg'
import { ReactComponent as CallIcon } from '../../../assets/nav/call.svg'
import Tabs from '@material-ui/core/Tabs'
import CTab from '@material-ui/core/Tab'
import { Grid, Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
import { SendCommand } from '../../../Pages/Units/Components/sendCommand';
import Dialog from '../../common/Dialog';
import instance from '../../../axios';
let win;

const VerticalTabs = withStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index ,...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{  color: 'inherit', ...other.style, 
      margin:"0",
      // margin:"10px 6px 0 6px",
      //  boxShadow:"0px 1px 4px rgb(10 1 1 / 58%)"
        }}
      className='custom-unit-tabpanel-container'
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let currentPosition = null;
  let currentDevice = null;
  if (state.deviceId) {
    currentDevice = state.devices3.find((d) => d.id === state.deviceId);
    currentPosition = state.deviceRelatedData[state.deviceId];
  }
 
  const allComputedAttributes = [];
  state &&
    state.allComputedAttributes.length &&
    state.allComputedAttributes.map((d) => {
      if (d.deviceIds.includes(state.deviceId)) {
        allComputedAttributes.push(d.item);
      }
    });

  return {
    currentPosition: Object.assign({}, currentPosition),
    currentDevice: Object.assign({}, currentDevice),
    currentVehicle: state.vehicles.find(
      (v) => v.deviceId && v.deviceId === state.deviceId
    ),
    currentDriver: state.drivers.find(
      (v) =>
        currentPosition &&
        currentPosition.attributes &&
        currentPosition.attributes.driverUniqueId &&
        currentPosition.attributes.driverUniqueId === v.uniqueId
    ),
    currentTrailer: state.trailers.find(
      (v) =>
        currentPosition &&
        currentPosition.attributes &&
        currentPosition.attributes.trailerUniqueId &&
        currentPosition.attributes.trailerUniqueId === v.uniqueId
    ),
    trailers: state.trailers,
    drivers: state.drivers,
    computedAttributes: state.computedAttributes,
    allComputedAttributes,
    events: state.events,
    groups: state.groupNames,
    deviceId: state.deviceId,
    insideGeofence: state.insideGeofence,
    geofences: state.geoFence,
    logInUser: state.logInUsers,
  };
};
class Row extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      device: {},
      details: {},
      currentTab: "basic",
      sendcommandDialog:false,
      list:[]
    };
    this.hideWidgets = this.hideWidgets.bind(this);
  }

  hideWidgets() {
    if (this.props.dispatch) {
      this.props.dispatch(unsetDeviceId({ id: this.props.deviceId }));
      document.body.classList.remove("shown-widgets");
    }
  }

  tabChange = (event, currentTab) => {
    this.setState({ currentTab });
  };

  UNSAFE_componentWillReceiveProps(n) {
    if (
      n.deviceId !== this.props.deviceId &&
      this.props.deviceId &&
      n.deviceId
    ) {
      this.setState({ currentTab: "basic" });
    }
  }
  closeModel = ()=>{
    this.setState({
      sendcommandDialog:false
    })
  }
  sendCommandFun = (itm) =>  {
    this.setState({
      sendcommandDialog:true
    })
    if(itm&&itm.id){
      instance({
        url: `/api/commands/send`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          deviceId: itm.id,
        },
      })
   
        .then((commands) => {
          const list = [];
          if (commands.length) {
            commands.map((item) => {
              list.push({ ...item, key: item.id, name: item.description });
              return null;
            });
          }
          this.setState({list}) 
        })
        .catch((error) => { console.log('error ====', error)  })
    }
  }

  render() {
     if (this.props.deviceId) {
      let color =
        this.props.currentDevice && this.props.currentDevice.status === "online"
          ? "#00BF4E"
          : "#FF0000";
      const { icons, deviceId } = this.props.currentPosition;
      const { category, name } = this.props.currentDevice;
      const icon =
        icons && icons.playicon && icons.playicon.icon
          ? icons.playicon.icon
          : "";
      let RelatedIcon = null;
      if (icon === "play") {
        RelatedIcon = PlayIcon;
      } else if (icon === "ignition") {
        RelatedIcon = IgnitionIcon;
      } else if (icon === "towing") {
        RelatedIcon = TowingIcon;
      } else if (icon === "parking") {
        RelatedIcon = ParkingIcon;
      } else if (icon === "stop") {
        RelatedIcon = StopIcon;
      } else if (icon === "unknown") {
        RelatedIcon = QuestionIcon;
      }

      const orientation = "horizontal";
      const { currentTab } = this.state;
     
  let serverTimeZoneName = 'Asia/Dubai'
    let vehicle = 'N/A'
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }
      return ( 
      <div className='feature-row' >
            {checkPrivileges('device') && <>
            <div className="sidebar-devices-list">
              <Scrollbar
              style={{height:"95%"}}
                      disableTracksWidthCompensation={true}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div
                              {...restProps}
                              ref={elementRef}
                              id="scrollableDivDevices"
                            />
                          );
                        },
                      }}
                    >
                <InfiniteScroll
                            dataLength={5000}
                            // next={() => this.props.dispatch(fetchMoreDevices())}
                            // hasMore={this.props.devices.hasNext}
                            // loader={<Loader defaultStyle={true} />}
                            scrollableTarget="scrollableDivDevices"
                            // endMessage={"endMessage"}
                          >
                          <div key={0} className={'feature-row-head'}>
                            <div className="unit-name">
                              <span className="unit-img" style={{}}> 
                              {setIconOnList(this.props.currentDevice.category,color)}
                                {/* <img src={'/assets/category/default/' +(category || 'default') + '.svg'} alt={name} /> */}
                              </span>
                              <CloseIcon
                                className={'feature-close-button'}
                                onClick={this.hideWidgets}
                              />
                              <span style={{minWidth: 0}}>
                                <span className="unit-name-text" style={{color:this.props.themecolors&&this.props.themecolors.menuActiveBackground}}>{name}</span>
                                {/* <span className={"unit-status-text "+icons?.play}><span className="unit-status-label">
                                  {icons && RelatedIcon && <RelatedIcon fill="currentColor" width={16} height={16} />} {this.props.translate(this.props.currentPosition?.icons?.playicon?.label || '')}
                                  </span></span>  */}
                                  <span className="unit-status-label" style={{color:'#3A3A39'}}>
                                    <span style={{fontStyle: "italic", }}>{moment.tz(this.props.currentDevice.lastUpdate, serverTimeZoneName).format('HH:mm:ss')}</span>
                                    {" | "}
                                    {moment(this.props.currentDevice.lastUpdate).utc().format('DD-MM-YYYY')}
                                    </span>
                              </span>
                            </div>
                          </div>
    
                  <TabPanel value={"basic"} index='basic'>
                    <FeatureSection
                      dispatch={this.props.dispatch}
                      key='basicDetails'
                      type='basicDetails'
                      drivers={this.props.drivers}
                      computedAttributes={this.props.computedAttributes}
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      groups={this.props.groups}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                      RelatedIcon={RelatedIcon}
                      logInUser={this.props.logInUser}
                    />
                  </TabPanel>
              
                  <TabPanel value={'sensors'} index='sensors'>
                    <FeatureSection
                      trailers={this.props.trailers}
                      dispatch={this.props.dispatch}
                      key='Sensors'
                      type='Sensors'
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      drivers={this.props.drivers}
                      computedAttributes={this.props.computedAttributes}
                      allComputedAttributes={this.props.allComputedAttributes}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                    />
                  </TabPanel>
                  <TabPanel value={'EVENTS'} index='EVENTS'>
                    <FeatureSection
                      dispatch={this.props.dispatch}
                      key='EVENTS'
                      type='EVENTS'
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      events={this.props.events}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                    />
                  </TabPanel>

                  <TabPanel value={'immobiliser'} index='immobiliser'>
                    <FeatureSection
                      trailers={this.props.trailers}
                      dispatch={this.props.dispatch}
                      themecolors={this.props.themecolors}
                      key='immobiliser'
                      type='immobiliser'
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      drivers={this.props.drivers}
                      computedAttributes={this.props.computedAttributes}
                      allComputedAttributes={this.props.allComputedAttributes}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                      closeModel={this.closeModel}
                      sendCommandFun={this.sendCommandFun}
                      sendcommandDialog={this.state.sendcommandDialog}
                      list={this.state.list}
                    />
                  </TabPanel>
               
                   
                </InfiniteScroll>
              </Scrollbar>
            </div>
            </>
          }
        </div>
      );
    } else {
      return null;
    }
  }
}

export class FeatureSection extends PureComponent {
  constructor(props) {
    super(props);
    this.getCardinal = this.getCardinal.bind(this);
    this.convertOdometer = this.convertOdometer.bind(this);
    this.checkLastResponseTime = this.checkLastResponseTime.bind(this);
    this.openProtocol = this.openProtocol.bind(this);
    this.closeProtocol = this.closeProtocol.bind(this);
    this.state = {
      displayOdo: "km",
      km: 0,
      mi: 0,
      modal: false,
      input: "",
      protocol: "",
      inputName: "",
      list:[],
    };
  }

  convertOdometer(unit) {
    if (unit !== this.state.displayOdo) {
      this.setState({ displayOdo: unit });
    }
  }

  UNSAFE_componentWillReceiveProps(NextProps) {
    this.setState({
      km:
        (NextProps.details &&
        NextProps.details.attributes &&
        NextProps.details.attributes.totalDistance
          ? NextProps.details.attributes.totalDistance / 1000
          : 0
        ).toFixed(2) || 0,
      mi:
        (NextProps.details &&
          NextProps.details.attributes &&
          NextProps.details.attributes.totalDistance &&
          (
            ((NextProps.details.attributes.totalDistance || 0) / 1000) *
            0.621371
          ).toFixed(2)) ||
        0,
    });
  }

  UNSAFE_componentWillMount() {
    this.setState({
      km:
        (this.props.details &&
        this.props.details.attributes &&
        this.props.details.attributes.totalDistance
          ? this.props.details.attributes.totalDistance / 1000
          : 0
        ).toFixed(2) || 0,
      mi:
        (this.props.details &&
          this.props.details.attributes &&
          this.props.details.attributes.totalDistance &&
          (
            ((this.props.details.attributes.totalDistance || 0) / 1000) *
            0.621371
          ).toFixed(2)) ||
        0,
    });
  }

  //given "0-360" returns the nearest cardinal direction "N/NE/E/SE/S/SW/W/NW/N"
  getCardinal(angle) {
    //easy to customize by changing the number of directions you have
    let directions = 8;

    let degree = 360 / directions;
    angle = angle + degree / 2;

    if (angle >= 0 * degree && angle < 1 * degree) return "N";
    if (angle >= 1 * degree && angle < 2 * degree) return "NE";
    if (angle >= 2 * degree && angle < 3 * degree) return "E";
    if (angle >= 3 * degree && angle < 4 * degree) return "SE";
    if (angle >= 4 * degree && angle < 5 * degree) return "S";
    if (angle >= 5 * degree && angle < 6 * degree) return "SW";
    if (angle >= 6 * degree && angle < 7 * degree) return "W";
    if (angle >= 7 * degree && angle < 8 * degree) return "NW";
    //Should never happen:
    return "N";
  }

  checkLastResponseTime = (minTime) => {
    let posDate = this.props.device.lastUpdate;
    if (minTime) {
      return moment(posDate).isBefore(moment().subtract(10, "minutes"));
    } else if (this.props.details.serverTime) {
      posDate = this.props.details.serverTime;
      return (
        moment(posDate).isBefore(moment().subtract(10, "minutes")) &&
        !moment(this.props.device.lastUpdate).isBefore(
          moment().subtract(10, "minutes")
        )
      );
    }
  };

  openProtocol(input, protocol, inputName) {
    this.setState({ modal: true, input, protocol, inputName });
  }
  closeProtocol() {
    this.setState({ modal: false, input: "", protocol: "", inputName: "" });
  }

  getStatusText = () => {
    let p = this.props.details;
    const { stopTime, motion, parking, ignition } = (p && p.attributes) || {};

    if (p && p.attributes) {
      if (!moment(p.serverTime).isBefore(moment().subtract(10, "minutes"))) {
        // idling
        if (
          ignition &&
          this.props.device &&
          this.props.device.attributes &&
          parseInt(stopTime) >
            parseInt(this.props.device.attributes.minimalParkingDuration) * 1000
        ) {
          return this.props.translate("idling");
        } else if (
          (p.attributes.ignition && motion) ||
          (p.protocol === "osmand" && motion)
        ) {
          // moving
          return this.props.translate("moving");
        } else if (p.attributes.ignition !== true && motion) {
          // towing
          return this.props.translate("towing");
        } else if (parking) {
          return this.props.translate("parking");
        } else if (
          this.props.device &&
          this.props.device.attributes &&
          parseInt(stopTime) <
            parseInt(this.props.device.attributes.minimalParkingDuration) * 1000
        ) {
          return this.props.translate("stop");
        }
      }
    }
    return this.props.translate("unknown");
  };
  closePrevious = () => {
    win.close();
    win = null;
    this.showStreetView();
  };

  showStreetView = (item) => {
    if (win && win.closed === false) {
      this.closePrevious();
    } else {
      if (item && item.exists) {
        win = window.open(
          prepareStreeView(
            item.latitude,
            item.longitude
          ),
          "newWindow"
        );
      }
    }
  };

  render() {
    const emptyLinks = null;
    const position = this.props.details;
    let parking = 0;
    let stopTime = 0;
    let idling = 0;
    const { icons } = position;
  
    const { attributes,deviceAttributes} = this.props.details
     if (
      parseInt(attributes&&attributes.stopTime) >
      parseInt(deviceAttributes&&deviceAttributes?.minimalParkingDuration) * 1000
    ) {
      if (attributes.parking) {
        const ms = (attributes&&attributes.stopTime) -
          parseInt(deviceAttributes&&deviceAttributes.minimalParkingDuration) * 1000;
        parking = ms > 0 ? ms : 0;
        stopTime = ms < 0 ? attributes&&attributes.stopTime : 0;
      } else {
        const ms =
        attributes&&attributes.idlTime -
          parseInt(deviceAttributes&&deviceAttributes.minimalParkingDuration) * 1000;
        idling = ms > 0 ? ms : 0;
        stopTime = ms < 0 ? attributes&&attributes.idlTime : 0;
      }
    } else {
      stopTime = attributes&&attributes.stopTime;
    }
    if (this.props.type === "basicDetails") {
    let serverTimeZoneName = 'Asia/Dubai'

      if (
        this.props.logInUser &&
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
      ) {
        serverTimeZoneName = this.props.logInUser.attributes.timezone
      }

      let statusText = position.statusText;
      const givenTime = moment.tz(this.props.device&&this.props.device.lastUpdate, serverTimeZoneName).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const timeToShow = moment.tz(this.props.device&&this.props.device.lastUpdate, serverTimeZoneName).format('HH:mm:ss');
  
      const currentDateTime = moment().tz(serverTimeZoneName).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const diffInHours = moment(currentDateTime).diff(moment(givenTime), 'hours');
      const diff = diffInHours > 2

       // let driverFound = this.props.drivers.find(item=> item.uniqueId === position.attributes.driverUniqueId)
      let driverFound =  deviceAttributes&&deviceAttributes?.driverName ?  deviceAttributes&&deviceAttributes : attributes
       
      let latitude = position && position.latitude ? position.latitude : 0;
      let longitude = position && position.longitude ? position.longitude : 0;
      return (
        <div style={{display:"block", lineHeight:1.4, position:'relative',padding:"5px 0"}} className="feature-section alternate">
              <div style={{display:"flex", padding:"0 12px"}}>
                  <span className="row-label" style={{fontSize:12}}>ID - </span>
                  <span className="row-value" style={{fontSize:12}}>{this.props.device.uniqueId}</span>
              </div>
              <div style={{display:'flex', marginTop:10, padding:"0 12px" }}>
                  {diff ? 
                    <>
                        {this.props.translate("Unknown")} | {"--"}
                    </>
                    :<>
                    {icons?.playicon?.label === 'moving' ? <PlayIcon fill="#28a745" width={16} height={16} />  
                      : icons?.playicon?.label === 'parking'? <ParkingIcon fill="#3083EA" width={16} height={16} /> 
                      : icons?.playicon?.label === 'idling'? <IgnitionIcon fill="#ffbb54eb" width={18} height={18} /> 
                      : icons?.playicon?.label === 'stop'? <StopIcon fill="currentColor" width={18} height={18} /> 
                      : <OfflineIcon fill="currentColor" width={18} height={18} />  }
                    <div>
                      <span style={{fontSize:12,marginLeft:10,fontWeight:600,fontStyle:"italic"}}>{icons?.playicon?.label || ""}</span>
                      {icons?.playicon?.label === 'moving'? 
                          <span style={{marginLeft:10}}>{"Speed"}{"  "}{setAttributeFormat("speed", this.props.details.speed)}</span>
                          : icons?.playicon?.label === 'parking'?
                          <span style={{marginLeft:10}}>{"  "}{setAttributeFormat("hours", parking)}</span>
                          : icons?.playicon?.label === 'idling'?
                          <span style={{marginLeft:10}}>{"  "}{setAttributeFormat("hours",idling)}</span>
                          : icons?.playicon?.label === 'stop'?
                          <span style={{marginLeft:10}}>{"  "}{setAttributeFormat("hours", stopTime)}</span>
                          : ""}
                    </div> 
                    </> }
               </div>
              <div style={{marginTop:5,backgroundColor:'#F0F4F7', padding:"8px 16px", borderTopLeftRadius:8, borderTopRightRadius:8,display:"flex", justifyContent:"space-between"}}>
                  <div style={{display:"flex", alignItems:"center"}}>
                     <GroupIcon fill="currentColor" width="24" height="24" />
                    <div style={{marginLeft:10}} className="item-row">
                      <span className='row-label'>{this.props.translate('Name')}</span><br/>
                      <span className='row-value'>{driverFound?.driverName || "-"}</span>
                      </div>
                  </div>
                  <div style={{display:"flex", alignItems:"center"}}>
                     <CallIcon fill="currentColor" width="24" height="24" />
                    <div style={{marginLeft:10}} className="item-row">
                      <span className='row-label'>{this.props.translate('Phone')}</span><br/>
                      <span className='row-value'>{driverFound?.driverPhoneNumber || "-"}</span>
                      </div>
                  </div> 
              </div>
             
              <div className="feature-content-section" style={{padding:"0px 18px 8px", borderBottomLeftRadius:8, borderBottomRightRadius:8}} >
                <div>
                    <div className="item-row">
                      <span className="row-label"  >
                        {this.props.translate("address")}:
                      </span>
                      <span className="row-value" >
                        {position.address
                          ? ReactHtmlParser(`${position.address}`)
                          : null}
                      </span>
                    </div>
                      {longitude !== 0 && latitude !== 0 && (
                        <div className="item-row" style={{display:"flex"}}>
                          <span className="row-label">
                              <GpsIcon width={18} height={18} fill={"#000000"} />
                          </span>
                          <span className="row-label">
                            {setAttributeFormat("latitude", latitude)},
                            {setAttributeFormat("longitude", longitude)}
                            {
                              <button
                                onClick={(e) => this.props.copyToClipboard(position)}
                                className="button-to-normal"
                              >
                                 <Tooltip title="Copy">
                                <FileCopyIcon
                                  fill="currentColor"
                                  width="14"
                                  height="14"
                                />
                                </Tooltip>
                              </button>
                            }
                              <button
                                onClick={(e) => this.showStreetView(this.props.details)}
                                className="button-to-normal"
                                style={{background: "#FFFFFF",boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",borderRadius: 8, marginLeft:10, padding:"1px 11px"}}
                              >
                                <Tooltip title="Street View">
                                <StreetViewIcon
                                  fill="currentColor"
                                  width="14"
                                  height="14"
                                />
                                </Tooltip>
                              </button>
                          </span>
                        </div>
                      )} 
                </div>
                  {/* <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginTop:"5px"}}>
                    <div className="feature-box-Icons" onClick={(e) => this.props.copyToClipboard(position)} ><span><FileCopyIcon fill="currentColor" width="18" height="18" /></span></div>
                    <div className="feature-box-Icons" ><span><PinIcon fill="currentColor" width="18" height="18" /></span></div>
                    <div className="feature-box-Icons" ><span><OpenLinkIcon fill="currentColor" width="18" height="18" /></span></div>
                    <div className="feature-box-Icons" ><span><OpenLinkIcon fill="currentColor" width="18" height="18" /></span></div>
                  </div> */}
            </div>
                 
                 <div style={{padding:"10px 15px"}}>
                    <div style={{display:"flex"}}>
                      <span><TimeIcon  fill="currentColor" width="18" height="18"/></span>
                      <span style={{fontSize:12,margin:"0 10px"}}> {this.props.translate("trackerUpdated")} </span>{" | "}
                      <span style={{fontSize:12,fontStyle:"italic",margin:"0 10px"}}>
                        {this.props.device?.lastUpdate ? 
                          <TimeAgo
                            title=""
                            date={this.props.device.lastUpdate}
                            minPeriod={60}
                          />
                          :  
                          "--"
                        }
                         {/* {console.log("test=====",this.props.device )}
                    {console.log("position=====",position)} */}
                      </span>
                    </div>
                    {position.serverTime ? (
                      <div style={{display:"flex"}}>
                        <span><TimeIcon  fill="currentColor" width="18" height="18"/></span>
                        <span style={{fontSize:12,margin:"0 10px"}}> {this.props.translate("gpsUpdated")} </span>{" | "}
                        <span style={{fontSize:12,fontStyle:"italic",margin:"0 10px"}}> <TimeAgo title="" date={position.serverTime} minPeriod={60} /></span>
                      </div>
                    ) : null}
                 </div>       
        </div>
        // <div className="feature-section alternate">
          /*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/
          /* <div className="feature-content-section">
            <div className="item-row">
              <span className="row-label">{this.props.translate("id")}</span>
              <span className="row-value">{this.props.device.uniqueId}</span>
            </div>
            {this.props.device.phone ? (
              <div className="item-row">
                <span className="row-label">
                  {this.props.translate("sharedPhone")}
                </span>
                <span className="row-value">{this.props.device.phone}</span>
              </div>
            ) : null}

            {checkPrivileges("deviceModel") && (
              <div className="item-row">
                <span className="row-label">
                  {this.props.translate("deviceModel")}
                </span>
                <span className="row-value">
                  {(this.props.device && this.props.device.model) ||
                    (position && position.protocol)}
                </span>
              </div>
            )}
            <div className="item-row">
              <span className="row-label">
                {this.props.translate("deviceStatus")}
              </span>
              <span className="row-value">
                {statusText && this.props.translate("tracker." + statusText)}
              </span>
            </div>
            {this.props.device && this.props.device.lastUpdate ? (
              <div className="item-row">
                <span className="row-label">
                  {this.props.translate("trackerUpdated")}
                </span>
                <span className="row-value">
                  <TimeAgo
                    title=""
                    date={this.props.device.lastUpdate}
                    minPeriod={60}
                  />
                </span>
              </div>
            ) : (
              <div className="item-row">
                <span className="row-label">
                  {this.props.translate("trackerUpdated")}
                </span>
                <span className="row-value">
                  <TimeAgo
                    title=""
                    date={this.props.device.lastUpdate}
                    minPeriod={60}
                  />
                </span>
              </div>
            )}
            {position.serverTime ? (
              <div className="item-row">
                <span className="row-label">
                  {this.props.translate("gpsUpdated")}
                </span>
                <span className="row-value">
                  <TimeAgo title="" date={position.serverTime} minPeriod={60} />
                </span>
              </div>
            ) : null}
          </div>
        </div> */
      );
    } 
    // short map / street view
    // sensors
    // -> deviceTemp
    // -> adb3 = fuel level
    // -> adb0 = ignition
    // -> hdop = GDP Accuracy
    else if (this.props.type === "Location") {
      let parking = 0;
      let stopTime = 0;
      let idling = 0;

      if (
        position.attributes &&
        this.props.device.attributes &&
        position.attributes.stopTime &&
        this.props.device.attributes.minimalParkingDuration
      ) {
        if (
          parseInt(position.attributes.stopTime) >
          parseInt(this.props.device.attributes.minimalParkingDuration) * 1000
        ) {
          if (position.attributes.parking) {
            const ms =
              position.attributes.stopTime -
              parseInt(this.props.device.attributes.minimalParkingDuration) *
                1000;
            parking = ms > 0 ? ms : 0;
            stopTime = ms < 0 ? position.attributes.stopTime : 0;
          } else {
            const ms =
              position.attributes.idlTime -
              parseInt(this.props.device.attributes.minimalParkingDuration) *
                1000;
            idling = ms > 0 ? ms : 0;
            stopTime = ms < 0 ? position.attributes.idlTime : 0;
          }
        } else {
          stopTime = position.attributes.stopTime;
        }
      }
      let latitude = position && position.latitude ? position.latitude : 0;
      let longitude = position && position.longitude ? position.longitude : 0;

      let hdop =
        position.attributes && position.attributes.hdop
          ? position.attributes.hdop
          : 0;

      return (
        <>
          <div className="feature-section alternate">
            {position.exists ? (
              <div className="feature-content-section">
                <div className="item-row">
                  <span className="row-label">
                    {this.props.translate("address")}:
                  </span>
                  <span className="row-value">
                    {position.address
                      ? ReactHtmlParser(`${position.address}`)
                      : null}
                  </span>
                </div>

                {longitude !== 0 && latitude !== 0 && (
                  <div className="item-row">
                    <span className="row-label">
                      {this.props.translate("settingsCoordinates")}:
                    </span>
                    <span className="row-value">
                      {setAttributeFormat("latitude", latitude)},
                      {setAttributeFormat("longitude", longitude)}
                      {
                        <button
                          onClick={(e) => this.props.copyToClipboard(position)}
                          className="button-to-normal"
                        >
                          <FileCopyIcon
                            fill="currentColor"
                            width="14"
                            height="14"
                          />
                        </button>
                      }
                    </span>
                  </div>
                )}
                <div className="item-row">
                  <span className="row-label">
                    {this.props.translate("sensorsTranslation.speed")}:
                  </span>
                  <span className="row-value">
                    {setAttributeFormat("speed", position.speed || 0)}
                  </span>
                </div>

                <div className="item-row">
                  <span className="row-label">
                    {this.props.translate("altitude")}:
                  </span>
                  <span className="row-value">
                    {setAttributeFormat("altitude", position.altitude)}
                  </span>
                </div>
                <div className="item-row">
                  <span className="row-label">
                    {this.props.translate("direction")}:
                  </span>
                  <span className="row-value">
                    <span style={{ display: "inline-block" }}>
                      {setAttributeFormat("course", position.course || 0)}
                    </span>
                  </span>
                </div>
                {parking ? (
                  <div className="item-row">
                    <span className="row-label">
                      {this.props.translate("parking")}:
                    </span>
                    <span className="row-value">
                      {setAttributeFormat("hours", parking)}
                    </span>
                  </div>
                ) : null}
                {idling ? (
                  <div className="item-row">
                    <span className="row-label">
                      {this.props.translate("idling")}:
                    </span>
                    <span className="row-value">
                      {setAttributeFormat("hours", idling)}
                    </span>
                  </div>
                ) : null}
                {stopTime ? (
                  <div className="item-row">
                    <span className="row-label">
                      {this.props.translate("stopTime")}:
                    </span>
                    <span className="row-value">
                      {setAttributeFormat("hours", stopTime)}
                    </span>
                  </div>
                ) : null}

                {hdop ? (
                  <div className="item-row">
                    <span className="row-label">
                      {this.props.translate("hdop")}:
                    </span>
                    <span className="row-value">
                      {setAttributeFormat("hdop", hdop)}
                    </span>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="feature-content-section">{this.props.translate("Not Available")}</div>
            )}
            {this.state.modal === true ? (
              <ProtocolDialog
                protocol={this.state.protocol}
                input={this.state.input}
                inputName={this.state.inputName}
                onClose={this.closeProtocol}
              />
            ) : null}
          </div>
        </>
      );
    } else if (this.props.type === "Sensors") {
      let checkIgnition = position.attributes&&position.attributes.ignition ? "On" :'Off' 
      let checkImmobilizer = position.attributes&& position.attributes.immobilizer ?(position.attributes.immobilizer == 0 || position.attributes.immobilizer==false|| position.attributes.immobilizer=='no'|| position.attributes.immobilizer=='No'|| position.attributes.immobilizer=='NO') ?"Off": "On" : "Off"
       return (
        <Grid item xs={12}  style={{display:'flex', justifyContent:"space-around", background:"transparent", width:"100%"}}>
                <div style={{display:"block",textAlign:"center"}}>
                    <IgnitionSensorIcon width={18} height={18} />
                    <span style={{display:"block", fontSize:11, fontStyle:"italic"}}>{this.props.translate("ignition")}</span>
                    <span className='row-label' style={{fontSize:11}}>{checkIgnition}</span>
                </div>
                <div style={{display:"block",textAlign:"center"}}>
                    <PowerCutIcon width={18} height={18} />
                    <span style={{display:"block", fontSize:11, fontStyle:"italic"}}>{this.props.translate("immobilizer")}</span>
                    <span className='row-label' style={{fontSize:11}}>{checkImmobilizer}</span>
                </div>
                <div style={{display:"block",textAlign:"center"}}>
                    <BoardPowerIcon width={18} height={18} />
                    <span style={{display:"block", fontSize:11, fontStyle:"italic"}}>{this.props.translate("boardPower")}</span>
                    <span className='row-label' style={{fontSize:11}}>{position.attributes&&position.attributes.boardPower}</span>
                </div> 
        </Grid>
      );
    }  else if (this.props.type === "EVENTS") {
      return (
        <div style={{backgroundColor:"#F0F4F7", borderRadius:8, marginTop:3}}>
          <div style={{padding:"5px 15px"}}>
            <div style={{marginTop:10}}>
                  <div style={{display:"flex", justifyContent:"space-between", marginTop:5}}>
                      <div style={{display:"flex"}}>
                          <p style={{display:'flex', alignItems:"center", margin:0}}>
                            <SpeedMeterIcon height={18} width={18}/>
                             </p>
                          <p style={{paddingLeft:5,margin: 0}}>
                           <span style={{display:'block', fontSize:12}}>{this.props.translate('odometer')}</span>
                            <span style={{fontSize:12}}><DrawHTML word={this.state[this.state.displayOdo]} />{" KM"}</span>
                          </p>
                      </div>
                      
                      <div style={{display:"flex"}}>
                          <p style={{display:'flex', alignItems:"center", margin:0}}>
                            <IgnitionSensorIcon height={18} width={18}/>
                          </p>
                          <p  style={{paddingLeft:5,margin: 0}}>
                            <span style={{display:'block', fontSize:12}}>{this.props.translate('reportEngineHours')}</span>
                            <span style={{fontSize:12}}>{this.props.details && this.props.details.attributes && this.props.details.attributes.hours ? (this.props.details.attributes.hours / (1000 * 3600)).toFixed(2)+" hrs" : "--"}</span>
                          </p>
                      </div>

                  </div>
            </div>
            
            <h3 style={{padding:"8px 0 0 0", margin:0}}>{this.props.translate("event")}</h3>
            <div className="feature-content-section" style={{padding:"4px 0",borderTop:'1px solid #CFCFCF'}}>
              <div style={{ width: "100%" }}>
                {this.props.events && this.props.events.length ? (
                  this.props.events.map((ev) => (
                    <p key={ev.id + "" + ev.type} style={{margin:0, fontSize:12}}>
                      <span>{ev.name}{" "}</span>
                      <span style={{float:"right"}}><TimeAgo title="" date={ev.eventTime} /></span>
                      
                    </p>
                  ))
                ) : (
                  <>{this.props.translate("Not Available")}</>
                )}
              </div>
            </div>
            
          </div>
        </div>
      )
   }
    else if(this.props.type === "immobiliser"){
      let checkImmobilizer = position.attributes&& position.attributes.immobilizer ?(position.attributes.immobilizer == 0 || position.attributes.immobilizer==false|| position.attributes.immobilizer=='no'|| position.attributes.immobilizer=='No'|| position.attributes.immobilizer=='NO') ?false: true : false

      let box = "rgb(183 183 183) 0px 3px 6px"
      return (
        <>
        <div style={{paddingLeft:12, margin:"10px 0"}}>
            <p style={{margin:0, fontSize:14, fontWeight:"600"}}>{this.props.translate('immobilizer')} </p>
          <div style={{display:" flex",flexDirection: "row", marginTop:5}}>
              <div style={{background: "#FFFFFF",
                border: "1px solid",
                borderColor: checkImmobilizer ? "#FF700A" : "#000000",
                backgroundColor: checkImmobilizer ? "#FF700A" : "#ffffff",
                borderRadius:"8px",padding: "7px 22px", display:"flex", alignItems:'center',
                boxShadow:checkImmobilizer ?box : "", cursor:"pointer",
                }} onClick={()=>{this.props.sendCommandFun(this.props.device)}}>
                  <ImmobiliserIcon  fill={checkImmobilizer ? "#ffffff" : "#FF700A"} width="14" height="14"/>
                  <span style={{marginLeft:2, color:checkImmobilizer ? "#ffffff" : "#000000"}}>{this.props.translate('Immobilize')}</span>
              </div>
              <div style={{background: "#FFFFFF",
                border: "1px solid",
                backgroundColor: checkImmobilizer  ? "#ffffff" : "#FF700A",
                borderColor: checkImmobilizer  ? "#000000" : "#FF700A",
                borderRadius:"8px",padding: "7px 22px", marginLeft:5, display:"flex", alignItems:'center',
                boxShadow:checkImmobilizer  ?"" :box,cursor:"pointer",
                }} onClick={()=>{this.props.sendCommandFun(this.props.device)}}>
                  <ImmobiliserIcon  fill={checkImmobilizer  ? "#FF700A" : "#FFFFFF"}  width="14" height="14"/>
                  <span style={{marginLeft:2, color:checkImmobilizer  ? "#000000" : "#FFFFFF"}}>{this.props.translate('Mobilize')}</span>
              </div>
          </div>
        </div>
        
          {this.props.sendcommandDialog && 
            <Dialog
              open
              title={this.props.translate("sendCommand")}
              themeColors={this.props.themecolors}
              // disableFooter
              onClose={this.props.closeModel}
              dialogHeight={250}
              draggable
              bodyPadding={0}
            >
              <SendCommand
                id={this.props.device.id}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                descriptionList={this.props.list}
                closeModel={this.props.closeModel}
          />
          </Dialog>}
        
        
        </>
      )
    }
    else {
      return null;
    }
    
  }
}

export class DrawHTML extends PureComponent {
  render() {
    const s = "" + this.props.word;
    const cls = this.props.className || "letter";
    const p = s.split(" ");
    if (p.length) {
      const before = p[0].split("");

      let w = [
        before.map((c, i) => {
          return (
            <span key={i} className={cls + " " + cls + "-" + i}>
              {c}
            </span>
          );
        }),
      ];

      if (p[1]) {
        const after = p[1].split("");
        w.push(
          after.map((c, i) => {
            return (
              <span
                key={i}
                className={cls + " " + cls + "-alt " + cls + "-" + i}
              >
                {c}
              </span>
            );
          })
        );
      }

      return w;
    }
  }
}

export class DrawOutputHTML extends PureComponent {
  render() {
    const s = this.props.attributes || {};
    const cls = this.props.className || "device-input";
    //const p = s.split(".");
    let a = {};
    a.value0 = s.out0;
    a.value1 = s.out1;
    a.value2 = s.out2;
    a.value3 = s.out3;
    a.value4 = s.out4;
    a.value5 = s.out5;

    const html = [];

    for (let i = 0; i <= 3; i++) {
      html.push(
        <span
          key={i}
          className={
            (a["value" + i] ? cls + "-alt " : "") + cls + " " + cls + "-" + i
          }
        >
          {i + 1}
        </span>
      );
    }

    return html;
  }
}

const Wrapper = ({
  unknown,
  attribute,
  value,
  unit,
  customUnit,
  description,
  logInUserAttr,
  category,
  drivers,
  trailers,
  translate,
  driverDetails,
  driverOnly,
  trailerOnly,
}) => {
  let u = "";

  let trailer = null;
  if (
    attribute === "trailerUniqueId" &&
    trailers &&
    trailers.length &&
    parseInt(value)
  ) {
    let trailerName = "";
    trailers.map((d) => {
      if (value && d.uniqueId === value) {
        trailerName = d.name;
      }
      return null;
    });

    trailer = (
      <div className="item-row">
        <span style={{ marginLeft: 5 }} className="row-label">
          {translate && translate("trailersName")}:
        </span>
        <span className="row-value">{trailerName || "unknown"}</span>
      </div>
    );
  }

  let driver = null;
  if (
    attribute === "driverUniqueId" &&
    drivers &&
    drivers.length &&
    parseInt(value) &&
    driverDetails
  ) {
    let driverName = "";
    let driverPhone = "";
    drivers.map((d) => {
      if (value && d.uniqueId === value) {
        driverName = d.name;
        driverPhone = d.attributes.phone;
      }
      return null;
    });

    driver = (
      <>
        <div className="item-row">
          <span style={{ marginLeft: 5 }} className="row-label">
            {translate && translate("driverName")}:
          </span>
          <span className="row-value">{driverName || "unknown"}</span>
        </div>
        <div className="item-row">
          <span style={{ marginLeft: 5 }} className="row-label">
            {translate && translate("driverPhone")}:
          </span>
          <span className="row-value">{driverPhone || ""}</span>
        </div>
      </>
    );
  }
  if (attribute === "mileage" || attribute === "distance") {
    u = logInUserAttr.distanceUnit || customUnit || unit;
  } else if (attribute === "fuel") {
    u = logInUserAttr.volumeUnit || customUnit || unit;
  } else if (attribute === "speed") {
    u = logInUserAttr.speedUnit || customUnit || unit;
  } else {
    u = customUnit || unit;
  }
  /* if (unknown && unknown.length && unknown.indexOf(attribute) !== -1) {
    return (
      <div className='item-row'>
        <span className='row-label'>{description}</span>
        <span className='row-value'>Unknown</span>
      </div>
    )
  } else */ if (value !== undefined || attribute === "ignition") {
    return !trailerOnly && !driverOnly ? (
      <div className="item-row">
        {category === 0 ? (
          <ElogicIcon
            style={{ verticalAlign: "middle" }}
            fill="currentColor"
            width={16}
            height={16}
          />
        ) : category === 1 ? (
          <DiscreteIcon
            style={{ verticalAlign: "middle" }}
            fill="currentColor"
            width={16}
            height={16}
          />
        ) : category === 2 ? (
          <MeasurementIcon
            style={{ verticalAlign: "middle" }}
            fill="currentColor"
            width={16}
            height={16}
          />
        ) : category === 3 ? (
          <OtherSensorIcon
            style={{ verticalAlign: "middle" }}
            fill="currentColor"
            width={16}
            height={16}
          />
        ) : (
          <AggregateIcon
            style={{ verticalAlign: "middle" }}
            fill="currentColor"
            width={16}
            height={16}
          />
        )}
        <span className="row-label" style={{ marginLeft: 5 }}>
          {description}:
        </span>
        <span className="row-value">
          {setAttributeFormat(attribute, value, u, null, category)}
        </span>
      </div>
    ) : trailerOnly ? (
      trailer
    ) : (
      driver
    );
  } else return null;
};

export class DrawInput extends PureComponent {
  render() {
    const s = this.props.attributes || {};
    const cls = this.props.className || "device-input";
    //const p = s.split(".");
    let a = {};
    a.adc0 = s.adc0 || s.in0;
    a.adc1 = s.adc1 || s.in1;
    a.adc2 = s.adc2 || s.in2;
    a.adc3 = s.adc3 || s.in3;
    a.adc4 = s.adc4 || s.in4;
    a.adc5 = s.adc5 || s.in5;

    const html = [];

    for (let i = 0; i <= 5; i++) {
      html.push(
        <span
          key={i}
          className={
            (a["adc" + i] ? cls + "-alt " : "") + cls + " " + cls + "-" + i
          }
        >
          {i + 1}
        </span>
      );
    }

    return html;
  }
}

export const FeatureRow = connect(mapStateToProps)(Row);

const signalStrength = (att) => {
  let ssd = 0;
  if (att.signalStrength) {
    ssd = Math.round(att.signalStrength / 20);
  }
  let drawValue = [];
  for (let i = 1; i < 6; i++) {
    if (i <= ssd) {
      drawValue.push(
        <li class={"sg-" + i}>
          <div id={"sg-" + i} style={{ background: "white" }}></div>
        </li>
      );
    } else {
      drawValue.push(
        <li class={"sg-" + i}>
          <div id={"sg-" + i} style={{ background: "#04526666" }}></div>
        </li>
      );
    }
  }
  return (
    <ul id="signal-strength" style={{ padding: 0 }}>
      {drawValue}
    </ul>
  );
};
