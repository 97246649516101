import React, { Fragment, PureComponent } from 'react'
import Menu from './../Menu/CommonMenu'
import MenuItem from './../common/MenuItem'
import Checkbox from './../common/Checkbox'
import { withLocalize } from 'react-localize-redux'
import Icon from '@material-ui/core/Icon'
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreVertIcon from '@material-ui/icons/FilterList';
import { connect } from 'react-redux'
import './PositionMenu.scss'
import Tooltip from '@material-ui/core/Tooltip'
import Style from 'style-it'
import { applyDevicesFilter } from '../../Actions/Devices'
class OptionMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  UNSAFE_componentWillMount () {}

  showSelectedUnits (data) {
    const list = [...this.props.filterList]
    let newList = []
    if (list.includes(data)) {
      list.map(l => (l != data ? newList.push(l) : null))
    } else {
      newList = [...list].concat(data)
    }
    // console.log('new list ====', newList)
    this.props.dispatch(applyDevicesFilter(newList))
  }

  UNSAFE_componentWillReceiveProps (n) {}

  render () {
    const { anchorEl } = this.state
    const empty = null
    return (
      <Style>
        {`
      .main-menu-list-wrapper {
        background-color: ${this.props.themecolors.themeLightColor};
      }
      a {
        color: inherit
      }
      `}
        <div>
          <a
            href={empty}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup='true'
            onClick={this.handleClick}
          >
            <MoreVertIcon style={{fontSize: 26, marginRight: -8, verticalAlign: 'middle'}}/>
          </a>
         
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
            {this.props && this.props.menuSettings && this.props.menuSettings.map((option, index) => (
                <MenuItem
                  key={option.value}
                  selected={this.props.filterList.includes(option.value)}
                  component='label'
                  className='menu-item'
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid transparent",
                    backgroundColor: "white",
                    transition: "border-color 0.2s, background-color 0.2s",
                    borderColor: this.props.filterList.includes(option.value) ? "red" : "transparent", // set border color based on selected state
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.borderColor = `${this.props.themecolors.menuActiveBackground}`;
                    event.target.style.backgroundColor = "transparent";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.borderColor = this.props.filterList.includes(option.value) ? "red" : "transparent"; // set border color based on selected state
                    event.target.style.backgroundColor = "white";
                  }}
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   border: "1px solid transparent",
                  //   backgroundColor: "white",
                  //   transition: "border-color 0.2s, background-color 0.2s", // add a transition effect
                  // }}
                  // onMouseEnter={(event) => {
                  //   event.target.style.borderColor =` ${this.props.themecolors.menuActiveBackground}`;
                  //   event.target.style.backgroundColor = "transparent"; // set background color to transparent on hover
                  // }}
                  // onMouseLeave={(event) => {
                  //   event.target.style.borderColor = "transparent";
                  //   event.target.style.backgroundColor = "white"; // set background color back to white on hover out
                  // }}
                  // onMouseEnter={option.label}
                >
                  <div style={{ display: 'none' }}>
                    <Checkbox
                      onChange={e => this.showSelectedUnits(option.value)}
                      checked={this.props.filterList.includes(option.value)}
                      value='checkedA'
                      label={option.label}
                    
                    />
                  </div>
               <Tooltip title={this.props.translate(option.label)}>
                  <div> {option.icon} </div>
                </Tooltip>
                </MenuItem>
            ))}
          </Menu>
        </div>
      </Style>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  filterList:
    state.filterList && state.filterList.filters ? state.filterList.filters : []
})

export default connect(mapStateToProps)(withLocalize(OptionMenu))
