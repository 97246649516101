import React, { Component } from "react";
import { connect } from "react-redux";
import { EditItem } from "./EditItem";
import CustomDialog from "./../common/Dialog";
import { checkPrivileges, selectAll, errorHandler } from "./../../Helpers";
import { withLocalize } from "react-localize-redux";
import { withSnackbar } from "notistack";
import Loader from "../../Layout/Loader";
import isEqual from "react-fast-compare";
import { addDriver, updateDriver } from "./../../Actions/Drivers";
import {
  updateDeviceUserWiseSelectedList,
  RemoveDeviceUserWise,
  addDeviceUserWise,
} from "./../../Actions/Notifications";
import AddDrivers from "./addDrivers";
import "react-confirm-alert/src/react-confirm-alert.css";
import Notifications from "react-notification-system-redux";
import CommonTableSection from "./../../Pages/Units/Components/commonTableSection";
import instance from "../../axios";

const driverDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data.name.trim(),
  uniqueId: data.uniqueId.trim(),
  expirationTime: data.expirationTime || "",
});

const formDefault = {
  name: "",
  uniqueId: "",
  attributes: {},
  driverAddress: "",
};

class driversModal extends Component {
  constructor() {
    super();
    this.state = {
      dm_isVisable: false,
      selectedDriver: "",
      addOption: false,
      editOption: false,
      dr_attriubtes: "",
      isVisableUserBtn: false,
      isAttChanged: false,
      isVisableTrackerModal: false,
      driverId: "",
      form: formDefault,
      itemAttributes: {},
      attributeChangesMessage: "",
      removedIdPropcess: "",
      imagesData: "",
    };
    this.ad_submit = this.ad_submit.bind(this);
    this.onCloseSubModal = this.onCloseSubModal.bind(this);
    this.ud_submit = this.ud_submit.bind(this);
    this.imageDataPrepare = this.imageDataPrepare.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.activeOperation === "") {
      this.setState({
        editOption: false,
        addOption: false,
      });
    }
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(this.props.selecteditem);
      let attributes = this.props.selecteditem.attributes;
      let trackers = this.props.multiTrackers;
      formData = { ...formData, attributes, trackers };
      this.setState({
        selectedDriver: formData,
        driverId: this.props.selecteditem.id,
      });
    } else {
      if (this.props.activeOperation === "edit") {
        let formData = this.setFormData(this.props.selecteditem);
        let attributes = this.props.selecteditem.attributes;
        formData = { ...formData, attributes };
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedDriver, formData)
        ) {
          this.setState(
            {
              driverId: this.props.selecteditem.id,
              selectedDriver: formData,
              itemAttributes: this.props.selecteditem.attributes,
              editOption: true,
              addOption: false,
              dm_isVisable: true,
              attributeChangesMessage: "",
              isVisableUserBtn: false,
              isAttChanged: false,
              dr_attriubtes: this.props.selecteditem.attributes,
              form: { ...formData },
            },
            () => this.checkRequiredFields()
          );
        }
      }
      if (this.props.activeOperation === "add" && !this.state.addOption) {
        this.setState({
          driverId: this.props.selecteditem.id,
          selectedDriver: "",
          addOption: true,
          editOption: false,
          dm_isVisable: true,
          form: formDefault,
          isVisableUserBtn: false,
          isAttChanged: false,
          attributeChangesMessage: "",
        });
      }
    }
    if (
      this.props.selectedAddress !== "" &&
      this.props.selectedAddress !== undefined
    ) {
      this.setState({
        form: {
          ...this.state.form,
          driverAddress: this.props.selectedAddress,
        },
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.selecteditem.id !== this.state.driverId) {
      if (nextprops.showItemDetail && nextprops.trackersApiResponce) {
        let formData = this.setFormData(nextprops.selecteditem);
        let attributes = nextprops.selecteditem.attributes;
        let trackers = nextprops.multiTrackers;
        formData = { ...formData, attributes, trackers };
        this.setState({
          selectedDriver: formData,
          driverId: nextprops.selecteditem.id,
        });
      } else {
        if (nextprops.activeOperation === "edit") {
          let formData = this.setFormData(nextprops.selecteditem);
          let attributes = nextprops.selecteditem.attributes;
          formData = { ...formData, attributes };
          if (
            !this.state.editOption ||
            !isEqual(this.state.selectedDriver, formData)
          ) {
            this.setState(
              {
                driverId: nextprops.selecteditem.id,
                selectedDriver: formData,
                editOption: true,
                addOption: false,
                dm_isVisable: true,
                attributeChangesMessage: "",
                isVisableUserBtn: false,
                isAttChanged: false,
                itemAttributes: nextprops.selecteditem.attributes,
                dr_attriubtes: nextprops.selecteditem.attributes,
                form: { ...formData },
              },
              () => this.checkRequiredFields()
            );
          }
        }
        if (nextprops.activeOperation === "add" && !this.state.addOption) {
          this.setState(
            {
              driverId: nextprops.selecteditem.id,
              selectedDriver: "",
              addOption: true,
              editOption: false,
              dm_isVisable: true,
              form: formDefault,
              isVisableUserBtn: false,
              isAttChanged: false,
              attributeChangesMessage: "",
            },
            () => this.checkRequiredFields()
          );
          if (
            nextprops.selectedAddress !== "" &&
            nextprops.selectedAddress !== undefined
          ) {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  driverAddress: nextprops.selectedAddress,
                },
              },
              () => this.checkRequiredFields()
            );
          }
        }
      }
    }
    if (
      nextprops.selectedAddress !== "" &&
      nextprops.selectedAddress !== undefined
    ) {
      this.setState(
        {
          form: {
            ...this.state.form,
            driverAddress: nextprops.selectedAddress,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  }
  setFormData = (data) => {
    let formData = {
      name: data.name,
      uniqueId: data.uniqueId,
      tracker: data.tracker,
      expirationTime: data.expirationTime || "",
      identityNumber: data.attributes.identityNumber || "",
      dateOfBirthGregorian: data.attributes.dateOfBirthGregorian || "",
      driverAddress: data.attributes.driverAddress || "",
      email: data.attributes.email || "",
      licenseClass: data.attributes.licenseClass || "",
      licenseNumb: data.attributes.licenseNumb || "",
      licenseExpDate: data.attributes.licenseExpDate || "",
      phone: data.attributes.phone || "",
      department: data.attributes.department || "",
      driverImg: data.attributes.driverImg || "",
    };
    return formData;
  };

  submitDataPepration = () => {
    const { form } = this.state;
    const att = [
      "dateOfBirthGregorian",
      "identityNumber",
      "tag_1",
      "tag_2",
      "tag_3",
      "tag_4",
      "tag_5",
      "email",
      "licenseClass",
      "licenseNumb",
      "licenseExpDate",
      "phone",
      "department",
      "driverImg",
    ];
    const attributes = {};
    att.map((key) => {
      if (form[key]) {
        attributes[key] = form[key];
      }
      return null;
    });
    if (this.props.driverLat) {
      attributes.driverLat = this.props.driverLat;
    }
    if (this.props.driverLon) {
      attributes.driverLon = this.props.driverLon;
    }
    if (form.driverAddress) {
      attributes.driverAddress = form.driverAddress
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }
    return attributes;
  };

  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false,
      enableImagePopup: false,
    });
  };

  imageDataPrepare() {
    const { waslEnabled } = this.props?.serversettings?.attributes || {};
    if (waslEnabled) {
      this.setState({
        waslStatus: (
          <span className="loading-status-text">
            {" "}
            <LoadingIndicator /> Processing
          </span>
        ),
      });
    }
    this.setState({
      driverStatus: (
        <span className="loading-status-text">
          <LoadingIndicator /> Processing
        </span>
      ),
    });
    let { imagesData } = this.state;
    if (imagesData) {
      instance({
        url: `/api/uploads`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...imagesData,
        },
      })
        .then((publicUrl) => {
          this.setState(
            {
              form: {
                ...this.state.form,
                driverImg: publicUrl.publicUrl,
              },
            },
            () => {
              if (this.state.editOption) {
                this.ud_submit(this.state.driverId);
              } else {
                this.ud_submit();
              }
            }
          );
          //   })
          // } else {
          //   //this.props.enqueueSnackbar(this.props.translate('somthingWentWrongMessage'));
          //   this.props.dispatch(
          //     Notifications.error({
          //       message: this.props.translate('somthingWentWrongMessage'),
          //       autoDismiss: 10
          //     })
          //   )
          //     throw response
          //   }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    } else {
      if (this.state.editOption) {
        this.ud_submit(this.state.driverId);
      } else {
        this.ud_submit();
      }
    }
  }
  ad_submit() {
    let { form } = this.state;
    let preperData = {
      name: form.name,
      uniqueId: form.uniqueId,
      expirationTime: form.expirationTime,
    };
    if (
      form.name &&
      form.name.trim() &&
      form.uniqueId &&
      form.uniqueId.trim()
    ) {
      let attriubtes = this.submitDataPepration();
      const obj = driverDataFormat(preperData, attriubtes);
      this.setState({
        isVisableUserBtn: false,
      });
      instance({
        url: `/api/drivers`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        // fetch("/api/drivers", {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     ...obj,
        //   }),
        // });
        // .then((response) => {
        //   if (response.ok) {
        //     response.json().
        .then((driver) => {
          this.props.dispatch(addDriver(driver));
          this.props.onCloseModal();
          this.props.fetchMoreItems();
          this.setState({
            dm_isVisable: !this.state.dm_isVisable,
            addOption: false,
            dr_attriubtes: "",
            selectedDriver: "",
            form: formDefault,
            attributeChangesMessage: "",
            imagesData: "",
          });
          //this.props.enqueueSnackbar(this.props.translate('driverIsCreated'));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("driverIsCreated"),
              autoDismiss: 10,
            })
          );
        })
        // } else {
        //   throw response;
        // }
        // })
        .catch((e) => {
          if (e && e.text) {
            e.text().then((err) => {
              if (err.includes("Duplicate entry")) {
                //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate("uniqueIdIsAllreadyInUsed"),
                    autoDismiss: 10,
                  })
                );
              } else {
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate("somthingWentWrongMessage"),
                    autoDismiss: 10,
                  })
                );
              }
            });
          }
        });
    } else {
      //this.props.enqueueSnackbar(this.props.translate('emptyField'));
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("emptyField"),
          autoDismiss: 10,
        })
      );
    }
  }
  ud_submit() {
    const { form } = this.state;
    let preperData = {
      name: form.name,
      uniqueId: form.uniqueId,
      expirationTime: form.expirationTime,
    };

    let attriubtes = this.submitDataPepration();
    const obj = driverDataFormat(preperData, attriubtes, this.state.driverId);

    delete obj.attributes.lat;
    delete obj.attributes.lng;
    delete obj.attributes.driverLng;

    if (!obj.attributes.tag_1) delete obj.attributes.tag_1;
    if (!obj.attributes.tag_2) delete obj.attributes.tag_2;
    if (!obj.attributes.tag_3) delete obj.attributes.tag_3;
    if (!obj.attributes.tag_4) delete obj.attributes.tag_4;
    if (!obj.attributes.tag_5) delete obj.attributes.tag_5;

    let url ;
    let method ;

    if(this.state.driverId){
      url=`/api/drivers/${this.state.driverId}`
      method=`PUT`
    }else{
      url=`/api/drivers`
      method="POST"
    }
    
    instance({
      url: url,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      // fetch(`/api/drivers/${this.state.driverId}`, {
      //   method: "PUT",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     ...obj,
      //   }),
      // })
      // .then((response) => {
      //   if (response.ok) {
      //     response.json()
      .then((driver) => {
        // this.props.dispatch(updateDriver(driver));
        this.props.onCloseModal();
        this.props.fetchMoreItems();
        //this.props.enqueueSnackbar(this.props.translate('driverIsUpdated'));
        if(this.state.driverId)  
       { this.props.dispatch(
          Notifications.success({
            message: this.props.translate("driverIsUpdated"),
            autoDismiss: 10,
          })
        ) }
        else{
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("driverIsCreated"),
            autoDismiss: 10,
          })
        )
        }

        this.setState({
          itemAttributes: driver.attributes,
          dr_attriubtes: driver.attributes,
          dm_isVisable: !this.state.dm_isVisable,
          selectedDriver: driver,
          attributeChangesMessage: "",
          isVisableUserBtn: false,
          isAttChanged: false,
          imagesData: "",
        });
      })
      //   } else {
      //     throw response;
      //   }
      // })
      .catch((e) => {
        if (e && e.text) {
          e.text().then((err) => {
            if (err.includes("Duplicate entry")) {
              //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate("uniqueIdIsAllreadyInUsed"),
                  autoDismiss: 10,
                })
              );
            } else {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate("somthingWentWrongMessage"),
                  autoDismiss: 10,
                })
              );
            }
          });
        }
      });
  }
  onCloseSubModal() {
    this.setState({
      dm_isVisable: false,
      addOption: false,
      editOption: false,
    });
    this.props.onCloseModal();
  }

  changeAttribute(name, value, selectedOption, updateAtt) {
    if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
      this.setState(
        {
          dr_attriubtes: { ...updateAtt },
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      );
    } else {
      this.setState(
        {
          dr_attriubtes: {
            ...this.state.selectedDriver.attributes,
            [name]: value,
          },
        },
        () => this.checkForAttrubutesChanged()
      );
    }
  }

  checkForAttrubutesChanged = (selectedOption) => {
    let changed = false;
    if (this.state.selectedDriver) {
      changed = !isEqual(
        this.state.dr_attriubtes,
        this.state.selectedDriver.attributes
      );
    } else {
      changed = !this.isEmpty(this.state.dr_attriubtes);
    }
    let { name, uniqueId } = this.state.form;
    let selectedDriver = {};
    if ((name && uniqueId) || (name && uniqueId && changed)) {
      selectedDriver = this.state.dr_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedDriver },
        });
      } else {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedDriver },
        });
      }
    } else {
      selectedDriver = this.state.dr_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState(
          {
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedDriver },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedDriver },
          },
          () => this.checkRequiredFields()
        );
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: "Change in attributes is pending",
      });
    } else {
      this.setState(
        {
          attributeChangesMessage: "",
        },
        () => this.checkRequiredFields()
      );
    }
  };
  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  handleChange = (name) => (event) => {
    const { target } = event;

    let value = "";

    if (
      name === "licenseExpDate" ||
      name === "dateOfBirthGregorian" ||
      name === "expirationTime"
    ) {
      value = event.toISOString();
    } else {
      value = target.value;
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  checkRequiredFields() {
    let { name, uniqueId } = this.state.form;
    let value = !isEqual(this.state.form, this.state.selectedDriver);
    if (name && uniqueId && value) {
      this.setState({
        isVisableUserBtn: true,
      });
    } else {
      this.setState({
        isVisableUserBtn: this.state.isAttChanged,
      });
    }
  }
  setExpirationTime = (e, name) => {
    e.stopPropagation();
    if (name === "licenseExpDate") {
      this.setState(
        {
          form: {
            ...this.state.form,
            licenseExpDate: null,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "expirationTime") {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  uploadImage = (event) => {
    let files = event.target.files;
    let imgData = "";
    let reader = new FileReader();
    if (files && files[0]) {
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        imgData = e.target.result;
        if (imgData.split(":")[1].split("/")[0] === "image") {
          let obj = {
            name: `${this.state.form.uniqueId + new Date().getTime()}`,
            contentType: `${files[0].type}`,
            oldUrl: `${this.state.form.driverImg}`,
            data: `${imgData.split("base64")[1].substring(1)}`,
          };
          this.setState(
            {
              imagesData: obj,
              form: {
                ...this.state.form,
                driverImg: imgData,
              },
            },
            () => {
              let { name, uniqueId } = this.state.form;
              if (name && uniqueId) {
                this.setState({
                  isVisableUserBtn: true,
                });
              } else {
                this.setState({
                  isVisableUserBtn: this.state.isAttChanged,
                });
              }
            }
          );
        } else {
          //this.props.enqueueSnackbar(this.props.translate('onlyAcceptImage'));
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("onlyAcceptImage"),
              autoDismiss: 10,
            })
          );
        }
      };
    } else {
      this.setState(
        {
          imagesData: "",
          form: {
            ...this.state.form,
            driverImg: this.props.selecteditem.attributes
              ? this.props.selecteditem.attributes.driverImg
              : "",
          },
        },
        () => {
          this.setState({
            isVisableUserBtn: this.state.isAttChanged,
          });
        }
      );
    }
  };
  imagePopup = () => {
    this.setState({
      enableImagePopup: true,
    });
  };

  unit_Selection = (checked, item) => {
    let method = "DELETE";
    let value = checked;
    let allData = [...this.props.selecteddevices];
    let obj = {
      deviceId: item.id,
      driverId: this.props.selecteditem.id,
    };
    if (value) {
      method = "POST";
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateDeviceUserWiseSelectedList(item));
          this.props.dispatch(RemoveDeviceUserWise(item));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("trackerIsUnAssignedSuccessfully"),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateDeviceUserWiseSelectedList(item));
          this.props.dispatch(addDeviceUserWise(item));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("trackerIsAssignedSuccessfully"),
              autoDismiss: 10,
            })
          );
        }
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  render() {
    const { selecteddevices } = this.props;
    return (
      <>
        {this.state.dm_isVisable && this.state.addOption && (
          <AddDrivers
            onClose={this.onCloseSubModal}
            selectedDrivers={this.state.selectedDriver}
            formSubmit={this.imageDataPrepare}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            buttonText="sharedCreate"
            translate={this.props.translate}
            isVisableUserBtn={this.state.isVisableUserBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            isHaveAccess="driverCreate"
            uploadImage={this.uploadImage}
            imagePopup={this.imagePopup}
          />
        )}
        {this.state.selectedDriver && this.state.editOption && (
          <AddDrivers
            onClose={this.onCloseSubModal}
            selectedDrivers={this.state.selectedDriver}
            formSubmit={this.imageDataPrepare}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            buttonText="update"
            translate={this.props.translate}
            isVisableUserBtn={this.state.isVisableUserBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            isHaveAccess="driverUpdate"
            uploadImage={this.uploadImage}
            imagePopup={this.imagePopup}
          />
        )}
        {this.state.selectedDriver &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            devices={this.props.devices}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedDriver}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
        {this.state.isVisableTrackerModal && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable
            title={this.props.translate("deviceTitle")}
            onClose={this.onCloseModal}
            isButtonVisable
            bodyPadding={0}
          >
            <div style={{ minHeight: 200 }}>
              <CommonTableSection
                component_Selection={this.unit_Selection}
                themecolors={this.props.themecolors}
                componentData={selecteddevices}
                showCheckbox
                onDelete={this.onRemovedDevice}
                label={this.props.translate("sensorUnits")}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                canUpdate={false}
                rowDefinition={[
                  {
                    id: "label",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("sensorUnits"),
                  },
                ]}
                canDelete={checkPrivileges("deviceDelete")}
                canRemove={checkPrivileges("deviceLinkDriver")}
                canAssign={checkPrivileges("deviceUnlinkDriver")}
                selectAll={(e) =>
                  selectAll(
                    e,
                    "deviceId",
                    "driverId",
                    this.props.selecteditem.id,
                    this.props.selecteddevices,
                    this.props.devices2,
                    this.props.dispatch,
                    "deviceAlt",
                    this.props.translate
                  )
                }
              />
            </div>
          </CustomDialog>
        )}
        {this.state.enableImagePopup && this.state.form.driverImg && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            bodyPadding={10}
            cancelText={this.props.translate("sharedCancel")}
            noFullScreen
          >
            <div style={{ minHeight: 200, margin: 0, textAlign: "center" }}>
              <img
                style={{ width: 500, height: 400 }}
                alt="driverImage"
                src={this.state.form.driverImg}
              />
            </div>
          </CustomDialog>
        )}
      </>
    );
  }
}

const mapState = (state) => ({
  devices2: state.devices2,
  drivers: state.drivers,
  devices: state.devices.data,
  themecolors: state.themeColors,
  selecteddevices: state.selecteddevices,
});
const mapStateToProps = connect(mapState);
export const DriversModal = mapStateToProps(
  withSnackbar(withLocalize(driversModal))
);
const LoadingIndicator = () => {
  return (
    <span className="status-icon">
      <span className="loading-dot" style={{ animationDelay: "0ms" }} />
      <span className="loading-dot" style={{ animationDelay: "160ms" }} />
      <span className="loading-dot" style={{ animationDelay: "320ms" }} />
    </span>
  );
};
