export const themes  = {
  "lite_1": {
    id: 'lite_1',
    name: 'Theme lite_1',
    themeDarkColor: "#95c5fe",
    themeInverse: "#ffffff",
    themeLightColor: "#217ed8",
    themeLightInverse: "#ffffff",
    themeListingColor: "#efeffb",
    themeListingInverse: "#27272e",
    themeListingActiveColor: "#409CF7",
    themeListingActiveInverse: "#ffffff",
    backgroundColor: "#F6F6F6",
    textColor: "#000",
    dashboardBackgroundColor: "#ffffff",
    tableBorderColor: "#D4D4D6",
    InputBorderColor: "#222C3C88",
    menuActiveColor: "#ffffff",
    menuActiveBackground: "#ed3a3a",
    menuBackgroundColor: "#fbfbff",
    menuTextColor: "#27272e",
    tabBgColor: "#ffffff",
    tabTextColor: "#204B70",
    error: "#ff424c",
    success:'#4BB543',
  },
  "lite_2": {
    id: 'lite_2',
    name: 'Theme lite_2',
    themeDarkColor: "#9597B2",
    themeInverse: "#ffffff",
    themeLightColor: "#409CF7",
    themeLightInverse: "#ffffff",
    themeListingColor: "#1A374D",
    themeListingInverse: "#ffffff",
    themeListingActiveColor: "#2F455C",
    themeListingActiveInverse: "#ffffff",
    backgroundColor: "#2F455C",
    textColor: "#ffffff",
    dashboardBackgroundColor: "#2F455C",
    tableBorderColor: "#ffffff",
    InputBorderColor: "#ffffff88",
    menuActiveColor: "#ffffff",
    menuActiveBackground: "#409CF7",
    menuBackgroundColor: "#1A374D",
    menuTextColor: "#ffffff",
    tabBgColor: "#2F455C",
    tabTextColor: "#ffffff",
    error: "#ff424c",
    success:'#4BB543',
  }
}
export const themeColorsArray = (state = [], action) => {
  let a = Object.keys(themes);
  return a.map(b => { return {'color': themes[b]['themeDarkColor'], name: themes[b]['name'], id: b}})
}
export const themeColors = (state = {...themes['lite_1']}, action) => {
  switch (action.type) {
    case 'fetchThemeColors':    
      return themes['lite_1']
    
    case 'UPDATE_THEME':
      return themes[action.payload] || themes['lite_1']

    case 'MODIFY_THEME':
      return action.payload ||  state
    default:
      return state
  }
}