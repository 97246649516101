import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { checkPrivileges } from '../../../Helpers'
import { ReactComponent as Clip } from '../../../assets/nav/clip.svg'
import Loader from './../../Loader'
import AddRecourcesGroup from '../../../Components/Recources/AddRecourcesGroup';
import Collapse from "@material-ui/core/Collapse"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ChevronRight"

import { Translate } from 'react-localize-redux';
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag';
import Notifications from 'react-notification-system-redux';
class RecourcesList extends Component {
    constructor(props) {
        super(props);
        this.state={
          items: {}
        }
    }



    render() {
      
      const endMessage =
        this.props.resources && this.props.resources.total > 0 ? null : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
        
        return (
            <div>
               <div className='sidebar-users-list'>
            <Scrollbar
              disableTracksWidthCompensation
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableVehicle' />
                  )
                }
              }}
            >
              {checkPrivileges('itemgroup') && this.props.resources && this.props.resources.loading ? <p style={{ color: '#ccc' }}><Loader defaultStyle={true} /></p> : (
                <InfiniteScroll
                  dataLength={this.props.resources.data.length}
                  next={this.props.nextResources}
                  // hasMore={this.props.itemPagination.hasNext}
                  // loader={<Loader defaultStyle={false} />}
                  scrollableTarget='scrollableVehicle'
                  endMessage={endMessage}
                >
                <div className="group-view list-row-condest">
                  <ul className='list-view'>
                      <ListRow
                        editOption={this.state.editOption}
                        {...this.props}
                      />
                      </ul>
                </div>
                </InfiniteScroll>
               )}
            </Scrollbar>
          </div>
            </div>
        );
    }
}
class ListRow1 extends Component {
    constructor(props) {
      super(props);
      this.state = {collapseId: 0}
    }
    collapseGroup = collapseId => {
      if(this.state.collapseId !== collapseId) {
        this.setState({collapseId}, () => {
          this.props.fetchNestedItems(collapseId, 1)
        })
      }
      else {
        this.setState({collapseId: 0});
      }
    }
    confirmDelete = deleteSelection => {
      this.setState({openConfirm: true, deleteSelection})
    }

    resetDelete = () => {
      this.setState({openConfirm: false, deleteSelection: ''})
    }

    deleteNow = () => {
      if(this.state.deleteSelection) {
        this.props.deleteResource(this.state.deleteSelection.id, (response) => {
          if(response.state === 200) {
            this.props.dispatch(Notifications.success({
              message: <Translate id='groupDeleted' />,
              autoDismiss: 10
            }));
          }
          this.resetDelete();
        });
      }
    }

    render () {
      const { ItemChild, ItemChildProps, fromArray, exact, itemType} = this.props;
        return <>
        {this.state.openConfirm && <ConfirmDialoag onCancel={this.resetDelete} onOk={this.deleteNow} title={<Translate id="areYouWantToDelete" />}>
        {this.state.deleteSelection.name}
        </ConfirmDialoag>}
        {this.props.resources.data.map(item =><li>
        <div className='clearfix list-row'>
          
            <label className='checkbox' onClick={e => this.collapseGroup(item.id)} >
                <span
                className='unit-name'
                style={{ minWidth: 200, maxWidth: 200, marginLeft: 0 }}
                >
                {item.id === this.state.collapseId ? <ExpandMoreIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} /> : <ExpandLessIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} />}
                {item.name}
                </span>
            </label>
              <div className='pull-right'>
                {checkPrivileges('itemgroupDelete') && (
                    <span
                    className='action-item hovered'
                    title={this.props.translate('delete')}
                      onClick={() => this.confirmDelete(item)}
                    >
                    <DeleteIcon style={{fontSize: 16}}/>
                    </span>
                )}
                {checkPrivileges('itemgroupUpdate') && !this.props.disableEdit && (
                  <span
                    className='action-item hovered'
                    title={this.props.translate('edit')}
                    onClick={() => this.props.onEditResource(item)}
                  >
                    <EditIcon style={{fontSize: 16}}/>
                  </span>
                )}
                {checkPrivileges('itemgroupLinkItem') && (
                  <span
                    className='action-item hovered'
                    title={this.props.translate('edit')}
                    onClick={() => this.props.onLinkResource(item)}
                  >
                    <Clip fill="currentColor" style={{fontSize: 16}}/>
                  </span>
                )}
              </div>
          </div>
          <Collapse style={{display: 'block'}} in={item.id === this.state.collapseId} timeout='auto' unmountOnExit>
          <div style={{height: 200}}>
            {this.props.nestedResources && this.props.nestedResources.loading && item.id === this.state.collapseId ? <p style={{ color: '#ccc' }}><Loader defaultStyle={true} /></p> : null}
            {this.props.nestedResources && !this.props.nestedResources.loading ? <Scrollbar disableTracksWidthCompensation>
              {itemType === 'Device' ? (this.props.nestedResources.total > 0 && fromArray && fromArray.length ? <ul className="list-view list-view-children">{fromArray.map(e => <ItemChild item={this.props.nestedResources.data.find(row => row.id === e.id) ? this.props.nestedResources.data.find(row => row.id === e.id) : (exact ? null: e)} {...ItemChildProps} />)}</ul> : <><p style={{ textAlign: 'center', color: '#ccc' }}>
              <Translate id='notFound' />
            </p></>) : null}
              {itemType !== 'Device' ? (this.props.nestedResources.total > 0 ? this.props.nestedResources.data.map(e => <ItemChild item={fromArray && fromArray.find(row => row.id === e.id) ? fromArray.find(row => row.id === e.id) : (exact ? null: e)} {...ItemChildProps} />) : <><p style={{ textAlign: 'center', color: '#ccc' }}>
              <Translate id='notFound' />
            </p></>) : null}
            </Scrollbar> : null}
          </div>
          </Collapse>
         </li>
        )}</>
    }
  }
export const ListRow = (ListRow1)
export default RecourcesList;