import React from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@material-ui/core/Grid'
import Button from '../../../Components/common/Button'
import Checkbox from '../../../Components/common/Checkbox'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {Tooltip} from '@material-ui/core';
import SingleSelect from '../../../Components/common/SingleSelect'
import { ReactComponent as SendIcon } from '../../../assets/nav/send.svg'

export default function AdvanceForm (props) {
  const { unitParkingAttributesform } = props
  let selectedlang = props.languages.find(id => id.code===unitParkingAttributesform.lang)
  return (
    <div>

      <div style={{  padding: '16px 0px 0px 0px' }} className='clearfix'>
        <h4 className="all-attributes-heading" style={{margin:"5px 0", fontSize:16}}>{props.translate('tripDetection')}</h4>
      </div>

      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
              <TextField
                id='useIgnition'
                label={props.translate('movementDetection')}
                type='text'
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.useIgnition) ||
                  '2'
                }
                onChange={props.handleChange('useIgnition')}
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value='2'>{props.translate('gpsSpeed')}</MenuItem>
                <MenuItem value='1'>
                  {props.translate('engineIgnitionSensor')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalNoDataDuration')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalNoDataDuration !== undefined
                    ? unitParkingAttributesform.minimalNoDataDuration
                    : ''
                }
                onChange={props.handleChange('minimalNoDataDuration')}
                id='minimalNoDataDuration'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
             {/* { console.log("unitParkingAttributesform====", unitParkingAttributesform)} */}
              <TextField
                type='number'
                label={props.translate('minimalParkingDuration')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalParkingDuration !== undefined
                    ? unitParkingAttributesform.minimalParkingDuration
                    : ''
                }
                onChange={props.handleChange('minimalParkingDuration')}
                id='minimalParkingDuration'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalTripDuration')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalTripDuration !== undefined
                    ? unitParkingAttributesform.minimalTripDuration
                    : ''
                }
                onChange={props.handleChange('minimalTripDuration')}
                id='minimalTripDuration'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
              <TextField
                type='number'
                label={
                  props.translate('minimalTripDistance') + ' (meter)'
                }
                id='minimalTripDistance'
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.minimalTripDistance) !== undefined
                    ? unitParkingAttributesform.minimalTripDistance
                    : ''
                }
                onChange={props.handleChange('minimalTripDistance')}
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
              <TextField
                type='number'
                label={
                  props.translate('speedThreshold') +
                  '(' +
                  ((localStorage.getItem('userInfoFront') &&
                    JSON.parse(localStorage.getItem('userInfoFront')).attributes
                      .speedUnit) ||
                    'kn') +
                  ')'
                }
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.speedThreshold) ||
                  ''
                }
                onChange={props.handleChange('speedThreshold')}
                id='speedThreshold'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={6} xl={6} xs={12}>
              <Checkbox
                id='processInvalidPositions'
                onChange={props.handleChange('processInvalidPositions')}
                checked={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.processInvalidPositions) ||
                  false
                }
                label={props.translate('processInvalidPositions')}
              />
            </Grid>
          </Grid>
        </div>
      </div>

    {!props.monitoring && (<>
      <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left'>
          <SendIcon width={14} height={14} style={{marginRight: 10, verticalAlign: 'middle'}} />
          {props.translate('geoCoding')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={5} sm={6}>
              <SingleSelect
                    array={props.languages.map(l => ({...l, id: l.code, key: l.code, value: l.code, name: l.name}))}
                    selectName='lang'
                    label={props.translate('loginLanguage')}
                    value={selectedlang ? 
                      {active:false,
                      code:selectedlang.code,
                      id:selectedlang.code,
                      key:selectedlang.code,
                      label:selectedlang.name,
                      name:selectedlang.name}
                      : ''}
                    handleChange={props.handleChange2}
                    canAssign
                  />
            </Grid>
            <Grid item xs={1} md={1} sm={1} style={{display: 'flex', alignItems: 'center'}}>
              <Tooltip 
                classes={{
                  popper: 'menu-popper',
                  tooltip: 'menu-popper-tooltip'
                }}
                title={props.translate(`Geo-coding address stored in ${selectedlang ? selectedlang.name:'English'} language`)}>
                <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>
      </> )}
      {/* <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left'>
          <SendIcon width={14} height={14} style={{marginRight: 10, verticalAlign: 'middle'}} />
          {props.translate('movementDetection')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <Grid container spacing={2}>
          <Grid item xl={5} md={4} xs={12}>
            <TextField
              id='storeTime'
              label={props.translate('deviceNoDataDuration')}
              placeholder=''
              value={
                unitParkingAttributesform && unitParkingAttributesform.storeTime
                  ? unitParkingAttributesform.storeTime
                  : ''
              }
              onChange={props.handleChange('storeTime')}
              variant='outlined'
              margin='dense'
              type='number'
              fullWidth
            />
          </Grid>
        </Grid>
      </div> */}
      {/* {console.log("isVisableParkingAttBtn=====", props.isVisableParkingAttBtn)} */}
        <div style={{ textAlign: 'right', padding: '0 16px 16px'}}>
          {props.isVisablBtn && (
            <Button
            disabled={props.isVisableParkingAttBtn}
            onClick={props.onSubmit}
            >
              {props.translate('sharedSave')}
            </Button>
          )}
        </div>
    </div>
  )
}
