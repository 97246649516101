import React, { Fragment, PureComponent } from "react";
import Menu from "./../Menu/CommonMenu";
import { updateNotificationSetting } from "./../../Actions/Notifications";
import { logInUserInfo } from "./../../Actions/Users";
import MenuItem from "./../common/MenuItem";
import Checkbox from "./../common/Checkbox";
import { withLocalize } from "react-localize-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import { connect } from "react-redux";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";
let notificationSettings = [];
class OptionMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      process: false,
    };
    this.activateOption = this.activateOption.bind(this);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  activateOption(o, id, event) {
    this.props.dispatch(
      updateNotificationSetting({ id: id, checked: event.target.checked })
    );

    const currentObj = { ...o, checked: event.target.checked };
    const updatedObject = this.props.notificationSettings.map((op) => {
      if (o.id === op.id) {
        return currentObj;
      } else {
        return op;
      }
    });

    instance({
      url: `/api/users/${this.props.logInUser.id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...this.props.logInUser,
        attributes: {
          ...this.props.logInUser.attributes,
          notificationSettings: updatedObject,
        },
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((user) => {
        this.props.dispatch(logInUserInfo(user));
        this.setState({ process: false });
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //     if (err.includes('Duplicate entry')) {
        //       this.setState({
        //         duplicateEntry: true
        //       })
        //       alert('User Email is Allready in Used')
        //     }
        //   })
        // }
      });
  }

  render() {
    const { anchorEl } = this.state;
    const empty = null;

    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.notificationSettings
    ) {
      notificationSettings = [];

      this.props.notificationSettings.map((o) => {
        if (this.props.logInUser.attributes.notificationSettings) {
          this.props.logInUser.attributes.notificationSettings.map((n) => {
            if (o.id === n.id) {
              o["checked"] = n["checked"];
              notificationSettings.push(o);
            }
            return null;
          });
        }

        return null;
      });
    } else {
      notificationSettings = this.props.notificationSettings;
    }

    return (
      <Fragment>
        <a
          href={empty}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <SettingsIcon style={{ fontSize: 18 }} />
        </a>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          themecolors={this.props.themecolors}
        >
          {notificationSettings.map((option, index) => (
            <MenuItem key={index}>
              <Checkbox
                onChange={(e) => this.activateOption(option, option.id, e)}
                checked={option.checked}
                value="checkedA"
                label={this.props.translate(option.label)}
              />
            </MenuItem>
          ))}
        </Menu>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  notificationSettings: state.notificationSettings,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withLocalize(OptionMenu));
