import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import { fetchMoreDevices, searchDevices } from '../../Actions/Devices'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Layout/Loader'
import Button from '../common/Button'
class DeviceSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      others: [],
      search: '',
      isChecked: false
    }
  }
  UNSAFE_componentWillMount () {
    this.props.dispatch(fetchMoreDevices(true, this.props.rows || 10))

    const list = {}
    const others = []
    if (this.props.value && this.props.value.length && this.props.devices3 && this.props.devices3.length) {
      this.props.value.map(d => {
        const found = this.props.devices3.find(dev => dev.id === parseInt(d));
        if(found) {
          list[d] = true
        }
        else {
          others.push(d)
        }
        return null
      })
    }
    this.setState({ list, others, })
  }

  UNSAFE_componentWillReceiveProps (nextprops) {
    const list = {}
    const others = []
    if (nextprops.value && nextprops.value.length && nextprops.devices3 && nextprops.devices3.length) {
      nextprops.value.map(d => {
        const found = nextprops.devices3.find(dev => dev.id === parseInt(d));
        if(found) {
          list[d] = true
        }
        else {
          others.push(d)
        }
        return null
      })
    }else{
      this.setState({ isChecked: false  })
    }
    this.setState({ list, others,  })
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      isChecked: false
    })
  }
  sendToParent () {
    if (this.props.onChange) {
       this.props.onChange([...Object.keys(this.state.list), ...this.state.others])
    }
  }
  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    this.setState(
      {
        list,
        isChecked: this.props.devicesList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }
  searchItem = text => {
    this.setState({ search: text.target.value }, () => {
      this.props.dispatch(searchDevices({ device: this.state.search }))
    })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      let items = []
      if (this.state.search) {
        items = this.props.devicesList.data
      } else {
        items = this.props.devices3
      }
      items.map(d => (list[d.id] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  prv = () => {
    if (this.props.handleChangeTab) {
      this.props.handleChangeTab('', this.props.tabPostion - 1)
    }
  }
  render () {
    const endMessage =
      this.props.devicesList && this.props.devicesList.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Style>
        {`
        .ScrollbarsCustom-Wrapper {
          margin: 0 -15px;
        }`}
        <div style={{ width: '100%' }}>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0 }}
          >
            <ul className='filter-row'>
              <li>
                <SearchField
                  label={this.props.translate('searchUnits')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3, paddingLeft: 6, flex: 0.01 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className='selector-count'>
              <span style={{color:this.props.themecolors.textColor}}><span style={{marginRight: 3,color:this.props.themecolors.textColor}}>{Object.keys(this.state.list).length}</span>
              {this.props.translate('UnitsSelected')}</span>

              {/* <span style={{color:this.props.themecolors.textColor}}><span style={{marginRight: 3,color:this.props.themecolors.textColor}}>{Object.keys(this.state.others).length}</span>
              {this.props.translate('more')}</span> */}
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation
            style={{
              height: (this.props.hideIcons ? 32 : 38) * (this.props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDeviceSelector'
                  />
                )
              }
            }}
          >
            {this.props.devicesList.total ? (
              <InfiniteScroll
                dataLength={this.props.devicesList.data.length}
                next={() => this.props.dispatch(fetchMoreDevices())}
                hasMore={this.props.devicesList.hasNext}
                loader={<Loader defaultStyle={true} />}
                scrollableTarget='scrollableDeviceSelector'
                endMessage={endMessage}
              >
                <ul 
                className='list-view'
                >
                  {this.props.devicesList.data.map(row => (
                    <li
                    style={{
                      padding:this.props.eventsModule ? "8px":"",
                    }}
                    key={row.id} className={"list-row "+(this.state.list[row.id] ? 'active' : '')}>
                      <label
                        className={this.props.eventsModule ? " " : 'unit-name'}
                        style={{
                          fontWeight:this.props.eventsModule ? 500:"",
                          fontSize:this.props.eventsModule ? "12px":"",
                        }}
                      >
                        {!this.props.readOnly ? (
                          <span className='selector-check'>
                            <Checkbox
                              checked={this.state.list[row.id]}
                              value={row}
                              onChange={this.onChange}
                            />
                          </span>
                        ) : null}
                        {this.props.hideIcons ? null : (
                          <span className='unit-img'>
                            <img
                              src={
                                '/assets/category/default/' +
                                (row.category || 'default') +
                                '.svg'
                              }
                              alt={row.name}
                            />
                          </span>
                        )}
                        <span className='unit-name-text'>{row.name}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
            ) : this.props.devicesList.hasNext ||
              this.props.devicesList.isFetching ? (
              <Loader defaultStyle={true} />
            ) : null}
          </Scrollbar>
          {this.props.nextBtn &&(
            <div>
              
              <Button variant='contained' onClick={this.prv}>
            {this.props.translate('previous')}
          </Button>


          
                <Button
                  disabled={this.props.mode !== "update" ? !this.props.devicesEnable : ""}
                  // disabled={!this.props.devicesEnable}
                  variant='contained'
                  onClick={()=>this.props.next()}
                  style={{ float: 'right', margin: 0 }}
                >
                  {this.props.translate('next')}
                </Button>
            </div>)
          }        
</div>
      </Style>
    )
  }
}

const mapState = state => ({
  devices3: state.devices3,
  devicesList: state.devices
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(DeviceSelector))
