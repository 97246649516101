import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "../../../Components/common/MenuItem";
import { withSnackbar } from "notistack";
import TextField from "../../../Components/common/TextField";
import Button from "../../../Components/common/Button";
import Loader from "../../../Layout/Loader";
import { EcoDrivingTable } from "./drawEcoDriving";
import { checkPrivileges, errorHandler } from "../../../Helpers";
import Notifications from "react-notification-system-redux";
import instance from "../../../axios";
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class EcoDriving extends Component {
  constructor() {
    super();
    this.state = {
      isRecived: false,
      selecteditem: "",
      currentId: 0,
      editOption: false,
      form: {
        preset: "",
        id: "",
        attributes: {},
      },
    };
    this.Submit = this.Submit.bind(this);
  }

  fetchEcoData = (id) => {
    instance({
      url: `/api/ecodriving/`,
      method: "GET",
      params: {
        deviceId: id,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((comp) => {
        // if (comp.status === 'success') {
        if (comp.length) {
          this.setState({
            form: {
              attentance: true,
              preset: comp[0].attributes.preset,
              id: comp[0].id,
              attributes: comp[0].attributes,
            },
            editOption: true,
          });
        } else {
          this.setState({
            editOption: false,
            form: {
              preset: "",
              id: "",
              attributes: {},
            },
          });
        }
        this.setState({
          isRecived: true,
        });
        // } else {
        //   this.props.enqueueSnackbar(comp.message, { autoHideDuration: 1000 })
        // }
        //   })
        // } else{
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // this.props.enqueueSnackbar(error.message, { autoHideDuration: 1000 })
      });
  };
  UNSAFE_componentWillMount() {
    if (this.props.selectItemId !== this.state.currentId) {
      this.setState(
        {
          currentId: this.props.selectItemId,
        },
        () => {
          this.fetchEcoData(this.props.selectItemId);
        }
      );
    }
  }
  UNSAFE_componentWillReceiveProps(n) {
    if (n.selectItemId !== this.state.currentId) {
      this.setState(
        {
          currentId: n.selectItemId,
        },
        () => {
          this.fetchEcoData(n.selectItemId);
        }
      );
    }
  }
  Submit = () => {
    this.setState(
      {
        isRecived: false,
      },
      () => {
        let { editOption, form } = this.state;
        let option = "POST";
        let callSufax = "";
        if (editOption) {
          option = "PUT";
          callSufax = `/${this.props.selectItemId}`;
        }
        let obj = {
          id: editOption ? form.id : "",
          attributes: { ...form.attributes, preset: form.preset },
          entityId: this.props.selectItemId,
          property: "ecoDriving",
          description: "",
        };

        instance({
          url: `/api/ecodriving${callSufax}`,
          method: `${option}`,
          data: {
            ...obj,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((comp) => {
            // if (comp.status === 'success') {
            this.setState({
              form: {
                id: comp.id,
                preset: comp.attributes.preset,
                attributes: comp.attributes,
              },
              editOption: true,
              isRecived: true,
            });
            if (option === "POST") {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate("createdSuccessfully"),
                  autoDismiss: 10,
                })
              );
            } else {
              if (Object.keys(comp.attributes).length > 1) {
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate("updatedSuccessfully"),
                    autoDismiss: 10,
                  })
                );
              } else {
                this.DeleteSubmit();
              }
            }
            // } else {
            //   this.props.enqueueSnackbar(this.props.translate('error'), {
            //     autoHideDuration: 1000
            //   })
            // }
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch);
            this.setState({ isRecived: true });
          });
        // .catch(e => {
        //   if (e && e.text) {
        //     e.text().then(err => {
        //       this.props.enqueueSnackbar(err, { autoHideDuration: 1000 })
        //     })
        //   }
        // })
      }
    );
  };

  DeleteSubmit = () => {
    let { form } = this.state;

    instance({
      url: `/api/ecodriving/${form.id}`,
      method: `DELETE`,
    })
      .then((response) => {
        // if (response.ok) {
        this.setState({
          form: {
            preset: "",
            attributes: "",
          },
          editOption: false,
          isRecived: true,
        });
        this.props.enqueueSnackbar(this.props.translate("attributeIsDelete"), {
          autoHideDuration: 1000,
        });
        // } else {
        //   throw response
        // }
      })
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       this.props.enqueueSnackbar(err, { autoHideDuration: 1000 })
      //     })
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };
  handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if ((value === "none" || value === "") && this.state.editOption) {
      // call delete
      this.setState(
        {
          isRecived: false,
        },
        () => this.DeleteSubmit()
      );
    }
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };
  handleChangeAttributesValue = (itemKey, index, event) => {
    let { attributes } = { ...this.state.form };
    if (event) {
      const { target } = event;
      let value = target.value;
      attributes[itemKey][index] = value ? parseFloat(value) : null;
      this.setState({
        attributes: {
          ...this.state.form.attributes,
          attributes,
        },
      });
    }
  };
  onDelete = (Itemkey) => {
    let { attributes } = { ...this.state.form };
    delete attributes[Itemkey];
    this.setState(
      {
        attributes: {
          ...this.state.form.attributes,
          attributes,
        },
        isRecived: false,
      },
      () => this.Submit()
    );
  };
  onUpdate = () => {
    this.setState(
      {
        isRecived: false,
      },
      () => this.Submit()
    );
  };
  render() {
    const { classes } = this.props;
    const { form, editOption, isRecived } = this.state;
    return (
      <div style={{ padding: 16 }}>
        {!isRecived ? (
          <Loader defaultStyle />
        ) : (
          <>
            <div className={classes.paper + " clearfix"}>
              <Grid container spacing={1}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="preset"
                    select
                    label={this.props.translate("preset")}
                    value={form.preset || ""}
                    onChange={this.handleChange("preset")}
                    margin="dense"
                    fullWidth
                  >
                    <MenuItem value="">
                      <em> {this.props.translate("none")}</em>
                    </MenuItem>
                    {preset.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.key)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  {checkPrivileges("settingCreate") && (
                    <Button
                      onClick={() => this.Submit()}
                      style={{ marginTop: 8 }}
                    >
                      {" "}
                      {this.props.translate("sharedAdd")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
            {editOption && (
              <EcoDrivingTable
                row={this.state.form.attributes}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                handleChange={this.handleChangeAttributesValue}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  themecolors: state.themeColors,
});

const enhance = compose(withStyles(styles), connect(mapState));

export default enhance(withSnackbar(EcoDriving));

const preset = [
  {
    key: "bus",
  },
  {
    key: "truck",
  },
  {
    key: "automobile",
  },
];
