export const mapLayerTypes = [
  {
    key: 'carto',
    name: 'mapCarto'
  },
  {
    key: 'osm',
    name: 'mapOsm'
  },
  {
    key: 'googleTerrain',
    name: 'mapGoogleTerrain'
  },
  {
    key: 'googleSatellite',
    name: 'mapGoogleSatellite'
  },
  {
    key: 'googleHybrid',
    name: 'mapGoogleHybrid'
  },
  {
    key: 'googleRoad',
    name: 'mapGoogleRoad'
  },
  {
    key: 'yandexMap',
    name: 'mapYandexMap'
  },
  {
    key: 'yandexSat',
    name: 'mapYandexSat'
  },
  {
    key: 'baidu',
    name: 'mapBaidu'
  }
]

export const coordinateFormatTypes = [
  {
    key: 'dd',
    name: 'sharedDecimalDegrees'
  },
  {
    key: 'ddm',
    name: 'sharedDegreesDecimalMinutes'
  },
  {
    key: 'dms',
    name: 'sharedDegreesMinutesSeconds'
  }
]

export const ownerUserType = [
  {
    key: 1,
    name: 'user'
  },
  {
    key: 2,
    name: 'userAdmin'
  }
]
export const dealerUserType = [
  {
    key: 1,
    name: 'user'
  },
  {
    key: 2,
    name: 'userAdmin'
  }
]
export const adminAccountUserType = [
  {
    key: 1,
    name: 'user'
  },
  {
    key: 2,
    name: 'userAdmin'
  }
]
export const serviceProviderUserType = [
  {
    key: 1,
    name: 'user'
  },
  {
    key: 2,
    name: 'userAdmin'
  }
]
export const adminUserType = [
  {
    key: 1,
    name: 'user'
  }
]

export const measurementStandard = [
  {
    key: 'metric',
    value: 'Metric'
  },
  {
    key: 'imperial',
    value: 'Imperial'
  },
  {
    key: 'US',
    value: 'US'
  }, {
    key: 'metricWithUSGallons',
    value: 'Metric with US Gallons'
  }
]

export const currencies = [
  {
      "key": "US Dollar",
      "value": "USD"
  },
  {
      "key": "Canadian Dollar",
      "value": "CAD"
  },
  {
      "key": "Euro",
      "value": "EUR"
  },
  {
      "key": "United Arab Emirates Dirham",
      "value": "AED"
  },
  {
      "key": "Afghan Afghani",
      "value": "AFN"
  },
  {
      "key": "Albanian Lek",
      "value": "ALL"
  },
  {
      "key": "Armenian Dram",
      "value": "AMD"
  },
  {
      "key": "Argentine Peso",
      "value": "ARS"
  },
  {
      "key": "Australian Dollar",
      "value": "AUD"
  },
  {
      "key": "Azerbaijani Manat",
      "value": "AZN"
  },
  {
      "key": "Bosnia-Herzegovina Convertible Mark",
      "value": "BAM"
  },
  {
      "key": "Bangladeshi Taka",
      "value": "BDT"
  },
  {
      "key": "Bulgarian Lev",
      "value": "BGN"
  },
  {
      "key": "Bahraini Dinar",
      "value": "BHD"
  },
  {
      "key": "Burundian Franc",
      "value": "BIF"
  },
  {
      "key": "Brunei Dollar",
      "value": "BND"
  },
  {
      "key": "Bolivian Boliviano",
      "value": "BOB"
  },
  {
      "key": "Brazilian Real",
      "value": "BRL"
  },
  {
      "key": "Botswanan Pula",
      "value": "BWP"
  },
  {
      "key": "Belarusian Ruble",
      "value": "BYN"
  },
  {
      "key": "Belize Dollar",
      "value": "BZD"
  },
  {
      "key": "Congolese Franc",
      "value": "CDF"
  },
  {
      "key": "Swiss Franc",
      "value": "CHF"
  },
  {
      "key": "Chilean Peso",
      "value": "CLP"
  },
  {
      "key": "Chinese Yuan",
      "value": "CNY"
  },
  {
      "key": "Colombian Peso",
      "value": "COP"
  },
  {
      "key": "Costa Rican Colón",
      "value": "CRC"
  },
  {
      "key": "Cape Verdean Escudo",
      "value": "CVE"
  },
  {
      "key": "Czech Republic Koruna",
      "value": "CZK"
  },
  {
      "key": "Djiboutian Franc",
      "value": "DJF"
  },
  {
      "key": "Danish Krone",
      "value": "DKK"
  },
  {
      "key": "Dominican Peso",
      "value": "DOP"
  },
  {
      "key": "Algerian Dinar",
      "value": "DZD"
  },
  {
      "key": "Estonian Kroon",
      "value": "EEK"
  },
  {
      "key": "Egyptian Pound",
      "value": "EGP"
  },
  {
      "key": "Eritrean Nakfa",
      "value": "ERN"
  },
  {
      "key": "Ethiopian Birr",
      "value": "ETB"
  },
  {
      "key": "British Pound Sterling",
      "value": "GBP"
  },
  {
      "key": "Georgian Lari",
      "value": "GEL"
  },
  {
      "key": "Ghanaian Cedi",
      "value": "GHS"
  },
  {
      "key": "Guinean Franc",
      "value": "GNF"
  },
  {
      "key": "Guatemalan Quetzal",
      "value": "GTQ"
  },
  {
      "key": "Hong Kong Dollar",
      "value": "HKD"
  },
  {
      "key": "Honduran Lempira",
      "value": "HNL"
  },
  {
      "key": "Croatian Kuna",
      "value": "HRK"
  },
  {
      "key": "Hungarian Forint",
      "value": "HUF"
  },
  {
      "key": "Indonesian Rupiah",
      "value": "IDR"
  },
  {
      "key": "Israeli New Sheqel",
      "value": "ILS"
  },
  {
      "key": "Indian Rupee",
      "value": "INR"
  },
  {
      "key": "Iraqi Dinar",
      "value": "IQD"
  },
  {
      "key": "Iranian Rial",
      "value": "IRR"
  },
  {
      "key": "Icelandic Króna",
      "value": "ISK"
  },
  {
      "key": "Jamaican Dollar",
      "value": "JMD"
  },
  {
      "key": "Jordanian Dinar",
      "value": "JOD"
  },
  {
      "key": "Japanese Yen",
      "value": "JPY"
  },
  {
      "key": "Kenyan Shilling",
      "value": "KES"
  },
  {
      "key": "Cambodian Riel",
      "value": "KHR"
  },
  {
      "key": "Comorian Franc",
      "value": "KMF"
  },
  {
      "key": "South Korean Won",
      "value": "KRW"
  },
  {
      "key": "Kuwaiti Dinar",
      "value": "KWD"
  },
  {
      "key": "Kazakhstani Tenge",
      "value": "KZT"
  },
  {
      "key": "Lebanese Pound",
      "value": "LBP"
  },
  {
      "key": "Sri Lankan Rupee",
      "value": "LKR"
  },
  {
      "key": "Lithuanian Litas",
      "value": "LTL"
  },
  {
      "key": "Latvian Lats",
      "value": "LVL"
  },
  {
      "key": "Libyan Dinar",
      "value": "LYD"
  },
  {
      "key": "Moroccan Dirham",
      "value": "MAD"
  },
  {
      "key": "Moldovan Leu",
      "value": "MDL"
  },
  {
      "key": "Malagasy Ariary",
      "value": "MGA"
  },
  {
      "key": "Macedonian Denar",
      "value": "MKD"
  },
  {
      "key": "Myanma Kyat",
      "value": "MMK"
  },
  {
      "key": "Macanese Pataca",
      "value": "MOP"
  },
  {
      "key": "Mauritian Rupee",
      "value": "MUR"
  },
  {
      "key": "Mexican Peso",
      "value": "MXN"
  },
  {
      "key": "Malaysian Ringgit",
      "value": "MYR"
  },
  {
      "key": "Mozambican Metical",
      "value": "MZN"
  },
  {
      "key": "Namibian Dollar",
      "value": "NAD"
  },
  {
      "key": "Nigerian Naira",
      "value": "NGN"
  },
  {
      "key": "Nicaraguan Córdoba",
      "value": "NIO"
  },
  {
      "key": "Norwegian Krone",
      "value": "NOK"
  },
  {
      "key": "Nepalese Rupee",
      "value": "NPR"
  },
  {
      "key": "New Zealand Dollar",
      "value": "NZD"
  },
  {
      "key": "Omani Rial",
      "value": "OMR"
  },
  {
      "key": "Panamanian Balboa",
      "value": "PAB"
  },
  {
      "key": "Peruvian Nuevo Sol",
      "value": "PEN"
  },
  {
      "key": "Philippine Peso",
      "value": "PHP"
  },
  {
      "key": "Pakistani Rupee",
      "value": "PKR"
  },
  {
      "key": "Polish Zloty",
      "value": "PLN"
  },
  {
      "key": "Paraguayan Guarani",
      "value": "PYG"
  },
  {
      "key": "Qatari Rial",
      "value": "QAR"
  },
  {
      "key": "Romanian Leu",
      "value": "RON"
  },
  {
      "key": "Serbian Dinar",
      "value": "RSD"
  },
  {
      "key": "Russian Ruble",
      "value": "RUB"
  },
  {
      "key": "Rwandan Franc",
      "value": "RWF"
  },
  {
      "key": "Saudi Riyal",
      "value": "SAR"
  },
  {
      "key": "Sudanese Pound",
      "value": "SDG"
  },
  {
      "key": "Swedish Krona",
      "value": "SEK"
  },
  {
      "key": "Singapore Dollar",
      "value": "SGD"
  },
  {
      "key": "Somali Shilling",
      "value": "SOS"
  },
  {
      "key": "Syrian Pound",
      "value": "SYP"
  },
  {
      "key": "Thai Baht",
      "value": "THB"
  },
  {
      "key": "Tunisian Dinar",
      "value": "TND"
  },
  {
      "key": "Tongan Paʻanga",
      "value": "TOP"
  },
  {
      "key": "Turkish Lira",
      "value": "TRY"
  },
  {
      "key": "Trinidad and Tobago Dollar",
      "value": "TTD"
  },
  {
      "key": "New Taiwan Dollar",
      "value": "TWD"
  },
  {
      "key": "Tanzanian Shilling",
      "value": "TZS"
  },
  {
      "key": "Ukrainian Hryvnia",
      "value": "UAH"
  },
  {
      "key": "Ugandan Shilling",
      "value": "UGX"
  },
  {
      "key": "Uruguayan Peso",
      "value": "UYU"
  },
  {
      "key": "Uzbekistan Som",
      "value": "UZS"
  },
  {
      "key": "Venezuelan Bolívar",
      "value": "VEF"
  },
  {
      "key": "Vietnamese Dong",
      "value": "VND"
  },
  {
      "key": "CFA Franc BEAC",
      "value": "XAF"
  },
  {
      "key": "CFA Franc BCEAO",
      "value": "XOF"
  },
  {
      "key": "Yemeni Rial",
      "value": "YER"
  },
  {
      "key": "South African Rand",
      "value": "ZAR"
  },
  {
      "key": "Zambian Kwacha",
      "value": "ZMK"
  },
  {
      "key": "Zimbabwean Dollar",
      "value": "ZWL"
  }
]
export const checkUnitsWithStandardType = (type) => {
  let data = []
  switch (type) {
    case 'metric':
      data = [{
        'distanceUnit': {
          key: 'km',
          name: 'sharedKm',
          factor: 0.001
        },
        'speedUnit': {
          key: 'kmh',
          name: 'sharedKmh',
          factor: 1.852
        },
        'volumeUnit': {
          key: 'ltr',
          name: 'sharedLiterAbbreviation',
          fullName: 'sharedLiter',
          factor: 1
        }
      }]
      break;
    case 'imperial':
      data = [{
        'distanceUnit': {
          key: 'mi',
          name: 'sharedMi',
          factor: 0.000621371
        },
        'speedUnit': {
          key: 'mph',
          name: 'sharedMph',
          factor: 1.15078
        },
        'volumeUnit': {
          key: 'ukGal',
          name: 'sharedUkGallonAbbreviation',
          fullName: 'sharedUkGallon',
          factor: 3.785
        }
      }]
      break;
    case 'US':
      data = [{
        'distanceUnit': {
          key: 'mi',
          name: 'sharedMi',
          factor: 0.000621371
        },
        'speedUnit': {
          key: 'mph',
          name: 'sharedMph',
          factor: 1.15078
        },
        'volumeUnit':{
          key: 'usGal',
          name: 'sharedUsGallonAbbreviation',
          fullName: 'sharedUsGallon',
          factor: 3.785
        }
      }]
      break;
    case 'metricWithUSGallons':
      data = [{
        'distanceUnit': {
          key: 'km',
          name: 'sharedKm',
          factor: 0.001
        },
        'speedUnit': {
          key: 'kmh',
          name: 'sharedKmh',
          factor: 1.852
        },
        'volumeUnit':
        {
          key: 'usGal',
          name: 'sharedUsGallonAbbreviation',
          fullName: 'sharedUsGallon',
          factor: 3.785
        }
      }]
      break;
    default:
      data = [
      ]
  }
  return data
}