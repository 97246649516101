import React, { Component } from 'react';
import CustomDialog from '../common/Dialog'
import Table from '../common/Table'
import {unitsList} from './unitsPortList'

export default class DevicePortList extends Component {
  	render () {

		return (
            <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.props.onCloseModal}
            isButtonVisable={false}
            draggable={true}
            CancelText={this.props.translate('sharedCancel')}
            bodyPadding={0}>
            <Table insideDialog={true}
                title={this.props.translate('deviceModel')}
                rows={unitsList}
                rowsPerPage={10}
                searchable={true}
                themecolors={this.props.themecolors}
                rowDefinition={[
                    { id: 'device', numeric: false, disablePadding: false, label: this.props.translate('device') },
                    { id: 'protocol',numeric: false, disablePadding: false, label: this.props.translate('protocol') },
                    { id: 'port',numeric: false, disablePadding: false, label: this.props.translate('port') },
                ]} />
        </CustomDialog>
        ) 
	}
}
