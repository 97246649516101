import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "../common/Checkbox";
import { getNotification } from "../../Actions/Notifications";
import Style from "style-it";
import SearchField from "../common/SearchField";
import { withLocalize } from "react-localize-redux";
import Scrollbar from "react-scrollbars-custom";
import instance from "../../axios";
class NotificationSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      displayList: [],
      search: "",
      isChecked: false,
    };
  }

  UNSAFE_componentWillMount() {
    let list = {};
    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list });
    }

    this.prepareNotifications(this.props);
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      search: "",
      displayList: [],
      isChecked: false,
    });
  }

  UNSAFE_componentWillReceiveProps(n) {
    this.prepareNotifications(n);
  }

  prepareNotifications = (n) => {
    if(n&&n.notificationList.length){
      let displayList = n.notificationList||[];
      this.setState({ displayList });
    }
  };

  sendToParent() {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list));
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list };
    if (e.target.checked) {
      list[v.id] = true;
    } else {
      delete list[v.id];
    }
    this.setState(
      {
        list,
        isChecked:
          this.props.notificationList.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };

  searchItem = (text) => {
    this.setState({ search: text.target.value });
  };

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      this.props.notificationList.map((d) => (list[d.id] = true));
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };

  componentDidMount() {
    if (this.props.logInUser) {
      this.fetchData();
    }
  }

  fetchData = () => {
    instance({
      url: `/api/notifications/get`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        limit: -1,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((res) => {
        this.setState(
          {
            displayList: res.data,
          },
          () => {
            this.props.dispatch(getNotification(res.data));
          }
        );
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  render() {

    let list = this.state.displayList&&this.state.displayList.filter(
      (item) =>
        (item.name + "" + item.id).toLowerCase().indexOf(this.state.search) !==
        -1
    )
    return (
      <Style>
        {`
          .ScrollbarsCustom-Wrapper {
            margin: 0 -15px;
          }`}
        <div>
          <div
            className="section-head clearfix section-head-filter"
            style={{ padding: 0 }}
          >
            <ul className="filter-row pull-left">
              <li style={{width:300}}>
                <SearchField
                  label={this.props.translate("searchNotification")}
                  type="search"
                  placeholder="exp: ID, Name"
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3, paddingLeft: 6 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate("selectAll")}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className="selector-count">
              {" "}
              {Object.keys(this.state.list).length}{" "}
              {this.props.translate("EventSelected")}
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 32) *
                (this.props.rows || 10),
            }}
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id="scrollableDivNotification"
                  />
                );
              },
            }}
          >
            <ul className="list-view" style={{margin:'0 6px'}}>
              {list&&list.map((row) => (
                <li
                  key={row.id}
                  className={
                    "list-row " + (this.state.list[row.id] ? "active" : "")
                  }
                >
                  <label className="unit-name">
                    {!this.props.readOnly ? (
                      <span style={{ marginRight: 6 }}>
                        <Checkbox
                          checked={this.state.list[row.id]}
                          value={row}
                          onChange={this.onChange}
                        />
                      </span>
                    ) : null}
                    <span className="unit-name-text">{row.name}</span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    );
  }
}

const mapState = (state) => ({
  notificationList: state.notification,
  logInUser: state.logInUsers,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withLocalize(NotificationSelector));
