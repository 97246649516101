import React from "react";
import { connect } from "react-redux";
import { isAuthenticated } from "./index";
import { getUsers } from "../Actions/Users";
import { getLayer } from "./../Actions/Maps";
import { addcalendars, getGeoFence, fetchUsers } from "../Actions/Devices";
import {
  getNotification,
  getNotificationType,
  getNotificationChannel,
} from "../Actions/Notifications";

import { getComputedAttributes } from "../Actions/ComputedAttributes";
import { getCommands, getCommandsTypes } from "../Actions/Commands";
import { getMaintenance } from "../Actions/Maintenance";
import { getGroups } from "../Actions/Groups";
import { getDrivers } from "../Actions/Drivers";
import { ServerInfo } from "../Actions/serverSetting";
import { checkPrivileges } from "./index";
import axios from "axios";

const CancelToken = axios.CancelToken;
let source;

class CommonSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calenderFetched: false,
      commandFetched: false,
      commandsTypesFetched: false,
      notificationFetched: false,
      notificationTypeFetched: false,
      notificatorsFetched: false,
      attributeFetched: false,
      maintenanceFetched: false,
      geofencesFetched: false,
      userFetched: false,
      driversFetched: false,
      groupsFetched: false,
      vehiclesFetched: false,
    };
  }
  displayError = (e) => {
    if (e && e.response && e.response.statusText) {
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  UNSAFE_componentWillMount() {
    source = CancelToken.source();

    if (isAuthenticated(this.props)) {
      //server setting call for default setting
      if (!this.props.ServerSetting && this.props.logInUser) {
        axios({
          method: "GET",
          url: `/api/accounts?accountId=${this.props.logInUser.accountId}`,
          cancelToken: source.token,
        }).then((response) => {
          if (response.status === 200) {
            const server = response.data;
            let domElements = document.getElementById("favicon");
            if (
              server &&
              server[0].attributes &&
              server[0].attributes.favIcon &&
              domElements
            ) {
              domElements.setAttribute("href", server[0].attributes.favIcon);
            }
            this.props.dispatch(ServerInfo(server[0]));
            this.props.dispatch(
              getLayer({
                id: this.props.logInUser.map || server[0].map || "osm",
              })
            );
          }
        });
      }
      let query = "";
      if (this.props.logInUser.userType === -1) {
        query = "all=true";
      } else {
        query = "userId=" + this.props.logInUser.id;
      }
      //inital fetch call fetch call for users
      if (this.props.location.pathname !== "/reports") {
        if (checkPrivileges("notification")) {
          // inital fetch call for notification
          if (
            !this.props.notification.length &&
            this.state.notificationFetched === false
          ) {
            this.setState({ notificationFetched: true }, () => {
              axios({
                method: "GET",
                url: `/api/notifications`,
                cancelToken: source.token,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    this.props.dispatch(getNotification(response?.data?.data||[]));
                  }
                })
                .catch((e) => {
                  this.displayError(e);
                });
            });
          }

          // get Types of notificatrion
          if (
            !this.props.notificationType.length &&
            this.state.notificationTypeFetched === false
          ) {
            this.setState({ notificationTypeFetched: true }, () => {
              axios({
                method: "GET",
                url: `/api/notifications/types/`,
                cancelToken: source.token,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    this.props.dispatch(getNotificationType(response.data));
                  }
                })
                .catch((e) => {
                  this.displayError(e);
                });
            });
          }

          // get notificatrion channel
          if (
            !this.props.notificationChannel.length &&
            this.state.notificatorsFetched === false
          ) {
            this.setState({ notificatorsFetched: true }, () => {
              axios({
                method: "GET",
                url: `/api/notifications/notificators/`,
                cancelToken: source.token,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    this.props.dispatch(getNotificationChannel(response.data));
                  }
                })
                .catch((e) => {
                  this.displayError(e);
                });
            });
          }
        }

        if (checkPrivileges("calendar") &&
          !this.props.calendars.length &&
          this.state.calenderFetched === false
        ) {
          this.setState({ calenderFetched: true }, () => {
            //get canenders
            axios({
              method: "GET",
              url: `/api/calendars/?${query}`,
              cancelToken: source.token,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  this.props.dispatch(addcalendars(response.data));
                }
              })
              .catch((e) => {
                this.displayError(e);
              });
          });
        }

        // inital fetch call for computed attributes
        if (
          checkPrivileges("attribute") &&
          !this.props.computedAttributes.length &&
          this.state.attributeFetched === false
        ) {
          this.setState({ attributeFetched: true }, () => {
            axios({
              method: "GET",
              url: `/api/attributes/computed/?userId=${this.props.logInUser.id}&all=true`,
              cancelToken: source.token,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  this.props.dispatch(getComputedAttributes(response.data));
                }
              })
              .catch((e) => {
                this.displayError(e);
              });
          });
        }
        if (checkPrivileges("command")) {
          // inital fetch call for commands types
          if (
            !this.props.commandsTypes.length &&
            this.state.commandsTypesFetched === false
          ) {
            this.setState({ commandsTypesFetched: true }, () => {
              axios({
                method: "GET",
                url: `/api/commands/types`,
                cancelToken: source.token,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    this.props.dispatch(getCommandsTypes(response.data));
                  }
                })
                .catch((e) => {
                  this.displayError(e);
                });
            });
          }
          // inital fetch call for commands
          if (
            !this.props.commands.length &&
            this.state.commandFetched === false
          ) {
            this.setState({ commandFetched: true }, () => {
              axios({
                method: "GET",
                url: `/api/commands/?userId=${this.props.logInUser.id}&all=true`,
                cancelToken: source.token,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    this.props.dispatch(getCommands(response.data&&response.data.data));
                  }
                })
                .catch((e) => {
                  this.displayError(e);
                });
            });
          }
        }
        // inital fetch call for maintenance
        if (
          checkPrivileges("maintenance") &&
          !this.props.maintenance.length &&
          this.state.maintenanceFetched === false
        ) {
          this.setState({ maintenanceFetched: true }, () => {
            axios({
              method: "GET",
              url: `/api/maintenance/?${query}&all=true`,
              cancelToken: source.token,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  response.data.map((item) => {
                    item.check = false;
                    return null;
                  });
                  this.props.dispatch(getMaintenance(response.data));
                }
              })
              .catch((e) => {
                this.displayError(e);
              });
          });
        }
      }

      // inital fetch call for groups
      // if (
      //   checkPrivileges('group') &&
      //   !this.props.groups.length &&
      //   this.state.groupsFetched === false
      // ) {
      //   this.setState({ groupsFetched: true }, () => {
      //     axios({
      //       method: 'GET',
      //       url: `/api/groups/?${query}`,
      //       cancelToken: source.token,
      //       headers: {
      //         Accept: 'application/json',
      //         'Content-Type': 'application/json'
      //       }
      //     })
      //       .then(response => {
      //         if (response.status === 200) {
      //           response.data.map(item => {
      //             item.check = false
      //             return null
      //           })
      //           this.props.dispatch(getGroups(response.data))
      //         }
      //       })
      //       .catch(e => {
      //         this.displayError(e)
      //       })
      //   })
      // }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  computedAttributes: state.computedAttributes,
  maintenance: state.maintenance,
  geofences: state.geoFence,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  calendars: state.calendars,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  users: state.users,
  drivers: state.drivers,
  groups: state.groups,
});

export default connect(mapStateToProps)(CommonSection);
