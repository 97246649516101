import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import Style from 'style-it'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: ownProps.themecolors || state.themeColors
  }
}

const styles = theme => ({})

class CustomTextField extends React.Component {
  render () {
    const { classes } = this.props

    const p = { ...this.props }

    delete p.dispatch

    return (<div>
          <TextField
            margin='dense'
            {...p}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...this.props.InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: this.props.multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline'
              }
            }}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: 'custom-menu-paper'
                }
              },
              classes: {
                icon: 'menu-list-icon'
              }
            }}
            InputLabelProps={{
              ...this.props.InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink',
                error: 'theme-label-error'
              }
            }}
          />
        </div>
    )
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(CustomTextField))
