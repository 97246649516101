import React, { Component } from 'react'
import SimpleModalWrapped from './../common/Modal'
import { withLocalize } from 'react-localize-redux'
import Grid from '@material-ui/core/Grid'
import TextField from './../common/TextField'
import DateFnsUtils from '@date-io/date-fns'
import Tooltip from './../common/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'
import CrossIcons from '@material-ui/icons/Remove'
import IconButton from '@material-ui/core/IconButton'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import { withStyles } from '@material-ui/core/styles'
import Attributes from './../Attributes/attributesModal'
import { connect } from 'react-redux'
import { ReactComponent as SendIcon } from './../../assets/nav/send.svg'
import moment from 'moment'

const mapStateToProps = (state, ownProps) => {
  return {
    logInUsers: state.logInUsers,
    themecolors: state.themeColors
  }
}

const styles = theme => ({})

class addDrivers extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false
    }
    this.addAttributes = this.addAttributes.bind(this)
  }

  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }

  render () {
    const { selectedDrivers, classes } = this.props

    return (
      <div style={{ height: '100%' }}>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title='sharedDriver'
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.onClose}
          buttonText={this.props.buttonText}
          btnCloseText='sharedBack'
          isVisableBtn={this.props.isVisableUserBtn}
          selectedItemId={selectedDrivers.uniqueId}
          attributeChangesMessage={this.props.attributeChangesMessage}
          content={
            <form autoComplete='off'>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='name'
                    label={this.props.translate('sharedName')}
                    required
                    placeholder=''
                    value={this.props.form.name}
                    onChange={this.props.handleChange('name')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='uniqueId'
                    label={this.props.translate('uniqueId')}
                    type='text'
                    required
                    placeholder=''
                    value={this.props.form.uniqueId}
                    onChange={this.props.handleChange('uniqueId')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='department'
                    label={this.props.translate('department')}
                    type='text'
                    placeholder=''
                    value={this.props.form.department}
                    onChange={this.props.handleChange('department')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={
                        this.props.form.licenseExpDate &&
                        this.props.form.licenseExpDate <
                          moment().startOf('day').toISOString()
                      }
                      helperText={
                        this.props.form.licenseExpDate &&
                        this.props.form.licenseExpDate <
                          moment().startOf('day').toISOString()
                          ? this.props.translate('licenseExpired')
                          : ''
                      }
                      margin='dense'
                      label={this.props.translate('D/LExpirationTime')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.licenseExpDate
                          ? new Date(
                              `${this.props.form.licenseExpDate}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChange('licenseExpDate')}
                      classes={classes}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={this.props.translate('noLimit')}>
                              <IconButton
                                onClick={e => this.props.setExpirationTime(e,'licenseExpDate')}
                                style={{ color: 'inherit' }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf('day').toISOString()
                      }
                      helperText={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf('day').toISOString()
                          ? this.props.translate('licenseExpired')
                          : ''
                      }
                      margin='dense'
                      label={this.props.translate('expirationTime')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.expirationTime
                          ? new Date(
                              `${this.props.form.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChange('expirationTime')}
                      classes={classes}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={this.props.translate('noLimit')}>
                              <IconButton
                                onClick={e => this.props.setExpirationTime(e,'expirationTime')}
                                style={{ color: 'inherit' }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      margin='dense'
                      label={this.props.translate('dateOfBirthGregorian')}
                      variant='outlined'
                      fullWidth
                      maxDate={new Date()}
                      value={
                        this.props.form.dateOfBirthGregorian
                          ? new Date(
                              `${this.props.form.dateOfBirthGregorian}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChange('dateOfBirthGregorian')}
                      classes={classes}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='identityNumber'
                    label={this.props.translate('identityNumber')}
                    type='text'
                    placeholder=''
                    value={this.props.form.identityNumber}
                    onChange={this.props.handleChange('identityNumber')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='phone'
                    label={this.props.translate('sharedPhone')}
                    type='text'
                    placeholder=''
                    value={this.props.form.phone}
                    onChange={this.props.handleChange('phone')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='email'
                    label={this.props.translate('userEmail')}
                    type='text'
                    placeholder=''
                    value={this.props.form.email}
                    onChange={this.props.handleChange('email')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='licenseNumb'
                    label={this.props.translate('driverLicenseNumber')}
                    type='text'
                    placeholder=''
                    value={this.props.form.licenseNumb}
                    onChange={this.props.handleChange('licenseNumb')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='driverAddress'
                    multiline
                    rows={3}
                    label={this.props.translate('address')}
                    type='text'
                    placeholder=''
                    value={this.props.form.driverAddress}
                    onChange={this.props.handleChange('driverAddress')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              
                <Grid item sm={6} xs={12}>
                  <p style={{marginTop: 0, marginBottom: 4}}><TextField
                    id='licenseClass'
                    label={this.props.translate('licenseClass')}
                    type='text'
                    placeholder=''
                    value={this.props.form.licenseClass}
                    onChange={this.props.handleChange('licenseClass')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                  </p>
                  <input
                    type='file'
                    name='pic'
                    accept='image/*'
                    onChange={e => this.props.uploadImage(e)}
                  />
                  {this.props.form.driverImg && (
                    <Tooltip
                      placement='right-end'
                      title={this.props.translate('clickForPreview')}
                    >
                      <img
                        style={{ width: 120, height: 'auto' }}
                        alt='driverImage'
                        src={this.props.form.driverImg}
                        onClick={this.props.imagePopup}
                      />
                    </Tooltip>
                  )}
                </Grid>
                {/* <h4 style={{ margin: 0, marginTop: '15px' }}>
                  <SendIcon width={14} height={14} style={{marginRight: 10, verticalAlign: 'middle'}} />
                  {this.props.translate('tags')}
                </h4>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id='tag_1'
                      label={
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st1
                          ? this.props.logInUsers.attributes.st1
                          : this.props.translate('tag_1')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_1 !== undefined
                          ? this.props.form.tag_1
                          : this.props.form.attributes.tag_1) || ''
                      }
                      onChange={this.props.handleChange('tag_1')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id='tag_2'
                      label={
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st2
                          ? this.props.logInUsers.attributes.st2
                          : this.props.translate('tag_2')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_2 !== undefined
                          ? this.props.form.tag_2
                          : this.props.form.attributes.tag_2) || ''
                      }
                      onChange={this.props.handleChange('tag_2')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id='tag_3'
                      label={
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st3
                          ? this.props.logInUsers.attributes.st3
                          : this.props.translate('tag_3')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_3 !== undefined
                          ? this.props.form.tag_3
                          : this.props.form.attributes.tag_3) || ''
                      }
                      onChange={this.props.handleChange('tag_3')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id='tag_4'
                      label={
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st4
                          ? this.props.logInUsers.attributes.st4
                          : this.props.translate('tag_4')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_4 !== undefined
                          ? this.props.form.tag_4
                          : this.props.form.attributes.tag_4) || ''
                      }
                      onChange={this.props.handleChange('tag_4')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id='tag_5'
                      label={
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st5
                          ? this.props.logInUsers.attributes.st5
                          : this.props.translate('tag_5')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_5 !== undefined
                          ? this.props.form.tag_5
                          : this.props.form.attributes.tag_5) || ''
                      }
                      onChange={this.props.handleChange('tag_5')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid> */}
              </Grid>
            </form>
          }
        />
        {this.state.attm_isVisable && (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType={'driver'}
            isHaveAccess={this.props.isHaveAccess}
          />
        )}
      </div>
    )
  }
}

const AddDrivers = withStyles(styles)
export default connect(mapStateToProps)(AddDrivers(withLocalize(addDrivers)))
