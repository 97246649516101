import React, { Component } from "react";
import Routes from "./Routes";
import { withLocalize } from "react-localize-redux";
import de from "./lang/de.json";
import en from "./lang/en.json";
import fr from "./lang/fr.json";
import fa from "./lang/fa.json";
import gu from "./lang/gu.json";
import he from "./lang/he.json";
import hi from "./lang/hi.json";
import hu from "./lang/hu.json";
import id from "./lang/id.json";
import it from "./lang/it.json";
import ja from "./lang/ja.json";
import urdu from "./lang/urdu.json";
import arabic from "./lang/arabic.json";
import ku from "./lang/kurdish.json";
import km from "./lang/khmer.json";
import ko from "./lang/ko.json";
import mn from "./lang/mn.json";
import my from "./lang/my.json";
import ne from "./lang/ne.json";
import pt from "./lang/pt.json";
import ru from "./lang/ru.json";
import sq from "./lang/sq.json";
import sr from "./lang/sr.json";
import te from "./lang/te.json";
import th from "./lang/th.json";
import tr from "./lang/tr.json";
import zh from "./lang/zh.json";
import am from "./lang/amharic.json";
import spanish from "./lang/es.json";
import Loader from "./Layout/Loader";
import Axios from "axios";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import instance from "./axios";
import axios from "axios";
import DrawMap from "./Components/Maps/DrawMap";
import { setTrackId, unsetDeviceId, updateDeviceVisible } from "./Actions/Devices";

const CancelToken = axios.CancelToken;
let source;

let mapLayer = {
  id: 'googleRoad',
  name: 'Google Road',
  maxZoom: 22,
  checked: true,
  url: '//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  trafficUrl: '//{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}',
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  attribution: 'Google Roadmap'
}



const onMissingTranslation = ({ translationId }) => translationId;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabling: "",
      callResponse: false,
      loading: false,
      isDown: false,

      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      selecteItemUrl: "",
      activeOperation: "",
      vehicleAddress: "",
      vehicleLat: "",
      vehicleLon: "",
      selecteditemId:"",
      trackersApiResponce: false,
      trackerData: "",
      assignVehicle: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      animCount: 0,
      assigned: false,
      tracking: false,
      applied: false,
      resourceList: false,
      allVehicleLocation: {},
      eventLat:0,
      eventLng:0,
      eventDeviceTime:0,
      routes: {},
      kmlContent: '',
      tracksPage:false,
      serverTimeZoneName:"",
      deviceCategory:""
    };
    this.map = null;

    source = CancelToken.source();

    this.props.initialize({
      languages: [
        { name: "Albanian", code: "sq" },
        { name: "Amharic", code: "am" },
        { name: "Burmese", code: "my" },
        { name: "Chinese", code: "zh" },
        { name: "English", code: "en" },
        { name: "German", code: "de" },
        { name: "French", code: "fr" },
        { name: "Gujarati", code: "gu" },
        { name: "Hebrew", code: "he" },
        { name: "Hindi", code: "hi" },
        { name: "Hungarian", code: "hu" },
        { name: "Indonesian", code: "id" },
        { name: "Italian", code: "it" },
        { name: "Japanese", code: "ja" },
        { name: "Arabic", code: "ar" },
        { name: "Central Khmer", code: "km" },
        { name: "Korean", code: "ko" },
        { name: "Kurdish", code: "ku" },
        { name: "Mongolian", code: "mn" },
        { name: "Nepali", code: "ne" },
        { name: "Persian", code: "fa" },
        { name: "Portuguese", code: "pt" },
        { name: "Russian", code: "ru" },
        { name: "Serbian", code: "sr" },
        { name: "Telugu", code: "te" },
        { name: "Thai", code: "th" },
        { name: "Turkish", code: "tr" },
        { name: "Urdu", code: "ur" },
        { name: "Spanish", code: "es" },
      ],
      //translation: en,
      options: {
        renderToStaticMarkup: false,
        defaultLanguage: "en",
        onMissingTranslation,
      },
    });

    this.props.addTranslationForLanguage(am, "am");
    this.props.addTranslationForLanguage(en, "en");
    this.props.addTranslationForLanguage(de, "de");
    this.props.addTranslationForLanguage(fr, "fr");
    this.props.addTranslationForLanguage(fa, "fa");
    this.props.addTranslationForLanguage(gu, "gu");
    this.props.addTranslationForLanguage(he, "he");
    this.props.addTranslationForLanguage(hi, "hi");
    this.props.addTranslationForLanguage(hu, "hu");
    this.props.addTranslationForLanguage(id, "id");
    this.props.addTranslationForLanguage(it, "it");
    this.props.addTranslationForLanguage(ja, "ja");
    this.props.addTranslationForLanguage(km, "km");
    this.props.addTranslationForLanguage(ko, "ko");
    this.props.addTranslationForLanguage(ku, "ku");
    this.props.addTranslationForLanguage(mn, "mn");
    this.props.addTranslationForLanguage(my, "my");
    this.props.addTranslationForLanguage(ne, "ne");
    this.props.addTranslationForLanguage(pt, "pt");
    this.props.addTranslationForLanguage(ru, "ru");
    this.props.addTranslationForLanguage(sq, "sq");
    this.props.addTranslationForLanguage(sr, "sr");
    this.props.addTranslationForLanguage(te, "te");
    this.props.addTranslationForLanguage(th, "th");
    this.props.addTranslationForLanguage(tr, "tr");
    this.props.addTranslationForLanguage(zh, "zh");
    this.props.addTranslationForLanguage(urdu, "ur");
    this.props.addTranslationForLanguage(arabic, "ar");
    this.props.addTranslationForLanguage(spanish, "es");

    /* document.addEventListener("visibilitychange", event => {

      if(document.visibilityState === 'hidden') {
        
        localStorage.setItem('rawValue', new Date().toISOString());
        
      } else {
        
        const startTime = moment(localStorage.getItem('rawValue'));
        const duration = moment.duration(moment().diff(startTime));
        const sec = duration.asMinutes();

        if(sec > 60) {
          this.checkWebsite();
        }

      }
      
     
    }) */
  }

  checkWebsite = () => {
    this.setState({ loading: true, callResponse: false }, () => {
      instance({
        url: `/api/accounts/labeling`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          hostUrl: window.location.host,
        },
      })
        // .then(response => {
        //   this.setState({loading: false});
        //   if (response.ok) {
        // response.json()
        .then((res) => {
          this.setState(
            {
              whiteLabling: res,
              callResponse: true,
              loading: false,
              serverinfo: { attributes: { ...res } },
            },
            () => {
              // this.props.dispatch(ServerInfo(this.state.serverinfo));
            }
          );
          document.title = res.serviceTitle;
          let domElements = document.getElementById("favicon");
          if (res && res.favIcon && domElements) {
            domElements.setAttribute("href", res.favIcon);
          }
          //   })
          // } else {
          //   this.setState({
          //     isDown: true
          //   })
          //     throw response
          //   }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            isDown: false,
            callResponse: true,
          });
          // errorHandler(error, this.props.dispatch, this.errorCallBack)
        });
    });
  };

  setCookie(name, value, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "= " + encodeURIComponent(value) + ";" + expires;
  }

  UNSAFE_componentWillMount() {


    this.checkWebsite();
    // this.saveIp();
    this.map && this.map .setMaxZoom(16)
            .fitBounds([
              [33, 67],
            ]);
        setTimeout(() => {
          this.map && this.map.setMaxZoom(mapLayer.maxZoom);
          this.setState({ applied: true, animCount: 1 });
        }, 200);
  }
  
  saveIp = async () => {
    instance({
      url: `https://api.ipify.org/?format=json`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // data: {
      //   ...obj,
      // },
    }).then((response) => {
      this.setCookie("UIP", response.data.ip, 1);
    });
    // const response = await Axios.get("https://api.ipify.org/?format=json");
    // if (response.status === 200 && response.data && response.data.ip) {
    //   this.setCookie("UIP", response.data.ip, 1);
    // }
  };

  
  showDetail =(item)=> {
    /* let device = null
    device = this.props.devices.find(d => item.deviceId === d.id)
    if (!device) {
      device = null
    }
    */
      this.getPostionAddress(item.positionId, item.deviceId);
    // if (item.read !== true) this.makeRead(item);
    // this.setSelectedDevice();

    

    // const scrollTime = this.state.applied ? 0 : 1000;

    // setTimeout(() => {
    //   if (this.detailsDiv.current) {
    //     this.setState({ applied: true });
    //     this.detailsDiv.current.scrollIntoView({
    //       behavior: "smooth",
    //       block: "start",
    //     });
    //   }
    // }, scrollTime);
  }

  hideDetails = () => {
    this.setState({
      eventEnableMap: false,
      itemDetails: "",
      deviceData: {},
      longitude: 0,
      latitude: 0,
    });
  };

  getPostionAddress = (id, deviceId) => {
    this.setState({
            address: 0,
            eventLat: 0,
            eventLng:0,
            eventDeviceTime:0,
            deviceCategory:""
    },()=>{
      if (id !== 0) {
        instance({
          url: `/api/positions`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            id: id,
          },
        })
          .then((postion) => {
            let deviceIcon = "";
            if(deviceId && this.props.devices2)  {
              const d = this.props.devices2.find(dev => dev.id === deviceId);
              if(d) {
                deviceIcon = d.category || 'default';
              }
            } 
            this.setState({
              eventEnableMap: true,
              // itemDetails: { ...item, read: true },
              address: postion[0].address,
              eventLat: postion[0].latitude,
              eventLng: postion[0].longitude,
              eventDeviceTime:postion[0]?.deviceTime||"",
              deviceCategory:deviceIcon
            })
          })
          .catch((error) => {
            this.setState({
              address: null,
              latitude: 0,
              longitude: 0,
            });
          });
      } else {
        this.setState({
          address: null,
          latitude: 0,
          longitude: 0,
        }) 
      }
    })
  };

  setMapRef = (ref) => {
    this.map = ref;
  };

  copyToClipboard = obj => {
    const el = document.createElement('textarea')
    el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

  collapseSidebar() {
    const w = window.innerWidth;
    if (w <= 959) {
      const v = this.state.collapse;
      this.setState({ collapse: !v }, () => {
        if (!v) {
          this.collapseSidebarClass("sidebar-collapse");
        } else {
          this.collapseSidebarClass("");
        }
      });
    }
  }
   collapseSidebarClass(value) {
    this.setState({ collapse_sidebar: value || "" });
  }

  viewOnMap = (item) => {
    let relatedData=this.props.deviceRelatedData&&this.props.deviceRelatedData[item.id]
    if (relatedData && relatedData.exists) {
      this.props.dispatch(
        updateDeviceVisible({ checked: true, id: item.id })
      );
      this.props.dispatch(setTrackId(item.id));
    }

    if (this.props.deviceId !== item.id) {
      this.props.dispatch(unsetDeviceId({ id: item.id }));
      document.body.classList.remove("shown-widgets");
    }
    this.collapseSidebar();
  };

  drawTracks = (routes,kmlContent)=>{
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }

    this.setState({routes,kmlContent,tracksPage:true, serverTimeZoneName:serverTimeZoneName})
  }
  render() {
    const currentUrl = window.location.href;

    const { ...resetProps } = this.props;    
    if (this.state.callResponse) {
      return (
        <div style={{position:'relative',background:'#e6edf3'}}>
          <div style={{position:"inherit", zIndex:6}}>
            <Routes {...this.state} {...this.props} 
                showDetail={this.showDetail}
                hideDetails={this.hideDetails}
                viewOnMap={this.viewOnMap}
                drawTracks={this.drawTracks}
                 />
          </div>
          <div style={{position:"relative",zIndex:5, }}>
          {currentUrl.includes('login') ? (
            <div className="white-label-bg" style={{backgroundSize:"cover", background: 'url(/assets/images/login-bg.jpg)'}}>
            </div>
          ) :
              <>
              <DrawMap
                {...resetProps}
                setMapRef={this.setMapRef}
                devices={this.props.devices&&this.props.devices.data}
                copyToClipboard={this.copyToClipboard}
                themeColors={this.props.themecolors}
                mapLayer={mapLayer}
                eventLat={this.state.eventLat}
                eventLng={this.state.eventLng}
                eventDeviceTime={this.state.eventDeviceTime}
                eventEnableMap={this.state.eventEnableMap}
                deviceCategory={this.state.deviceCategory}
                themecolors={this.props.themecolors}
                serverTimeZoneName={this.state.serverTimeZoneName}
                routes={this.state.routes}
                kmlContent={this.state.kmlContent}
                tracksPage={this.state.tracksPage}
              />
              </>
          }
            </div>
 
        </div>
      );
    }
    if (this.state.loading) {
      return <Loader />;
    } else {
      if (this.state.isDown) {
        return (
          <h2 style={{ textAlign: "center" }}>
            Oops Something Went Wrong Try Again
          </h2>
        );
      } else {
        return null;
      }
    }
  }
}
const mapStateToProps = (state) => {
  return {
    devicesFetched: state.devices.isFetching,
    devices: state.devices,
    devices2:state.devices2,
    trailers: state.trailers,
    tags: state.tags,
    driverTags: state.driverTags,
    allNotifications: state.allNotifications,
    deviceId: state.deviceId,
    trackId: state.trackId,
    drivers: state.drivers,
    ServerSetting: state.ServerSetting,
    mapLayer: state.mapLayer,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    filterTagsList: state.filterTagsList,
    filterDriversList: state.filterDriversList,
    relatedData:state.relatedData,
    deviceRelatedData:state.deviceRelatedData,
    filterList:
      state.filterList && state.filterList.filters
        ? state.filterList.filters
        : [],
  };
};
// export default withLocalize(App);
export default connect(mapStateToProps)(withLocalize(App));
