import React, { Component } from "react";
import PropTypes from "prop-types";
import MainUnitModal from "./MainUnitModal";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import { withLocalize } from "react-localize-redux";
import { removeDevice, resetFilters } from "./../../Actions/Devices";
import Layout from "../../Layout";
import { withSnackbar } from "notistack";
import { getGroups, saveGroupWiseDevices } from "../../Actions/Groups";
import EmptyState from "../../Components/common/EmptyState";
import { DeviceModal } from "../../Components/Devices/deviceModal";
import { checkPrivileges, errorHandler } from "../../Helpers/index";
import "./Style.scss";
import { withStyles } from "@material-ui/core/styles";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import Notifications from "react-notification-system-redux";
import withResources from "../HOCRecources";
import ResourceModal from "../../Components/Recources/resourceModal";
import instance from "../../axios";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    background: "#ebebeb",
    marginLeft: 5,
    borderRadius: "4px 4px 0 0",
  },
  selected: {
    background: "#fff",
    color: "#333 !important",
  },
  scrollButtons: {
    color: "#333",
  },
  indicator: {
    backgroundColor: "#333",
  },
});
const deviceDataFormat = {
  id: "",
  attributes: "",
  name: "",
  uniqueId: "",
  status: "",
  disabled: "",
  lastUpdate: "",
  positionId: "",
  groupId: "",
  phone: "",
  model: "",
  contact: "",
  category: "",
  geofenceIds: "",
};

class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //comman for all
      isNewAddEnable: false,
      activeOperation: "",
      slectedUnits: "",
      // all fields of form
      resFormData: { ...deviceDataFormat },
      resourceList: false,
      linkResource: false,

      initFetch: false,
      currentPage2: 1,
      pageSize2: 50,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: "",
      },
      searchText2: "",
      limit: 0,
    };

    this.addNewDevice = this.addNewDevice.bind(this);
    this.addNewGroup = this.addNewGroup.bind(this);
    this.removeDevice = this.removeDevice.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.checkDeviceLimit();
  }

  checkDeviceLimit = () => {
    instance("/api/devices/count")
      .then((response) => {
        if (response.ok) {
          response.json().then((count) => {
            this.setState({
              limit: count.remaningLimit > 0 || count.remaningLimit === -1,
            });
          });
        } else {
          this.setState({
            limit: 0,
          });
          throw response;
        }
      })
      .catch((e) => {});
  };

  removeDevice() {
    let { slectedUnits } = this.state;

    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: "DELETE",
    })
      .then((response) => {
        // if (response.status === 204) {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("trackersIsDeleted"),
            autoDismiss: 10,
          })
        );
        this.props.dispatch(removeDevice(slectedUnits.id));
        this.props.history.push("/units");
        this.setState({
          onDeleteConfirmationUnits: false,
        });
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }
  addNewGroup() {
    this.props.history.push("/units/add/group");
    /*
    this.setState({
      isNewAddEnable: true,
      activeOperation: 'add',
      isVisable: false,
    })*/
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (!["addResource", "editResource"].includes(this.state.activeOperation)) {
      if (n.addUnitScreen) {
        this.setState({
          isNewAddEnable: true,
          activeOperation: "add",
        });
      } else if (n.match.params.id) {
        this.setState({
          isNewAddEnable: false,
          activeOperation: "edit",
        });
      }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }
  // new code for changing attribute

  addNewDevice() {
    this.props.history.push("/units/add/unit");
    /*this.setState({
      isNewAddEnable: true,
      activeOperation: 'add'
    })*/
  }

  onRemovedDevice = (e, item) => {
    e.preventDefault();
    this.setState({
      slectedUnits: item,
      onDeleteConfirmationUnits: true,
    });
    return false;
  };

  onCloseModal = () => {
    this.setState({
      isNewAddEnable: false,
      activeOperation: "",
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false,
    });
  };

  fetchMoreItems2 = (page, searchText) => {
    this.setState(
      {
        searchText2: "",
      },
      () => {
        this.fetchData2(
          this.props.logInUser.id,
          this.state.currentPage2,
          this.state.pageSize2,
          true
        );
      }
    );
  };

  resetFrom = () => {
    this.setState({
      activeOperation: "",
    });
  };

  showResources = (resourceList) => {
    if (!resourceList) {
      this.props.dispatch(saveGroupWiseDevices([]));
      this.props.dispatch(resetFilters());
    }
    this.setState({
      resourceList,
      isVisable: false,
      showItemDetail: "",
      activeOperation: "",
    });
  };
  addResource = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "addResource",
      selecteditem: "",
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onEditResource = (item) => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "editResource",
      selecteditem: item,
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onLinkResource = (item) => {
    this.setState(
      {
        linkResource: true,
        selecteditem: item,
      },
      () => {
        this.props.fetchNestedItems(item.id, 1);
      }
    );
  };
  onCloseResource = () => {
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  };

  searchItems2 = (text) => {
    this.setState(
      {
        searchText2: text,
      },
      () => {
        this.fetchData2(this.props.logInUser.id, 1, this.state.pageSize2, true);
      }
    );
  };

  removeDuplicates = (n, o) => {
    let items = [];
    o.map((j) => {
      let found = n.data.find((e) => e.id === j.id);
      if (found) {
        items.push(found);
      }
      return null;
    });

    if (!items.length) {
      this.setState(
        {
          itemPagination2: {
            ...n,
            items: o.concat(n.data),
          },
          currentPage2: n.hasNext ? n.page + 1 : n.page,
        },
        () => {
          this.props.dispatch(getGroups(o.concat(n.data)));
        }
      );
    }
  };

  fetchData2 = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText2;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }

    let items = this.state.itemPagination2.items;
    if (reset) {
      this.setState(
        {
          itemPagination2: { ...this.state.itemPagination2, items: [] },
        },
        () => {
          items = [];
        }
      );
    }

    instance({
      url: `/api/groups/get`,
      method: "GET",
      parmas: {
        page: page,
        limit: perPage,
        search: this.state.searchText2,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((res) => {
        this.removeDuplicates(res, items);
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  componentWillUnmount() {
    this.setState({
      isNewAddEnable: false,
      activeOperation: "",
      slectedUnits: "",
      resFormData: { ...deviceDataFormat },

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      resourceList: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      currentPage2: 1,
      pageSize2: 50,
      searchText2: "",
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: "",
      },
    });
  }

  render() {
    if (checkPrivileges("device") || checkPrivileges("group")) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {" "}
            -- {this.props.translate("end")} --{" "}
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {" "}
            {this.props.translate("notFound")}{" "}
          </p>
        );
      return (
        <Layout
          {...this.props}
          removeDevice={this.onRemovedDevice}
          resetFrom={this.resetFrom}
          addDevice={this.addNewDevice}
          limit={this.state.limit}
          goAddGroup={this.addNewGroup}
          fetchMoreItems={this.fetchMoreItems}
          itemPagination={{ ...this.state.itemPagination, endMessage }}
          searchItems={this.searchItems}
          fetchMoreItems2={this.fetchMoreItems2}
          itemPagination2={{ ...this.state.itemPagination2 }}
          searchItems2={this.searchItems2}
          showResources={this.showResources}
          onEditResource={this.onEditResource}
          onLinkResource={this.onLinkResource}
          addResource={this.addResource}
          resourceList={this.state.resourceList}
        >
          {!["addResource", "editResource"].includes(
            this.state.activeOperation
          ) ? (
            <div className="main-content-page has-padding">
              {this.state.onDeleteConfirmationUnits && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.removeDevice}
                  title={this.props.translate("areYouWantToDelete")}
                  children={this.state.slectedUnits.name}
                />
              )}

              {this.state.isNewAddEnable && this.props.ServerSetting ? (
                <DeviceModal
                  {...this.props}
                  logInUser={this.props.logInUser}
                  onCloseModal={this.onCloseModal}
                  activeOperation={this.state.activeOperation}
                  selecteditem={this.state.selecteditem}
                  checkDeviceLimit={this.checkDeviceLimit}
                />
              ) : this.props.match.params.id ? (
                <MainUnitModal
                  id={this.props.match.params.id}
                  history={this.props.history}
                  hash={this.props.location.hash}
                />
              ) : (
                <EmptyState text={this.props.translate("noTrackersSelected")} />
              )}
            </div>
          ) : null}
          {/* {this.state.editResource ?  */}
          <ResourceModal
            changeResource={this.props.changeResource}
            selectedResourse={this.state.selecteditem}
            activeOperation={this.state.activeOperation}
            fetchNestedItems={this.props.fetchNestedItems}
            itemPagination={this.props.devices3}
            assignItem={this.props.assignItem}
            unassignItem={this.props.unassignItem}
            nestedResources={this.props.nestedResources}
            translate={this.props.translate}
            editResource={this.state.editResource}
            linkResource={this.state.linkResource}
            onCloseResource={this.onCloseResource}
            themecolors={this.props.themecolors}
            itemType="Device"
          />
          {/* //  : null} */}
        </Layout>
      );
    } else {
      return null;
    }
  }
}

const mapState = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  positions: state.positions,
  devices: state.devices,
  devices3: state.devices3,
  themecolors: state.themeColors,
});

Units.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = connect(mapState);

export default mapStateToProps(
  withSnackbar(withLocalize(withStyles(styles)(withResources(Units, "Device"))))
);
