import React from "react";
import {
  ResponsiveContainer,
  BarChart as BChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Tooltip
} from "recharts";


const CustomTooltip = ({ active, payload, label, dKey }) => {
    if (active) {
      return (<div className="recharts-default-tooltip">
          <strong>{label}</strong> <br />
          {dKey}: <strong>{payload && payload[0]?.payload[dKey]}</strong>
        </div>);
    }

    return null;
  };

export const BarChart = ({ data, yKey, xKey, dKey, fillColor, xAxisFill }) => {
  
    return (<ResponsiveContainer width={"100%"} height={'90%'}>
        <BChart  data={data} layout="vertical">
            <XAxis hide stroke={ xAxisFill } tickLine={{ stroke: xAxisFill, fill: xAxisFill }} type="number" tick={{ fill: xAxisFill }} />
            <YAxis
                yAxisId={0}
                dataKey={xKey}
                type="category"
                tickFormatter={ value => value.toLocaleString() }
                tickLine={{ stroke: xAxisFill, fill: xAxisFill }}
                // tick={{ fill: xAxisFill }}
                stroke={ xAxisFill }
                // tick={{width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                width={200}
                // style={{
                //   overflow: 'hidden',
                //   textOverflow: 'ellipsis',
                //   whiteSpace: 'nowrap'
                // }}
                tick={
                  // ({ payload, x, y, index }) => 
                {
                    // const text = payload.value;
                    
                    // return (
                    //   <text
                    //     x={x}
                    //     y={y}
                    //     // width={10}
                    //     // textAnchor="end"
                    //     width={150} // set the desired width
                    //       textAnchor="end"
                    //       style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 12,
                            fill: xAxisFill
                          }}
                //       >
                //         {text}
                //       </text>
                //     );
                //   }}
                // // overflow="hidden"
            />
            <Tooltip 
             content={<CustomTooltip yKey={yKey} xKey={xKey} dKey={dKey} />}
             cursor={{fill: 'rgba(0,0,0,0.15)'}}
            />
            <Bar dataKey={ yKey } minPointSize={30} barSize={10}>
                {data.map(d => {
                return <Cell key={ d[xKey] } fill={ fillColor } />;
                })}
            </Bar>
        </BChart>
    </ResponsiveContainer>);
}