import React from 'react'
import { Collapse, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Paper, RadioGroup, Tooltip } from '@material-ui/core'
import { checkPrivileges, selectAll, validEmailPattern } from '../../Helpers'
import Loader from '../../Layout/Loader'
import Button from '../../Components/common/Button'
import SimpleModalWrapped from '../../Components/common/Modal'
import TextField from '../../Components/common/TextField'
import SingleSelect from '../../Components/common/SingleSelect'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import PublishIcon from '@material-ui/icons/Publish';
import SearchDevices from '../../Components/Devices/searchItems'
import AsyncSelect from '../../Components/common/asyncSelect'
import DeleteIcon from '@material-ui/icons/Delete'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { FormHelperText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Checkbox from '../../Components/common/Checkbox'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment'
const HelperTextStyle = {
  display:'flex',
  justifyContent:'flex-end',
  color:' white', 
  marginTop:0,
  fontSize:10
};
export const CreateExpense = ({
    expenseForm,
    handleChangeExpense,
    onChangedDevice,
    serviceExpenseCollection,
    handleChangeServices,
    drivers,
    vehicles,
    subTypes,
    submitExpense,
    closeCreateFrom,
    addMore,
    isVisible,
    deleteRow,
    themecolors,
    selectedUnit,
    vehicleTracker,
    uploadImage,
    translate,
    mode,
    uploadFile,
    loader,
    deleteImage,
    image64Type,
    expenseType,
    logInUser,
    dueActive,
    imagePopup,
    check
  }) => {
    let image =expenseForm.files && expenseForm.files.split(',')
    let imageCount = 1;
    let searchVehicle = [];
    const searchList = async (inputValue) => {
      searchVehicle = [];
      vehicles.map(item =>  ((item.label+' '+item.vehicleLP).toLowerCase() ).includes(inputValue.toLowerCase())? searchVehicle.push(item) : '')

      return await searchVehicle
    }
  return (
    <div style={{ height: '100%' }}>
      <div style={{ background: themecolors.backgroundColor, color: themecolors.textColor, borderRadius: 6 }}>
        <SimpleModalWrapped
            // {...props}
            visable
            title={translate('expenses')}
            modalControle={()=> closeCreateFrom( mode? 'due' : 'history')}
            notShowAttributesBtn
            btnCloseText='sharedBack'
            content={
            <>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <TextField
                      id='name'
                      label={translate('sharedName')}
                      required
                      placeholder=''
                      value={expenseForm.name || ''}
                      onChange={e => handleChangeExpense('name', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item md={6}
                      style={{ postion: 'relative', zIndex: 99 }}>
                      <SearchDevices
                          isMulti={false}
                          api='devices'
                          name='devices'
                          isClearable
                          onChange={handleChangeExpense}
                          // value={expenseForm&&expenseForm.device}
                          value={expenseForm.device.label ? 
                            {
                              id: expenseForm.device.id,
                              value: expenseForm.device.id,
                              label: expenseForm.device.label,
                            }
                            :{
                              id: expenseForm.device.id,
                              value: expenseForm.device.id,
                              label: expenseForm.device.name,
                            }
                          }
                          placeholder={'Devices'}
                      />
                  </Grid> */}
                  <Grid item md={6}>
                    <TextField
                      id='sharedDescription'
                      label={translate('sharedDescription')}
                      type='text'
                      multiline
                      placeholder=''
                      value={expenseForm.description || ''}
                      onChange={e => handleChangeExpense('description', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 6 }}>
                    <AsyncSelect
                      value={expenseForm.vehicles}
                      placeholder={translate('selectVehicle')}
                      selectName='vehicles'
                      // resultMessage={resultMessage}
                      defaultOptions={searchVehicle.length ? searchVehicle : vehicles}
                      handleChange={handleChangeExpense}
                      loadOptions={searchList}
                      isMulti={false}
                    />
                    {/* <SingleSelect
                        array={vehicles}
                        selectName='vehicles'
                        label={'vehicles'}
                        value={expenseForm.vehicles&&expenseForm.vehicles.id
                            ? {
                                id: expenseForm.vehicles.id,
                                value: expenseForm.vehicles.id,
                                label: expenseForm.vehicles.label
                              }
                            : {
                              key: '',
                              id: '',
                              value: '',
                              label: 'Vehicles*'
                            }
                          }
                        handleChange={handleChangeExpense}
                        canRemove={true}
                        canAssign={true}
                      /> */}
                  </Grid>
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 5 }}>
                    <SingleSelect
                        array={drivers}
                        selectName='driver'
                        label={translate('selectedDrivers')}
                          value={expenseForm.driver&&expenseForm.driver.id
                            ? {
                                id: expenseForm.driver.id,
                                value: expenseForm.driver.id,
                                label: expenseForm.driver.name,
                                uniqueId:expenseForm.driver.uniqueId,
                              }
                            : ''
                          }
                        handleChange={handleChangeExpense}
                        canRemove={true}
                        canAssign={true}
                      />
                  </Grid>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('date')}
                          variant='outlined'
                          fullWidth
                          required
                          format={'dd/MM/yyyy'}
                          maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.serviceTime
                              ? new Date(`${expenseForm.serviceTime}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('serviceTime', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6}></Grid>
                  {dueActive && (<><Grid item md={6} style={{marginLeft: 5}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <Checkbox
                        checked={expenseForm&&expenseForm.attributes&&expenseForm.attributes.updateMaintenance}
                        onChange={e => handleChangeExpense('updateMaintenance', e.target.checked)}
                        color='primary'
                        label={'Update Maintenance'}
                      />
                      <Tooltip 
                        classes={{
                          popper: 'menu-popper',
                          tooltip: 'menu-popper-tooltip'
                        }}
                        title={translate('updateMaintenanceTooltip')}>
                        <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
                      </Tooltip>
                    </div>
                  </Grid><Grid item md={6} style={{marginLeft: 5}}></Grid></>)}
                  <Grid item md={6} >
                    <div style={{
                        background: themecolors.themeLightColor + '40',
                        marginTop: 5,
                        padding: 10,
                        maxHeight: 575,
                        // overflow: 'auto',
                        boxSizing: 'border-box',
                        borderRadius: 10
                      }}>
                    <TextField
                      id='odometer'
                      label={translate('odometer')}
                      type='text'
                      placeholder=''
                      required
                      value={expenseForm.odometer || ''}
                      onChange={e => handleChangeExpense('odometer', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                    {check ? 
                        <FormHelperText style={HelperTextStyle}>
                              {expenseForm.currentOdometer ? ('Current : '+expenseForm.currentOdometer):('Current : 0')}
                        </FormHelperText>
                       : ''}
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div style={{
                      background: themecolors.themeLightColor + '40',
                      marginTop: 5,
                      padding: 10,
                      maxHeight: 575,
                      // overflow: 'auto',
                      boxSizing: 'border-box',
                      borderRadius: 10
                      }}>   
                      <TextField
                        id='engineHours'
                        label={translate('reportEngineHours')}
                        type='text'
                        placeholder=''
                        value={expenseForm.engineHours || ''} 
                        onChange={e => handleChangeExpense('engineHours', e.target.value)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                      {check ? 
                        <FormHelperText style={HelperTextStyle}>
                          {expenseForm.currentEngineHours ? ('Current : '+expenseForm.currentEngineHours): ('Current : 0')}
                        </FormHelperText>
                        : ''  
                      }
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id='Address'
                      label={translate('address')}
                      type='text'
                      multiline
                      rows={3}
                      placeholder=''
                      value={expenseForm.address || ''}
                      onChange={e => handleChangeExpense('address', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                </Grid>
                </Grid>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Grid container spacing={0}>
                  <Grid item md={12} xs={12} style={{display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
                      <h3>{translate('addAllExpenses')}</h3>
                </Grid>
                  <Grid item md={12} xs={12} style={{ postion: 'relative', zIndex: 100 }}>
                        <SingleSelect
                            array={expenseType}
                            selectName='type'
                            label={translate('sharedType')}
                            isDisabled={dueActive}
                            value={expenseForm.type && expenseForm.type.name ?
                              {
                                key: expenseForm.type.key,
                                id: expenseForm.type.id,
                                value: expenseForm.type.name,
                                label: expenseForm.type.name
                              } :''
                            }
                            handleChange={handleChangeExpense}
                            canRemove={true}
                            canAssign={true}
                            // index={index}
                            />
                    </Grid> 
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={1} >
                      {serviceExpenseCollection.map((row, index) => (
                        <Grid item md={12} xs={12} style={{
                          background: themecolors.themeLightColor + '40',
                          marginTop: 5,
                          padding: 16,
                          maxHeight: 575,
                          // overflow: 'auto',
                          boxSizing: 'border-box',
                          borderRadius: 10
                        }}>
                        <Grid container spacing={1} >
                          <Grid item md={11} xs={12} style={{ postion: 'relative', zIndex: (99 - index)}}>
                            <SingleSelect
                              id='expensetypeId'
                              array={(expenseForm.type && subTypes) || []}                  
                              selectName='expensetypeId'
                              label={translate('subType')}
                              value={row.expensetypeId ?
                              {
                                key: row.expensetypeId.key,
                                id: row.expensetypeId.id,
                                value: row.expensetypeId.id,
                                label: row.expensetypeId.subType ? row.expensetypeId.subType : row.expensetypeId.label
                              }: ''
                              }
                              handleChange={handleChangeServices}
                              canRemove={true}
                              index={index}
                              canAssign={true}
                            />
                          </Grid>    
                          <Grid item md={1} style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                          {serviceExpenseCollection.length > 1 ? (
                            <DeleteIcon
                              onClick={e => deleteRow(row, index, e)}
                              style={{ cursor: 'pointer' }}
                              />            
                            ) 
                          : <Grid item md={2} style={{marginTop:11}}></Grid> }
                        </Grid>                      
                          <Grid item md={6}>
                            <TextField
                              id='Quantity'
                              label={translate('quantity')}
                              type='number'
                              placeholder=''
                              value={row.quantity || ''}
                              onChange={e => handleChangeServices('quantity', e.target.value, index)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                            />
                            </Grid>
                        <Grid item md={6}>
                            <TextField
                            id='Cost'
                            label={translate('cost')}
                            type='number'
                            placeholder=''
                            value={row.cost || ''}
                            onChange={e => handleChangeServices('cost', e.target.value, index)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            InputProps={{
                              style: {padding: 0},
                              endAdornment: (
                                <InputAdornment
                                  position='start'
                                  disableTypography
                                >
                                  {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                                </InputAdornment>
                              )
                            }}
                            />
                      </Grid>                                                                     
                        </Grid>  
                        </Grid>
                        ))}
                         
                    </Grid>
                  </Grid>
                
                <Grid item md={12} xs={12}>

                  <div style={{float:'right', marginTop:10}}>
                      {expenseForm.type ? (
                        <div>
                          <Grid item sm={2} xs={12} >
                            <Button
                                  // href={emptyLink}
                                  onClick={e => addMore(e)}
                                  size='small'
                                  color='inherit'
                                  style={{ minWidth: '70px' }}
                                >
                                {translate('addMore')}
                              </Button>
                          </Grid>
                        </div>
                      ) :''}
                      </div>
                </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{marginTop:12}}>
              <Grid item sm={6} xs={12}>
                  <Tooltip title='Upload Image'>
                        <Button
                          style={{ float: 'left'}}
                          variant='contained'
                          margin='dense'
                          component="label"
                          onChange={e => uploadImage(e,'expenseCreate')}
                        >
                          <input
                            type="file"
                            hidden
                            multiple
                          />
                          {translate('onlyAcceptDocFile')}
                          <PublishIcon  style={{ color: '#fff', marginLeft:15}}/>
                        </Button>
                      </Tooltip>
                </Grid>   
            </Grid>
              {!loader ? (<div style={{marginTop:12, display:'flex'}}>
                {image64Type&&image64Type.map((item,i) =>
                  item && (
                    <div> 
                      {item&&(
                        <a href={item} download target="_blank">
                          <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                        </a>
                      )}
                      <CloseIcon 
                        style={{verticalAlign:'top', marginLeft: -20, marginRight: 5}}
                        onClick={()=>deleteImage(i,image64Type,'base64')}
                      />
                      <p style={{marginLeft:20, marginTop: 0}}>File {imageCount++}</p>
                    </div>
                  )
                )}
                {image&&image.map((item,i) =>
                  item && (
                    <div> 
                      {item&&(
                      <a href={item} download target="_blank">
                        <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                      </a>
                      )}
                      <CloseIcon 
                        style={{verticalAlign:'top', marginLeft: -20, marginRight: 5}}
                        onClick={()=>deleteImage(i,image,'url')}
                      />
                      <p style={{marginLeft:20, marginTop: 0}}>File {imageCount++}</p>
                  </div>
                  )
                )}
              </div>): 
              <Grid container spacing={1} style={{marginTop:12}}>
                <Grid item sm={12} xs={12}><Loader defaultStyle={true}/></Grid>
            </Grid>}
             <hr/>
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button
                        size='small'
                        style={{ marginRight: 15 }}
                        // className='button-white'
                        onClick={()=>uploadFile(mode)} 
                        disabled={isVisible}
                    >
                        {translate('submit')}{' '}
                    </Button>
                </Grid>
             </Grid>           

            </>
        }
        />
        </div>
      </div>
  )
  }
   