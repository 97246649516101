import React, { Component } from "react";
import SearchField from "../../../Components/common/SearchField";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import isEqual from "react-fast-compare";
import { checkPrivileges, errorHandler } from "../../../Helpers";
import ConfirmDialoag from "../../../Components/common/ConfirmDialoag";
import Style from "style-it";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import { removedCommands } from "../../../Actions/Commands";
import Notifications from "react-notification-system-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import instance from "../../../axios";
class Commands extends Component {
  constructor(props) {
    super(props);
    this.state = { searchRecently: "" };
  }

  search(e) {
    this.props.searchItems(e.target.value);
  }

  render() {
    const { commands } = this.props;
    let cmds;
    if (commands.length) {
      cmds = commands;
    } else {
      cmds = commands.data;
    }
    

    const commands1 =
      cmds &&
      cmds.map((g) => (
        <ListRow
          key={g.id}
          item={g}
          editCommandForm={this.props.editCommandForm}
          modeEmpty={this.props.modeEmpty}
        />
      ));

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className="fms-sidebar sidebar-bg-layer">
          {checkPrivileges("command") && (
            <div>
              <div className="section-head section-head-filter">
                <ul className="filter-row">
                  <li>
                    <SearchField
                      label={this.props.translate("searchCommand")}
                      fullWidth
                      onChange={(e) => this.search(e)}
                    />
                  </li>
                  <li style={{ paddingLeft: 10, flex: 0.01 }}>
                    {checkPrivileges("commandCreate") && (
                      <IconButton onClick={(e) => this.props.openCreateFrom()}>
                        <AddIcon />
                      </IconButton>
                    )}
                  </li>
                </ul>
              </div>
              <ul className="list-view with-padding-right sidebar-devices-list">
                <Scrollbar
                  disableTracksWidthCompensation
                  scrollerProps={{
                    renderer: (props) => {
                      const { elementRef, ...restProps } = props;
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id="scrollableDiv"
                        />
                      );
                    },
                  }}
                >
                  {this.props.itemPagination ? (
                    <InfiniteScroll
                      dataLength={this.props.itemPagination.items.length}
                      next={this.props.fetchMoreItems}
                      hasMore={this.props.itemPagination.hasNext}
                      loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget="scrollableDiv"
                      endMessage={this.props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    );
  }
}

class ListRow1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onDeleteConfirmation: false,
    };
  }

  onDelete = () => {
    if (this.props.item.id) {
      instance({
        url: `api/commands/${this.props.item.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(removedCommands({ id: this.props.item.id }));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("savedCommandsIsDeleted"),
              autoDismiss: 10,
            })
          );
          this.props.modeEmpty();
          this.setState({ item: {} });
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true,
    });
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  render() {
    return (
      <li className={"list-row clearfix"} key={this.props.item.id}>
        <label
          className="checkbox"
          onClick={(e) => this.props.editCommandForm(this.props.item)}
        >
          <span className="unit-name">
            <span className="unit-name-text">
              {this.props.item.description}
            </span>
          </span>
        </label>
        <div className="callout-actions">
          {checkPrivileges("commandDelete") && (
            <span className="action" onClick={this.onRemovedItem}>
              <DeleteIcon className="material-icons" />
            </span>
          )}
        </div>

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.onDelete}
            title={this.props.translate("areYouWantToDelete")}
            children={this.props.item.description}
          />
        )}
      </li>
    );
  }
}

const mapState = (state) => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors,
});

const mapStateToProps = connect(mapState);
export const ListRow = mapStateToProps(withLocalize(ListRow1));
export default mapStateToProps(Commands);
