import React, { Component } from "react";
import { logout } from "./../../Actions/Devices";
import { getCookie } from "../../Helpers";
import { connect } from "react-redux";
import Loader from "./../../Layout/Loader";
import instance from "../../axios";
class logoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  componentWillMount() {
    let backurl = this.props.location.state;
    this.setState({
      loading: true,
    });
    if (getCookie("JSESSIONID")) {
      instance({
        url: `/api/session`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          if (localStorage.getItem("backtoAdmin")) {
            window.open(backurl, "_self");
          } else {
            this.props.history.push("/login");
            this.props.dispatch(logout());
          }
          localStorage.removeItem("backtoAdmin");
          localStorage.removeItem("frontPrivileges");
          this.setState({
            loading: false,
          });
          // } else {
          //   localStorage.removeItem('backtoAdmin')
          //   localStorage.removeItem('frontPrivileges')
          //   this.props.dispatch(logout())
          //   this.setState({
          //     loading: false
          //   })
          //   this.props.history.push('/login')
          //     throw response
          //   }
          // }).catch(error => {errorHandler(error, this.props.dispatch, this.errorCallBack)
        })
        .catch((error) => {
          localStorage.removeItem("backtoAdmin");
          localStorage.removeItem("frontPrivileges");
          this.props.dispatch(logout());
          this.setState({
            loading: false,
          });
          this.props.history.push("/login");
        });
    } else {
      this.setState({
        loading: false,
      });
      localStorage.removeItem("backtoAdmin");
      localStorage.removeItem("frontPrivileges");
      this.props.history.push("/login");
    }
  }
  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return null;
    }
  }
}
const mapState = (state) => {
  return {};
};
const mapStateToProps = connect(mapState);

export const LogOut = mapStateToProps(logoutPage);
