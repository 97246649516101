import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import TextField from './../../../Components/common/SearchField'
import { GroupModal } from './../../../Components/Groups/groupModal'
import { DevicesShortList, ListRow } from './../../../Components/Devices/shortList'
import { checkPrivileges } from '../../../Helpers'
import Style from 'style-it'
import Loader from './../../Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import Scrollbar from 'react-scrollbars-custom'
import { fetchMoreDevices } from '../../../Actions/Devices'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import RecourcesList from './RecourcesList'
import { useSelector } from 'react-redux'
import { FormControlLabel, IconButton } from '@material-ui/core'
import Switch from '../../../Components/common/Switch'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';


function importAll (r) {
  return r.keys().map(r)
}
const images = importAll(
  require.context('./../../../images', false, /\.(png|jpe?g|svg)$/)
)

export default class UnitsSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      switchView: 'units',
      isPopupVisable: false,
      is_gm_Visable: false,
    }
    this.switchView = this.switchView.bind(this)
  }

  switchView (type, e) {
    this.setState({
      switchView: type,
      isChecked: true,
      sort: 'ASC',
      search: ''
    })
  }

  toggleSearch = (val) => {
    if(val==="create"){
      this.setState({
        searchBox: true
      })
    }
    else{
      this.setState({
        searchBox: false
      }, ()=>this.props.setSearchEmpty())
    }
   
  }
  render () {
    const endMessage =
      this.props.devices && this.props.devices.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
    return (<div>
          {this.state.is_gm_Visable && (
            <GroupModal onCloseModal={this.onCloseModal} />
          )}
          <ul className='filter-row clearfix'>
          {!this.state.searchBox ? <li className='section-head-filter'>
              <div className="filter-row">
                <IconButton onClick={()=>this.toggleSearch("create")}><SearchIcon /></IconButton>
                {this.props.resourceList && checkPrivileges('itemgroupCreate') ? 
                <IconButton
                  onClick={() => {
                  this.props.addResource()
                }}><AddIcon /></IconButton> 
                  : checkPrivileges('deviceCreate')   ?
                  <IconButton
                  onClick={() => {
                    this.props.addDevice()}}
                  ><AddIcon /></IconButton> 
                : null}
                  <FormControlLabel
                    style={{ marginLeft: 'auto', flex: 1 }}
                    control={
                      <Switch
                        checked={this.props.resourceList}
                        onChange={() => this.props.showResources(!this.props.resourceList)}
                        name='isOpenEmailSection'
                      />
                    }
                  label={this.props.translate('groups')}
                  labelPlacement='start'
                  classes={{ label: 'color-inherit' }}
                />
                <ExportImportMenu
                  fileName = 'devices'
                  checkPrivilege = 'deviceCreate'
                  data = {this.props.devices.data}
                  themecolors = {this.props.themecolors}
                />
              </div>
            </li> :
            <li className='section-head-filter' style={{position: 'relative', flex: 1}}>
              <div className="filter-row" style={{flex: 1, marginTop: -2}}>
                <div style={{flex: 1}}>
                  <TextField
                    label={this.props.translate('searchUnits')}
                    // type='search'
                    placeholder='exp: ID, Name'
                    value={this.props.search}
                    onChange={this.props.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </div>
                <IconButton onClick={()=>this.toggleSearch("close")} className="search-close-icon"><CloseIcon /></IconButton>
              </div>
              </li>}
            </ul>
          {this.props.resourceList ? 
          <RecourcesList 
          ItemChild={ListRow}
          ItemChildProps={this.props}
          fromArray={this.props.devices && this.props.devices.data || []}
          {...this.props} /> :
          <div className='sidebar-devices-list'>
            <Scrollbar
              disableTracksWidthCompensation
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div
                      {...restProps}
                      ref={elementRef}
                      id='scrollableDivDevices'
                    />
                  )
                }
              }}
            >
              {this.props.devices.isFetching ? (
                <Loader defaultStyle={true} />
              ) : this.props.devices.total ? (
                <InfiniteScroll
                  dataLength={this.props.devices.data.length}
                  next={() => this.props.dispatch(fetchMoreDevices())}
                  hasMore={this.props.devices.hasNext}
                  loader={<Loader defaultStyle={true} />}
                  scrollableTarget='scrollableDivDevices'
                  endMessage={endMessage}
                >
                  <DevicesShortList
                    {...this.state}
                    removeDevice={this.props.removeDevice}
                    resetFrom={this.props.resetFrom}
                    devicesIcons={images}
                    devices={this.props.devices.data}
                    match={this.props.match}
                    isChecked={this.props.isChecked}
                    collapseSidebar={this.props.collapseSidebar}
                    translate={this.props.translate}
                  />
                </InfiniteScroll>
              ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold'}}>
                    {this.props.translate('notFound')}
                  </p>}
            </Scrollbar>
          </div>}
        </div>
    )
  }
}
