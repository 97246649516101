import {
  defaultDevicesLength,
  checkPrivileges,
  storeTime,
  storeTime2,
} from "../../Helpers";
import moment from "moment";

import { fetchTrailers } from "../Trailer";
import { getDrivers, isDriverLoad } from "../Drivers";
import { getUsers } from "../Users";
import { getVehicles } from "../Vehicles";
import * as turf from "@turf/turf";
import L from "leaflet";
import axios from "axios";
import { fetchNotificationCount } from "../Notifications";
import { fetchTemplates } from "../Templates";
import { batch } from "react-redux";
import instance from "../../axios";
export const logout = (reset) => ({
  type: "RESET_APP",
  reset,
});

function paginate(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export const fetchMoreDevices = (reset, length) => {
  return function (dispatch, getState) {
    dispatch(requestDevices());
    const {
      devices3,
      devices,
      allNotifications,
      insideGeofence,
      filterList,
      filterCatList,
      filterTagsList,
      filterDriversList,
      deviceIdsList,
      deviceRelatedData,
      // groupWiseDevices,
      // groupBy
    } = getState();

    let ids = [];

    /* if (groupBy) {
      if (!groupWiseDevices[groupBy]) {
        ids = []
      }
    } */

    if (filterCatList.length) {
      devices3.map((d) => {
        let cat = d.category;
        if (!d.category) {
          cat = "default";
        }
        if (filterCatList.includes(cat)) {
          ids.push(d.id);
        }
        return null;
      });
    }

    if (filterTagsList.length) {
      ids = filterTagsList.map((e) => parseInt(e));
    }
    if (filterDriversList.length) {
      ids = filterDriversList.map((e) => parseInt(e));
    }

    if (filterList && filterList.filters && filterList.filters.length) {
      filterList.filters.map((key) => {
        if (key === "geoIds") {
          ids.push(...new Set(insideGeofence[filterList.id] || []));
        } else {
          if (
            allNotifications &&
            allNotifications[key] &&
            allNotifications[key].length
          ) {
            ids.push(...new Set(allNotifications[key]));
          }
        }
        return null;
      });
    }

    let pageSize, page, lastPage, dev, nextDevices;
    if (
      (filterList && filterList.filters && filterList.filters.length) ||
      filterCatList.length ||
      filterTagsList.length ||
      filterDriversList.length
    ) {
      const positionsArray = [];

      if (Object.keys(deviceRelatedData).length) {
        ids.map((id) => {
          if (deviceRelatedData[id] && deviceRelatedData[id].exists) {
            positionsArray.push([
              deviceRelatedData[id].latitude,
              deviceRelatedData[id].longitude,
            ]);
          }
          return "";
        });
      }

      if (positionsArray.length) {
        dispatch(setBounds([positionsArray]));
      }
      if (ids.length === 1) {
        dispatch(setTrackId(ids[0]));
      } else {
        dispatch(setTrackId(0));
      }

      nextDevices = {};

      if (!ids.length) {
        nextDevices = {
          total: 0,
          hasNext: false,
          lastPage: 0,
          data: [],
          page: 1,
          pageSize: defaultDevicesLength,
          reset: true,
        };
      }

      let final = [];
      if (deviceIdsList.length) {
        if (ids && ids.length) {
          final = ids.filter((id) => deviceIdsList.includes(id));
        }
      } else {
        final = [...ids];
      }

      pageSize = length || devices.pageSize;
      page = reset ? 1 : devices.page + 1;
      lastPage = Math.ceil(final.length / pageSize);
      const finalIds = paginate(final, pageSize, page);

      if (reset) {
        dev = [];
      } else {
        dev = [...devices.data];
      }

      devices3.map((d) =>
        finalIds.includes(d.id) ? dev.push({ ...d, visible: true }) : null
      );

      nextDevices = {
        total: ids.length,
        hasNext: lastPage > page,
        lastPage,
        data: paginate(dev, pageSize, page),
        page,
        pageSize,
        reset: reset || false,
      };
    } else {
      nextDevices = {};
      dev = [];

      if (deviceIdsList.length) {
        if (ids && ids.length) {
          dev = devices3.filter(
            (device) =>
              ids.includes(device.id) && deviceIdsList.includes(device.id)
          );
        } else {
          dev = devices3.filter((device) => deviceIdsList.includes(device.id));
        }
      } else {
        dev = [...devices3];
      }

      page = reset ? 1 : devices.page + 1;
      pageSize = reset
        ? length || devices.pageSize || defaultDevicesLength
        : devices.pageSize;
      const total = reset ? dev.length : devices.total;
      const data = paginate(dev, pageSize, page);
      const lastPage = Math.ceil(total / pageSize);

      nextDevices = {
        total,
        hasNext: lastPage > page,
        lastPage,
        data,
        page,
        pageSize,
        reset: reset || false,
      };
    }
    return dispatch(receiveDevices(nextDevices));
  };
};

export const prepareDriverTags = () => {
  return function (dispatch, getState) {
    const { drivers } = getState();
    const tags = {};
    const tag1 = [];
    const tag2 = [];
    const tag3 = [];
    const tag4 = [];
    const tag5 = [];

    if (drivers && drivers.length) {
      drivers.map((t) => {
        if (
          t.attributes.tag_1 ||
          t.attributes.tag_2 ||
          t.attributes.tag_3 ||
          t.attributes.tag_4 ||
          t.attributes.tag_5
        ) {
          if (t.attributes.tag_1) {
            tag1.push(t.attributes.tag_1);
          }
          if (t.attributes.tag_2) {
            tag2.push(t.attributes.tag_2);
          }
          if (t.attributes.tag_3) {
            tag3.push(t.attributes.tag_3);
          }
          if (t.attributes.tag_4) {
            tag4.push(t.attributes.tag_4);
          }
          if (t.attributes.tag_5) {
            tag5.push(t.attributes.tag_5);
          }
        }
        return null;
      });
    }

    tags.tag_1 = [...new Set(tag1)];
    tags.tag_2 = [...new Set(tag2)];
    tags.tag_3 = [...new Set(tag3)];
    tags.tag_4 = [...new Set(tag4)];
    tags.tag_5 = [...new Set(tag5)];
    dispatch(getDriverTags(tags));
  };
};
export const fetchUsers = (dispatch, userInfoFront) => {
  let query = "";
  if (userInfoFront.userType === -1) {
    query = "userId=" + userInfoFront.id + "&all=true";
  } else {
    query = "userId=" + userInfoFront.id + "&all=true";
  }
  instance({
    method: "GET",
    url: `/api/users/get?limit=-1&${query}`,
  })
    .then((response) => {
      // if (response && response.status === 200) {
      response.data &&
        response.data.length &&
        dispatch(getUsers(response.data));
      // }
    })
    .catch((error) => {
      // errorHandler(error, dispatch)
    });
};
export const fetchDrivers = (dispatch, userInfoFront) => {
  const query = "userId=" + userInfoFront.id + "&all=true";
  // inital fetch call for driver
  if (checkPrivileges("driver")) {
    instance({
      url: `/api/drivers/get`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        userId: userInfoFront.id,
        all: true,
        limit: -1,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((drivers) => {
        const { data } = drivers;
        dispatch(getDrivers(data));
        dispatch(isDriverLoad(false));
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  }
};

export const fetchGeofences = (dispatch, userInfoFront) => {
  const query = "userId=" + userInfoFront.id + "&all=true";
  // inital fetch call for geofences
  if (checkPrivileges("geofence")) {
    instance({
      url: `/api/geofences/get`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        userId: userInfoFront.id,
        all: true,
        limit: -1,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((geofences) => {
        const { data } = geofences;
        dispatch(getGeoFence(data));
        dispatch(isGeofenceLoad(false));
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  }
};

export const prepareVehicleTags = () => {
  return function (dispatch, getState) {
    const { vehicles } = getState();
    const data = vehicles;
    const tags = {};
    const tag1 = [];
    const tag2 = [];
    const tag3 = [];
    const tag4 = [];
    const tag5 = [];

    if (data && data.length) {
      data.map((t) => {
        if (
          t.attributes.tag_1 ||
          t.attributes.tag_2 ||
          t.attributes.tag_3 ||
          t.attributes.tag_4 ||
          t.attributes.tag_5
        ) {
          if (t.attributes.tag_1) {
            tag1.push(t.attributes.tag_1);
          }
          if (t.attributes.tag_2) {
            tag2.push(t.attributes.tag_2);
          }
          if (t.attributes.tag_3) {
            tag3.push(t.attributes.tag_3);
          }
          if (t.attributes.tag_4) {
            tag4.push(t.attributes.tag_4);
          }
          if (t.attributes.tag_5) {
            tag5.push(t.attributes.tag_5);
          }
        }
        return null;
      });
    }

    tags.tag_1 = [...new Set(tag1)];
    tags.tag_2 = [...new Set(tag2)];
    tags.tag_3 = [...new Set(tag3)];
    tags.tag_4 = [...new Set(tag4)];
    tags.tag_5 = [...new Set(tag5)];
    dispatch(getTags(tags));
  };
};

export const fetchVehicles = (dispatch, userInfoFront) => {
  const query = "all=true&userId=" + userInfoFront.id;

  // inital fetch call for vehicles
  if (checkPrivileges("vehicle")) {
    instance({
      url: `/api/vehicles/get`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        userId: userInfoFront.id,
        all: true,
        limit: -1,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((vehicles) => {
        const { data } = vehicles;

        if (data) {
          dispatch(getVehicles(data));
        }
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  }
};

export const updateRelatedData = (payload) => ({
  type: "GET_DEVICES_DATA",
  payload,
});

export const updateDevicesBySocket = (devices) => ({
  type: "UPDATE_DEVICES",
  devices,
});

const calcParams = (device, position, currentRow, allNotifications) => {
  const getData = { ...position, ...device };

  const definedStoreTime = storeTime;
  /* device && device.attributes && device.attributes.storeTime
      ? device.attributes.storeTime
      : storeTime */

  if (!position) {
    getData.exists = false;
    if (
      device &&
      device.lastUpdate &&
      !moment(device.lastUpdate).isBefore(
        moment().subtract(definedStoreTime, "minutes")
      )
    ) {
      getData.icons = {
        playicon: {
          label: "unknown",
          value: "",
          notExists: true,
        },
      };
      getData.statusClass = getData.statusText = "online";
      allNotifications &&
        allNotifications.statusOnline &&
        allNotifications.statusOnline.push(device.id);
    } else {
      getData.statusClass = "not-exists";
      getData.statusText = "notRegisteredYet";
      getData.icons = {
        playicon: {
          label: "unknown",
          value: "",
          notExists: true,
        },
      };
      allNotifications &&
        allNotifications.notRegistered &&
        allNotifications.notRegistered.push(device.id);
    }
  } else {
    getData.exists = true;
    getData.attributes = position.attributes;
    getData.deviceAttributes = device.attributes;

    if (currentRow && currentRow.serverTime) {
      const end = moment(position.serverTime);
      const start = moment(currentRow.serverTime);
      const sec = Math.round(moment.duration(end.diff(start)).asSeconds());
      getData.animationTime = 0.5;
      if (sec > 0) {
        getData.animationTime = sec > 60 ? 60 : sec;
      } else if (currentRow.animationTime) {
        getData.animationTime = currentRow.animationTime;
      } else {
        getData.animationTime = 0.5;
      }
    }

    if (
      position &&
      position.attributes &&
      position.attributes.batteryLevel &&
      position.attributes.batteryLevel <= 15
    ) {
      allNotifications &&
        allNotifications.lowBattery &&
        allNotifications.lowBattery.push(device.id);
    }

    // assign motion
    const { motion, ignition, parking, stopTime, idlTime } =   position.attributes;
    const powerCutCheck = getData&&getData.attributes&&getData.attributes.immobilizer?true:false
    const checkPingTime =
      device &&
      device.lastUpdate &&
      !moment(device.lastUpdate).isBefore(
        moment().subtract(definedStoreTime, "minutes")
      ) &&
      !moment(position.serverTime).isBefore(
        moment().subtract(definedStoreTime, "minutes")
      );

    if (
      ignition &&
      device &&
      device.attributes &&
      parseInt(idlTime) >
        parseInt(device.attributes.minimalParkingDuration) * 1000
    ) {
      if (checkPingTime) {
        allNotifications &&
          allNotifications.Idling &&
          allNotifications.Idling.push(device.id);
        // idling
        getData.icons = {
          play: "unknown",
          playicon: {
            label: "idling",
            value: "pause",
            icon: "ignition",
          },
        };
      } else {
        getData.icons = {
          play: "unknownunknown",
          playicon: {
            label: "idling",
            value: "pause",
            icon: "ignition",
          },
        };
      }
    } else if (
      (ignition && motion) ||
      (position.protocol === "osmand" && motion)
    ) {
      if (checkPingTime) {
        // moving
        getData.icons = {
          play: "online",
          playicon: {
            label: "moving",
            value: "play_arrow",
            icon: "play",
          },
        };
        allNotifications &&
          allNotifications.Moving &&
          allNotifications.Moving.push(device.id);
      } else {
        getData.icons = {
          play: "unknownonline",
          playicon: {
            label: "moving",
            value: "play_arrow",
            icon: "play",
          },
        };
      }
    } else if (ignition !== true && motion) {
      if (checkPingTime) {
        // towing
        getData.icons = {
          play: "online",
          playicon: {
            label: "towing",
            value: "rv_hookup",
            icon: "towing",
          },
        };
        allNotifications &&
          allNotifications.Towing &&
          allNotifications.Towing.push(device.id);
      } else {
        getData.icons = {
          play: "unknownonline",
          playicon: {
            label: "towing",
            value: "rv_hookup",
            icon: "towing",
          },
        };
      }
    } else if (parking) {
      if (checkPingTime) {
        // parking
        getData.icons = {
          play: "online",
          playicon: {
            label: "parking",
            value: "local_parking",
            icon: "parking",
          },
        };
        allNotifications &&
          allNotifications.statusParking &&
          allNotifications.statusParking.push(device.id);
      } else {
        getData.icons = {
          play: "unknownonline",
          playicon: {
            label: "parking",
            value: "local_parking",
            icon: "parking",
          },
        };
      }
    } else if (
      device &&
      device.attributes &&
      parseInt(stopTime) <
        parseInt(device.attributes.minimalParkingDuration) * 1000
    ) {
      if (checkPingTime) {
        // stop
        getData.icons = {
          play: "online",
          playicon: {
            label: "stop",
            value: "pause",
            icon: "stop",
          },
        };
        allNotifications &&
          allNotifications.stop &&
          allNotifications.stop.push(device.id);
      } else {
        getData.icons = {
          play: "unknownonline",
          playicon: {
            label: "stop",
            value: "pause",
            icon: "stop",
          },
        };
      }
    } else {
      if (checkPingTime) {
        getData.icons = {
          play: "online",
          playicon: {
            label: "stop",
            value: "pause",
            icon: "stop",
          },
        };
        allNotifications &&
          allNotifications.stop &&
          allNotifications.stop.push(device.id);
      } else {
        getData.icons = {
          play: "unknownonline",
          playicon: {
            label: "stop",
            value: "pause",
            icon: "stop",
          },
        };
      }
    }

    if (
      device &&
      device.lastUpdate &&
      !moment(device.lastUpdate).isBefore(
        moment().subtract(definedStoreTime, "minutes")
      ) &&
      moment(position.serverTime).isBefore(
        moment().subtract(storeTime2, "minutes")
      )
    ) {
      getData.statusText = "GPS not updated";
      getData.statusClass = "not-updated";
      allNotifications &&
        allNotifications.gpsNotUpdated &&
        allNotifications.gpsNotUpdated.push(device.id);
    } else if (
      device &&
      device.lastUpdate &&
      !moment(device.lastUpdate).isBefore(
        moment().subtract(definedStoreTime, "minutes")
      )
    ) {
      getData.statusClass = getData.statusText = "online";
      allNotifications &&
        allNotifications.statusOnline &&
        allNotifications.statusOnline.push(device.id);
    } else {
      getData.statusClass = getData.statusText = "offline";
      allNotifications &&
        allNotifications.statusOffline &&
        allNotifications.statusOffline.push(device.id);
    }
  }
  if(getData&&getData.attributes&&getData.attributes.immobilizer){
    allNotifications && allNotifications.immobilizer && allNotifications.immobilizer.push(device.id)
  }
  return getData;
};
export const updatePositions = (positions) => ({
  type: "UPDATE_POSITIONS",
  positions,
});

export const getPositions = (positions) => ({
  type: "GET_POSITIONS",
  positions,
});

export const fetchDashboards = (dispatch, userInfoFront) => {
  dispatch(requestDashboards());
  const query = "userId=" + userInfoFront.id + "&all=true";

  instance({
    url: `/api/dashboards/get`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params: {
      userId: userInfoFront.id,
      all: true,
      limit: -1,
    },
  })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
    .then((dashboards) => {
      dispatch(receiveDashboards(dashboards));
      //   })
      // }
      // else{
      //   throw response
      // }
    })
    .catch((error) => {
      // errorHandler(error, dispatch)
    });
  // }
};
export const fetchWidgets = (dispatch, userInfoFront) => {
  dispatch(requestWidgets());
  const query = "userId=" + userInfoFront.id + "&all=true";

  instance({
    url: `/api/dashboards/gadgets`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params: {
      userId: userInfoFront.id,
      all: true,
      limit: -1,
    },
  })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
    .then((widgets) => {
      dispatch(receiveWidgets(widgets.data));
      //   })
      // }
      // else{
      //   throw response
      // }
    })
    .catch((error) => {
      // errorHandler(error, dispatch)
    });
  // }
};

export const fetchDevices = (params, isTrue) => {
  const resgroups = [];
  return async function (dispatch, getState) {
    dispatch(requestDevices(params));

    await instance(`/api/devices/get?userId=${params.id}&all=true&limit=-1`)
      .then((response) => {
        // if (response && response.status === 200) {
        const res = response || {};
        instance(`/api/itemgroups/get?limit=-1&itemType=Device`)
          // .then(({data: {data:responseGroups = []}}) => {
          .then(({ responseGroups = [] }) => {
            resgroups = responseGroups;
          })
          .catch((error) => {
            // errorHandler(error, dispatch)
          });
        if (!isTrue) {
          batch(() => {
            checkPrivileges("user") && fetchUsers(dispatch, params);
            // checkPrivileges("driver") && fetchDrivers(dispatch, params);
            // checkPrivileges("binder") && fetchTrailers(dispatch, params);
            checkPrivileges("geofence") && fetchGeofences(dispatch, params);
            checkPrivileges("notification") && fetchNotificationCount(dispatch, params);
            // checkPrivileges("vehicle") && fetchVehicles(dispatch, params);
            checkPrivileges("rptmpl") && fetchTemplates(dispatch, params);
            checkPrivileges("dashboard") && fetchDashboards(dispatch, params);
            checkPrivileges("dashboard") && fetchWidgets(dispatch, params);
            checkPrivileges("sensor") &&fetchAllComputedAttributes(dispatch, params);
            // checkPrivileges('garage') && fetchGarages(dispatch, params)
            // checkPrivileges('area') && fetchAreas(dispatch, params)
            // checkPrivileges('service') && fetchServices(dispatch, params)

            // dispatch(saveResourceGroups(resgroups.data || {}))
          });
        }
        const { devices, positions, deviceRelatedData } = getState();

        const pos = {};
        const getData = JSON.parse(JSON.stringify(deviceRelatedData));
        positions.map((position) => {
          pos[position.deviceId] = position;
          return null;
        });

        const data = res.data.map((d) => {
          const device = d.entity;
          const colors = [
            "#FF5733",
            "#FFC300",
            "#287EE3",
            "#FA6458",
            "#70D3D0",
            "#00C690",
            "#19646A",
            "#E13943",
            "#942B4A",
            "#C49140",
          ];
          const random = Math.floor(Math.random() * colors.length);

          device.attributes.color = colors[random];
          getData[device.id] = calcParams(
            device,
            pos[device.id],
            getData[device.id]
          );
          return { ...device, visible: true };
        });
        batch(() => {
          dispatch(updateRelatedData(getData));
          dispatch(getDevices3(res.data));

          const pageSize = 20;
          const page = 1;
          const lastPage = Math.ceil(res.total / pageSize);
          const d = {
            total: res.total,
            hasNext: lastPage > page,
            lastPage,
            data: paginate(data, pageSize, page),
            page,
            pageSize,
          };

          dispatch(receiveDevices(d));
          dispatch(updateAllNotifications());
        });
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  };
};
export const fetchDevicesList = (params) => {
  return async function (dispatch) {
    try {
      const response = await instance(
        `/api/devices/list?userId=${params.id}&all=true&limit=-1`
      );
      // if(response.status===200){
      //   dispatch(getDevices2(response.data&&response.data.data))
      dispatch(getDevices2(response.data));
      // }
    } catch (error) {
      // errorHandler(error, dispatch)
    }
  };
};

function saveNotifications(payload) {
  return { type: "UPDATE_NOTIFICATIONS", payload };
}

let poly = {};
let circle = {};

export const updateAllNotifications = () => {
  return function (dispatch, getState) {
    const {
      positions,
      devices3,
      tail,
      trackId,
      deviceRelatedData,
      geoFence: geofences = [],
    } = getState();

    const pos = {};
    positions.map((p) => (pos[p.deviceId] = p));

    if (geofences && geofences.length && positions && positions.length) {
      const insideGeofence = {};
      const list = [];
      positions.map((pos) => {
        const point = turf.points([
          [parseFloat(pos.latitude), parseFloat(pos.longitude)],
        ]);

        geofences.map((g) => {
          if (!poly[g.id]) {
            const v = g.attributes;

            if (v.type === "polygon") {
              const pp = [];
              if (v.latlng.length) {
                let arr = true;
                v.latlng.map((r) => {
                  if (Array.isArray(r)) {
                    pp.push(r);
                  } else {
                    arr = false;
                    pp.push([r.lat, r.lng]);
                  }
                  return null;
                });
                if (arr) {
                  pp.push(v.latlng[0]);
                } else {
                  pp.push([v.latlng[0].lat, v.latlng[0].lng]);
                }
              }

              poly[g.id] = turf.polygon([pp]);
            } else if (v.type === "circle") {
              poly[g.id] = null;

              if (!circle[g.id]) {
                circle[g.id] = L.latLng(v.latlng);
              }

              if (
                circle[g.id].distanceTo([
                  parseFloat(pos.latitude),
                  parseFloat(pos.longitude),
                ]) <= v.radius
              ) {
                insideGeofence[g.id] = insideGeofence[g.id] || [];
                insideGeofence[g.id] = [
                  ...new Set(insideGeofence[g.id]),
                  pos.deviceId,
                ];
                list.push(pos.deviceId);
              }
            } else if (v.type === "polyline") {
              const line = turf.lineString(
                v.latlng.map((r) =>
                  Array.isArray(r)
                    ? [parseFloat(r[0]), parseFloat(r[1])]
                    : [parseFloat(r.lat), parseFloat(r.lng)]
                )
              );
              poly[g.id] = turf.buffer(line, 50, { units: "meters" });
            }
          }

          if (poly && poly[g.id]) {
            const inSide = turf.pointsWithinPolygon(point, poly[g.id]);

            if (inSide.features && inSide.features.length) {
              insideGeofence[g.id] = insideGeofence[g.id] || [];
              insideGeofence[g.id] = [
                ...new Set(insideGeofence[g.id]),
                pos.deviceId,
              ];

              list.push(pos.deviceId);
            }
          }
          return null;
        });
        return null;
      });

      batch(() => {
        dispatch(updateNotificationInsideGeofence(list));
        dispatch(updateInsideGeofence(insideGeofence));
      });
    }

    const getData = deviceRelatedData;

    const notifications = {
      overSpeed: [],
      Idling: [],
      harshBreak: [],
      Moving: [],
      Towing: [],
      statusOnline: [],
      statusOffline: [],
      statusParking: [],
      lowBattery: [],
      gpsNotUpdated: [],
      notRegistered: [],
      stop: [],
      immobilizer:[]
    };

    devices3.map((device) => {
      getData[device.id] = calcParams(
        device,
        pos[device.id],
        getData[device.id],
        notifications
      );
      return null;
    });
    batch(() => {
      if (trackId) {
        const dev = devices3.find((d) => d.id === trackId);
        const currentPos = pos && pos[trackId];
        if (dev && dev.visible && currentPos) {
          let updatedTail = [];
          if (!tail.length) {
            updatedTail = tail.concat({
              lat: currentPos.latitude,
              lng: currentPos.longitude,
              color: dev.attributes.color,
            });
            const tailSeg = prepareTail(updatedTail);
            dispatch(updateTail(updatedTail));
            dispatch(updateSegments(tailSeg));
          } else {
            if (
              !compare(
                {
                  lat: currentPos.latitude,
                  lng: currentPos.longitude,
                  color: dev.attributes.color,
                },
                tail[0]
              )
            ) {
              if (tail.length === 7) {
                tail.pop();
              }
              tail.unshift({
                lat: currentPos.latitude,
                lng: currentPos.longitude,
                color: dev.attributes.color,
              });
              const tailSeg = prepareTail(tail);

              dispatch(updateTail(tail));
              dispatch(updateSegments(tailSeg));
            }
          }

          // dispatch(setBounds([[currentPos.latitude, currentPos.longitude]]))
        }
      }
      dispatch(updateRelatedData(getData));
      dispatch(saveNotifications(notifications));
    });
  };
};
const compare = (e, t) => {
  return e.lat === t.lat && e.lng === t.lng;
};

const prepareTail = (tail) => {
  var e = tail[0],
    t = function (t) {
      var n;
      return (
        (null === t ||
        void 0 === t ||
        null === (n = t.slice(-1)[0]) ||
        void 0 === n
          ? void 0
          : n.to) || e
      );
    },
    n = tail;
  return n
    .reduce(function (e, n) {
      var r = t(e);
      return e.concat({
        from: r,
        to: n,
      });
    }, [])
    .slice(0, 7);
};

export const updateInsideGeofence = (action) => ({
  type: "UPDATE_INSIDE_GEO",
  payload: action,
});
export const updateNotificationInsideGeofence = (action) => ({
  type: "UPDATE_NOTIFICATION_INSIDE_GEO",
  payload: action,
});

const saveFilters = (payload, id) => ({
  type: "UPDATE_FILTER",
  payload,
  id,
});
const saveGroupBy = (payload) => ({
  type: "UPDATE_GROUP_BY",
  payload,
});
const saveTagsFilters = (payload) => ({
  type: "UPDATE_TAGS_FILTER",
  payload,
});
const saveDriversFilters = (payload) => ({
  type: "UPDATE_DRIVERS_FILTER",
  payload,
});
export const applyDevicesFilter = (keys, id) => {
  return function (dispatch) {
    batch(() => {
      dispatch(saveFilters(keys, id));
      dispatch(fetchMoreDevices(true, null, true));
    });
  };
};
export const applyTagsFilter = (keys) => {
  return function (dispatch) {
    batch(() => {
      dispatch(saveTagsFilters(keys));
      dispatch(fetchMoreDevices(true, null, true));
    });
  };
};
export const applyDriversFilter = (keys) => {
  return function (dispatch) {
    batch(() => {
      dispatch(saveDriversFilters(keys));
      dispatch(fetchMoreDevices(true, null, true));
    });
  };
};
export const resetFilters = () => {
  return function (dispatch) {
    batch(() => {
      dispatch(saveFilters([]));
      dispatch(saveTagsFilters([]));
      dispatch(saveDriversFilters([]));
      dispatch(fetchMoreDevices(true));
    });
  };
};
export const resetGroupBy = () => {
  return function (dispatch) {
    dispatch(saveGroupBy(""));
    dispatch(fetchMoreDevices(true));
  };
};
const saveCatFilters = (payload) => ({
  type: "UPDATE_CAT_FILTER",
  payload,
});
export const applyDevicesCatFilter = (keys) => {
  return function (dispatch) {
    batch(() => {
      dispatch(saveCatFilters(keys));
      dispatch(fetchMoreDevices(true, null, true));
    });
  };
};
export const resetCatFilters = () => {
  return function (dispatch) {
    batch(() => {
      dispatch(saveCatFilters([]));
      dispatch(fetchMoreDevices(true));
    });
  };
};

function requestSummery() {
  return {
    type: "REQUEST_SUMMERY",
  };
}
function receiveSummery(data) {
  return {
    type: "RECEIVE_SUMMERY",
    payload: data,
  };
}
export function fetchSummery() {
  const header = {
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  };
  return async function (dispatch) {
    dispatch(requestSummery());
    try {
      const response = await instance("/api/reports/dashboard", {
        method: "GET",
      });
      response.forEach(calculateReverse)
      // if(response.status===200){
      //   dispatch(receiveSummery(response.data))
      dispatch(receiveSummery(response));
      // }
    } catch (error) {
      // errorHandler(error, dispatch)
    }
  };
}
function calculateReverse (it) {
    let z = it.data
    return z.reverse()
 }

function requestDevices(data) {
  return {
    type: "REQUEST_DEVICES",
    payload: data,
  };
}
function receiveDevices(data) {
  return {
    type: "RECEIVE_DEVICES",
    payload: data,
    receivedAt: Date.now(),
  };
}

function requestDashboards(data) {
  return {
    type: "REQUEST_DASHBOARDS",
    payload: data,
  };
}
function receiveDashboards(data) {
  return {
    type: "RECEIVE_DASHBOARDS",
    payload: data,
    receivedAt: Date.now(),
  };
}

function requestWidgets(data) {
  return {
    type: "REQUEST_WIDGETS",
    payload: data,
  };
}
function receiveWidgets(data) {
  return {
    type: "RECEIVE_WIDGETS",
    payload: data,
    receivedAt: Date.now(),
  };
}

export const getTags = (tags) => ({
  type: "GET_TAGS",
  tags,
});
export const getDriverTags = (tags) => ({
  type: "GET_DRIVER_TAGS",
  tags,
});
export const getDevices = (devices) => ({
  type: "GET_DEVICES",
  devices,
});
export const getDevices2 = (devices) => ({
  type: "GET_DEVICES2",
  devices,
});
export const getDevices3 = (devices) => ({
  type: "GET_DEVICES3",
  devices,
});
export const updateDevices = (devices) => ({
  type: "UPDATE_DEVICES",
  devices,
});
export const updateDevice = (device) => ({
  type: "UPDATE_DEVICE",
  device,
});

const concatDevices = (devices) => ({
  type: "ADD_DEVICES",
  devices,
});
export const addDevices = (devices) => {
  return function (dispatch, getState) {
    batch(() => {
      dispatch(concatDevices(devices));
      dispatch(fetchMoreDevices(2));
    });
  };
};
export const removeDevice = (device) => ({
  type: "REMOVE_DEVICE",
  device,
});

export const addcalendars = (calendars) => ({
  type: "ADD_CALENDARS",
  calendars,
});

export const getcalendars = (calendars) => ({
  type: "GET_CALENDARS",
  calendars,
});
export const removedcalendar = (calendar) => ({
  type: "REMOVED_CALENDAR",
  calendar,
});
export const updatecalendar = (calendar) => ({
  type: "UPDATE_CALENDAR",
  calendar,
});

export const searchcalendar = (calendar) => ({
  type: "SEARCH_CALENDAR",
  payload: calendar.calendar,
});
export const sortcalendars = (calendars) => ({
  type: "SORT_CALENDARS",
  calendars,
});

export const showOverSpeed = (obj) => ({
  type: "SHOW_OVER_SPEED",
  payload: obj.payload,
});

export const addPositions = (positions) => ({
  type: "ADD_POSITIONS",
  positions,
});

export const setBoundApply = (apply) => ({
  type: "APPLY_BOUNDED",
  payload: apply,
});

export const updateDeviceVisible = (obj) => ({
  type: "UPDATE_DEVICE_VISIBLE",
  payload: obj,
});

export const updateDevicesVisible = (obj) => ({
  type: "UPDATE_DEVICES_VISIBLE",
  payload: obj,
});

export const toggleAllDevices = (obj) => ({
  type: "TOGGLE_ALL_DEVICES",
  payload: obj,
});

export const toggleGroupDevices = (obj) => ({
  type: "TOGGLE_GROUP_DEVICES",
  payload: obj,
});

export const setDeviceId = (obj) => ({
  type: "ADD_DEVICE_ID",
  payload: obj,
});

export const unsetDeviceId = (obj) => ({
  type: "REMOVE_DEVICE_ID",
  payload: obj,
});

export const setBounds = (obj) => ({
  type: "SET_BOUNDS",
  payload: obj,
});

export const resetBounds = (obj) => ({
  type: "RESET_BOUNDS",
  payload: obj,
});

export const sortDevices = (obj) => ({
  type: "SORT_DEVICES",
  payload: obj,
});

export const sortDeviceTypes = (action) => {
  return function (dispatch, getState) {
    const { devices3, positions } = getState();
    const pos = {};
    positions.map((p) => (pos[p.deviceId] = p));
    const online = [];
    const offline = [];
    const gps = [];
    const unregistered = [];

    devices3.map((dev) => {
      if (!pos[dev.id]) {
        unregistered.push(dev);
      } else if (
        pos[dev.id] &&
        !moment(dev.lastUpdate).isBefore(
          moment().subtract(storeTime, "minutes")
        )
      ) {
        if (
          pos[dev.id] &&
          moment(pos[dev.id].serverTime).isBefore(
            moment().subtract(storeTime2, "minutes")
          )
        ) {
          gps.push(dev);
        } else {
          online.push(dev);
        }
      } else {
        offline.push(dev);
      }
      return null;
    });
    batch(() => {
      if (action.sort === "DESC") {
        dispatch(
          saveSortDeviceTypes([...unregistered, ...offline, ...gps, ...online])
        );
      } else {
        dispatch(
          saveSortDeviceTypes([...online, ...gps, ...offline, ...unregistered])
        );
      }
      dispatch(resetFilters());
    });
  };
};

const saveSortDeviceTypes = (obj) => ({
  type: "SORT_DEVICE_TYPES",
  payload: obj,
});

const searchDeviceFinal = (obj) => ({
  type: "SEARCH_DEVICES",
  payload: obj,
});

export const searchDevices = (obj) => {
  return function (dispatch, getState) {
    const {
      devices3,
      deviceIdsList,
      /* groupBy, groupWiseDevices, */ filterCatList,
      filterTagsList,
      filterDriversList,
      insideGeofence,
      devices,
      allNotifications,
      filterList,
    } = getState();
    let dev = [];

    if (devices3.length) {
      let ids = [];

      /* if (groupBy) {
        if (!groupWiseDevices[groupBy]) {
          ids = []
        }
      } */

      if (filterCatList.length) {
        devices3.map((d) => {
          let cat = d.category;
          if (!d.category) {
            cat = "default";
          }
          if (filterCatList.includes(cat)) {
            ids.push(d.id);
          }
          return null;
        });
      }

      if (filterTagsList.length) {
        ids = filterTagsList.map((e) => parseInt(e));
      }

      if (filterDriversList.length) {
        ids = filterDriversList.map((e) => parseInt(e));
      }

      if (filterList && filterList.filters && filterList.filters.length) {
        filterList.filters.map((key) => {
          if (key === "geoIds") {
            ids.push(...new Set(insideGeofence[filterList.id] || []));
          } else {
            if (
              allNotifications &&
              allNotifications[key] &&
              allNotifications[key].length
            ) {
              ids.push(...new Set(allNotifications[key]));
            }
          }
          return null;
        });
      }

      let final = [];
      if (deviceIdsList.length) {
        dev = [];
        if (ids && ids.length) {
          final = ids.filter((id) => deviceIdsList.includes(id));
        } else {
          final = deviceIdsList;
        }
      } else {
        final = [...ids];
      }

      if (final && final.length) {
        devices3.map((e) => {
          if (final.includes(e.id)) {
            const name =
              e.name + " " + e.uniqueId + " " + e.id + " " + e.phone + " ";
            const searchRecently = obj.device + "";
            if (name.toLowerCase().includes(searchRecently.toLowerCase())) {
              dev.push(e);
            }
          }
          return null;
        });
      } else {
        devices3.map((e) => {
          const name =
            e.name + " " + e.uniqueId + " " + e.id + " " + e.phone + " ";
          const searchRecently = obj.device + "";
          if (name.toLowerCase().includes(searchRecently.toLowerCase())) {
            dev.push(e);
          }
          return null;
        });
      }
    }

    const pageSize = devices.pageSize;
    const page = 1;
    const lastPage = Math.ceil(dev.length / pageSize);

    const nextDevices = {
      total: devices.total,
      hasNext: lastPage > page,
      lastPage,
      data: paginate(dev, pageSize, page),
      page,
      pageSize,
      reset: true,
    };
    batch(() => {
      dispatch(receiveDevices(nextDevices));
      dispatch(searchDeviceFinal(obj));
    });
  };
};

export const markerEnable = (obj) => ({
  type: "MARKER_ENABLE",
  payload: obj.enable,
});

export const enqueueSnackbar = (notification) => ({
  type: "ENQUEUE_SNACKBAR",
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const removeSnackbar = (key) => ({
  type: "REMOVE_SNACKBAR",
  key,
});

// Actions for GeoFence
export const updateGeoFence = (geofence) => ({
  type: "UPDATE_GEOFANEC",
  geofence,
});

export const addGeoFence = (geofence) => ({
  type: "ADD_GEOFANEC",
  geofence,
});

export const getGeoFence = (geofence) => ({
  type: "GET_GEOFANEC",
  geofence,
});

export const deleteGeofence = (geofence) => ({
  type: "DELETE_GEOFENCE",
  geofence,
});

export const updateGeofenceVector = (geofence) => ({
  type: "UPDATE_GEOFENSE_VECTOR",
  payload: geofence,
});

export const updateGeofenceAttributes = (geofence) => ({
  type: "UPDATE_GEOFENSE_ATTRIBUTES",
  payload: geofence,
});

export const isGeofenceLoad = (loadGeofence) => ({
  type: "LOAD_GEOFENCE",
  loadGeofence,
});

export const saveTrackId = (trackId) => ({
  type: "SET_TRACK_ID",
  payload: trackId,
});
export const setTrackId = (trackId) => {
  return function (dispatch, getState) {
    const { positions, devices3 } = getState();

    const pos = {};
    positions.map((p) => (pos[p.deviceId] = p));
    dispatch(saveTrackId(trackId));

    if (trackId) {
      const dev = devices3.find((d) => d.id === trackId);
      const currentPos = pos && pos[trackId];

      if (dev && dev.visible && currentPos) {
        let tail = [];
        const updatedTail = tail.concat({
          lat: currentPos.latitude,
          lng: currentPos.longitude,
          color: dev.attributes.color,
        });
        const tailSeg = prepareTail(updatedTail);
        dispatch(updateTail(updatedTail));
        dispatch(updateSegments(tailSeg));
      }
    }
  };
};

export const addOverspeed = (events) => ({
  type: "ADD_OVERSPEED",
  events,
});
export const fetchThemeColors = (events) => ({
  type: "fetchThemeColors",
  events,
});
export const geoFenceVisible = (obj) => ({
  type: "GEO_FENSE_VISIBLE",
  payload: obj,
});
export const toggleAllGeofences = (obj) => ({
  type: "TOGGLE_GEOFENCES",
  payload: obj,
});
export const saveAllComputedAttributes = (obj) => ({
  type: "GET_ALL_COMPUTED_ATTRIBUTES",
  payload: obj,
});
export const updateGroupNames = (obj) => ({
  type: "UPDATE_GROUP_NAMES",
  payload: obj,
});

export const saveResourceGroups = (payload) => ({
  type: "SAVE_RESOURCE_GROUPS",
  payload,
});
export const updateTail = (payload) => ({
  type: "UPDATE_TAIL",
  payload,
});
export const updateSegments = (payload) => ({
  type: "UPDATE_SEGMENTS",
  payload,
});
export const enabledTail = (payload) => ({
  type: "ENABLE_TAIL",
  payload,
});

const fetchAllComputedAttributes = (dispatch, userInfoFront) => {
  let query = "userId=" + userInfoFront.id;
  // inital fetch call for driver
  if (checkPrivileges("sensor")) {
    instance({
      url: `/api/attributes/computed/list`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        userId: userInfoFront.id,
        category: "all",
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((res) => {
        // if(res && res.status === 'success') {
        // const { data } = res
        /* const dd = {};
            data.map(row => {
              dd[row.item.id] = row.deviceIds;
              return null
            }) */
        dispatch(saveAllComputedAttributes(res));
        // }
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  }

  // if (checkPrivileges('attribute')) {
  //   // will remove this call
  ////   fetch(`/api/attributes/computed/?${query}&category=all&all=true`, {
  ////     method: 'GET',
  ////     headers: {
  ////       Accept: 'application/json',
  ////       'Content-Type': 'application/json'
  ////     }
  ////   })
  // instance({
  //   url: `/api/attributes/computed`,
  //   method: 'GET',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json'
  //   },
  //   params:{
  //     userId: userInfoFront.id,
  //     category: all
  //   }
  // })
  //// .then(response => {
  ////   if (response.ok) {
  ////     response.json()
  // .then(res => {
  //         dispatch(getComputedAttributes(res))
  ////   })
  //// }
  //// else{
  ////   throw response
  //// }
  //   }).catch(error => {errorHandler(error, dispatch)})
  // }
};
