import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Layout from "../../Layout";
import { ShowReports } from "../../Components/Trash/";
import { logInUserInfo } from "../../Actions/Users";
import instance from "../../axios";
class SystemLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userFetch: false,
    };
  }

  render() {
    if (this.props.logInUser) {
      return (
        <Layout noSidebar {...this.props}>
          <div className="has-padding">
            <ShowReports {...this.props} />
          </div>
        </Layout>
      );
    } else {
      instance({
        url: `/api/session`,
        method: "GET",
        headers: { Accept: "application/json; odata=verbose" },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((userInfo) => {
          this.props.setActiveLanguage(userInfo.attributes.lang || "en");
          this.props.dispatch(logInUserInfo(userInfo));
          //   })
          // } else {
          //   this.props.history.push('/logout')
          //     throw response
          //   }
        })
        .catch((error) => {
          // errorHandler(error,this.props.dispatch)
          this.props.history.push("/logout");
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
});

export default connect(mapStateToProps)(withLocalize(SystemLogs));
