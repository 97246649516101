import React, { Component } from "react";
import CustomDialog from "./../common/Dialog";
import Table from "./../common/Table";
import { connect } from "react-redux";
import { addGroup, updateGroup, removeGroup } from "./../../Actions/Groups";
import { withLocalize } from "react-localize-redux";
import { withSnackbar } from "notistack";
import Button from "./../common/Button";
import TextField from "./../common/TextField";
import MenuItem from "./../common/MenuItem";
import isEqual from "react-fast-compare";
import Attributes from "./../Attributes/attributesModal";
import { withStyles } from "@material-ui/core/styles";
import { checkPrivileges, errorHandler } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import Notifications from "react-notification-system-redux";
import instance from "../../axios";

const groupDefaultForm = {
  name: "",
  groupId: "",
};

const groupDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data && data.name && data.name.trim(),
  groupId: data.groupId ? data.groupId : 0,
});

const styles = (theme) => ({
  label: {
    marginTop: -4,
  },
  focused: {
    marginTop: 0,
  },
  shrink: {
    marginTop: 0,
  },
  input: {
    padding: "12px 14px 12px",
    fontSize: 14,
  },
  MenuItem: {
    fontSize: "0.8rem",
    paddingTop: 8,
    paddingBottom: 8,
  },
});

class groupModal extends Component {
  constructor() {
    super();
    this.state = {
      gm_isVisable: false,
      selectedGroup: "",
      addOption: false,
      editOption: false,
      isHaveAccess: "",
      g_attriubtes: "",
      attm_isVisable: false,
      itemAttributes: {},
      attributeChangesMessage: "",
      isVisableGroupBtn: false,
      isAttChanged: false,
      // all fields of form
      form: groupDefaultForm,
    };
    this.ag_submit = this.ag_submit.bind(this);
    this.ug_submit = this.ug_submit.bind(this);
    this.addGroup = this.addGroup.bind(this);
    //this.updateGroup = this.updateGroup.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this);
  }

  editEnable = (selected) => {
    if (selected[0]) {
      this.setState(
        {
          itemAttributes: selected[0].attributes,
          editOption: true,
          isHaveAccess: "groupUpdate",
          addOption: false,
          selectedGroup: selected[0],
          selectedOption: true,

          form: { ...selected[0] },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          itemAttributes: this.state.g_attriubtes,
          editOption: true,
          selectedOption: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };

  ag_submit() {
    let { name } = this.state.form;
    if (name.trim().length > 0) {
      const { itemAttributes, form } = this.state;
      const obj = groupDataFormat(form, itemAttributes);

      instance({
        url: `/api/groups`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        // .then(response => {
        //     if (response.ok) {
        //         response.json()
        .then((group) => {
          this.setState({
            addOption: false,
            g_attriubtes: "",
            isVisableGroupBtn: false,
            isAttChanged: false,
            form: "",
            attributeChangesMessage: "",
          });
          this.props.dispatch(addGroup(group));
          //this.props.enqueueSnackbar(this.props.translate('groupAdded'), { autoHideDuration: 1000 });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("groupAdded"),
              autoDismiss: 10,
            })
          );
          //     });

          // }
          // else {
          //     throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    } else {
      this.setState({
        isVisableGroupBtn: false,
        isAttChanged: false,
      });
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("emptyField"),
          autoDismiss: 10,
        })
      );
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("emptyField"),
          autoDismiss: 10,
        })
      );
    }
  }

  ug_submit() {
    let { name } = this.state.form;
    if (name.trim().length > 0) {
      const { itemAttributes, selectedGroup, form } = this.state;
      let itemId = selectedGroup.id;
      const obj = groupDataFormat(form, itemAttributes, itemId);

      instance({
        url: `/api/groups/${itemId}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        // .then(response => {
        //     if (response.ok) {
        //         response.json()
        .then((group) => {
          this.props.dispatch(updateGroup(group));
          //this.props.enqueueSnackbar(this.props.translate('groupUpdated'), { autoHideDuration: 1000 });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("groupUpdated"),
              autoDismiss: 10,
            })
          );
          this.setState({
            attributeChangesMessage: "",
          });
          //     });
          // }
          // else {
          //     throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
      this.setState({
        selectedGroup: "",
        editOption: false,
        g_attriubtes: "",
        isVisableGroupBtn: false,
        isAttChanged: false,
        form: "",
      });
    } else {
      this.setState({
        isVisableGroupBtn: false,
        isAttChanged: false,
      });
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("emptyField"),
          autoDismiss: 10,
        })
      );
    }
  }

  deleteGroup() {
    let { selectedGroup } = this.state;
    if (selectedGroup.id) {
      instance({
        url: `/api/groups/${selectedGroup.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...selectedGroup,
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(removeGroup(selectedGroup));
          //this.props.enqueueSnackbar(this.props.translate('groupDeleted'), { autoHideDuration: 1000 });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("groupDeleted"),
              autoDismiss: 10,
            })
          );
          this.setState({
            selectedGroup: "",
            addOption: false,
            editOption: false,
            attributeChangesMessage: "",
            onDeleteConfirmation: false,
          });
          // }
          // else {
          //     throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }
  modalControle() {
    this.setState({
      addOption: false,
      editOption: false,
      selectedGroup: "",
      isVisableGroupBtn: false,
      isAttChanged: false,
      attributeChangesMessage: "",
      isHaveAccess: "",
      form: "",
    });
  }
  addGroup() {
    this.setState({
      selectedGroup: "",
      editOption: false,
      addOption: true,
      isHaveAccess: "groupCreate",

      form: "",
      attributeChangesMessage: "",
    });
  }
  changeAttribute(name, value, selectedOption, updateAtt) {
    if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
      this.setState(
        {
          g_attriubtes: { ...updateAtt },
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      );
    } else {
      this.setState(
        {
          g_attriubtes: {
            ...this.state.selectedGroup.attributes,
            [name]: value,
          },
        },
        () => this.checkForAttrubutesChanged()
      );
    }
  }

  checkForAttrubutesChanged(selectedOption) {
    let changed = false;
    if (this.state.selectedGroup) {
      changed = !isEqual(
        this.state.g_attriubtes,
        this.state.selectedGroup.attributes
      );
    } else {
      changed = !this.isEmpty(this.state.g_attriubtes);
    }
    let { name } = this.state.form;
    let selectedGroup = {};
    if (name || (name && changed)) {
      selectedGroup = this.state.g_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState({
          isVisableGroupBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedGroup },
        });
      } else {
        this.setState({
          isVisableGroupBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedGroup },
        });
      }
    } else {
      selectedGroup = this.state.g_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState(
          {
            isVisableGroupBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedGroup },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      } else {
        this.setState(
          {
            isVisableGroupBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedGroup },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: "Attributes changed in pending",
      });
    } else {
      this.setState(
        {
          attributeChangesMessage: "",
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  }
  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  groupSelection(item) {
    if (item) {
      this.setState({
        selectedGroup: item,
        selectedOption: true,
        g_attriubtes: item.attributes,
        form: { ...item },
      });
    }
  }
  handleChange = (name) => (event) => {
    const { target } = event;

    this.setState(
      (preState) => ({
        ...preState,
        form: {
          ...preState.form,
          [name]: target.value,
        },
      }),
      () => this.checkRequiredFields()
    );
  };

  checkRequiredFields() {
    let { name } = this.state.form;
    let changed = !isEqual(this.state.form, this.state.selectedGroup);
    if (name && name.trim().length > 0 && changed) {
      this.setState({
        isVisableGroupBtn: true,
      });
    } else {
      this.setState({
        isVisableGroupBtn: this.state.isAttChanged,
      });
    }
  }

  addAttributes = () => {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable,
    });
  };
  onCloseSubModal = () => {
    this.setState({
      gm_isVisable: false,
      addOption: false,
      editOption: false,
      isVisableGroupBtn: false,
      isAttChanged: false,
      form: "",
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  onRemovedItem = (selected) => {
    if (selected[0]) {
      this.setState({
        selectedGroup: selected[0],
        selectedOption: true,
        attributeChangesMessage: "",
        onDeleteConfirmation: true,
        form: {
          ...selected[0],
        },
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        selectedOption: false,
        form: "",
        attributeChangesMessage: "",
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CustomDialog
          themecolors={this.props.themecolors}
          visable={true}
          title={false}
          onClose={this.props.onCloseModal}
          draggable={true}
          isVisableBtn={checkPrivileges("groupCreate")}
          headerActions={
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => this.addGroup()}
            >
              {" "}
              {this.props.translate("sharedAdd")}
            </Button>
          }
          bodyPadding={0}
        >
          <Table
            isEditable={true}
            actionInHead={true}
            canUpdate={checkPrivileges("groupUpdate")}
            canDelete={checkPrivileges("groupDelete")}
            showInHead="name"
            insideDialog={true}
            rows={this.props.groups}
            themecolors={this.props.themecolors}
            title="Groups"
            onCreate={this.addGroup}
            onDelete={this.onRemovedItem}
            onEdit={this.editEnable}
            rowDefinition={[
              {
                id: "name",
                numeric: false,
                disablePadding: false,
                label: "Name",
              },
            ]}
          />
        </CustomDialog>

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteGroup}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selectedGroup.name}
          />
        )}

        {this.state.addOption ? (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            title={this.props.translate("AddNewGroup")}
            onClose={this.onCloseSubModal}
            isVisableBtn={true}
            maxWidth="xs"
            draggable={true}
            invisible={true}
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => this.ag_submit()}
                disabled={!this.state.isVisableGroupBtn}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            leftActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => this.addAttributes()}
              >
                {" "}
                {this.props.translate("sharedAttribute")}
              </Button>
            }
            bodyPadding={"18px 24px"}
          >
            <TextField
              label="Name"
              type="search"
              placeholder=""
              value={this.state.form.name || ""}
              onChange={this.handleChange("name")}
              variant="outlined"
              margin="dense"
              inputProps={{ className: classes.input }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                  shrink: classes.shrink,
                },
              }}
              fullWidth
            />
            <TextField
              id="groupId"
              select
              label="Group"
              value={this.state.form.groupId || ""}
              variant="outlined"
              onChange={this.handleChange("groupId")}
              margin="dense"
              inputProps={{ className: classes.input }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                  shrink: classes.shrink,
                },
              }}
              fullWidth
            >
              {this.props.groups.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  className={classes.MenuItem}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </CustomDialog>
        ) : null}
        {this.state.selectedGroup && this.state.editOption ? (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            title={"Update Group"}
            onClose={this.onCloseSubModal}
            isVisableBtn={true}
            draggable={true}
            invisible={true}
            maxWidth="xs"
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => this.ug_submit()}
                disabled={!this.state.isVisableGroupBtn}
              >
                {" "}
                {this.props.translate("update")}
              </Button>
            }
            leftActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => this.addAttributes()}
              >
                {" "}
                {this.props.translate("sharedAttribute")}
              </Button>
            }
            bodyPadding={"18px 24px"}
          >
            <TextField
              id="name"
              label="Name"
              type="text"
              placeholder=""
              value={this.state.form.name || ""}
              onChange={this.handleChange("name")}
              variant="outlined"
              margin="dense"
              inputProps={{ className: classes.input }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                  shrink: classes.shrink,
                },
              }}
              fullWidth
            />
            <TextField
              id="groupId"
              select
              label="Group"
              value={this.state.form.groupId || ""}
              variant="outlined"
              onChange={this.handleChange("groupId")}
              margin="dense"
              inputProps={{ className: classes.input }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                  shrink: classes.shrink,
                },
              }}
              fullWidth
            >
              {this.props.groups.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  className={classes.MenuItem}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </CustomDialog>
        ) : null}
        {this.state.attm_isVisable && (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.state.itemAttributes}
            changeAttribute={this.changeAttribute}
            componentType={"group"}
            isHaveAccess={this.state.isHaveAccess}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  groups: state.groups,
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export const GroupModal = mapStateToProps(
  withSnackbar(withLocalize(withStyles(styles)(groupModal)))
);
