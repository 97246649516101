import React, { Component } from 'react'
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export'
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '../common/MenuItem'

export default class ExportExcel extends React.Component {
  exportFile = (fileName, Dform) => {
    let api, Accept;
        if(['vehicles','drivers','devices','trailers','users','services'].includes(fileName)){
            api = `/api/${fileName}/export?sample=${this.props.sample}`;
        }else if(fileName === 'geofences'){
          api = `/api/geofences/export/circle?sample=${this.props.sample}`;
        }else if(fileName === 'Dlogs'){
          api = `/api/positions/export?from=${Dform.from}&to=${Dform.to}&deviceId=${Dform.device}`;
        }
        if(this.props.type === 'PDF'){
          Accept = 'application/pdf'
        }else{
          Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        axios({
          method: 'GET',
          url: api,
          headers:{
            Accept: Accept
          },
          responseType: 'blob'
        })
        .then(response => {
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          if(this.props.type === 'PDF'){
            a.setAttribute('download', `${fileName}.pdf`);
          }else{
            a.setAttribute('download', `${fileName}.xlsx`);
          }
          document.body.appendChild(a)
          a.href = url
          a.click()
        }
        )
        
        .catch(error => {
            console.log("er =",error);
        })

  }

  render () {
    return (
      <div>
          <Tooltip title={this.props.translate('downloadExcel')}>
              <MenuItem
                style={{ minWidth: 'inherit' }}
                component="label"
                onClick={e => this.exportFile(this.props.fileName)}
              >
                {/* <GetAppIcon /> */}
                {this.props.fileName !== 'geofences'?
                this.props.translate('Download') + " "+this.props.title :  this.props.translate('Circle Geofences')+ this.props.translate(this.props.title) + this.props.translate('Download')}
              </MenuItem>
            </Tooltip>
      </div>
  );


   {/*  return (
      <>
       <Tooltip title='Download Excel'>
          <Button style={{ minWidth: 'inherit' }} onClick={this.export}>
            <GetAppIcon />
          </Button>
        </Tooltip>
        <ExcelExport
          data={this.props.data}
          fileName={this.props.fileName + '.xlsx'}
          ref={exporter => {
            this._exporter = exporter
          }}
        >

           {this.props.columns.map(col => {
            let w = 100
            if (col.key) {
              switch (col.key) {
                case 'id':
                case 'model':
                case 'deviceId':
                case 'vehicleType':
                  w = 60
                  break
                case 'vin':
                case 'make':
                  w = 120
                  break
                case 'chassisNumber':
                case 'garage':
                case 'label':
                  w = 250
                  break
                default:
                  w = null
                  break
              }
            }
            return (
              <ExcelExportColumn
                key={col.key}
                field={col.key}
                title={col.value}
                width={w}
              />
            )
          })} 
        </ExcelExport>
      </>
    )*/}
  }
}
