import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import TextField from "../common/TextField";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "../common/Checkbox";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Place";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../common/Tooltip";
import MenuItem from "../common/MenuItem";
import MarkerMap from "../common/Maps";
import { withSnackbar } from "notistack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "../common/Button";
import { logInUserInfo } from "./../../Actions/Users";
import CustomDialog from "../common/Dialog";
import { checkPrivileges, errorHandler } from "../../Helpers";
import isEqual from "react-fast-compare";
import Notifications from "react-notification-system-redux";
import { updateLayer } from "../../Actions/Maps";
import instance from "../../axios";

const coordinateFormatTypes = [
  {
    key: "dd",
    name: "sharedDecimalDegrees",
  },
  {
    key: "ddm",
    name: "sharedDegreesDecimalMinutes",
  },
  {
    key: "dms",
    name: "sharedDegreesMinutesSeconds",
  },
];

const styles = (theme) => ({});

class ServicePreferences extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        name: "",
        map: "osm",
        latitude: "",
        longitude: "",
        zoom: "",
        twelveHourFormat: "",
        coordinateFormat: "",
        poiLayer: "",
        attributes: { notificationEnabled: true },
      },
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: "",
      isVisableAddBtn: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.setState({
      form: {
        ...this.props.logInUser,
        attributes: {
          ...this.state.form.attributes,
          ...this.props.logInUser.attributes,
        },
      },
    });
  }
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
    });
  };
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true,
      });
    }
  };
  setSelectedLocation = () => {
    const { localPostionObj } = this.state;
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom,
          },
          isMapModal: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields = () => {
    let { zoom } = this.state.form;
    let value = !isEqual(this.state.form, this.props.logInUser);
    this.setState({
      isVisableAddBtn: value,
    });
    if (zoom) {
      if (value && zoom > 2 && zoom < 19) {
        this.setState({
          isVisableAddBtn: value,
        });
      } else {
        this.setState({
          isVisableAddBtn: false,
        });
      }
    } else {
      this.setState({
        isVisableAddBtn: false,
      });
    }
  };
  formReset = () => {
    this.setState({
      form: { ...this.props.logInUser },
      isVisableAddBtn: false,
    });
  };

  formSubmit() {
    let { logInUser } = this.props;
    const { form } = this.state;
    logInUser = { ...form };

    instance({
      url: `api/users/${logInUser.id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...logInUser,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((user) => {
        this.props.dispatch(logInUserInfo(user));
        this.props.dispatch(updateLayer({ id: user.map }));
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("servicePerferenceInfoIsUpdated"),
            autoDismiss: 10,
          })
        );
        // })
        this.setState({
          isVisableAddBtn: false,
        });
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (target.type === "number") {
      value = parseFloat(value);
    }
    if (name === "notificationEnabled" || name === "soundAlert") {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...this.state.form.attributes, [name]: value },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  onCloseModal = () => {
    this.setState({
      isMapModal: false,
    });
  };

  handleUserSetting = () => {
    this.props.history.push("/monitoring");
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate("setLocation")}
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            isVisableBtn={true}
            draggable={true}
            CancelText={this.props.translate("sharedBack")}
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.form.zoom || 0}
              lat={this.state.form.latitude || 0}
              lng={this.state.form.longitude || 0}
              height={"100%"}
            />
          </CustomDialog>
        )}
        <Grid container spacing={1}>
        <Grid item xs={12} md={12} sm={12} >
          <div style={{marginLeft:10}}>
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",}}>
                <p style={{fontSize:18, fontWeight:600, margin:0}}>{this.props.translate("settingsTitle")}</p>
                <Button
                onClick={ this.handleUserSetting} 
                style={{margin:"5px", textTransform:"capitalize"}}  
                variant="contained" >{this.props.translate("close")}</Button>
          </div>

                <p style={{fontSize:14, fontWeight:700, margin:"10px 0 0 0"}}>{this.props.translate("sharedPreferences")}</p>
          </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} 
        // style={{marginTop:12}}
        >
          <Grid item xs={12} md={3} sm={6} >
            <TextField
              id="name"
              label={this.props.translate("sharedName")}
              type="text"
              value={this.state.form.name}
              onChange={this.handleChange("name")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id="map"
              select
              label={this.props.translate("mapTitle")}
              value={this.state.form.map}
              onChange={this.handleChange("map")}
              margin="dense"
              fullWidth
            >
              {this.props.logInUser &&
                this.props.logInUser.attributes.maps &&
                this.props.logInUser.attributes.maps.map((option) => (
                  <MenuItem key={option} value={option}>
                    {this.props.translate(option)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id="coordinateFormat"
              select
              label={this.props.translate("settingsCoordinateFormat")}
              value={this.state.form.coordinateFormat}
              onChange={this.handleChange("coordinateFormat")}
              margin="dense"
              fullWidth
            >
              <MenuItem value="">
                <em>{this.props.translate("none")}</em>
              </MenuItem>
              {coordinateFormatTypes.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* <Grid item xs={12} md={3} sm={6}>
                        <TextField
                            id='poiLayer'
                            label={this.props.translate('mapPoiLayer')}
                            type="text"
                            placeholder=""
                            value={this.state.form.poiLayer}
                            onChange={this.handleChange('poiLayer')}
                            variant="outlined"
                            margin='dense'
                            fullWidth
                        />
                    </Grid> */}
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="zoom"
              label={this.props.translate("serverZoom")}
              type="number"
              placeholder=""
              error={
                this.state.form.zoom > 18 || this.state.form.zoom < 3
                  ? true
                  : false
              }
              helperText={this.props.translate("Enter Value 3 to 18")}
              value={this.state.form.zoom}
              onChange={this.handleChange("zoom")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="latitude"
              type="number"
              label={this.props.translate("positionLatitude")}
              placeholder=""
              value={this.state.form.latitude}
              onChange={this.handleChange("latitude")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="longitude"
              type="number"
              label={this.props.translate("positionLongitude")}
              value={this.state.form.longitude}
              onChange={this.handleChange("longitude")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Tooltip title={this.props.translate("addLocation")}>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip>
            <div style={{ display: "inline-flex" }}>
              <div style={{ marginRight: 20 }}>
                <Checkbox
                  checked={this.state.form.twelveHourFormat}
                  onChange={this.handleChange("twelveHourFormat")}
                  value="twelveHourFormat"
                  canAssign
                  canRemove
                  label={this.props.translate("settingsTwelveHourFormat")}
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <Checkbox
                  checked={this.state.form.attributes.notificationEnabled}
                  onChange={this.handleChange("notificationEnabled")}
                  value="twelveHourFormat"
                  canAssign
                  canRemove
                  label={this.props.translate("settingsNotificationEnabled")}
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <Checkbox
                  checked={this.state.form.attributes?.soundAlert}
                  onChange={this.handleChange("soundAlert")}
                  canAssign
                  canRemove
                  label={this.props.translate("soundAlert")}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {/* <br /> */}
        {checkPrivileges("userUpdate") && (
          <Grid container spacing={1} >
              <Grid item sm={6} xs={12}>
                <Button
                  variant="contained"
                  onClick={this.formReset}
                  style={{ marginRight: 8 }}
                >
                  {" "}
                  {this.props.translate("resetButton")}
                </Button>
                <Button
                  variant="contained"
                  disabled={!this.state.isVisableAddBtn}
                  onClick={() => this.formSubmit()}
                >
                  {" "}
                  {this.props.translate("sharedSave")}
                </Button>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const enhance = compose(withStyles(styles), connect());

export default enhance(withSnackbar(ServicePreferences));
