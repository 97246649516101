import React, { PureComponent } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./index.scss";
import { Icon, Menu, MenuItem, Tooltip } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { checkPrivileges } from "../../Helpers";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Style from "style-it";
import MenuBar from "./navbar";
import { ReactComponent as Logo } from "../../assets/mob-icon.svg";
import { ReactComponent as MemberIcon } from "./../../assets/nav/member.svg";
import { ReactComponent as MonitorIcon } from "./../../assets/nav/monitor.svg";
import { ReactComponent as DashboardIcon } from "./../../assets/nav/dashboard.svg";
import { ReactComponent as EmployeeIcon } from "./../../assets/nav/employees-one.svg";
import { ReactComponent as TrailerIcon } from "./../../assets/nav/trailerr.svg";
import { ReactComponent as TrashIcon } from "./../../assets/nav/trash.svg";
import { ReactComponent as GeofenceIcon } from "./../../assets/nav/geofence.svg";
import { ReactComponent as MessageIcon } from "./../../assets/nav/message.svg";
import { ReactComponent as MailIcon } from "./../../assets/nav/mail.svg";
import { ReactComponent as TracksIcon } from "./../../assets/nav/tracks.svg";
import { ReactComponent as TimelineIcon } from "./../../assets/nav/timeline-1-01.svg";
import { ReactComponent as UnitsIcon } from "./../../assets/nav/units.svg";
import { ReactComponent as VehicleIcon } from "./../../assets/nav/vehicle.svg";
import { ReactComponent as StaffIcon } from "./../../assets/nav/staff.svg";
import { ReactComponent as TimeMachineIcon } from "./../../assets/nav/time-machine.svg";
import { ReactComponent as ReportIcon } from "./../../assets/nav/reports.svg";
import { ReactComponent as CalendarIcon } from "./../../assets/nav/calendar.svg";
import { ReactComponent as NotificationIcon } from "./../../assets/nav/notifications.svg";
import { ReactComponent as UsersIcon } from "./../../assets/nav/user-two.svg";
import { ReactComponent as CommandIcon } from "./../../assets/nav/command.svg";
import { ReactComponent as MaintenanceIcon } from "./../../assets/nav/spanner.svg";
import { ReactComponent as ParameterIcon } from "./../../assets/nav/algorithm.svg";
import { ReactComponent as Services } from './../../assets/nav/car-expence.svg'
import SettingsIcon from "@material-ui/icons/Settings";
import DescriptionIcon from "@material-ui/icons/Description";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import Scrollbar from "react-scrollbars-custom";
import instance from "../../axios";


const emptyLink = "#";
const mapStateToProps = (state) => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  dashboards:
    state.dashboards?.data?.map((item) => ({
      ...item,
      icon: DashboardIcon,
    })) || [],
});

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentLang: "en",
    };
  }
  UNSAFE_componentWillReceiveProps(n) {
    if (
      n.activeLanguage &&
      n.activeLanguage.code &&
      n.activeLanguage.code !== this.props.activeLanguage.code
    ) {
      this.setState({ currentLang: "" }, () => {
        this.setState({ currentLang: n.activeLanguage.code });
      });
    }
  }

  render() {
    return this.props.logInUser &&
      this.props.logInUser.id &&
      localStorage.getItem("frontPrivileges") &&
      this.state.currentLang ? (
      <HeaderInner {...this.props} />
    ) : null;
  }
}

function ProfileMenu({ history, attributes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToLogoutpage = () => {
    localStorage.removeItem("backtoAdmin");
    history.push("/logout");
    handleClose();
  };
  const handleUserSetting = () => {
    history.push("/accountManagements");
    handleClose();
  };
  return (
    <div>
      <a
        href={null}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MemberIcon width={30} height={30} fill="currentColor" />
      </a>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="profile-menu"
      >
        <MenuItem onClick={handleUserSetting}>
          <SettingsIcon />
          <Translate id="settingsUserSetting" />
        </MenuItem>
        {attributes.privacyPolicyLink ? (
          <MenuItem
            component="a"
            href={attributes.privacyPolicyLink}
            onClick={handleClose}
          >
            <DescriptionIcon /> <Translate id="settingsPrivacyPolicyLink" />
          </MenuItem>
        ) : null}
        {attributes.termsLink ? (
          <MenuItem
            component="a"
            href={attributes.termsLink}
            onClick={handleClose}
          >
            <DescriptionIcon /> <Translate id="settingsTermsLink" />
          </MenuItem>
        ) : null}
        <MenuItem onClick={goToLogoutpage}>
          <Icon>logout</Icon> <Translate id="loginLogout" />
        </MenuItem>
      </Menu>
    </div>
  );
}

class HeaderInner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapseMenu: true,
      privilagesProcess: false,
      navigation: {
        dashboard: {
          checked: true,
          href: "/dashboard",
          icon: DashboardIcon,
          title: this.props.translate("dashboard"),
        },
        monitoring: {
          checked: checkPrivileges("device"),
          href: "/monitoring",
          icon: MonitorIcon,
          title: this.props.translate("Monitoring"),
        },
        // units: {
        //   checked: checkPrivileges("device"),
        //   href: "/units",
        //   icon: UnitsIcon,
        //   title: this.props.translate("trackers"),
        // },
        // resources: {
        //   checked: checkPrivileges("group"),
        //   href: "/resources",
        //   icon: DeviceHubIcon,
        //   title: this.props.translate("resources"),
        // },
        users: {
          checked: checkPrivileges("user"),
          href: "/users",
          icon: UsersIcon,
          title: this.props.translate("Users"),
        },
        // drivers: {
        //   checked: checkPrivileges("driver"),
        //   href: "/drivers",
        //   icon: EmployeeIcon,
        //   title: this.props.translate("Staff"),
        // },
        // trailer: {
        //   checked: checkPrivileges("trailer"),
        //   href: "/trailers",
        //   icon: TrailerIcon,
        //   title: this.props.translate("trailer"),
        // },
        // staff: {
        //   checked: false,
        //   href: "/staff",
        //   icon: StaffIcon,
        //   title: "Staff",
        // },
        // vehicles: {
        //   checked: checkPrivileges("vehicle"),
        //   href: "/vehicles",
        //   icon: VehicleIcon,
        //   title: this.props.translate("sharedVehicle"),
        // },
        geofences: {
          checked: checkPrivileges("geofence"),
          href: "/geofences",
          icon: GeofenceIcon,
          title: this.props.translate("Geofences"),
        },
        // calendars: {
        //   checked: false, //checkPrivileges('calendar'),
        //   href: "/calendars",
        //   icon: CalendarIcon,
        //   title: this.props.translate("sharedCalendar"),
        // },
        events: {
          checked: checkPrivileges("device"),
          href: "/events",
          icon: NotificationIcon, // EventsIcon,
          title: this.props.translate("reportEvents"),
        },
        notifications: {
          checked: false, //checkPrivileges('device'),
          href: "/notifications",
          icon: NotificationIcon,
          title: this.props.translate("Notifications"),
        },
        reports: {
          checked: checkPrivileges("report"),
          href: "/reports",
          icon: ReportIcon,
          title: this.props.translate("Reports"),
        },
        messages: {
          checked: checkPrivileges("position"),
          href: "/Dlogs",
          icon: MessageIcon,
          title: this.props.translate("Dlogs"),
        },
        tracks: {
          checked: checkPrivileges("track"),
          href: "/tracks",
          icon: TracksIcon,
          title: this.props.translate("Tracks"),
        },
        // timeline: {
        //   checked: true,
        //   href: "/timeline",
        //   icon: TimelineIcon,
        //   title: this.props.translate("timeline"),
        // },
        // timemachine: {
        //   checked: checkPrivileges("timeLap"),
        //   href: "/timemachine",
        //   icon: TimeMachineIcon,
        //   title: this.props.translate("timeMachine"),
        // },
        // jobs: {
        //   checked: checkPrivileges("job"),
        //   href: "/schedules",
        //   icon: MailIcon,
        //   title: this.props.translate("sharedSchedule"),
        // },

        // task: {
        //   checked: false,
        //   href: "/task",
        //   icon: DashboardIcon,
        //   title: "Task",
        // },

        // commands: {
        //   checked: checkPrivileges("command"),
        //   href: "/commands",
        //   icon: CommandIcon,
        //   title: this.props.translate("sharedSavedCommand"),
        // },
        // services: {
        //   checked: checkPrivileges("device"),
        //   href: '/services',
        //   icon: Services,
        //   title: this.props.translate('Services')
        // },
        // maintenance: {
        //   checked: checkPrivileges("maintenance"),
        //   href: "/maintenance",
        //   icon: MaintenanceIcon,
        //   title: this.props.translate("sharedMaintenance"),
        // },
        // parameters: {
        //   checked: checkPrivileges("attribute"),
        //   href: "/parameters",
        //   icon: ParameterIcon,
        //   title: this.props.translate("sharedComputedAttributes"),
        // },
        // trash: {
        //   checked: true,
        //   href: "/trash",
        //   icon: TrashIcon,
        //   title: this.props.translate("trash"),
        // },
      },
      rightNav: {},
    };
    this.collapseMenu = this.collapseMenu.bind(this);
  }

  UNSAFE_componentWillReceiveProps(n) {
    this.setState({
      navigation: {
        ...this.state.navigation,
        events: { ...this.state.navigation.events, count: n.notificationCount },
      },
    });
  }

  UNSAFE_componentWillMount() {
    this.setState({
      privilagesProcess: false,
    });
  }
  handleUserSetting = () => {
    this.props.history.push("/accountManagements");
  };
  handleUserChangedPassword = () => {
    let changedPass = 4;
    this.props.history.push("/accountManagements", [changedPass]);
  };
  collapseMenu() {
    // document.body.classList.toggle('header-open')
  }

  VisibleLeftMenuItem(items) {
    return (
      <MenuBar {...this.props} items={items} collapseMenu={this.collapseMenu} />
    );
  }

  AllMenuItem(items) {
    return Object.keys(items).map((userData, i) => {
      return (
        <li key={i}>
          <label href={items[userData].href} title={items[userData].title}>
            <input
              type="checkbox"
              name={userData}
              onChange={this.showHideMenuItems(userData)}
              checked
            />
            {items[userData].title}
          </label>
        </li>
      );
    });
  }

  refreshPriviliges = () => {
    this.setState(
      {
        privilagesProcess: true,
      },
      () => {
        instance({
          url: `/api/privileges`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            roleId: this.props.logInUser.roleId,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((privileges) => {
            let privilegeKeys = [];
            privileges.map((item) => {
              privilegeKeys.push(item.key);
              return null;
            });
            localStorage.setItem(
              "frontPrivileges",
              JSON.stringify({ ...privilegeKeys })
            );
            //   })
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
    );
  };

  switchUserAccount = () => {
    this.props.history.push(
      "/logout",
      this.props.ServerSetting.attributes.adminUrl
    );
  };

  collapseSidebar = () => {
    this.props.collapseSidebar();
  };

  render() {
    let www = "90px"
    let shade = "#FFFFFFF"
    if (this.props.ServerSetting) {
      const { attributes } = this.props.ServerSetting;
      return (
        <Style>
          {`
				.nav .active {
					color:  ${this.props.themecolors.themeDarkColor};
        }
        .left-nav {
          color:  ${this.props.themecolors.menuTextColor};
        }
        .nav .user-avatar {
          background-color:  ${this.props.themecolors.themeDarkColor};
        }
				.sub-menu label:hover, .sub-menu .active a, .sub-menu a:hover {
					color:  ${this.props.themecolors.themeDarkColor}
        }
        .navbar-menu .navbar-menu-item:hover {
          color:  ${this.props.themecolors.menuActiveBackground};
        }
        .navbar-menu .navbar-menu-item.active {
          color:  ${this.props.themecolors.themeLightColor}; 
          border-left-color:  ${this.props.themecolors.menuActiveBackground}; 
          width: ${www};
          box-shadow:none;
        }
        .navbar-menu-item .counter {
          background: ${this.props.themecolors.themeLightInverse};
          color: ${this.props.themecolors.themeLightColor}
        }
			`}
      
          <div style={{position:"relative"}}>
            <div className="left-nav">
              <ul className="nav">
                <Scrollbar disableTracksWidthCompensation>
                  {this.VisibleLeftMenuItem(this.state.navigation)}
                </Scrollbar>
              </ul>
              {/* <div className="profile-link">
                <ProfileMenu
                  history={this.props.history}
                  attributes={attributes}
                />
              </div> */}
            </div>  
            <header className="fms-header">
              <nav className="nav-container">
                <div className="logo-section">
                  <NavLink to="/">
                    <img
                      className="logo"
                      // src={"./../../assets/images/common/logo.png"}
                      src={this.props?.whiteLabling?.logo || ''}

                      alt=""
                    />
                  </NavLink>
                </div>
                <div style={{background:" #fff",  display: "flex", borderRadius:" 8px", alignItems: "center", marginRight: "11px", }}>
                  <div className="notification-link header-link">
                    {/* <Tooltip
                      title={
                        this.props.notificationCount +
                        " " +
                        this.props.translate("Notifications")
                      }
                      classes={{
                        popper: "menu-popper",
                        tooltip: "menu-popper-tooltip",
                      }}
                    > */}
                      <NavLink to="/events" style={{ position: "relative" }}>
                        {this.props.notificationCount !== undefined ? (
                          <span className="counter"></span>
                        ) : null}
                        <NotificationIcon
                          fill="currentColor"
                          width={18}
                          height={18}
                        />
                      </NavLink>
                    {/* </Tooltip> */}
                  </div>
                  {localStorage.getItem("backtoAdmin") ? (
                    <div className="header-link">
                      <Tooltip
                        title={
                          this.props.translate(
                            "youAreTemporarilyLoggedInAsAnotherUser"
                          ) +
                          " " +
                          this.props.translate("switch") +
                          " " +
                          this.props.translate("backToYourAccount")
                        }
                        classes={{
                          popper: "menu-popper",
                          tooltip: "menu-popper-tooltip",
                        }}
                      >
                        <a
                          href={emptyLink}
                          onClick={this.switchUserAccount}
                          title=""
                        >
                          <Icon>logout</Icon>
                        </a>
                      </Tooltip>
                    </div>
                  ) : null}
                  <div className="header-link">
                    <ProfileMenu
                      history={this.props.history}
                      attributes={attributes}
                    />
                  </div>
                </div>
              </nav>
            </header>
          </div>
        </Style>
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps)(withLocalize(withRouter(Header)));
