import React, { Component, Fragment } from "react";
import Icon from "@material-ui/core/Icon";
import {
  geoFenceVisible,
  deleteGeofence,
  resetBounds,
} from "./../../../Actions/Devices";
import { withLocalize } from "react-localize-redux";
import { POIVisible, deletePOI, getPOI } from "./../../../Actions/POI";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import { EditForm, EditFormPOI } from "./../../Geofence/editForm";
import Button from "./../../common/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import TextField from "./../../common/TextField";
//import { addcalendars } from './../../../Actions/Devices'
import "./index.scss";
import { checkPrivileges } from "../../../Helpers";
import Style from "style-it";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./../../../Layout/Loader";
import Scrollbar from "react-scrollbars-custom";
import { CheckIcon } from "../../common/CheckIcon";
import GeofenceModal from "../../Geofence/GeofenceModal";
import axios from "axios";
import htmlParser from "react-html-parser";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import instance from "../../../axios";

const CancelToken = axios.CancelToken;
let source;

const mapStateToProps = (state) => ({
  calendars: state.calendars,
  logInUser: state.logInUsers,
  geofences: state.geoFence,
});

export class CalloutGeo extends Component {
  constructor(props) {
    super(props);
    this.closeCallout = this.closeCallout.bind(this);
    this.toggleCheckGeo = this.toggleCheckGeo.bind(this);
    this.addCircle = this.addCircle.bind(this);
    this.addPolygon = this.addPolygon.bind(this);
    this.addPolyline = this.addPolyline.bind(this);
    this.state = {
      searchRecently: "",
      isCheckedAll: true,
      addCircleRow: null,

      initFetch: false,
      currentPage: 1,
      pagesize: 10,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMapData: {},
    };
  }

  openGeofenceModal = (data) => {
    let form = null;
    if (data) {
      form = {
        ...data,
        name: htmlParser(data.name)[0] || "",
        description: htmlParser(data.description)[0] || "",
      };
    }
    this.setState({
      geofenceModal: true,
      geofenceModalData: form,
      geofenceModalMapData: {
        center: this.props.mapRef.getCenter(),
        zoom: this.props.mapRef.getZoom(),
      },
    });
  };
  closeGeofenceModal = (data) => {
    this.setState({
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMapData: {},
    });
  };

  UNSAFE_componentWillMount() {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(this.props);
        }
      );
    }
  }

  componentWillUnmount() {
    this.setState({
      searchRecently: "",
      isCheckedAll: true,
      addCircleRow: null,

      initFetch: false,
      currentPage: 1,
      pagesize: 10,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  search(e) {
    this.searchItems(e.target.value);
  }

  closeCallout() {
    this.props.setCallout(0);
  }

  addCircle() {
    this.props.drawNewVector("circle");
  }
  addPolygon() {
    this.props.drawNewVector("polygon");
  }
  addPolyline() {
    var apl = document.getElementById("addPolyline");
    apl.click();
  }

  toggleCheckGeo(e) {
    this.setState({
      item: {
        ...this.state.item,
        color: e.hex,
      },
    });
  }

  UNSAFE_componentWillReceiveProps(NextProps) {
    if (!isEqual(NextProps.geofences, this.props.geofences)) {
      this.setState({
        isCheckedAll:
          NextProps.geofences.filter((item) => item.visible === true).length ===
          NextProps.geofences.length,
      });
      this.fetchData(NextProps);
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(this.props);
  };

  searchItems = (text) => {
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchData(this.props);
      }
    );
  };

  fetchData = (props) => {
    let searchText = this.state.searchText;
    let items = searchText
      ? props.geofences.filter((e) =>
          e.name.toLowerCase().includes((searchText + "" || "").toLowerCase())
        )
      : props.geofences;
    this.setState({
      itemPagination: {
        items,
        total: items.length,
        currentPage: 1,
        currentDevice: props.deviceId,
        hasNext: false,
      },
    });
  };

  render() {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")}--{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          {this.props.translate("notFound")}{" "}
        </p>
      );

    const List = this.state.itemPagination.items.map((item) => {
      return (
        <ListRow
          key={item.id}
          {...this.props}
          item={item}
          openGeofenceModal={this.openGeofenceModal}
        />
      );
    });

    return (
      <div className={"callout " + this.props.className}>
        {this.state.geofenceModal && (
          <GeofenceModal
            geofence={this.state.geofenceModalData}
            {...this.state.geofenceModalMapData}
            onClose={this.closeGeofenceModal}
          />
        )}
        <div
          className="callout-header"
          style={{
            background: this.props.themecolors.themeLightColor,
          }}
        >
          <span className="callout-title">{this.props.title}</span>

          <button className="callout-close" onClick={this.closeCallout}>
            <CloseIcon className="on-inactive material-icons" />
          </button>
        </div>
        <div className="callout-filter" style={{ padding: "0 8px" }}>
          <table className="callout-filter-table">
            <tbody>
              <tr>
                <td>
                  <TextField
                    label={this.props.translate("searchGeofence")}
                    fullWidth
                    onChange={(e) => this.search(e)}
                    style={{ marginBottom: 10 }}
                  />
                </td>

                <td width="4"></td>
                {checkPrivileges("geofenceCreate") && (
                  <td width="40" className="has-menu">
                    <Button onClick={(e) => this.openGeofenceModal(null)}>
                      {this.props.translate("sharedCreate")}
                    </Button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={
            "callout-body callout-body-scroll " +
            (this.props.EditMode === true
              ? "callout-body-scroll-edit-mode"
              : "")
          }
        >
          <Scrollbar
            disableTracksWidthCompensation
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div {...restProps} ref={elementRef} id="scrollableDiv2" />
                );
              },
            }}
          >
            <div className="callout-body-inner">
              {checkPrivileges("geofence") && this.state.itemPagination ? (
                <InfiniteScroll
                  dataLength={this.state.itemPagination.items.length}
                  next={this.fetchMoreItems}
                  hasMore={this.state.itemPagination.hasNext}
                  loader={<Loader defaultStyle={true} imgSize={50} />}
                  scrollableTarget="scrollableDiv2"
                  endMessage={endMessage}
                >
                  <ul className="items">{List}</ul>
                </InfiniteScroll>
              ) : null}
            </div>
          </Scrollbar>
        </div>
        <div className="callout-footer"></div>
      </div>
    );
  }
}

export class CalloutPOI2 extends Component {
  constructor(props) {
    super(props);
    this.closeCallout = this.closeCallout.bind(this);
    this.toggleCheckGeo = this.toggleCheckGeo.bind(this);
    this.state = {
      searchRecently: "",
      isCheckedAll: true,
      addCircleRow: null,

      initFetch: false,
      currentPage: 1,
      pagesize: 10,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    };
  }

  UNSAFE_componentWillMount() {
    source = CancelToken.source();
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            this.props.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    }
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }

    this.setState({
      searchRecently: "",
      isCheckedAll: true,
      addCircleRow: null,

      initFetch: false,
      currentPage: 1,
      pagesize: 10,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  search(e) {
    this.searchItems(e.target.value);
  }

  closeCallout() {
    this.props.setCallout(0);
  }

  addShape(type) {
    this.props.addPOIOnMap();
  }

  toggleCheckGeo(e) {
    this.setState({
      item: {
        ...this.state.item,
      },
    });
  }

  UNSAFE_componentWillReceiveProps(NextProps) {
    if (!isEqual(NextProps.POI, this.props.POI)) {
      this.setState({
        isCheckedAll:
          NextProps.POI.filter((item) => item.visible === true).length ===
          NextProps.POI.length,
      });
    }

    if (
      NextProps.logInUser &&
      NextProps.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            NextProps.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser.id,
      this.state.currentPage,
      this.state.pagesize
    );
  };

  searchItems = (text) => {
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true);
      }
    );
  };

  fetchData = (userId, page, perPage, reset) => {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    let searchText = this.state.searchText;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }

    let items = this.state.itemPagination.items;
    if (reset) {
      items = [];
    }

    axios({
      method: "GET",
      url: `/api/pois/get?userId=${userId}&all=true&page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 200) {
        const res = response.data;
        this.setState(
          {
            itemPagination: {
              ...res,
              items: items.concat(res.data),
            },
            currentPage: res.hasNext ? res.page + 1 : res.page,
          },
          () => {
            this.props.dispatch(getPOI(items.concat(res.data)));
          }
        );
      }
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")} --{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {this.props.translate("notFound")}
        </p>
      );

    //const emptyLinks = "javascript:void(0)";
    const List = this.props.POI.map((item, i) => {
      if (item.id === this.props.poiForm.id) {
        return (
          <ListRowPOI
            key={item.id + "__POI_" + i}
            {...this.props}
            item={this.props.poiForm}
          />
        );
      } else {
        return (
          <ListRowPOI
            key={item.id + "__POI_" + i}
            {...this.props}
            item={item}
          />
        );
      }
    });

    let POIList = null;

    if (this.props.poiForm?.name && !this.props.poiForm["id"]) {
      POIList = (
        <ListRowPOI
          key={"newPOI"}
          {...this.props}
          newPOI
          item={this.props.poiForm}
        />
      );
    }

    return (
      <div
        className={"callout " + this.props.className}
        style={{ color: this.props.themecolors.textColor }}
      >
        <div
          className="callout-header"
          style={{
            background: this.props.themecolors.themeLightColor,
          }}
        >
          <span className="callout-title">{this.props.title}</span>
          <button className="callout-close" onClick={this.closeCallout}>
            <CloseIcon className="on-inactive material-icons" />
          </button>
        </div>
        <div className="callout-filter" style={{ margin: "0 8px" }}>
          <table className="callout-filter-table">
            <tbody>
              <tr>
                {this.props.Drawing === "" && (
                  <td>
                    <TextField
                      label={this.props.translate("SearchPOIs")}
                      fullWidth
                      onChange={(e) => this.search(e)}
                      style={{ marginBottom: 0, marginTop: 0 }}
                    />
                  </td>
                )}
                <td width="4"></td>
                {checkPrivileges("poiCreate") && (
                  <td width="40">
                    <IconButton
                      style={{
                        background: this.props.themecolors.themeLightColor,
                        color: this.props.themecolors.textColor,
                      }}
                      variant="contained"
                      children={<AddIcon fontSize="small" />}
                      onClick={(e) => this.addShape("circle")}
                    />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={
            "callout-body callout-body-scroll " +
            (this.props.EditMode === true
              ? "callout-body-scroll-edit-mode"
              : "")
          }
        >
          <Scrollbar
            disableTracksWidthCompensation
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div {...restProps} ref={elementRef} id="scrollableDiv1" />
                );
              },
            }}
          >
            <div className="callout-body-inner">
              {checkPrivileges("poi") && this.state.itemPagination ? (
                <InfiniteScroll
                  dataLength={this.state.itemPagination.items.length}
                  next={this.fetchMoreItems}
                  hasMore={this.state.itemPagination.hasNext}
                  loader={<Loader defaultStyle={true} imgSize={50} />}
                  scrollableTarget="scrollableDiv1"
                  endMessage={endMessage}
                >
                  <ul className="items">
                    {POIList}
                    {List}
                  </ul>
                </InfiniteScroll>
              ) : null}
            </div>
          </Scrollbar>
        </div>
        <div className="callout-footer"></div>
      </div>
    );
  }
}

class ListRow2 extends Component {
  constructor(props) {
    super(props);
    this.state = { edit: false, item: {}, colorPicker: false };
  }

  onUpdate = (id, event) => {
    this.props.dispatch(resetBounds());
    this.props.dispatch(
      geoFenceVisible({ visible: event.target.checked, id: id })
    );
    this.props.updateVisible();
  };

  onDelete = () => {
    if (window.confirm("Are you sure?")) {
      instance({
        url: `api/geofences/${this.props.item.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(deleteGeofence({ id: this.props.item.id }));
          this.setState({ item: {} });
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };
  enableEditMap = (event, item) => {
    this.setState({ edit: true }, () => {
      this.props.dispatch(geoFenceVisible({ visible: true, id: item.id }));
      this.props.updateVisible();
      this.props.openGeofenceModal(item);
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    return (
      <li key={this.props.item.id} className="callout-list-item">
        <label className="checkbox">
          <input
            id={this.props.item.id}
            disabled={this.state.edit[this.props.item.id]}
            type="checkbox"
            checked={this.props.item.visible}
            onChange={(e) => this.onUpdate(this.props.item.id, e)}
          />
          <CheckIcon />
          <span className="unit-name">
            <span className="unit-name-text">
              {htmlParser(this.props.item.name)}
            </span>
          </span>
        </label>
        <div className="callout-actions">
          {checkPrivileges("geofenceDelete") && (
            <span className="action" onClick={this.onDelete}>
              <DeleteIcon />
            </span>
          )}
          {checkPrivileges("geofenceUpdate") && (
            <span
              className="action"
              onClick={(e) => this.enableEditMap(e, this.props.item)}
            >
              <EditIcon />
            </span>
          )}
        </div>
      </li>
    );
  }
}

class ListRow_POI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.newPOI || false,
      colorPicker: false,
    };
    this.onDelete = this.onDelete.bind(this);
    this.saveMapChanges = this.saveMapChanges.bind(this);
    this.cancelMapChanges = this.cancelMapChanges.bind(this);
    this.enableEditMap = this.enableEditMap.bind(this);
  }

  onUpdate = (id, event) => {
    this.props.dispatch(resetBounds());
    this.props.dispatch(POIVisible({ visible: event.target.checked, id: id }));
    this.props.updatePOIVisible();
  };

  onDelete() {
    if (window.confirm("Are you sure?")) {
      instance({
        url: `api/pois/${this.props.item.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(deletePOI({ id: this.props.item.id }));
          this.setState({ item: {} });
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  }

  saveMapChanges() {
    this.setState({
      edit: false,
    });

    this.props.savePOI();
  }

  cancelMapChanges() {
    this.props.cancelMapChanges(this.props.item.id);
    this.setState({
      edit: false,
    });
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.newPOI) {
      this.setState({ edit: true });
    }
  }

  enableEditMap(item) {
    if (this.props.Drawing === "") {
      this.props.dispatch(
        POIVisible({ visible: true, id: this.props.item.id })
      );
      this.setState(
        {
          edit: true,
        },
        () => {
          this.props.enableEditMap(item);
        }
      );
    } else {
      alert("Please complete or cancel your new vector first.");
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }
  render() {
    const EditView = (
      <EditFormPOI
        vector={{
          ...this.props.item,
          name: htmlParser(this.props.item.name),
          description: htmlParser(this.props.item.description),
        }}
        onChange={this.props.onChangePOI}
        save={this.saveMapChanges}
        cancel={this.cancelMapChanges}
        translate={this.props.translate}
      />
    );

    return this.state.edit ? (
      <li key={this.props.item.id} className="callout-list-form">
        {EditView}
      </li>
    ) : (
      <li key={this.props.item.id} className="callout-list-item">
        <label className="checkbox">
          <input
            id={this.props.item.id}
            disabled={this.state.edit[this.props.item.id]}
            type="checkbox"
            checked={this.props.item.visible || false}
            onChange={(e) => this.onUpdate(this.props.item.id, e)}
          />
          <CheckIcon />
          <span className="unit-name">
            <span className="unit-name-text">
              {htmlParser(this.props.item.name)}
            </span>
          </span>
        </label>
        <div className="callout-actions">
          {checkPrivileges("poiDelete") && (
            <span className="action" onClick={this.onDelete}>
              <DeleteIcon />
            </span>
          )}
          {checkPrivileges("poiUpdate") && (
            <span
              className="action"
              onClick={(e) => this.enableEditMap(this.props.item)}
            >
              <EditIcon />
            </span>
          )}
        </div>
      </li>
    );
  }
}

export const Callout = connect(mapStateToProps)(CalloutGeo);
export const CalloutPOI = connect(mapStateToProps)(CalloutPOI2);
export const ListRow = connect(mapStateToProps)(withLocalize(ListRow2));
export const ListRowPOI = connect(mapStateToProps)(withLocalize(ListRow_POI));
