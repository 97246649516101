import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import moment from "moment";
import { withLocalize } from "react-localize-redux";
import Layout from "./../../Layout";
//import RouteMap from './../../Components/Maps/RouteMap';
import TimemachineMap from "./../../Components/Maps/Timemachine";
import { updateGroups } from "./../../Actions/Groups";
import "moment-duration-format";
import {
  checkPrivileges,
  getDateTimeFormat,
  setAttributeFormat,
} from "../../Helpers";
import "./Style.scss";
import instance from "../../axios";
import $ from "jquery";

const ReportData = [];
let trips = {};

class Timemachine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsCall: false,
      view: "displayForm",

      from: "",
      trackLoading: false,
      mainView: "",
      to: "",
      selectedDate: "",
      deviceId: [],
      groupId: [],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      reportUnits: true,
      notificationId: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      shownRows: 0,
      processInvalidPositions: false,
      useIgnition: true,
      timelineRanges: {},
      advanceSettingsForm: {
        // minimalNoDataDuration: 600,
        // minimalParkingDuration: 300,
        // minimalTripDuration: 60,
        // minimalTripDistance: 0.1,
        // speedThreshold: 1.61987,
        // advanceSettings: false,
        // processInvalidPositions: false,
        // useIgnition: true
      },
      routes: {},
      trips: trips,
      routesLoading: {},
      fetchAll: false,
      timemachineArray: {},
      timemachinePositions: [],
      items: [],
      groups: [],
      options: {},
    };

    this.openForm = this.openForm.bind(this);
    this.showDates = this.showDates.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.selectedDevices = this.selectedDevices.bind(this);
    this.selectedGroups = this.selectedGroups.bind(this);
    this.createReport = this.createReport.bind(this);
    this.advanceFormChange = this.advanceFormChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.reportTypeSelection = this.reportTypeSelection.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.pageLoading = this.pageLoading.bind(this);
    this.changeMainView = this.changeMainView.bind(this);
  }
  pageLoading(action) {
    this.setState({ trackLoading: action });
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  changeCheckbox(groupDate, groupId, rowId, check) {
    let devices = Object.assign({ ...this.state.trips });
    devices[groupId]["data"][groupDate].map((row) => {
      if (row.startPositionId === rowId) {
        row.checked = check;
      }
      return null;
    });

    this.setState({ trips: devices });
  }

  updateColor(groupDate, groupId, rowId, color) {
    let devices = Object.assign({ ...this.state.trips });
    let r = {};

    devices[groupId]["data"][groupDate].map((row) => {
      if (row.startPositionId === rowId) {
        row.color = color;
        r = row;
      }
      return null;
    });

    this.setState({ trips: devices }, () => {
      this.cancelRoute(r);
      this.drawRoute(r);
    });
  }

  calcTime = (date, offset) => {
    let d = new Date(date);

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);

    return nd.toISOString();
  };

  changeMainView(mainView) {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }

    if (mainView === "timemachine") {
      this.setState({ mainView }, () => {
        if (this.state.fetchAll === false) {
          this.setState({ fetchAll: true }, () => {
            let deviceId = "";
            let groupId = "";

            this.state.deviceId.map(
              (id) => (deviceId += "deviceId=" + id + "&")
            );
            this.state.groupId.map((id) => (groupId += "groupId=" + id + "&"));

            let to = this.state.to;
            let from = this.state.from;
            let datetimeformat = getDateTimeFormat();
            let arr = [];
            let items = [];
            let groups = [];
            let startDates = [];
            let endDates = [];
            let options = {};

            instance({
              url: `/api/reports/timelap?${deviceId}${groupId}`,
              method: "GET",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
              },
              params: {
                type: "allEvents",
                from: from,
                to: to,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((data) => {
                let positions = [];
                Object.keys(data).map((k) => {
                  let c = [];
                  if (data[k].length) {
                    positions.push([data[k][0].lat, data[k][0].lng]);

                    startDates.push(data[k][0].t);
                    endDates.push(data[k][data[k].length - 1].t);

                    let device = this.props.devices2.find(
                      (d) => d.id.toString() === k.toString()
                    );

                    groups.push({ content: device.label, id: parseInt(k) });

                    c = data[k].map((l) => {
                      items.push({
                        className: "custom-timemachine-item",
                        group: parseInt(k),
                        start: l.t,
                        title: "Speed: " + setAttributeFormat("speed", l.s),
                        type: "point",
                      });
                      return {
                        id: k,
                        lat: l.lat,
                        lng: l.lng,
                        time: parseInt(moment(l["t"]).format("x") || 0),
                        info: [
                          { key: "Name", value: device.label },
                          {
                            key: "Speed",
                            value: setAttributeFormat("speed", l.s),
                          },
                          {
                            key: "Time",
                            value: moment
                              .utc(l.t)
                              .tz(timezone)
                              .format(datetimeformat),
                          },
                        ],
                      };
                    });
                    if (c.length) {
                      arr.push(c);
                    }
                  }
                  return null;
                });

                if (startDates.length) {
                  endDates.sort(function (a, b) {
                    return moment(b).unix() - moment(a).unix();
                  });
                  startDates.sort(function (a, b) {
                    return moment(a).unix() - moment(b).unix();
                  });
                  let s = moment(startDates[0]).subtract(60, "m").valueOf();
                  let e = moment(endDates[0]).add(60, "m").valueOf();
                  options.start = s;
                  options.end = e;
                  options.min = s;
                  options.max = e;
                }

                this.setState({
                  shownRows: Object.keys(groups).length,
                  timemachineArray: arr,
                  timemachineBounds: positions,
                  items,
                  groups,
                  options,
                  loading: false,
                  trackLoading: false,
                  fetchAll: false,
                });
                //   })
                // }
                // else{
                //   throw response
                // }
              })
              .catch((error) => {
                // errorHandler(error, this.props.dispatch)
              });
          });
        }
      });
    } else {
      this.setState({ mainView });
    }
  }

  componentWillReceiveProps(NextProps) {
    if (
      NextProps.logInUser.id &&
      NextProps.groups &&
      !NextProps.groups.length &&
      this.state.groupsCall === false
    ) {
      this.setState({ groupsCall: true }, () => {
        instance({
          url: `/api/groups`,
          method: "GET",
          params: {
            userId: NextProps.logInUser.id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((groups) => {
            NextProps.dispatch(updateGroups(groups));
          })
          //   } else {
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      });
    }
  }

  advanceFormChange(event, name) {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      advanceSettingsForm: { ...this.state.advanceSettingsForm, [name]: value },
    });
  }

  reportTypeSelection() {
    let v = this.state.reportUnits;
    this.setState({
      reportUnits: !v,
    });
  }

  onCloseModal(e, name) {
    this.setState({ [name]: false });
  }

  openForm() {
    this.setState({ view: "displayForm", reportsData: [], routes: {} });
  }

  saveData(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  createReport(type) {
    this.setState(
      { loading: true, trackLoading: true, view: "", reportsData: [] },
      () => {
        this.changeMainView("timemachine");
      }
    );
  }

  showDates(e, date) {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    let endDate = null;

    if (e.type === "apply") {
      let date1 = moment(date.startDate.format("YYYY-MM-DD HH:mm"));
      let date2 = moment(date.startDate.format("YYYY-MM-DD HH:mm"));
      let date3 = moment(date.endDate.format("YYYY-MM-DD HH:mm"));
      let end = date1.add(1, "month");
      let from = date2.subtract(1, "month");

      if (
        end.valueOf() >= date3.valueOf() &&
        from.valueOf() <= date3.valueOf()
      ) {
        endDate = moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString();
      } else {
        endDate = end.toISOString();
      }
    }

    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
    };

    let timeFormat = getDateTimeFormat();

    this.setState(
      {
        selectedDate:
          moment(formatedDate.from).format(timeFormat) +
          " - " +
          moment(formatedDate.to).format(timeFormat),
        ...formatedDate,
      },
      () => {
        if (
          (this.state.groupId.length || this.state.deviceId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      }
    );
  }

  onDismiss() {
    this.setState({
      shownRows: 0,
      timemachineArray: {},
      timemachinePositions: [],
      loading: false,
      trackLoading: false,
      fetchAll: false,
    });
  }

  selectedNotifications = (notificationId) => {
    var notif = [];
    if (notificationId && notificationId.length) {
      notificationId.map((d) => {
        notif.push(d.value);
        return null;
      });
    }
    this.setState({ notificationId: notif }, () => {
      if (
        (this.state.groupId.length || this.state.deviceId.length) &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  };

  selectedDevices(deviceId) {
    var devices = [];
    if (deviceId && deviceId.length) {
      this.setState({ deviceId }, () => {
        if (
          (this.state.groupId.length || this.state.deviceId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      });
    }
  }

  selectedGroups(groupId) {
    var groups = [];
    if (groupId && groupId.length) {
      groupId.map((d) => {
        groups.push(d.value);
        return null;
      });
    }
    this.setState({ groupId: groups }, () => {
      if (
        (this.state.groupId.length || this.state.deviceId.length) &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  }

  render() {
    return (
      <div className="track-wrapper">
        {checkPrivileges("timeLap") ? (
          <Layout
            {...this.props}
            timelineRanges={this.state.timelineRanges}
            classFromChildren={
              (this.state.mainView === "timemachine" ? "with-timeline" : "") +
              (this.state.shownRows
                ? " rows-shown-" + this.state.shownRows
                : "")
            }
            {...this.state}
            openForm={this.openForm}
            pageLoading={this.pageLoading}
            drawRoute={this.drawRoute}
            cancelRoute={this.cancelRoute}
            createReport={this.createReport}
            advanceSettings={this.state.advanceSettingsForm.advanceSettings}
            advanceSettingsForm={this.state.advanceSettingsForm}
            advanceFormChange={this.advanceFormChange}
            reportTypeSelection={this.reportTypeSelection}
            showDates={this.showDates}
            selectedDevices={this.selectedDevices}
            selectedNotifications={this.selectedNotifications}
            selectedGroups={this.selectedGroups}
            changeCheckbox={this.changeCheckbox}
            updateColor={this.updateColor}
            timemachineDevices={this.state.deviceId}
            changeMainView={this.changeMainView}
          >
            {this.state.mainView === "timemachine" && (
              <TimemachineMap
                routes={this.state.routes}
                timemachineArray={this.state.timemachineArray}
                timemachineBounds={this.state.timemachineBounds}
                options={this.state.options}
                items={this.state.items}
                groups={this.state.groups}
                reportsData={this.state.reportsData}
                loading={this.state.loading}
                onDismiss={this.onDismiss}
              />
            )}
          </Layout>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state.devices.data,
  devices2: state.devices2,
  logInUser: state.logInUsers,
  groups: state.groups,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors,
});

export default connect(mapStateToProps)(withLocalize(Timemachine));
