import { checkPrivileges } from "../../Helpers";
import instance from "../../axios";

export const enqueueSnackbar = (notification) => ({
  type: "ENQUEUE_SNACKBAR",
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const removeSnackbar = (key) => ({
  type: "REMOVE_SNACKBAR",
  key,
});

export const getNotification = (notifications) => ({
  type: "GET_NOTIFICATIONS",
  notifications,
});

export const getNotificationType = (notifications) => ({
  type: "GET_NOTIFICATIONS_TYPE",
  notifications,
});

export const getNotificationChannel = (notifications) => ({
  type: "GET_NOTIFICATIONS_CHANNEL",
  notifications,
});
export const addNotification = (notifications) => ({
  type: "ADD_NOTIFICATION",
  notifications,
});
export const updateNotification = (notifications) => ({
  type: "UPDATE_NOTIFICATION",
  notifications,
});
export const removedNotification = (notifications) => ({
  type: "REMOVED_NOTIFICATION",
  notifications,
});

export const addGeofenceDeviceWise = (geofence) => ({
  type: "ADD_GEOFENCE_DEVICE_WISE",
  geofence,
});
export const RemoveGeofenceDeviceWise = (geofence) => ({
  type: "REMOVE_GEOFENCE_DEVICE_WISE",
  geofence,
});
export const getGeofenceDeviceWise = (geofence) => ({
  type: "GET_GEOFENCES_DEVICE_WISE",
  geofence,
});
export const geofenceDeviceWiseSelectedList = (geofences) => ({
  type: "GEOFENCES_DEVICE_WISE_SELECTED_LIST",
  geofences,
});
export const updateGeofenceDeviceWiseSelectedList = (geofence) => ({
  type: "UPDATE_GEOFENCES_DEVICE_WISE_SELECTED_LIST",
  geofence,
});
export const removeGeofenceDeviceWiseSelectedList = (geofence) => ({
  type: "REMOVED_GEOFENCES_DEVICE_WISE_SELECTED_LIST",
  geofence,
});
export const addGeofenceDeviceWiseSelectedList = (geofence) => ({
  type: "ADD_GEOFENCES_DEVICE_WISE_SELECTED_LIST",
  geofence,
});

export const addGroupsDeviceWise = (group) => ({
  type: "ADD_GROUP_DEVICE_WISE",
  group,
});
export const RemoveGroupsDeviceWise = (group) => ({
  type: "REMOVE_GROUP_DEVICE_WISE",
  group,
});
export const groupDeviceWiseSelectedList = (groups) => ({
  type: "GROUPS_DEVICE_WISE_SELECTED_LIST",
  groups,
});
export const updateGroupsDeviceWiseSelectedList = (group) => ({
  type: "UPDATE_GROUPS_DEVICE_WISE_SELECTED_LIST",
  group,
});
export const removeGroupDeviceWiseSelectedList = (group) => ({
  type: "REMOVED_GROUPS_DEVICE_WISE_SELECTED_LIST",
  group,
});
export const addGroupsDeviceWiseSelectedList = (group) => ({
  type: "ADD_GROUPS_DEVICE_WISE_SELECTED_LIST",
  group,
});

// user wise device

export const addDeviceUserWise = (device) => ({
  type: "ADD_DEVICE_USER_WISE",
  device,
});
export const RemoveDeviceUserWise = (device) => ({
  type: "REMOVE_DEVICE_USER_WISE",
  device,
});
export const getDeviceUserWise = (device) => ({
  type: "GET_DEVICE_USER_WISE",
  device,
});
export const deviceUserWiseSelectedList = (device) => ({
  type: "DEVICE_USER_WISE_SELECTED_LIST",
  device,
});
export const updateDeviceUserWiseSelectedList = (device) => ({
  type: "UPDATE_DEVICE_USER_WISE_SELECTED_LIST",
  device,
});
export const removeDeviceUserWiseSelectedList = (device) => ({
  type: "REMOVED_DEVICE_USER_WISE_SELECTED_LIST",
  device,
});
export const addDeviceUserWiseSelectedList = (device) => ({
  type: "ADD_DEVICE_USER_WISE_SELECTED_LIST",
  device,
});

// user wise template
export const templateUserWiseSelectedList = (template) => ({
  type: "TEMPLATE_USER_WISE_SELECTED_LIST",
  template,
});
export const updateTemplateUserWiseSelectedList = (template) => ({
  type: "UPDATE_TEMPLATE_USER_WISE_SELECTED_LIST",
  template,
});

export const removeTemplateUserWiseSelectedList = (template) => ({
  type: "REMOVED_TEMPLATE_USER_WISE_SELECTED_LIST",
  template,
});
export const addTemplateUserWiseSelectedList = (template) => ({
  type: "ADD_TEMPLATE_USER_WISE_SELECTED_LIST",
  template,
});

export const getUserUsereWise = (users) => ({
  type: "ADD_USER_USER_WISE_LIST",
  users,
});

export const removeUserUsereWise = (user) => ({
  type: "REMOVE_USER_USER_WISE",
  user,
});

export const addNotificationDeviceWise = (notification) => ({
  type: "ADD_NOTIFICATION_DEVICE_WISE",
  notification,
});
export const RemoveNotificationDeviceWise = (notification) => ({
  type: "REMOVE_NOTIFICATION_DEVICE_WISE",
  notification,
});
export const getNotificationDeviceWise = (notification) => ({
  type: "GET_NOTIFICATIONS_DEVICE_WISE",
  notification,
});

export const notificationDeviceWiseSelectedList = (notifications) => ({
  type: "NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST",
  notifications,
});
export const addNotificationDeviceWiseSelectedList = (notification) => ({
  type: "ADD_NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST",
  notification,
});
export const updateNotificationDeviceWiseSelectedList = (notification) => ({
  type: "UPDATE_NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST",
  notification,
});
export const removeNotificationDeviceWiseSelectedList = (notification) => ({
  type: "REMOVED_NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST",
  notification,
});

// driver
export const driverDeviceWiseSelectedList = (drivers) => ({
  type: "DRIVERS_DEVICE_WISE_SELECTED_LIST",
  drivers,
});
export const addDriverDeviceWiseSelectedList = (driver) => ({
  type: "ADD_DRIVERS_DEVICE_WISE_SELECTED_LIST",
  driver,
});
export const updateDriverDeviceWiseSelectedList = (driver) => ({
  type: "UPDATE_DRIVERS_DEVICE_WISE_SELECTED_LIST",
  driver,
});
export const removeDriverDeviceWiseSelectedList = (driver) => ({
  type: "REMOVED_DRIVERS_DEVICE_WISE_SELECTED_LIST",
  driver,
});

export const addDriverDeviceWise = (driver) => ({
  type: "ADD_DRIVER_DEVICE_WISE",
  driver,
});
export const RemoveDriverDeviceWise = (driver) => ({
  type: "REMOVE_DRIVER_DEVICE_WISE",
  driver,
});
export const getDriverDeviceWise = (driver) => ({
  type: "GET_DRIVERS_DEVICE_WISE",
  driver,
});

// trailer
export const trailerDeviceWiseSelectedList = (trailers) => ({
  type: "TRAILERS_DEVICE_WISE_SELECTED_LIST",
  trailers,
});
export const addTrailerDeviceWiseSelectedList = (trailer) => ({
  type: "ADD_TRAILERS_DEVICE_WISE_SELECTED_LIST",
  trailer,
});
export const updateTrailerDeviceWiseSelectedList = (trailer) => ({
  type: "UPDATE_TRAILERS_DEVICE_WISE_SELECTED_LIST",
  trailer,
});
export const removeTrailerDeviceWiseSelectedList = (trailer) => ({
  type: "REMOVED_TRAILERS_DEVICE_WISE_SELECTED_LIST",
  trailer,
});

export const addTrailerDeviceWise = (trailer) => ({
  type: "ADD_TRAILER_DEVICE_WISE",
  trailer,
});
export const RemoveTrailerDeviceWise = (trailer) => ({
  type: "REMOVE_TRAILER_DEVICE_WISE",
  trailer,
});
export const getTrailerDeviceWise = (trailer) => ({
  type: "GET_TRAILERS_DEVICE_WISE",
  trailer,
});
// dashboard
export const dashboardDeviceWiseSelectedList = (dashboards) => ({
  type: "DASHBOARDS_DEVICE_WISE_SELECTED_LIST",
  dashboards,
});
export const addDashboardDeviceWiseSelectedList = (dashboard) => ({
  type: "ADD_DASHBOARDS_DEVICE_WISE_SELECTED_LIST",
  dashboard,
});
export const updateDashboardDeviceWiseSelectedList = (dashboard) => ({
  type: "UPDATE_DASHBOARDS_DEVICE_WISE_SELECTED_LIST",
  dashboard,
});
export const removeDashboardDeviceWiseSelectedList = (dashboard) => ({
  type: "REMOVED_DASHBOARDS_DEVICE_WISE_SELECTED_LIST",
  dashboard,
});

// export const addDashboardDeviceWise = dashboard => ({
//   type: 'ADD_DASHBOARD_DEVICE_WISE',
//   dashboard
// })
// export const RemoveDashboardDeviceWise = dashboard => ({
//   type: 'REMOVE_DASHBOARD_DEVICE_WISE',
//   dashboard
// })
// export const getDashboardDeviceWise = dashboard => ({
//   type: 'GET_DASHBOARDS_DEVICE_WISE',
//   dashboard
// })

// widget
export const widgetDeviceWiseSelectedList = (widgets) => ({
  type: "WIDGETS_DEVICE_WISE_SELECTED_LIST",
  widgets,
});
export const addWidgetDeviceWiseSelectedList = (widget) => ({
  type: "ADD_WIDGETS_DEVICE_WISE_SELECTED_LIST",
  widget,
});
export const updateWidgetDeviceWiseSelectedList = (widget) => ({
  type: "UPDATE_WIDGETS_DEVICE_WISE_SELECTED_LIST",
  widget,
});
export const removeWidgetDeviceWiseSelectedList = (widget) => ({
  type: "REMOVED_WIDGETS_DEVICE_WISE_SELECTED_LIST",
  widget,
});

// vehicle
export const vehicleDeviceWiseSelectedList = (vehicles) => ({
  type: "VEHICLES_DEVICE_WISE_SELECTED_LIST",
  vehicles,
});
export const addVehicleDeviceWiseSelectedList = (vehicle) => ({
  type: "ADD_VEHICLES_DEVICE_WISE_SELECTED_LIST",
  vehicle,
});
export const updateVehicleDeviceWiseSelectedList = (vehicle) => ({
  type: "UPDATE_VEHICLES_DEVICE_WISE_SELECTED_LIST",
  vehicle,
});
export const removeVehicleDeviceWiseSelectedList = (vehicle) => ({
  type: "REMOVED_VEHICLES_DEVICE_WISE_SELECTED_LIST",
  vehicle,
});

export const addVehicleDeviceWise = (vehicle) => ({
  type: "ADD_VEHICLE_DEVICE_WISE",
  vehicle,
});
export const RemoveVehicleDeviceWise = (vehicle) => ({
  type: "REMOVE_VEHICLE_DEVICE_WISE",
  vehicle,
});
export const getVehicleDeviceWise = (vehicle) => ({
  type: "GET_VEHICLES_DEVICE_WISE",
  vehicle,
});
// user
export const userDeviceWiseSelectedList = (users) => ({
  type: "USERS_DEVICE_WISE_SELECTED_LIST",
  users,
});
export const addUserDeviceWiseSelectedList = (user) => ({
  type: "ADD_USERS_DEVICE_WISE_SELECTED_LIST",
  user,
});
export const updateUserDeviceWiseSelectedList = (user) => ({
  type: "UPDATE_USERS_DEVICE_WISE_SELECTED_LIST",
  user,
});

export const removedUserDeviceWiseSelectedList = (user) => ({
  type: "REMOVED_USERS_DEVICE_WISE_SELECTED_LIST",
  user,
});
export const getUserDeviceWise = (user) => ({
  type: "GET_USERS_DEVICE_WISE",
  user,
});

export const updateEvents = (events) => ({
  type: "ADD_EVENTS",
  events,
});

export const updateIdling = (payload) => ({
  type: "UPDATE_IDLING",
  payload,
});

export const updateMoving = (payload) => ({
  type: "UPDATE_MOVING",
  payload,
});

export const updateOverspeed = (payload) => ({
  type: "UPDATE_OVERSPEED",
  payload,
});

export const updateTowing = (payload) => ({
  type: "UPDATE_TOWING",
  payload,
});

export const updateOnline = (payload) => ({
  type: "UPDATE_ONLINE",
  payload,
});

export const updateOffline = (payload) => ({
  type: "UPDATE_OFFLINE",
  payload,
});

export const updateGPSNotUpdated = (payload) => ({
  type: "UPDATE_GPS_NOT_UPDATED",
  payload,
});

export const updateNotRegistered = (payload) => ({
  type: "UPDATE_NOT_REGISTERED",
  payload,
});

export const updateParking = (payload) => ({
  type: "UPDATE_PARKING",
  payload,
});

export const updateStop = (payload) => ({
  type: "UPDATE_STOP",
  payload,
});

export const updateGeofenceEntered = (payload) => ({
  type: "UPDATE_GEOFENCE_ENTERED",
  payload,
});

export const updateLowBattery = (payload) => ({
  type: "UPDATE_LOW_BATTERY",
  payload,
});

export const updateEventsByPing = (events) => ({
  type: "ADD_EVENTS_BY_NODE",
  events,
});

export const updateNotificationSetting = (settings) => ({
  type: "UPDATE_NOTIFICATION_SETTING",
  payload: settings,
});

export const updateNotificationCount = (count) => ({
  type: "UPDATE_NOTIFICATION_COUNT",
  payload: count,
});

export const fetchNotificationCount = (dispatch, userInfoFront) => {
  // inital fetch call for geofences
  if (checkPrivileges("device")) {
    instance({
      url: `/api/events/unread`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((result) => {
        // if(result && result.status === 'success') {
        //   const { data } = result
        dispatch(updateNotificationCount(result.count));
        // }
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, dispatch)
      });
  }
};
