import React, { Component } from 'react'
import { connect } from 'react-redux' 
import ChangedPasword from './changedPassword'
import ServicePreferences from './servicePerferences'
import UserDefault from './userDefault'
import Permissions from './Permissions'
// import UserDefault from './userDefault'
//import EmailGatway from './emailGatway'
 
class accountManagements extends Component {
  constructor () {
    super()
    
  }
  
  render () {
     if (this.props.logInUser.id) {
      return (
        <div 
        // style={{ paddingTop: 17 }}
        >
            <div>
                <ServicePreferences {...this.props} />
              </div>
             
              <div 
              // style={{marginTop:15}}
              >
                <ChangedPasword {...this.props} />
              </div>

              <div 
              // style={{marginTop:15}}
              >

              <UserDefault {...this.props} />
              </div>
              <div
              //  style={{marginTop:15, }}
              >
               <Permissions {...this.props} />
              </div>
              {/* <Permissions */}
       </div>    
      )
    } else {
      return null
    }
  }
}

const mapState = state => ({
  users: state.users,
  devices: state.devices.data,
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export const AcManagements = mapStateToProps(accountManagements)
