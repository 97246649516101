import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withLocalize } from "react-localize-redux";
import Layout from "./../../Layout";
import RouteMap from "./../../Components/Maps/RouteMap";
import instance from "../../axios";
import "moment-duration-format";
import {
  checkPrivileges,
  getDateTimeFormat,
  setAttributeFormat,
  errorHandler,
} from "../../Helpers";
import jsontoxml from "jsontoxml";

const ReportData = [];

let trips = {};

class Tracks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsCall: false,
      view: /* 'reportsData', // */ "displayForm",

      from: "",
      trackLoading: false,
      mainView: "tracks",
      to: "",
      selectedDate: "",
      deviceId: [],
      groupId: [],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      reportUnits: true,
      notificationId: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      processInvalidPositions: false,
      useIgnition: false,
      advanceSettingsForm: {},
      routes: {},
      trips: trips,
      routesLoading: {},
      fetchAll: false,
      timemachineArray: {},
      timemachinePositions: [],
      kmlContent: "",
      isCheck: false,
      selectedDateLabel:"Choose Date"
    };

    this.openForm = this.openForm.bind(this);
    this.showDates = this.showDates.bind(this);
    this.selectedDevices = this.selectedDevices.bind(this);
    this.selectedGroups = this.selectedGroups.bind(this);
    this.createReport = this.createReport.bind(this);
    this.advanceFormChange = this.advanceFormChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.reportTypeSelection = this.reportTypeSelection.bind(this);
    this.drawRoute = this.drawRoute.bind(this);
    this.cancelRoute = this.cancelRoute.bind(this);
    //this.drawOnMap = this.drawOnMap.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.pageLoading = this.pageLoading.bind(this);
    this.changeMainView = this.changeMainView.bind(this);
  }
  pageLoading(action) {
    this.setState({ trackLoading: action });
  }
  UNSAFE_componentWillMount() {
    let form = {
      to: moment().endOf("day").toISOString(),
      from: moment().startOf("day").toISOString(),
      deviceIds: this.props.match.params.id || [],
      //  ...advanceSettings
    };
    if (this.props.match.params.id) {
      this.createReport(form);
    }
  }
  toggleDaywiseGroup = (groupId, check, key) => {
    let trips = JSON.parse(JSON.stringify(this.state.trips));
    // Object.keys(trips[groupId]['data']).map(key => {
    trips[groupId]["data"][key].map((row) => {
      if (check === true) {
        row.checked = true;
        this.setState(
          {
            [key]: true,
          },
          () => {
            this.drawRoute(row, this.state[key]);
          }
        );
      } else {
        row.checked = false;
        this.setState(
          {
            [key]: false,
          },
          this.cancelRoute(row)
        );
      }

      return null;
    });
    //   return null
    // })

    this.setState({ trips });
  };

  toggleAllGroup = (groupId, check) => {
    let trips = JSON.parse(JSON.stringify(this.state.trips));
    Object.keys(trips[groupId]["data"]).map((key) => {
      trips[groupId]["data"][key].map((row) => {
        if (check === true) {
          row.checked = true;
          this.setState(
            {
              isCheck: true,
            },
            () => {
              this.drawRoute(row, this.state.isCheck);
            }
          );
        } else {
          row.checked = false;
          this.setState(
            {
              isCheck: false,
            },
            this.cancelRoute(row)
          );
        }

        return null;
      });
      return null;
    });

    this.setState({ trips });
  };

  changeCheckbox(groupDate, groupId, rowId, check) {
    let trips = JSON.parse(JSON.stringify(this.state.trips));

    trips[groupId]["data"][groupDate].map((row) => {
      if (row.startPositionId === rowId) {
        row.checked = check;
      }
      return null;
    });
    this.setState({ trips: trips });
  }

  updateColor(groupDate, groupId, rowId, color) {
    let trips = JSON.parse(JSON.stringify(this.state.trips));
    let routes = JSON.parse(JSON.stringify(this.state.routes));

    trips[groupId]["data"][groupDate].map((row) => {
      if (row.startPositionId === rowId) {
        row.color = color;
        row.checked = true;

        if (routes[row.startPositionId] && routes[row.startPositionId].row) {
          routes[row.startPositionId].row.color = color;
        }
      }
      return null;
    });

    let r = trips[groupId]["data"][groupDate].find(
      (row) => row.startPositionId === rowId
    );

    this.setState({ trips, routes }, () => {
      this.cancelRoute(r);
      this.drawRoute(r);
    });
  }

  // changeMainView (mainView) {
  //   if (mainView === 'timemachine') {
  //     this.setState({ mainView }, () => {
  //       if (this.state.fetchAll === false) {
  //         this.setState({ fetchAll: true }, () => {
  //           let deviceId = ''
  //           let groupId = ''

  //           this.state.deviceId.map(id => (deviceId += 'deviceId=' + id + '&'))
  //           this.state.groupId.map(id => (groupId += 'groupId=' + id + '&'))

  //           fetvch(
  //             '/api/reports/route?' +
  //               deviceId +
  //               groupId +
  //               '&type=allEvents&from=' +
  //               this.state.from +
  //               '&to=' +
  //               this.state.to,
  //             {
  //               headers: new Headers({
  //                 'Content-Type': 'application/json',
  //                 Accept: 'application/json'
  //               })
  //             }
  //           ).then(response => {
  //             if (response.ok) {
  //               response.json().then(positions => {
  //                 let data = {}
  //                 positions.map(p => {
  //                   data[p.deviceId] = data[p.deviceId] || []
  //                   data[p.deviceId].push(p)
  //                   return null
  //                 })
  //                 /* this.setState({
  //                   timemachineArray: data,
  //                   timemachinePositions: positions
  //                 }) */
  //               })
  //             }
  //             else{
  //               throw response
  //             }
  //           }).catch(e => {})
  //         })
  //       }
  //     })
  //   } else {
  //     this.setState({ mainView })
  //   }
  // }

  changeMainView(mainView) {
    this.setState({ mainView });
  }

  advanceFormChange(event, name) {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      advanceSettingsForm: { ...this.state.advanceSettingsForm, [name]: value },
    });
  }

  reportTypeSelection() {
    let v = this.state.reportUnits;
    this.setState({
      reportUnits: !v,
    });
  }

  onCloseModal(e, name) {
    this.setState({ [name]: false });
  }

  openForm() {
    this.setState({ view: "displayForm", reportsData: [], routes: {} });
  }

  saveData(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  calcTime = (date, offset) => {
    let d = new Date(date);

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);

    return nd.toISOString();
  };

  createReport(data) {
    this.setState(
      { loading: true, trackLoading: true, view: "", reportsData: [] },
      () => {
        let header;

        let advanceSettings = {
          ...this.state.advanceSettingsForm,
          minimalTripDistance:
            this.state.advanceSettingsForm.minimalTripDistance !== ""
              ? parseFloat(this.state.advanceSettingsForm.minimalTripDistance) *
                1000
              : 500,
        };
        delete advanceSettings.advanceSettings;

        let form = {
          to: this.state.to || data.to,
          from: this.state.from || data.from,
          deviceIds:  (this.state.deviceId.length !== 0 && this.state.deviceId) ||
            (data.deviceIds.length !== 0 && [data.deviceIds]) ||
            [],
          itemGroupIds: this.state.groupId || [],
          detail: this.state.detail1,
          types: this.state.notificationId || [],
          maintenanceIds:[],
          geofenceIds:[],
          notificationIds:[],
          mail: false,
          //  ...advanceSettings
        };

        instance({
          url: `/api/reports/trips`,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
          }),
          method: "POST",
          data: { ...form },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((reports) => {
            let trips = {};

            if (reports.length) {
              reports.map((position) => {
                position.color = this.getRandomColor();

                trips[position.deviceId] = trips[position.deviceId] || {
                  detail: position,
                  data: {},
                };
                trips[position.deviceId]["data"][
                  moment(position.startTime).format("YYYY-MM-DD")
                ] =
                  trips[position.deviceId]["data"][
                    moment(position.startTime).format("YYYY-MM-DD")
                  ] || [];
                trips[position.deviceId]["data"][
                  moment(position.startTime).format("YYYY-MM-DD")
                ].push(position);
                return null;
              });
            } else {
              this.setState({ trackLoading: false });
            }

            this.setState({
              reportsData: reports,
              trips: trips,
              view: "reportsData",
              fetchAll: false,
            });
            //   })
            // } else {
            //   throw response
            // }
            //this.setState({loading: false})
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch);
          });
      }
    );
  }
  formatXml = (xml) => {
    var formatted = "";
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, "$1\r\n$2$3");
    var pad = 0;
    xml.split("\r\n").forEach((node, index) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad !== 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var tab = "";
      for (var i = 0; i < pad; i++) {
        tab += "  ";
      }

      formatted += tab + node + "\r\n";
      pad += indent;
    });

    return formatted;
  };
  wrapperXML = (name, description, text) => {
    return this.formatXml(
      '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><name>' +
        name +
        "</name><description>" +
        description +
        "</description>" +
        text +
        "</Document></kml>"
    );
  };

  clearSelection = () => {
    this.setState(
      {
        kmlContent: "",
        kmlType: null,
      },
      () => {
        const el = document.getElementById("clearSelection");
        if (el) {
          el.click();
        }
      }
    );
  };
  downloadAllTracks = () => {
    if (this.state.reportsData && this.state.reportsData.length) {
      this.state.reportsData.map((row, index) => {
        this.fetchRoute(
          row,
          this.prepareRoute,
          undefined,
          this.state.reportsData.length === index + 1
        );
        return null;
      });
    }
  };
  downloadTracks = () => {
    let obj = JSON.parse(JSON.stringify(this.state.routes));
    let tracks = {};
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }

    // rearrange tracks by unit
    Object.keys(obj).map((key) => {
      const routeDef = obj[key]["row"];
      const deviceId = routeDef["deviceId"];
      tracks[deviceId] = tracks[deviceId] || {};
      tracks[deviceId][key] = routeDef;
      tracks[deviceId].colors = tracks[deviceId].colors || [];
      tracks[deviceId].colors.push(routeDef.color);
      tracks[deviceId].routesSummery = routeDef;
      tracks[deviceId].routes = tracks[deviceId].routes || [];
      tracks[deviceId].routes.push(obj[key].positions);
      return null;
    });

    // initialize variables

    Object.keys(tracks).map((id) => {
      let geojsonFeature = "";
      let geojsonFeatureStyle = "";
      let RouteTitle = "";
      let RouteDescription = "";
      let fileName = "";

      const { routesSummery, routes, colors } = tracks[id];
      RouteTitle =
        "Track " +
        routesSummery.deviceName +
        " " +
        moment(routesSummery.startTime)
          .tz(serverTimeZoneName)
          .format("YYYY-MM-DD HH:mm") +
        " - " +
        moment(routesSummery.endTime)
          .tz(serverTimeZoneName)
          .format("YYYY-MM-DD HH:mm");
      RouteDescription = "All tracks of " + routesSummery.deviceName;
      fileName = routesSummery.deviceName;

      routes.map((route, index) => {
        let points = [];
        route.map((pos) => {
          points.push([pos.longitude + "," + pos.latitude + " "]);
          return null;
        });
        // get color, replace hash, split after each 2 letters, make reverse and finally join them back;
        const color = colors[index]
          .replace("#", "")
          .match(/.{1,2}/g)
          .reverse()
          .join("");

        // make styles
        const startId = "markerStyleStart_" + index;
        const endId = "markerStyleEnd_" + index;
        const styleId = "mainKMLstyles_" + index;

        let Placemark =
          '<Style id="' +
          startId +
          '"><IconStyle><Icon><href>https://flotilla.enroutech.com/assets/images/maps/start-marker.svg</href></Icon></IconStyle></Style>';
        Placemark +=
          '<Style id="' +
          endId +
          '"><IconStyle><Icon><href>https://flotilla.enroutech.com/assets/images/maps/end-marker.svg</href></Icon></IconStyle></Style>';
        Placemark +=
          '<Style id="' +
          styleId +
          '"><LineStyle><color>FF' +
          color +
          "</color><width>6</width></LineStyle></Style>";

        const currentRoute = route[0]["id"];
        if (tracks[id] && tracks[id][currentRoute]) {
          const {
            startAddress,
            startTime,
            endAddress,
            endTime,
            distance,
            duration,
          } = tracks[id][currentRoute];
          // make start point
          Placemark += jsontoxml({
            Placemark: {
              styleUrl: "#" + startId,
              name: "Track #" + (index + 1) + " start",
              Popup: {
                address: startAddress,
                time: moment(startTime)
                  .tz(serverTimeZoneName)
                  .format("YYYY-MM-DD HH:mm"),
                distance: setAttributeFormat("distance", distance),
                duration: setAttributeFormat("hours", duration),
              },
              Point: {
                coordinates: route[0].longitude + "," + route[0].latitude,
              },
              TimeStamp: {
                when: route[0].fixTime,
              },
            },
          });

          // make end point
          Placemark += jsontoxml({
            Placemark: {
              styleUrl: "#" + endId,
              name: "Track #" + (index + 1) + " end",
              Popup: {
                address: endAddress,
                time: moment(endTime)
                  .tz(serverTimeZoneName)
                  .format("YYYY-MM-DD HH:mm"),
                distance: setAttributeFormat("distance", distance),
                duration: setAttributeFormat("hours", duration),
              },
              Point: {
                coordinates:
                  route[route.length - 1].longitude +
                  "," +
                  route[route.length - 1].latitude,
              },
              TimeStamp: {
                when: route[route.length - 1].fixTime,
              },
            },
          });

          // make track
          Placemark += jsontoxml({
            Placemark: {
              name: "Track #" + (index + 1),
              styleUrl: "#" + styleId,
              TimeSpan: {
                begin: route[0].fixTime,
                end: route[route.length - 1].fixTime,
              },
              LineString: {
                coordinates: points,
              },
            },
          });

          let folderXML = jsontoxml({
            Folder: {
              name: "Track #" + (index + 1),
            },
          });

          let folderXMLFinal = folderXML.split("</name>");
          geojsonFeature +=
            folderXMLFinal[0] + "</name>" + Placemark + folderXMLFinal[1];
        }
        return null;
      });

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href =
        "data: application/xml;charset=utf-8," +
        encodeURIComponent(
          this.wrapperXML(
            RouteTitle,
            RouteDescription,
            geojsonFeatureStyle + "" + geojsonFeature
          )
        );
      a.download = fileName + ".kml";
      a.click();
      a.remove();
      return null;
    });
  };

  fetchRoute = (row, callback, visible, isLastChild, type) => {
    instance({
      url: `/api/positions/${row.deviceId}`,
      method: "GET",
      params: {
        startPositionId: row.startPositionId,
        endPositionId: row.endPositionId,
        from: moment.utc(row.startTime).toISOString(),
        to: moment.utc(row.endTime).toISOString(),
      },
    })
      // .then(response => {
      // if (response.ok) {
      //   response.json()
      .then((positions) => {
        callback(positions, row, visible, isLastChild, type);
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
  upload = (n) => {
    if (n.target.files) {
      let e = n.target.files[0],
        t = e.name.split("."),
        i = e.size,
        ko = 50000000,
        r = ["geojson", "json", "gpx", "kml", "xml"],
        u = t[t.length - 1].toLowerCase();

      if (r.includes(u)) {
        if (i > ko) {
          return (
            "File size is too large. Max allowed size is " +
            Math.round(ko / (1024 * 1000)) +
            "MB"
          );
        } else {
          let p = new FileReader();
          p.readAsText(e, "UTF-8");
          p.onload = (o) => {
            let n = o.target.result;
            if ("kml" === u) {
              let t = new DOMParser().parseFromString(n, "text/xml"),
                tag = t && t.documentElement && t.documentElement.nodeName;
              if ("kml" !== tag && "gpx" !== tag) {
                return "File content is not valid.";
              }
              u = tag;
            }

            const parser = new DOMParser();
            const kml = parser.parseFromString(n, "text/xml");
            this.setState({
              kmlContent: kml,
              kmlType: u,
            });
            return {
              name: e.name,
              size: Math.round(e.size / (1024 * 1000)) + "MB",
            };
          };
        }
      } else {
        return "Unsupported file type.";
      }
      return { name: e.name, size: (e.size / 1024).toFixed(2) + "KB" };
    }
  };

  prepareRoute = (positions, row, visible, isLastChild, type) => {
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }
    let key = moment(row.startTime).format("YYYY-MM-DD");
    if (positions.length) {
      let routes = JSON.parse(JSON.stringify(this.state.routes));
      routes[row.startPositionId] = {
        visible:
          type === "single"
            ? visible
            : visible !== undefined
            ? this.state[key] || this.state.isCheck
            : this.state.routes[row.startPositionId] &&
              this.state.routes[row.startPositionId].visible,
        positions,
        row: row,
      };

      this.setState(
        {
          routes,
          routesLoading: {
            ...this.state.routesLoading,
            [row.startPositionId]: false,
          },
        },
        () => {
          this.props.drawTracks(this.state.routes,this.state.kmlContent)
          if (isLastChild) {
            this.downloadTracks();
          }
        }
      );
    } else {
      alert("data not found!");
      this.setState({
        routesLoading: {
          ...this.state.routesLoading,
          [row.startPositionId]: false,
        },
      });
    }
  };

  drawRoute(row, visible, type) {
    if (this.state.routes && !this.state.routes[row.startPositionId]) {
      let routesLoading = { ...this.state.routesLoading };
      routesLoading[row.startPositionId] = visible;
      this.setState({ routesLoading }, () => {
        this.fetchRoute(row, this.prepareRoute, visible, false, type)
        this.props.drawTracks(this.state.routes,this.state.kmlContent)
      });
    } else {
      let routes = { ...this.state.routes };
      routes[row.startPositionId].visible = true;
      this.setState({ routes });
    }
  }
  cancelRoute(row) {
    if (row && row.startPositionId) {
      if (this.state.routes[row.startPositionId]) {
        if (this.state.routes && this.state.routes[row.startPositionId]) {
          let routes = { ...this.state.routes }
          routes[row.startPositionId].visible = false;
          this.setState({
            routes,
          },()=>{
              this.props.drawTracks(this.state.routes,this.state.kmlContent)
          })
        }
      }
    }
  }

  showDates(e, date) {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
    };

    let timeFormat = getDateTimeFormat();

    this.setState(
      {
        selectedDateLabel: date.chosenLabel,
        selectedDate:
          moment(date.startDate).format(timeFormat) +
          " - " +
          moment(date.endDate).format(timeFormat),
        ...formatedDate,
      },
      () => {
        if (
          (this.state.groupId.length || this.state.deviceId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      }
    );
  }

  selectedNotifications = (notificationId) => {
    var notif = [];
    if (notificationId && notificationId.length) {
      notificationId.map((d) => {
        notif.push(d.value);
        return null;
      });
    }
    this.setState({ notificationId: notif }, () => {
      if (
        (this.state.groupId.length || this.state.deviceId.length) &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  };

  selectedDevices(deviceId) {
    this.setState({ deviceId }, () => {
      if (
        (this.state.groupId.length || this.state.deviceId.length) &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  }

  selectedGroups(groupId) {
    if (groupId && groupId.length) {
      this.setState({ groupId }, () => {
        if (
          (this.state.groupId.length || this.state.deviceId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      });
    }
  }

  render() {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }

    return (
      <div className="track-wrapper">
        {checkPrivileges("track") && (
          <Layout
            {...this.props}
            classFromChildren={
              this.state.mainView === "timemachine" && "with-timeline"
            }
            {...this.state}
            kmlFileUpload={this.upload}
            clearSelection={this.clearSelection}
            downloadAllTracks={this.downloadAllTracks}
            openForm={this.openForm}
            pageLoading={this.pageLoading}
            drawRoute={this.drawRoute}
            cancelRoute={this.cancelRoute}
            createReport={this.createReport}
            advanceSettingsForm={this.state.advanceSettingsForm}
            advanceSettings={this.state.advanceSettingsForm.advanceSettings}
            advanceFormChange={this.advanceFormChange}
            reportTypeSelection={this.reportTypeSelection}
            showDates={this.showDates}
            selectedDevices={this.selectedDevices}
            selectedNotifications={this.selectedNotifications}
            selectedGroups={this.selectedGroups}
            changeCheckbox={this.changeCheckbox}
            updateColor={this.updateColor}
            toggleAllGroup={this.toggleAllGroup}
            toggleDaywiseGroup={this.toggleDaywiseGroup}
            changeMainView={this.changeMainView}
          >
            {/* {this.state.mainView === "tracks" && (
              <RouteMap
                themecolors={this.props.themecolors}
                serverTimeZoneName={serverTimeZoneName}
                routes={this.state.routes}
                kmlContent={this.state.kmlContent}
              />
            )} */}
            {/* this.state.mainView === 'timemachine' && <Timemachine routes={this.state.routes} timemachineArray={this.state.timemachineArray} timemachinePositions={this.state.timemachinePositions} reportsData={this.state.reportsData} /> */}
          </Layout>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const deviceCategory = {};
  state.devices2.map((d) => {
    deviceCategory[d.id] = d.category || "default";
    return null;
  });
  return {
    deviceCategory,
    devices: state.devices.data,
    logInUser: state.logInUsers,
    groups: state.groups,
    ServerSetting: state.ServerSetting,
    themecolors: state.themeColors,
  };
};

export default connect(mapStateToProps)(withLocalize(Tracks));
