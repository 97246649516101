import axios from "axios";
import React from "react";
import { saveGroupWiseDevices } from "../../Actions/Groups";
import { fetchMoreDevices } from "../../Actions/Devices";
import Notifications from "react-notification-system-redux";
import { Translate } from "react-localize-redux";
import instance from "../../axios";
const initalState = {
  page: 1,
  pageSize: 50,
  total: 0,
  hasNext: false,
  data: [],
  loading: false,
};

const CancelToken = axios.CancelToken;
const CancelToken1 = axios.CancelToken;
let source;
let source1;

// This function takes a component...
export default function withResources(WrappedComponent, itemType) {
  // ...and returns another component...
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: { ...initalState },
        nested: { ...initalState },
        searchText: "",
        loadingItem: 0,
      };
    }

    changeResource = (body, callback) => {
      const method = body && body.id ? "PUT" : "POST";
      const url =
        body && body.id ? `/api/itemgroups/${body.id}` : `/api/itemgroups`;
      let dataFormat = {
        ...body,
        description: body.description || "",
      };
      axios({
        url,
        method,
        data: dataFormat,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.status === "success"
          ) {
            this.props.dispatch(
              Notifications.success({
                message:
                  method === "POST" ? (
                    <Translate id="groupAdded" />
                  ) : (
                    <Translate id="groupUpdated" />
                  ),
                autoDismiss: 5,
              })
            );
          }
          this.setState({ data: { ...initalState } }, () => {
            this.fetchMore(1);
          });
        })
        .catch((error) => {
          if (callback) {
            callback(error);
          }
          this.setState({
            data: {
              ...this.state.data,
              loading: false,
            },
          });
        });
    };

    deleteResource = (id, callback) => {
      const method = "DELETE";
      const url = `/api/itemgroups/${id}`;
      axios({
        url,
        method,
      })
        .then((response) => {
          if (response.status === 200) {
            this.props.dispatch(
              Notifications.success({
                message: <Translate id="groupDeleted" />,
                autoDismiss: 5,
              })
            );
          }
          if (callback) {
            callback(response);
          }
          this.setState({ data: { ...initalState } }, () => {
            this.fetchMore(1);
          });
        })
        .catch((error) => {
          if (callback) {
            callback(error);
          }
          this.setState({
            data: {
              ...this.state.data,
              loading: false,
            },
          });
        });
    };

    componentDidMount() {
      source = CancelToken.source();
      source1 = CancelToken1.source();
      this.fetchMore();
    }
    componentWillUnmount() {
      if (source) {
        source.cancel();
      }
      if (source1) {
        source1.cancel();
      }
      this.props.dispatch(saveGroupWiseDevices([]));
    }

    onSearchNested = (id, text) => {
      this.setState(
        {
          searchTextNested: text,
          searchText: text,
          nested: { ...initalState },
        },
        () => {
          this.fetchItems(id, 1);
        }
      );
    };

    onSearch = (text) => {
      this.setState(
        {
          searchText: text,
          data: { ...initalState },
        },
        () => {
          this.fetchMore();
        }
      );
    };

    onNext = () => {
      this.fetchMore();
    };
    fetchMore = (page) => {
      this.setState(
        {
          data: {
            ...this.state.data,
            loading: true,
          },
        },
        () => {
          if (source1) {
            source1.cancel();
          }
          source1 = CancelToken1.source();
          instance({
            method: "GET",
            cancelToken: source1.token,
            url: `/api/itemgroups/get?itemType=${itemType}&page=${
              page || this.state.data.page
            }&search=${this.state.searchText}`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              this.setState({
                data: {
                  ...response,
                  page: response.page + 1,
                  data: [...this.state.data.data, ...response.data],
                  loading: false,
                },
              });
            })
            .catch((error) => {
              // errorHandler(error, this.props.dispatch, this.errorCallBack)
              this.setState({
                data: {
                  ...this.state.data,
                  loading: false,
                },
              });
            });
        }
      );
    };

    fetchItems = (id, page) => {
      this.setState(
        {
          nested: {
            ...this.state.nested,
            loading: true,
          },
          loadingItem: id,
        },
        () => {
          if (source) {
            source.cancel();
          }
          source = CancelToken.source();

          axios({
            method: "GET",
            cancelToken: source.token,
            url: `/api/itemgroups/items?itemgroupId=${id}&page=${
              page || this.state.data.page
            }&limit=-1&search=${this.state.searchText}`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              this.setState({
                nested: {
                  ...response.data.data,
                  page: response.data.data.page + 1,
                  data: [...response.data.data.data],
                  loading: false,
                },
              });

              if (
                itemType === "Device" &&
                response.data &&
                response.data.data &&
                response.data.data.data &&
                response.data.data.data
              ) {
                this.props.dispatch(
                  saveGroupWiseDevices(
                    response.data.data.data.length
                      ? response.data.data.data.map((e) => e.id)
                      : []
                  )
                );
                this.props.dispatch(fetchMoreDevices(true, null, true));
              }
            })
            .catch((error) => {
              this.setState({
                nested: {
                  ...this.state.nested,
                  loading: false,
                },
              });
            });
        }
      );
    };

    assignItem = (itemgroupid, itemid, callback) => {
      axios({
        url: `/api/permissions`,
        method: "POST",
        data: { itemgroupid, itemid },
      })
        .then((response) => {
          if (response) {
            this.fetchItems(itemgroupid, 1);
          }
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          if (callback) {
            callback(error);
          }
        });
    };

    unassignItem = (itemgroupid, itemid, callback) => {
      axios({
        url: `/api/permissions`,
        method: "DELETE",
        data: { itemgroupid, itemid },
      })
        .then((response) => {
          if (response) {
            this.fetchItems(itemgroupid, 1);
          }
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          if (callback) {
            callback(error);
          }
        });
    };

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <WrappedComponent
          itemType={itemType}
          resources={this.state.data}
          nextResources={this.onNext}
          changeResource={this.changeResource}
          deleteResource={this.deleteResource}
          searchResources={this.onSearch}
          assignItem={this.assignItem}
          unassignItem={this.unassignItem}
          searchNestedResources={this.onSearchNested}
          nestedResources={this.state.nested}
          fetchNestedItems={this.fetchItems}
          loadingItem={this.state.loadingItem}
          {...this.props}
          key={itemType}
        />
      );
    }
  };
}
