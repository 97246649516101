import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateDevicesBySocket,
  updatePositions,
  updateAllNotifications,
} from "./Actions/Devices";
import {
  updateEventsByPing,
  updateNotificationCount,
} from "./Actions/Notifications";
import NotificationSystem from "./NotificationSystem";
import Notifications from "react-notification-system-redux";
import { setAttributeFormat } from "./Helpers";
import { withLocalize } from "react-localize-redux";
import ReactHtmlParser from "react-html-parser";
import success from "./assets/Success.mp3";

class SocketController extends Component {
  constructor(props) {
    super(props);
    this.showNotification = this.showNotification.bind(this);
    this.state = {
      socket: null,
      eventsData: "",
      playSound: 0,
    };
    this._notificationSystem = React.createRef();
    window.alertMessage = this.connectSocket.bind(this);
  }

  showNotification(data, level) {
    const notification = this._notificationSystem.current;
    if (notification) {
      notification.addNotification({
        ...data,
        level,
      });
    }
  }

  hideNotification(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false, notification: {} });
  }

  UNSAFE_componentWillReceiveProps(n, s) {
    if (
      Object.keys(n.logInUser).length &&
      !Object.keys(this.props.logInUser).length
    ) {
      if (this.state.socket === null) {
        this.connectSocket();
      }
    } else if (
      !Object.keys(n.logInUser).length &&
      Object.keys(this.props.logInUser).length
    ) {
      if (this.state.socket && this.state.socket.close) {
        this.state.socket.close();
        this.setState({ socket: null });
      }
    }
  }

  changeSoundStatus = () => {
    if (this.state.playSound > 0) {
      setTimeout(
        function () {
          this.setState({ playSound: 0 });
        }.bind(this),
        2000
      );
    } else return null;
  };

  connectSocket() {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    var url = protocol + "//" + window.location.host + "/api/socket";

    this.setState({ socket: new WebSocket(url) }, () => {
      if (this.state.socket.url !== url) {
      }

      this.state.socket.onclose = () => {
        if (this.props.logInUser && this.props.logInUser.id) {
          setTimeout(() => this.connectSocket(), 60 * 1000);
        }
      };

      this.state.socket.onerror = (e) => {
        if (e.type === "error") {
          // setTimeout(() => this.connectSocket(), 60 * 1000);
          // this.props.dispatch(enqueueSnackbar([{type: 'error', message: 'Socket disconnected!'}]));
          // this.props.dispatch(enqueueSnackbar([{type: 'error', message: 'Socket disconnected!'}]));
        }
        // this.props.enqueueSnackbar('Socket Error', {variant: 'error', persist: true});
      };

      this.state.socket.onopen = (e) => {
        // this.props.enqueueSnackbar('Socket Connnected', {variant: 'success', persist: true});
        // this.props.dispatch(enqueueSnackbar(e));
      };

      this.state.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data) {
          if (data.devices) {
            this.props.dispatch(updateDevicesBySocket(data.devices));
            return null;
          }
          if (data.positions) {
            this.props.dispatch(updatePositions(data.positions));
            this.props.dispatch(updateAllNotifications());
            return null;
            // DrawNotification(data.positions, this.props.devices, this.props.positions, this.props.logInUser, this.showNotification);
          }
          if (data.events) {
            let len = data.events && data.events.length;
            if (len > 0) {
              let count = this.props.notificationCount + len;
              this.props.dispatch(updateNotificationCount(count));
            }
            this.props.dispatch(updateEventsByPing(data.events));

            data.events.forEach((noti) => {
              const notificationData = {
                title: "",
                children: null,
                position: "tr",
                autoDismiss:
                  noti.attributes && noti.attributes.alarmState
                    ? noti.attributes.alarmState
                    : 10,
              };
              let level = "info";

              this.props.devices.map((device) => {
                if (device.id === noti.deviceId) {
                  let pos = {};
                  if (this.props.positions.length) {
                    this.props.positions.map((p) => {
                      if (p.deviceId === noti.deviceId) {
                        pos = p;
                      }
                      return null;
                    });
                  }

                  let title = this.props.translate("notification." + noti.type);

                  switch (noti.type) {
                    case "deviceOverspeed":
                      level = "info"; // i cannged to info from warning
                      title +=
                        " " +
                        setAttributeFormat("speed", noti.attributes.value);

                      break;
                    default:
                      break;
                  }

                  if (noti.name) {
                    title += "(" + noti.name + ")";
                  }
                  if (
                    this.props.logInUser &&
                    this.props.logInUser.attributes &&
                    this.props.logInUser.attributes.soundAlert
                  ) {
                    this.setState({ playSound: this.state.playSound + 1 });
                  }
                  notificationData.message = (
                    <GetMessage
                      title={title}
                      color={noti.attributes.alertColor}
                      message={
                        <>
                          {device.label} <br />{" "}
                          {pos && pos.address
                            ? ReactHtmlParser(pos.address)
                            : null}
                        </>
                      }
                    />
                  );
                }
                return null;
              });

              this.props.dispatch(Notifications.show(notificationData, level));
            });
            // this.props.dispatch(enqueueSnackbar(data.events));

            // this.showNotification(data.events)
            // displayNotifications(data.events);
            /* this.setState({
              eventsData: data.events
            })
            DrawNotification(
              data.events,
              this.props.devices,
              this.props.positions,
              this.props.logInUser,
              this.props.translate,
              this.showNotification,
              this.props
            ) */
            return null;
          }
        } else {
          // this.props.dispatch(enqueueSnackbar('Server Error'))
          // this.props.enqueueSnackbar('Server Error', {variant: 'error', persist: true});
        }
      };
    });
  }

  render() {
    return (
      <div>
        <NotificationSystem />
        {this.state.playSound > 0 ? (
          <>
            <audio ref="audio_tag" autoPlay={true} controls={false}>
              <source type="audio/mp3" src={success} />
            </audio>
            {this.changeSoundStatus()}
          </>
        ) : null}
      </div>
    );
  }

  /* render () {
    let { eventsData } = this.state
    return (
      <>
        {eventsData &&
          eventsData[0] &&
          eventsData[0].attributes &&
          eventsData[0].attributes.alertColor && (
            <Style>
              {`
            .notification-info {
            background: ${eventsData[0].attributes.alertColor} !important;
            border-color: ${eventsData[0].attributes.alertColor} !important;
           }
        `}
            </Style>
          )}
        <div>
          <NotificationSystem ref={this._notificationSystem} />
          <NotificationSystem2 />
          <Notifier />
        </div>
      </>
    )
  } */
}

const GetMessage = ({ title, message, color }) => {
  return (
    <div className="notification-inner-box" style={{ background: color }}>
      <h4
        className="notification-title"
        style={{
          fontSize: 14,
          margin: "0 0 5px",
          padding: 0,
          fontWeight: "bold",
        }}
      >
        {title}
      </h4>
      <div className="notification-message">{message}</div>
    </div>
  );
};

const mapState = (state) => ({
  devices: state.devices2,
  positions: state.positions,
  logInUser: state.logInUsers,
  notificationCount: state.notificationCount,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(withSnackbar(withLocalize(SocketController)));

export function DrawNotification(
  notification,
  devices,
  positions,
  logInUser,
  translate,
  callbackFunc,
  props
) {
  if (devices.length < 1) {
    return null;
  }
  if (
    logInUser &&
    logInUser.attributes &&
    logInUser.attributes.notificationEnabled === false
  ) {
    return null;
  }
  // let variant = 'default';
  const noti = notification[0];
  let inteverTime = 10;
  if (
    notification[0] &&
    notification[0].attributes &&
    notification[0].attributes.alarmState
  ) {
    inteverTime = notification[0].attributes.alarmState;
  }
  // let html = "";alarmState
  // let options = { variant, action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) }
  const notificationDataForSave = {};
  const notificationData = {
    title: "",
    message: "",
    position: "tr",
    autoDismiss: inteverTime,
    /* action: {
            label: 'Click me!!',
            callback: () => alert('clicked!')
        } */
  };
  let level = "info";

  devices.map((device) => {
    if (device.id === noti.deviceId) {
      let pos = {};
      if (positions.length) {
        positions.map((p) => {
          if (p.deviceId === noti.deviceId) {
            pos = p;
          }
          return null;
        });
      }

      switch (noti.type) {
        case "ignitionOn":
        case "ignitionOff":
        case "deviceMoving":
        case "deviceStop":
        case "geofenceExit":
        case "geofenceEnter":
          if (noti.name) {
            notificationData.title =
              translate("notification." + noti.type) +
              " " +
              "(" +
              noti.name +
              ")";
          } else {
            notificationData.title = translate("notification." + noti.type);
          }

          notificationData.message = (
            <div>
              {device.label} <br />
              {pos && pos.address ? ReactHtmlParser(pos.address) : null}
            </div>
          );
          // html = (<div><Typography variant="subtitle1" color="inherit">{translate('notification.'+noti.type)}</Typography>{device.label} <br />{(pos && pos.address) ? ReactHtmlParser(pos.address) : null} </div>);
          // variant = 'info'
          level = "info";
          break;
        case "deviceOverspeed":
          // variant = 'warning'
          level = "info"; // i cannged to info from warning
          if (noti.name) {
            notificationData.title =
              translate("notification." + noti.type) +
              " " +
              setAttributeFormat("speed", noti.attributes.value) +
              " " +
              "(" +
              noti.name +
              ")";
          } else {
            notificationData.title =
              translate("notification." + noti.type) +
              " " +
              setAttributeFormat("speed", noti.attributes.value);
          }

          notificationData.message = (
            <div>
              {device.label} <br />
              {pos && pos.address ? ReactHtmlParser(pos.address) : null}
            </div>
          );

          // html = (<div><Typography variant="subtitle1" color="inherit">{translate('notification.'+noti.type)}</Typography>{device.label} <br/>Speed: {setAttributeFormat('speed',noti.attributes.speed, speedUnit)} <br />{pos && pos.address}</div>);
          break;
        default:
          // variant = 'default';
          level = "info";
          if (noti.name) {
            notificationData.title =
              translate("notification." + noti.type) +
              " " +
              "(" +
              noti.name +
              ")";
          } else {
            notificationData.title = translate("notification." + noti.type);
          }
          notificationData.message = (
            <div>
              {device.label} <br />
              {pos && pos.address ? ReactHtmlParser(pos.address) : null}
            </div>
          );
          // html = (<div><Typography variant="subtitle1" color="inherit">{translate('notification.'+noti.type)}</Typography>{device.label} <br />{pos && pos.address}</div>);
          break;
      }

      notificationDataForSave.title = notificationData.title;
      notificationDataForSave.data = noti;
      notificationDataForSave.name = device.label;
      notificationDataForSave.id = device.id;
    } else {
      if (noti && noti.type) {
        if (noti.type === "error") {
          // variant = 'error';
          level = "error";
          notificationData.title = noti.message;
          notificationData.message = null;
          // options = {...options, action: ( <Button color="inherit" size="small">{'Dismiss'}</Button> ), persist: true}
          // html = (<div><Typography variant="subtitle1" color="inherit">{noti.message}</Typography></div>);
        }
      } else {
        // variant = 'error';
        level = "error";
        notificationData.title = notification;
        notificationData.message = null;
        // html = (<div><Typography variant="subtitle1" color="inherit">{notification}</Typography></div>);
      }
    }
    return null;
  });

  if (!devices.length && noti && noti.type) {
    if (noti.type === "error") {
      // variant = 'error';
      level = "error";
      notificationData.title = noti.message;
      notificationData.message = null;
      // options = {...options, action: ( <Button color="inherit" size="small">{'Dismiss'}</Button> ), persist: true}
      // html = (<div><Typography variant="subtitle1" color="inherit">{noti.message}</Typography></div>);
    }
  }

  // options = {...options, variant};

  props.dispatch(updateEventsByPing(notificationDataForSave));
  callbackFunc(notificationData, level);
  return null;
}
