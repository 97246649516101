import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import Notifications from "react-notification-system-redux";
import "./../Login/Style.scss";
import TextField from "./../../Components/common/TextField";
import { withLocalize } from "react-localize-redux";
import Button from "../../Components/common/Button";
import { connect } from "react-redux";
import Loader from "../../Layout/Loader";
import Style from "style-it";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPassword = (fn) => {
  let query = useQuery();
  let key = "dG9rZW5Gb3JQYXNzd29yZFJlc2V0";
  return <Params {...fn} tokenKey={key} token={query.get(key)} />;
};

class Params1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        loading: true,
        npass: "",
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.setState(
      {
        form: { ...this.state.form, [this.props.tokenKey]: this.props.token },
      },
      () => {
        this.verifyToken();
      }
    );
  }

  verifyToken = () => {
    instance({
      url: `/api/password/validate`,
      method: `POST`,
      data: `${this.props.tokenKey}=${this.props.token}`,
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((res) => {
        // if (res.status === 'success') {
        this.setState({ loading: false });
        // } else {
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate('tokenExpired'),
        //       autoDismiss: 10
        //     })
        //   )

        //   this.props.history.push('/logout')
        // }
        //   })
        // } else {
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate('invalid token'),
        //       autoDismiss: 10
        //     })
        //   )

        //   this.props.history.push('/logout')
        //     throw response
        //   }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
    // .catch(e => {
    //   this.props.dispatch(
    //     Notifications.error({
    //       message: this.props.translate('invalid token'),
    //       autoDismiss: 10
    //     })
    //   )

    //   this.props.history.push('/logout')
    // })
  };

  switchView = (view) => {
    this.setState({ view });
  };

  drawParams = (json) => {};

  handleChange = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleReset = (event) => {
    event.preventDefault();

    instance({
      url: `/api/password/reset`,
      method: `POST`,
      data: `${this.props.tokenKey}=${this.props.token}&npass=${this.state.form.npass}`,
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((res) => {
        // if (res.status === 'success') {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("passwordIsChanged"),
            autoDismiss: 10,
          })
        );

        this.props.history.push("/logout");
        // } else {
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate('somthingWentWrongMessage'),
        //       autoDismiss: 10
        //     })
        //   )
        // }
        //   })
        // } else {
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate('somthingWentWrongMessage'),
        //       autoDismiss: 10
        //     })
        //   )
        //     throw response
        //   }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  render() {
    const { form } = this.state;

    return (
      <div className="loginWrapper">
        <Style>
          {`
          a {
            color: ${this.props.themecolors.textColor}
          }
          
          .login-footer {
            border-top: 1px solid ${this.props.themecolors.themeLightColor};
          }
          `}
        </Style>
        {/* <ResetPassword callback={this.drawParams} /> */}
        <div className="loginWrapperInner">
          {this.state.loading === true ? (
            <Loader />
          ) : (
            <form
              className="loginForm"
              onSubmit={this.handleReset}
              autoComplete="off"
            >
              <div className="login-logo-wrapper">
                <img
                  className="login-logo"
                  src={"./../../assets/images/common/logo.png"}
                  alt="logo"
                />
              </div>

              <TextField
                themecolors={this.props.themecolors}
                id="npass"
                variant="outlined"
                label={this.props.translate("userNewPassword") + " *"}
                type="password"
                value={form.npass || ""}
                onChange={this.handleChange}
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <p style={{ textAlign: "center", marginTop: 0 }}>
                <Button
                  type="submit"
                  className="btn-submit"
                  themecolors={this.props.themecolors}
                >
                  {this.props.translate("userReset")}
                </Button>
              </p>

              <div className="login-footer">
                <a
                  href={
                    this.props.whiteLabling && this.props.whiteLabling.termsLink
                  }
                >
                  {this.props.translate("about")}
                </a>
                <a
                  href={
                    this.props.whiteLabling && this.props.whiteLabling.termsLink
                  }
                >
                  {this.props.translate("termsAndConditions")}
                </a>
                <a
                  href={
                    this.props.whiteLabling &&
                    this.props.whiteLabling.privacyPolicyLink
                  }
                >
                  {this.props.translate("privacy")}
                </a>
              </div>
            </form>
          )}
        </div>
        {/* <div
          className="white-label-bg"
          style={{
            backgroundImage: `url(${this.props.whiteLabling.logInPageImage})`,
          }}
        ></div> */}
      </div>
    );
  }
}
const mapState = (state) => ({
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export const Params = mapStateToProps(withLocalize(Params1));
