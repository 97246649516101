import React, { Component } from "react";
import TextField from "../../../Components/common/TextField";
import MenuItem from "../../../Components/common/MenuItem";
import Grid from "@material-ui/core/Grid";
import Notifications from "react-notification-system-redux";
import Checkbox from "../../../Components/common/CheckboxPermissions";
import Tooltip from "../../../Components/common/Tooltip";
import ReactHtmlParser from "react-html-parser";
import "@webscopeio/react-textarea-autocomplete/style.css";
import Autocomplete from "../../../Components/common/Autocomplete";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import instance from "../../../axios";
import { errorHandler } from "../../../Helpers";
import Button from "../../../Components/common/Button";
const operators = [
  { value: "+", label: "(+) Sum" },
  { value: "-", label: "(-) Subtract" },
  { value: "*", label: "(*) Multiply" },
  { value: "/", label: "(/) Divide" },
];
class Sensors extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
  }

  handleChange(name, event) {
    this.props.handleChange(name, event);
  }

  changeAttribute(name, value) {
    this.props.changeAttribute(name, value);
  }

  computedAttributes_Selection(deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId,
    };

    instance({
      url: `/api/permissions/`,
      method: "POST",
      data: {
        ...obj,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          // this.props.dispatch(addComputedAttributeDeviceWise(item));
          // this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("attributeAssignedSuccessfully"),
              autoDismiss: 10,
            })
          );
        } else {
          throw response;
        }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  render() {
    const disceteSensorsTypes = [
      {
        label: this.props.translate("sensorsTranslation.driverUniqueId"),
        value: "driverUniqueId",
      },
      {
        label: this.props.translate("sensorsTranslation.passengerId"),
        value: "passengerId",
      },
      {
        label: this.props.translate("sensorsTranslation.trailerUniqueId"),
        value: "trailerUniqueId",
      },
      {
        label: this.props.translate("sensorsTranslation.ignition"),
        value: "ignition",
      },
      {
        label: this.props.translate("sensorsTranslation.panicButton"),
        value: "panicButton",
      },
      {
        label: this.props.translate("sensorsTranslation.externalPower"),
        value: "externalPower",
      },
      {
        label: this.props.translate("sensorsTranslation.enginStatus"),
        value: "enginStatus",
      },
      {
        label: this.props.translate("sensorsTranslation.carAlarm"),
        value: "carAlarm",
      },
      {
        label: this.props.translate("sensorsTranslation.doors"),
        value: "doors",
      },
      { label: this.props.translate("sensorsTranslation.fuel"), value: "fuel" },
      {
        label: this.props.translate("sensorsTranslation.temperature"),
        value: "temperature",
      },
      { label: this.props.translate("sensorsTranslation.rpm"), value: "rpm" },
      {
        label: this.props.translate("sensorsTranslation.boardPower"),
        value: "boardPower",
      },
      {
        label: this.props.translate("sensorsTranslation.mileage"),
        value: "mileage",
      },
      {
        label: this.props.translate("sensorsTranslation.speed"),
        value: "speed",
      },
      {
        label: this.props.translate("sensorsTranslation.counter"),
        value: "counter",
      },

      {
        label: this.props.translate("sensorsTranslation.averagFuelConsumption"),
        value: "averagFuelConsumption",
      },
      {
        label: this.props.translate(
          "sensorsTranslation.instantFuelConsumption"
        ),
        value: "instantFuelConsumption",
      },
      {
        label: this.props.translate("sensorsTranslation.flowMeter"),
        value: "flowMeter",
      },
      {
        label: this.props.translate("sensorsTranslation.custom"),
        value: "custom",
      },
    ];

    const att_valueType = [
      {
        Type: "string",
      },
      { Type: "number" },
      { Type: "boolean" },
    ];

    return (
      <div
      //  style={{ minHeight: 300 }}
       >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="description"
              label={this.props.translate("sharedLabel")}
              type="text"
              value={ReactHtmlParser(this.props.form.description) || ""}
              onChange={(e) => this.handleChange("description", e)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              translate={this.props.translate}
              value={this.props.form.input || ""}
              suggestions={this.props.suggestions}
              handleChange={this.changeAttribute}
              fieldName="input"
              placeholder={this.props.translate("output")}
              label={this.props.translate("output")}
            />
          </Grid>
              <Grid item xs={12} style = {{display:'flex'}}>
                  <Checkbox
                    canAssign
                    canRemove
                    translate={this.props.translate}
                    checked={this.props.form&&this.props.form.applyInverse}
                    onChange={e => this.changeAttribute('applyInverse', e.target.checked)}
                    label={this.props.translate('applyInverse')}
                  />
                <div style={{paddingLeft:50}}>
                <Tooltip 
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={<div>
                    <p>Digital outputs ON and OFF states.</p>
                    <p>Reverse Unchecked:</p>
                    <p>0 – off-state output.</p>
                    <p>1 – on-state output.</p>
                    <p>Reverse Checked:</p>
                    <p>0 – on-state output.</p>
                    <p>1 – off-state output.</p></div>}>
                      <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
                  </Tooltip></div>
            </Grid>

          {/* <Grid item xs={6}>
            <TextField
              id="attribute"
              label={this.props.translate("positionTooltip.sensorsType")}
              type="text"
              value={this.props.form.attribute ? this.props.form.attribute : ""}
              onChange={(e) => this.handleChange("attribute", e)}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {disceteSensorsTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              id="expStart"
              label={this.props.translate("sharedAttributes")}
              type="text"
              value={this.props.form.expStart ? this.props.form.expStart : ""}
              onChange={(e) => this.handleChange("expStart", e)}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {this.props.suggestions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              id="expOpt"
              label={this.props.translate("operator")}
              type="text"
              value={this.props.form.expOpt ? this.props.form.expOpt : ""}
              onChange={(e) => this.handleChange("expOpt", e)}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {operators.map((option) => (
                <MenuItem key={option} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              id="expEnd"
              label={this.props.translate("sharedAttributes")}
              type="text"
              value={this.props.form.expEnd ? this.props.form.expEnd : ""}
              onChange={(e) => this.handleChange("expEnd", e)}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {this.props.suggestions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* {this.props.form.attribute || this.props.form.expStart ? (
            <Grid item xs={6}>
              {this.props.form.attribute ? this.props.form.attribute + ":" : ""}
              {this.props.form.expStart ? " " + this.props.form.expStart : ""}
              {this.props.form.expOpt ? " " + this.props.form.expOpt : ""}
              {this.props.form.expEnd ? " " + this.props.form.expEnd : ""} */}

              {/* <Tooltip
              classes={{
                popper: 'menu-popper',
                tooltip: 'menu-popper-tooltip'
              }}
              placement='bottom-start'
              title={
                <>{this.props.translate('InputStartingWith')}
                distance = speed*time.</>
              }
            >
              <div>
                <label className='custom-theme-label'>
                  {this.props.translate('sensorInput')}
                </label>
                <ReactTextareaAutocomplete
                  onChange={e => this.handleChange('expression', e)}
                  loadingComponent={() => <span>Loading</span>}
                  minChar={0}
                  placeholder={this.props.translate('sharedExpressionLabel')}
                  className='custom-theme-input theme-notchedOutline'
                  value={this.props.form.expression}
                  trigger={{
                    ':': {
                      dataProvider: token => {
                        return this.props.suggestions
                          .filter(r => r.includes(token))
                          .map(row => ({
                            name: row
                          }))
                      },
                      component: ({ entity: { name } }) => {
                        return <div>{`${name}`}</div>
                      },
                      output: (item, trigger) => item.name
                    }
                  }}
                />
              </div>
            </Tooltip> */}
            {/* </Grid>
          ) : null} */}
          {/* {this.props.form.attribute &&
          this.props.form.attribute === "driverUniqueId" ? (
            <Grid item xs={6}>
              <TextField
                id="resetDriver"
                label={this.props.translate("resetDriver")}
                type="text"
                value={
                  this.props.form.attributes &&
                  this.props.form.attributes.resetDriver
                    ? this.props.form.attributes.resetDriver
                    : "disabled"
                }
                onChange={(e) =>
                  this.changeAttribute("resetDriver", e.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
              >
                <MenuItem value={"disabled"}>
                  {this.props.translate("sharedDisabled")}
                </MenuItem>
                <MenuItem value={"parking"}>
                  {this.props.translate("parkingStart")}
                </MenuItem>
                <MenuItem value={"ignition"}>
                  {this.props.translate("notification.ignitionOff")}
                </MenuItem>
              </TextField>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <TextField
              id="type"
              select
              label={this.props.translate("sharedType")}
              value={this.props.form.type}
              onChange={(e) => this.handleChange("type", e)}
              margin="dense"
              fullWidth
            >
              {att_valueType.map((option) => (
                <MenuItem key={option.Type} value={option.Type}>
                  {this.props.translate(option.Type)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
            >
              <Checkbox
                canAssign
                canRemove
                translate={this.props.translate}
                checked={this.props.form.attributes.copyFromLast}
                onChange={(e) =>
                  this.changeAttribute("copyFromLast", e.target.checked)
                }
                label={this.props.translate("copyFromLast")}
              />
            </Tooltip>
          </Grid> */}
          {/* <Grid item xs={12}>
          <Grid container justify="center">

               <Button
                style={{
                  background:this.props.themecolors.backgroundColor,
                          color:"rgba(57, 58, 59, 1)",
                          border:`1px solid rgba(57, 58, 59, 1)`,
                          borderRadius:"8px",
                          width:"112px",
                          height:"32px",
                          fontWeight:"bold",
                          textTransform: "capitalize"
                }}
                // disabled={!this.props.isVisableDiscreteBtn}
                onClick={this.props.save}
              >
                {this.props.translate(this.props.addButton)}
              </Button>
              </Grid>
              </Grid> */}

        </Grid>
      </div>
    );
  }
}

export default Sensors;
