import React, { Component } from 'react'
import TextField from './../../../Components/common/SearchField'
import { TrailerShortList, ListRow } from './../../../Components/Trailer/shortList'
import { checkPrivileges } from '../../../Helpers'
import Loader from './../../Loader'
import Scrollbar from 'react-scrollbars-custom'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import RecourcesList from './RecourcesList'
import { FormControlLabel, IconButton } from '@material-ui/core'
import Switch from '../../../Components/common/Switch'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';


export default class TrailerSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isDeviceLimit: false,
      countCall: true
    }
  }

  toggleSearch = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  }
  render () {
    const trailers = this.props.itemPagination && this.props.itemPagination.items ? 
    this.props.itemPagination.items.map(row => {
        const attr = Object.keys(row.attributes)
        const others = {}
        attr.map(key => {
        others[key] = row.attributes[key]
        return null
        })
        return {
        name: row.name,
        uniqueId: row.uniqueId,
        ...others
        }
    }) : []

    const fields = []
    trailers.map(veh => {
        fields.push(...Object.keys(veh))
        return null
    })
    return (<div className="fms-sidebar sidebar-bg-layer">
          <ul className='filter-row clearfix'>
          {!this.state.searchBox ? <li className='section-head-filter'>
            <div className="filter-row">
              <IconButton onClick={this.toggleSearch}><SearchIcon /></IconButton>
              {this.props.resourceList && checkPrivileges('itemgroupCreate') ? <IconButton
                onClick={() => {
                this.props.addResource()
              }}><AddIcon /></IconButton> : checkPrivileges('trailerCreate') ? <IconButton
              onClick={() => {
                this.props.addTrailer()}}
              ><AddIcon /></IconButton> : null}
              <FormControlLabel
                style={{ marginLeft: 'auto', flex: 1 }}
                control={
                  <Switch
                    checked={this.props.resourceList}
                    onChange={() => this.props.showResources(!this.props.resourceList)}
                    name='isOpenEmailSection'
                  />
                }
                label={
                  this.props.translate('groups')
                }
                labelPlacement='start'
                classes={{ label: 'color-inherit' }}
              />
              <ExportImportMenu
                fileName = 'trailers'
                checkPrivilege = 'trailerCreate'
                data = {trailers}
                themecolors = {this.props.themecolors}
              />
              </div>
            </li> :
            <li className="section-head-filter" style={{position: 'relative'}}>
                <div className="filter-row" style={{flex: 1, marginTop: -2}}>
                  <div style={{flex: 1}}>
                  <TextField
                  label={this.props.translate('searchTrailers')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.props.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
                </div>
                <IconButton onClick={this.toggleSearch} className="search-close-icon"><CloseIcon /></IconButton>
              </div>
              </li>}
            </ul>
          {this.props.resourceList ? 
          <RecourcesList 
          ItemChild={ListRow}
          ItemChildProps={this.props}
          fromArray={(this.props.itemPagination && this.props.itemPagination.items) || []}
          {...this.props} /> :
          <div className='sidebar-devices-list' id="scrollableTrailers">
            <Scrollbar
              disableTracksWidthCompensation
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div
                      {...restProps}
                      ref={elementRef}
                      id='scrollableTrailers'
                    />
                  )
                }
              }}
            >

            {!this.props.isDriverLoad ? checkPrivileges('trailer') && this.props.itemPagination 
              && this.props.itemPagination.items && this.props.itemPagination.items.length ? (
                <TrailerShortList
                    {...this.state}
                    items={this.props.itemPagination.items}
                    allTrailerLocation={this.props.allTrailerLocation}
                    match={this.props.match}
                    editItem={this.props.editItem}
                    removedItem={this.props.removedItem}
                    selecteItem={this.props.selecteItem}
                    translate={this.props.translate}
                />
              ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold'}}>
              {this.props.translate('notFound')}
                </p> :
            <Loader/>}
            </Scrollbar>
          </div>}
        </div>
    )
  }
}
