import React, { Component } from "react";
import { connect } from "react-redux";
import { EditItem } from "./EditItem";
import CustomDialog from "./../common/Dialog";
import { checkPrivileges, errorHandler, selectAll } from "./../../Helpers";
import { withLocalize } from "react-localize-redux";
import { withSnackbar } from "notistack";
import Loader from "../../Layout/Loader";
import isEqual from "react-fast-compare";
// import { addDriver, removeDriver, updateDriver } from './../../Actions/Drivers'
import {
  addTrailer,
  removeTrailer,
  updateTrailer,
} from "./../../Actions/Trailer";
import {
  updateDeviceUserWiseSelectedList,
  RemoveDeviceUserWise,
  addDeviceUserWise,
  deviceUserWiseSelectedList,
} from "./../../Actions/Notifications";
import AddTrailer from "./addTrailer";
import "react-confirm-alert/src/react-confirm-alert.css";
import Notifications from "react-notification-system-redux";
import CommonTableSection from "./../../Pages/Units/Components/commonTableSection";
import instance from "../../axios";

const trailerDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data.name.trim(),
  description: "",
  uniqueId: data.uniqueId.trim(),
});

const formDefault = {
  name: "",
  uniqueId: "",
  attributes: {},
  trailerAddress: "",
};

class trailerModal extends Component {
  constructor() {
    super();
    this.state = {
      dm_isVisable: false,
      selectedTrailer: "",
      addOption: false,
      editOption: false,
      dr_attriubtes: "",
      isVisibleBtn: false,
      isAttChanged: false,
      trailerUniqueId: "",
      form: formDefault,
      itemAttributes: {},
      attributeChangesMessage: "",
      removedIdPropcess: "",
      imagesData: "",
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.activeOperation === "") {
      this.setState({
        editOption: false,
        addOption: false,
      });
    }
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(this.props.selecteditem);
      let attributes = this.props.selecteditem.attributes;
      let trackers = this.props.multiTrackers;
      formData = { ...formData, attributes, trackers };
      this.setState({
        selectedTrailer: formData,
        trailerUniqueId: this.props.selecteditem.id,
      });
    } else {
      if (this.props.activeOperation === "edit") {
        let formData = this.setFormData(this.props.selecteditem);
        let attributes = this.props.selecteditem.attributes;
        formData = { ...formData, attributes };
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedTrailer, formData)
        ) {
          this.setState(
            {
              trailerUniqueId: this.props.selecteditem.id,
              selectedTrailer: formData,
              itemAttributes: this.props.selecteditem.attributes,
              editOption: true,
              addOption: false,
              dm_isVisable: true,
              attributeChangesMessage: "",
              isVisibleBtn: false,
              isAttChanged: false,
              dr_attriubtes: this.props.selecteditem.attributes,
              form: { ...formData },
            },
            () => this.checkRequiredFields()
          );
        }
      }
      if (this.props.activeOperation === "add" && !this.state.addOption) {
        this.setState({
          trailerUniqueId: this.props.selecteditem.id,
          selectedTrailer: "",
          addOption: true,
          editOption: false,
          dm_isVisable: true,
          form: formDefault,
          isVisibleBtn: false,
          isAttChanged: false,
          attributeChangesMessage: "",
        });
      }
    }
    if (
      this.props.selectedAddress !== "" &&
      this.props.selectedAddress !== undefined
    ) {
      this.setState({
        form: {
          ...this.state.form,
          trailerAddress: this.props.selectedAddress,
        },
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.selecteditem.id !== this.state.trailerUniqueId) {
      if (nextprops.showItemDetail && nextprops.trackersApiResponce) {
        let formData = this.setFormData(nextprops.selecteditem);
        let attributes = nextprops.selecteditem.attributes;
        let trackers = nextprops.multiTrackers;
        formData = { ...formData, attributes, trackers };
        this.setState({
          selectedTrailer: formData,
          trailerUniqueId: nextprops.selecteditem.id,
        });
      } else {
        if (nextprops.activeOperation === "edit") {
          let formData = this.setFormData(nextprops.selecteditem);
          let attributes = nextprops.selecteditem.attributes;
          formData = { ...formData, attributes };
          if (
            !this.state.editOption ||
            !isEqual(this.state.selectedTrailer, formData)
          ) {
            this.setState(
              {
                trailerUniqueId: nextprops.selecteditem.id,
                selectedTrailer: formData,
                editOption: true,
                addOption: false,
                dm_isVisable: true,
                attributeChangesMessage: "",
                isVisibleBtn: false,
                isAttChanged: false,
                itemAttributes: nextprops.selecteditem.attributes,
                dr_attriubtes: nextprops.selecteditem.attributes,
                form: { ...formData },
              },
              () => this.checkRequiredFields()
            );
          }
        }
        if (nextprops.activeOperation === "add" && !this.state.addOption) {
          this.setState(
            {
              trailerUniqueId: nextprops.selecteditem.id,
              selectedTrailer: "",
              addOption: true,
              editOption: false,
              dm_isVisable: true,
              form: formDefault,
              isVisibleBtn: false,
              isAttChanged: false,
              attributeChangesMessage: "",
            },
            () => this.checkRequiredFields()
          );
          if (
            nextprops.selectedAddress !== "" &&
            nextprops.selectedAddress !== undefined
          ) {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  trailerAddress: nextprops.selectedAddress,
                },
              },
              () => this.checkRequiredFields()
            );
          }
        }
      }
    }
    if (
      nextprops.selectedAddress !== "" &&
      nextprops.selectedAddress !== undefined
    ) {
      this.setState(
        {
          form: {
            ...this.state.form,
            trailerAddress: nextprops.selectedAddress,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  }
  setFormData = (data) => {
    let formData = {
      name: data.name,
      uniqueId: data.uniqueId,
      // tracker: data.tracker,
      // trailerAddress: data.attributes.trailerAddress || '',
      // email: data.attributes.email || '',
      // licenseClass: data.attributes.licenseClass || '',
      // licenseNumb: data.attributes.licenseNumb || '',
      // licenseExpDate: data.attributes.licenseExpDate || '',
      // phone: data.attributes.phone || '',
      department: data.attributes.department || "",
      trailerImg: data.attributes.trailerImg || "",
    };
    return formData;
  };
  submitDataPepration = () => {
    let { itemAttributes, form } = this.state;
    if (form.trailerAddress) {
      let trailerAddress = form.trailerAddress;
      itemAttributes = {
        ...itemAttributes,
        trailerAddress: trailerAddress
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
      };
    }
    if (form.tag_1 !== undefined) {
      let tag_1 = form.tag_1;
      itemAttributes = { ...itemAttributes, tag_1 };
    }
    if (form.tag_2 !== undefined) {
      let tag_2 = form.tag_2;
      itemAttributes = { ...itemAttributes, tag_2 };
    }
    if (form.tag_3 !== undefined) {
      let tag_3 = form.tag_3;
      itemAttributes = { ...itemAttributes, tag_3 };
    }
    if (form.tag_4 !== undefined) {
      let tag_4 = form.tag_4;
      itemAttributes = { ...itemAttributes, tag_4 };
    }
    if (form.tag_5 !== undefined) {
      let tag_5 = form.tag_5;
      itemAttributes = { ...itemAttributes, tag_5 };
    }

    if (form.email) {
      let email = form.email;
      itemAttributes = { ...itemAttributes, email };
    }
    if (form.licenseClass) {
      let licenseClass = form.licenseClass;
      itemAttributes = { ...itemAttributes, licenseClass };
    }
    if (form.licenseNumb) {
      let licenseNumb = form.licenseNumb;
      itemAttributes = { ...itemAttributes, licenseNumb };
    }
    if (form.licenseExpDate) {
      let licenseExpDate = form.licenseExpDate;
      itemAttributes = { ...itemAttributes, licenseExpDate };
    }
    if (form.phone) {
      let phone = form.phone;
      itemAttributes = { ...itemAttributes, phone };
    }
    if (form.department) {
      let department = form.department;
      itemAttributes = { ...itemAttributes, department };
    }
    if (this.props.trailerLat) {
      let trailerLat = this.props.trailerLat;
      itemAttributes = { ...itemAttributes, trailerLat };
    }
    if (this.props.trailerLon) {
      let trailerLon = this.props.trailerLon;
      itemAttributes = { ...itemAttributes, trailerLon };
    }
    if (form.trailerImg) {
      let trailerImg = form.trailerImg;
      itemAttributes = { ...itemAttributes, trailerImg };
    }
    return itemAttributes;
  };
  onCloseModal = () => {
    this.setState({
      enableImagePopup: false,
    });
  };

  imageDataPrepare = () => {
    let { imagesData } = this.state;
    if (imagesData) {
      instance({
        url: "/api/uploads",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...imagesData,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((publicUrl) => {
          this.setState(
            {
              form: {
                ...this.state.form,
                trailerImg: publicUrl.publicUrl,
              },
            },
            () => {
              if (this.state.editOption) {
                this.ud_submit();
              } else {
                this.ad_submit();
              }
            }
          );
          //   })
          // } else {
          //   //this.props.enqueueSnackbar(this.props.translate('somthingWentWrongMessage'));
          //   this.props.dispatch(
          //     Notifications.error({
          //       message: this.props.translate('somthingWentWrongMessage'),
          //       autoDismiss: 10
          //     })
          //   )
          //     throw response
          //   }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // this.props.dispatch(
          //   Notifications.error({
          //     message: this.props.translate('somthingWentWrongMessage'),
          //     autoDismiss: 10
          //   })
          // )
        });
    } else {
      if (this.state.editOption) {
        this.ud_submit();
      } else {
        this.ad_submit();
      }
    }
  };

  ad_submit = () => {
    let { form } = this.state;
    let preperData = {
      name: form.name,
      uniqueId: form.uniqueId,
      expirationTime: form.expirationTime ? form.expirationTime : "",
      type: form.type ? form.type : "",
    };
    this.setState({
      isVisibleBtn: false,
    });
    if (
      form.name &&
      form.name.trim() &&
      form.uniqueId &&
      form.uniqueId.trim()
    ) {
      let attriubtes = this.submitDataPepration();
      const obj = trailerDataFormat(preperData, attriubtes);

      instance({
        url: "/api/trailers",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((trailer) => {
          // if(trailer.status === "success"){
          this.props.dispatch(addTrailer(trailer));
          this.props.onCloseModal();
          this.props.fetchMoreItems();
          this.setState({
            dm_isVisable: !this.state.dm_isVisable,
            addOption: false,
            dr_attriubtes: "",
            selectedTrailer: "",
            form: formDefault,
            attributeChangesMessage: "",
            imagesData: "",
          });
          //this.props.enqueueSnackbar(this.props.translate('driverIsCreated'));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("trailerIsCreated"),
              autoDismiss: 10,
            })
          );
          // } else {
          //     if (trailer.message.includes('Duplicate entry')) {
          //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          //       this.props.dispatch(
          //         Notifications.error({
          //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
          //           autoDismiss: 10
          //         })
          //       )
          //     } else {
          //       this.props.dispatch(
          //         Notifications.error({
          //           message: this.props.translate('somthingWentWrongMessage'),
          //           autoDismiss: 10
          //         })
          //       )
          //     }
          // }

          //   })
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          // if (err.includes('Duplicate entry')) {
          //   //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          //   this.props.dispatch(
          //     Notifications.error({
          //       message: this.props.translate('uniqueIdIsAllreadyInUsed'),
          //       autoDismiss: 10
          //     })
          //   )
          // } else {
          //   this.props.dispatch(
          //     Notifications.error({
          //       message: this.props.translate('somthingWentWrongMessage'),
          //       autoDismiss: 10
          //     })
          //   )
          // }
          //   })
          // }
        });
    } else {
      //this.props.enqueueSnackbar(this.props.translate('emptyField'));
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("emptyField"),
          autoDismiss: 10,
        })
      );
    }
  };

  ud_submit = () => {
    const { form } = this.state;
    let preperData = {
      name: form.name,
      uniqueId: form.uniqueId,
      expirationTime: form.expirationTime ? form.expirationTime : "",
      type: form.type ? form.type : "",
    };
    this.setState({
      isVisibleBtn: false,
    });
    let attriubtes = this.submitDataPepration();
    const obj = trailerDataFormat(
      preperData,
      attriubtes,
      this.state.trailerUniqueId
    );
    delete obj.attributes.lat;
    delete obj.attributes.lng;
    delete obj.attributes.trailerLng;

    if (!obj.attributes.tag_1) delete obj.attributes.tag_1;
    if (!obj.attributes.tag_2) delete obj.attributes.tag_2;
    if (!obj.attributes.tag_3) delete obj.attributes.tag_3;
    if (!obj.attributes.tag_4) delete obj.attributes.tag_4;
    if (!obj.attributes.tag_5) delete obj.attributes.tag_5;

    instance({
      url: `/api/trailers/${this.state.trailerUniqueId}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((trailer) => {
        // if(trailer.status === 'success'){
        this.props.dispatch(updateTrailer(trailer));
        this.props.onCloseModal();
        this.props.fetchMoreItems();
        //this.props.enqueueSnackbar(this.props.translate('driverIsUpdated'));
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("trailerIsUpdated"),
            autoDismiss: 10,
          })
        );
        this.setState({
          itemAttributes: trailer.attributes,
          dr_attriubtes: trailer.attributes,
          dm_isVisable: !this.state.dm_isVisable,
          selectedTrailer: trailer,
          attributeChangesMessage: "",
          isVisibleBtn: false,
          isAttChanged: false,
          imagesData: "",
        });
        // } else {
        //     if (trailer.message.includes('Duplicate entry')) {
        //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
        //           autoDismiss: 10
        //         })
        //       )
        //     } else {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 10
        //         })
        //       )
        //     }
        // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //   if (err.includes('Duplicate entry')) {
        //     //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        //     this.props.dispatch(
        //       Notifications.error({
        //         message: this.props.translate('uniqueIdIsAllreadyInUsed'),
        //         autoDismiss: 10
        //       })
        //     )
        //   } else {
        //     this.props.dispatch(
        //       Notifications.error({
        //         message: this.props.translate('somthingWentWrongMessage'),
        //         autoDismiss: 10
        //       })
        //     )
        //   }
        //   })
        // }
      });
  };

  onCloseSubModal = () => {
    this.setState({
      dm_isVisable: false,
      addOption: false,
      editOption: false,
    });
    this.props.onCloseModal();
  };

  changeAttribute = (name, value, selectedOption, updateAtt) => {
    if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
      this.setState(
        {
          dr_attriubtes: { ...updateAtt },
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      );
    } else {
      this.setState(
        {
          dr_attriubtes: {
            ...this.state.selectedTrailer.attributes,
            [name]: value,
          },
        },
        () => this.checkForAttrubutesChanged()
      );
    }
  };

  checkForAttrubutesChanged = (selectedOption) => {
    let changed = false;
    if (this.state.selectedTrailer) {
      changed = !isEqual(
        this.state.dr_attriubtes,
        this.state.selectedTrailer.attributes
      );
    } else {
      changed = !this.isEmpty(this.state.dr_attriubtes);
    }
    let { name, uniqueId } = this.state.form;
    let selectedTrailer = {};
    if ((name && uniqueId) || (name && uniqueId && changed)) {
      selectedTrailer = this.state.dr_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState({
          isVisibleBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedTrailer },
        });
      } else {
        this.setState({
          isVisibleBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedTrailer },
        });
      }
    } else {
      selectedTrailer = this.state.dr_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState(
          {
            isVisibleBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedTrailer },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            isVisibleBtn: false,
            isAttChanged: false,
            itemAttributes: {
              ...this.state.itemAttributes,
              ...selectedTrailer,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: "Change in attributes is pending",
      });
    } else {
      this.setState(
        {
          attributeChangesMessage: "",
        },
        () => this.checkRequiredFields()
      );
    }
  };
  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    if (name === "licenseExpDate") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.value,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields = () => {
    let { name, uniqueId } = this.state.form;
    let value = !isEqual(this.state.form, this.state.selectedTrailer);
    if (name && uniqueId && value) {
      this.setState({
        isVisibleBtn: true,
      });
    } else {
      this.setState({
        isVisibleBtn: this.state.isAttChanged,
      });
    }
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          licenseExpDate: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };

  uploadImage = (event) => {
    let files = event.target.files;
    let imgData = "";
    let reader = new FileReader();
    if (files && files[0]) {
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        imgData = e.target.result;
        if (imgData.split(":")[1].split("/")[0] === "image") {
          let obj = {
            name: `${this.state.form.uniqueId + new Date().getTime()}`,
            contentType: `${files[0].type}`,
            oldUrl: `${this.state.form.trailerImg}`,
            data: `${imgData.split("base64")[1].substring(1)}`,
          };
          this.setState(
            {
              imagesData: obj,
              form: {
                ...this.state.form,
                trailerImg: imgData,
              },
            },
            () => {
              let { name, uniqueId } = this.state.form;
              if (name && uniqueId) {
                this.setState({
                  isVisibleBtn: true,
                });
              } else {
                this.setState({
                  isVisibleBtn: this.state.isAttChanged,
                });
              }
            }
          );
        } else {
          //this.props.enqueueSnackbar(this.props.translate('onlyAcceptImage'));
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("onlyAcceptImage"),
              autoDismiss: 10,
            })
          );
        }
      };
    } else {
      this.setState(
        {
          imagesData: "",
          form: {
            ...this.state.form,
            trailerImg: this.props.selecteditem.attributes
              ? this.props.selecteditem.attributes.trailerImg
              : "",
          },
        },
        () => {
          this.setState({
            isVisibleBtn: this.state.isAttChanged,
          });
        }
      );
    }
  };
  imagePopup = () => {
    this.setState({
      enableImagePopup: true,
    });
  };

  unit_Selection = (checked, item) => {
    let method = "DELETE";
    let value = checked;
    let allData = [...this.props.selecteddevices];
    let obj = {
      deviceId: item.id,
      trailerUniqueId: this.props.selecteditem.id,
    };
    if (value) {
      method = "POST";
    }

    instance({
      url: "/api/permissions",
      method: `${method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateDeviceUserWiseSelectedList(item));
          this.props.dispatch(RemoveDeviceUserWise(item));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("trackerIsUnAssignedSuccessfully"),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateDeviceUserWiseSelectedList(item));
          this.props.dispatch(addDeviceUserWise(item));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("trackerIsAssignedSuccessfully"),
              autoDismiss: 10,
            })
          );
        }
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  render() {
    const { selecteddevices } = this.props;
    return (
      <div style={{ height: "100%", position: "relative", zIndex: 9999 }}>
        {this.state.dm_isVisable && this.state.addOption && (
          <AddTrailer
            onClose={this.onCloseSubModal}
            selectedTrailers={this.state.selectedTrailer}
            formSubmit={this.imageDataPrepare}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            buttonText="sharedCreate"
            translate={this.props.translate}
            isVisibleBtn={this.state.isVisibleBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            isHaveAccess="trailerCreate"
            uploadImage={this.uploadImage}
            imagePopup={this.imagePopup}
          />
        )}
        {this.state.selectedTrailer && this.state.editOption && (
          <AddTrailer
            onClose={this.onCloseSubModal}
            selectedTrailers={this.state.selectedTrailer}
            formSubmit={this.imageDataPrepare}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            buttonText="update"
            translate={this.props.translate}
            isVisibleBtn={this.state.isVisibleBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            isHaveAccess="trailerUpdate"
            uploadImage={this.uploadImage}
            imagePopup={this.imagePopup}
          />
        )}
        {this.state.selectedTrailer &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            devices={this.props.devices}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedTrailer}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
        {this.state.enableImagePopup && this.state.form.trailerImg && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            bodyPadding={10}
            cancelText={this.props.translate("sharedCancel")}
            noFullScreen
          >
            <div style={{ minHeight: 200, margin: 0, textAlign: "center" }}>
              <img
                style={{ width: 500, height: 400 }}
                alt="trailerImage"
                src={this.state.form.trailerImg}
              />
            </div>
          </CustomDialog>
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  devices2: state.devices2,
  drivers: state.drivers,
  trailers: state.trailers,
  devices: state.devices.data,
  themecolors: state.themeColors,
  selecteddevices: state.selecteddevices,
});

const mapStateToProps = connect(mapState);
export const TrailerModal = mapStateToProps(
  withSnackbar(withLocalize(trailerModal))
);
