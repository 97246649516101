import React from "react";
import TextField from "../../../Components/common/TextField";
import MenuItem from "../../../Components/common/MenuItem";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "../../../Components/common/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "../../../Components/common/Autocomplete";
import { Collapse, FormControlLabel, Paper } from "@material-ui/core";
import Switch from "../../../Components/common/Switch";
//import CloseIcon from '@material-ui/icons/Close';
//import IconButton from '@material-ui/core/IconButton';
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  ChartLabel,
  XAxis,
  YAxis,
  VerticalGridLines,
} from "react-vis";
import ReactHtmlParser from "react-html-parser";
import Notifications from "react-notification-system-redux";
import "react-vis/dist/style.css";
import Checkbox from "../../../Components/common/CheckboxPermissions";
import instance from "../../../axios";
import { errorHandler } from "../../../Helpers";
import Button from "../../../Components/common/Button";
class Sensors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      drawSensorFields: "",
      drawSensorUnitsFields: "",
      scalesHTML: [],
      scale: [],
      suggestions: [],
      error: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChart = this.handleChart.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
    this.addMore = this.addMore.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      suggestions: this.filterSensorsList(this.props.suggestions),
    });
    if (this.props.operation === "edit") {
      this.setState({
        scale: this.props.scale,
      });
    } else {
      this.addMore(true);
    }
  }

  addMore(fritCall) {
    let scale = [...this.state.scale];
    if (scale.length) {
      let last = { ...scale[scale.length - 1], e: true };
      scale.push(last);
    } else {
      scale.push({ x: 0, y: 0, e: false });
    }
    this.setState({ scale });
    if (fritCall === true) {
      this.props.checkVisablity(this.state.error);
    } else {
      this.props.checkVisablity(true);
    }
  }

  handleChart(name, event, index) {
    this.handleChart2(name, parseFloat(event.target.value), index);
  }

  handleChart2 = (name, value, index) => {
    let scale = [...this.state.scale];
    let keys = [];
    scale[index][name] = value;
    scale.map((i) => {
      keys.push(i.x);
      return null;
    });
    let sorted_arr = keys.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    let a = [];
    keys.map((item, index) => {
      results.map((w) => {
        if (item === w) {
          a.push(index);
        }
        return null;
      });
      return null;
    });
    scale.map((sal) => {
      sal.e = false;
      return null;
    });
    a.map((val) => {
      scale[val] = { ...scale[val], e: true };
      return null;
    });
    this.setState({ scale, error: a.length > 0 ? true : false }, () =>
      this.props.checkVisablity(this.state.error, this.state.scale)
    );
  };
  handleChange(name, event) {
    this.props.handleChange(name, event);
  }
  changeAttribute(name, value) {
    this.props.changeAttribute(name, value);
  }

  computedAttributes_Selection(deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId,
    };

    instance({
      url: `/api/permissions/`,
      method: "POST",
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        //this.props.dispatch(addComputedAttributeDeviceWise(item));
        //this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("attributeAssignedSuccessfully"),
            autoDismiss: 10,
          })
        );
        // } else {
        //   throw response
        // }
      })
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       alert(err)
      //     })
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  deleteRow(row, index) {
    let scale = [...this.state.scale];
    scale.splice(index, 1);

    this.setState({ scale }, () => {
      this.state.scale.map((row, index) => {
        this.handleChart2("x", row["x"], index);
        this.handleChart2("y", row["y"], index);
        return null;
      });
    });
  }

  filterSensorsList = (arr) => {
    const arr2 = ["input", "output", "ignition", "status", "motion", "parking"];
    let res = [];
    arr.map((r) => {
      if (!arr2.includes(r)) {
        res.push(r);
      }
      return null;
    });
    return res;
  };

  render() {
    const disceteSensorsTypes = [
      { label: this.props.translate("sensorsTranslation.fuel"), value: "fuel" },
      {
        label: this.props.translate("sensorsTranslation.temperature"),
        value: "temperature",
      },
      { label: this.props.translate("sensorsTranslation.rpm"), value: "rpm" },
      {
        label: this.props.translate("sensorsTranslation.boardPower"),
        value: "boardPower",
      },
      {
        label: this.props.translate("sensorsTranslation.mileage"),
        value: "mileage",
      },
      {
        label: this.props.translate("sensorsTranslation.speed"),
        value: "speed",
      },
      {
        label: this.props.translate("sensorsTranslation.counter"),
        value: "counter",
      },
      {
        label: this.props.translate("sensorsTranslation.humidity"),
        value: "humidity",
      },
      {
        label: this.props.translate("sensorsTranslation.custom"),
        value: "custom",
      },
      {
        label: this.props.translate("sensorsTranslation.averagFuelConsumption"),
        value: "averagFuelConsumption",
      },
      {
        label: this.props.translate(
          "sensorsTranslation.instantFuelConsumption"
        ),
        value: "instantFuelConsumption",
      },
      {
        label: this.props.translate("sensorsTranslation.flowMeter"),
        value: "flowMeter",
      },
      {
        label: this.props.translate("sensorsTranslation.weight"),
        value: "weight",
      },
    ];
    const paperStyle = {
      background: "none",
      marginBottom: 5,
      paddingTop: 0,
      paddingBottom: 0,
    };

    let drawSensorFields = "";
    let customUnit = this.props.form.unit === "custom" && (
      <Grid item md={6} xs={12}>
        <TextField
          id="customUnit"
          label={this.props.translate("custom")}
          type="text"
          value={this.props.form.customUnit || ""}
          onChange={(e) => this.changeAttribute("customUnit", e.target.value)}
          variant="outlined"
          margin="dense"
          fullWidth
        />
      </Grid>
    );

    if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "fuel"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"L"}>L</MenuItem>
              <MenuItem value={"gal(UK)"}>gal(UK)</MenuItem>
              <MenuItem value={"gal(US)"}>gal(US)</MenuItem>
              <MenuItem value={"percentage"}>%</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
          <Grid item md={6} xs={12}>
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={this.props.translate("customUnitTooltip1")}
            >
              <TextField
                id="accuracy"
                label={this.props.translate("sensorsTranslation.accuracy")}
                type="number"
                value={this.props.form.accuracy || ""}
                onChange={(e) =>
                  this.changeAttribute("accuracy", e.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={this.props.translate("customUnitTooltip2")}
            >
              <label>
                {this.props.translate("customUnitTooltip2Supporting")}
              </label>
            </Tooltip>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="drainByTime"
              label={this.props.translate("sensorsTranslation.bytime")}
              placeholder="e.g. 60 unit/h"
              type="number"
              value={this.props.form.drainByTime || ""}
              onChange={(e) =>
                this.changeAttribute("drainByTime", e.target.value)
              }
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="drainByDistance"
              label={this.props.translate("sensorsTranslation.bydistance")}
              type="number"
              value={this.props.form.drainByDistance || ""}
              onChange={(e) =>
                this.changeAttribute("drainByDistance", e.target.value)
              }
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        </React.Fragment>
      );
    } else if (
      (this.props.form.attributes &&
        this.props.form.attributes.sensorType === "counter") ||
      (this.props.form.attributes &&
        this.props.form.attributes.sensorType === "humidity")
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "temperature"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"C"}>℃</MenuItem>
              <MenuItem value={"F"}>℉</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "rpm"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value={"rpm"}>RPM </MenuItem>
              <MenuItem value="custom">Custom Unit</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      (this.props.form.attributes &&
        this.props.form.attributes.sensorType === "averagFuelConsumption") ||
      (this.props.form.attributes &&
        this.props.form.attributes.sensorType === "instantFuelConsumption") ||
      (this.props.form.attributes &&
        this.props.form.attributes.sensorType === "mileage")
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"L/100km"}>L/100km</MenuItem>
              <MenuItem value={"MPG(UK)"}>MPG(UK)</MenuItem>
              <MenuItem value={"MPG(US)"}>MPG(US)</MenuItem>
              <MenuItem value={"km/1L"}>km/1L</MenuItem>
              <MenuItem value={"G/100mi(UK)"}>G/100mi(UK)</MenuItem>
              <MenuItem value={"G/100mi(US)"}>G/100mi(US)</MenuItem>
              <MenuItem value={"km/G(US)"}>km/G(US)</MenuItem>
              <MenuItem value={"G/100km(US)"}>G/100km(US)</MenuItem>
              <MenuItem value={"l/h"}>l/h</MenuItem>
              <MenuItem value={"gal/h"}>gal/h</MenuItem>
              <MenuItem value={"gal/h(US)"}>gal/h(US)</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "boardPower"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value="V">V</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "speed"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"km/h"}>km/h</MenuItem>
              <MenuItem value={"mph"}>mph</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "flowMeter"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {" "}
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"L"}>L</MenuItem>
              <MenuItem value={"gal(UK)"}>gal(UK)</MenuItem>
              <MenuItem value={"gal(US)"}>gal(US)</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "flowMeter"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {" "}
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"L"}>L</MenuItem>
              <MenuItem value={"gal(UK)"}>gal(UK)</MenuItem>
              <MenuItem value={"gal(US)"}>gal(US)</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "weight"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value={"Kg"}>Kg</MenuItem>
              <MenuItem value={"Ton"}>Ton</MenuItem>
              <MenuItem value={"gram"}>gram</MenuItem>
              <MenuItem value={"ounce"}>ounce</MenuItem>
              <MenuItem value={"pound"}>pound</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    } else if (
      this.props.form.attributes &&
      this.props.form.attributes.sensorType === "custom"
    ) {
      drawSensorFields = (
        <React.Fragment>
          <Grid item md={6} xs={12}>
            <TextField
              id="unit"
              label={this.props.translate("sensorUnits")}
              type="text"
              value={this.props.form.unit || ""}
              onChange={(e) => this.changeAttribute("unit", e.target.value)}
              variant="outlined"
              margin="dense"
              select
              fullWidth
            >
              <MenuItem value="custom">
                {this.props.translate("sensorCustomUnit")}
              </MenuItem>
              <MenuItem value={"L"}>L</MenuItem>
              <MenuItem value={"gal(UK)"}>gal(UK)</MenuItem>
              <MenuItem value={"gal(US)"}>gal(US)</MenuItem>
              <MenuItem value={"C"}>℃</MenuItem>
              <MenuItem value={"F"}>℉</MenuItem>
              <MenuItem value={"L/100km"}>L/100km</MenuItem>
              <MenuItem value={"MPG(UK)"}>MPG(UK)</MenuItem>
              <MenuItem value={"MPG(US)"}>MPG(US)</MenuItem>
              <MenuItem value={"km/1L"}>km/1L</MenuItem>
              <MenuItem value={"G/100mi(UK)"}>G/100mi(UK)</MenuItem>
              <MenuItem value={"G/100mi(US)"}>G/100mi(US)</MenuItem>
              <MenuItem value={"km/G(US)"}>km/G(US)</MenuItem>
              <MenuItem value={"G/100km(US)"}>G/100km(US)</MenuItem>
              <MenuItem value={"km/h"}>km/h</MenuItem>
              <MenuItem value={"mph"}>mph</MenuItem>
              <MenuItem value={"percentage"}>%</MenuItem>
              <MenuItem value={"km"}>km</MenuItem>
              <MenuItem value={"m"}>m</MenuItem>
              <MenuItem value={"yd"}>yd</MenuItem>
              <MenuItem value={"ft"}>ft</MenuItem>
              <MenuItem value={"l/h"}>l/h</MenuItem>
              <MenuItem value={"gal/h"}>gal/h</MenuItem>
              <MenuItem value={"gal/h(US)"}>gal/h(US)</MenuItem>
              <MenuItem value={"V"}>V</MenuItem>
              <MenuItem value={"w"}>w</MenuItem>
              <MenuItem value={"d"}>d</MenuItem>
              <MenuItem value={"min"}>min</MenuItem>
              <MenuItem value={"s"}>s</MenuItem>
              <MenuItem value={"Kg"}>Kg</MenuItem>
              <MenuItem value={"Ton"}>Ton</MenuItem>
              <MenuItem value={"gram"}>gram</MenuItem>
              <MenuItem value={"ounce"}>ounce</MenuItem>
              <MenuItem value={"pound"}>pound</MenuItem>
            </TextField>
          </Grid>
          {customUnit}
        </React.Fragment>
      );
    }

    let emptyLink = null;

    return (
      <Grid container spacing={1}>
        {/* <Grid item sm={12} xs={12}> */}
          {/* <p style={{ marginTop: 0, marginBottom: 10, fontWeight: 700 }}>
            {this.props.translate("sharedRequired")}
          </p> */}
            <Grid item md={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextField
                id="description"
                label={this.props.translate("sensorLabel")}
                type="text"
                value={ReactHtmlParser(this.props.form.description) || ""}
                onChange={(e) => this.handleChange("description", e)}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          <Grid item md={6} xs={12}>
              <Autocomplete
                translate={this.props.translate}
                value={this.props.form.attributes.input || ""}
                suggestions={this.state.suggestions}
                handleChange={this.changeAttribute}
                fieldName="input"
                label={this.props.translate("sensorInput")}
              />
            </Grid>
{/*            
             <Grid item md={6} xs={12}>
              <TextField
                id="sensorType"
                label={this.props.translate("sensorType")}
                type="text"
                value={
                  this.props.form.sensorType ? this.props.form.sensorType : ""
                }
                onChange={(e) => this.props.handleChange("sensorType", e)}
                variant="outlined"
                margin="dense"
                fullWidth
                select
              >
                {disceteSensorsTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            {/* <Grid item md={6} xs={12}>
              <TextField
                id="index"
                label={this.props.translate("index")}
                type="number"
                value={this.props.form.index ? this.props.form.index : ""}
                onChange={(e) => this.changeAttribute("index", e.target.value)}
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disableTypography
                      position="end"
                      style={{
                        color: this.props.themecolors.textColor,
                        fontSize: 10,
                      }}
                    >
                      {this.props.form.sensorType
                        ? this.props.form.sensorType +
                          "" +
                          (this.props.form.index || "")
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
           {drawSensorFields}
              <Grid item xs={12}>
              <p style={{ marginTop: 0, marginBottom: 10, fontWeight: 700 }}>
                {this.props.translate("ignoreValues")} (
                {this.props.translate("optional")})
              </p>
              <Grid container spacing={2}>
               <Paper
                  className={
                    " " +
                    (this.props.form.attributes.allowMinMax === true
                      ? "opened "
                      : "")
                  }
                  style={paperStyle}
                  elevation={0}
                >
                  <Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div style={{ display: "flex", marginLeft: 0 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                this.props.form.attributes.allowMinMax === true
                              }
                              onChange={(e) =>
                                this.changeAttribute(
                                  "allowMinMax",
                                  this.props.form.attributes.allowMinMax
                                )
                              }
                              name="allowMinMax"
                            />
                          }
                          label={
                            this.props.form.attributes.allowMinMax === true
                              ? this.props.translate("enable")
                              : this.props.translate("disable")
                          }
                          labelPlacement="start"
                          classes={{  color:this.props.themecolors.textColor }}
                          style={{ color:this.props.themecolors.textColor }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse
                        in={this.props.form.attributes.allowMinMax === true}
                      >
                        <Grid container spacing={2} style={{ marginLeft: 0 }}>
                          <Grid item md={6} xs={12}>
                            <Tooltip
                              classes={{
                                popper: "menu-popper",
                                tooltip: "menu-popper-tooltip",
                              }}
                              title={this.props.translate("customUnitTooltip3")}
                            >
                              <TextField
                                type="number"
                                label={this.props.translate("lessThan")}
                                value={this.props.form.minThreshold || 0}
                                onChange={(e) =>
                                  this.changeAttribute(
                                    "minThreshold",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Tooltip
                              classes={{
                                popper: "menu-popper",
                                tooltip: "menu-popper-tooltip",
                              }}
                              title={this.props.translate("customUnitTooltip3")}
                            >
                              <TextField
                                type="number"
                                label={this.props.translate("moreThan")}
                                value={this.props.form.maxThreshold || 0}
                                onChange={(e) =>
                                  this.changeAttribute(
                                    "maxThreshold",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item md={6} xs={12}>
                  <Tooltip
                    classes={{
                      popper: "menu-popper",
                      tooltip: "menu-popper-tooltip",
                    }}
                    title={this.props.translate("customUnitTooltip4")}
                  >
                    <TextField
                      type="number"
                      label={this.props.translate("multiplier")}
                      value={this.props.form.multiplier || 0}
                      onChange={(e) =>
                        this.changeAttribute("multiplier", e.target.value)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Tooltip>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Tooltip
                    classes={{
                      popper: "menu-popper",
                      tooltip: "menu-popper-tooltip",
                    }}
                    title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
                  >
                    <div style={{ marginTop: 8 }}>
                      <Checkbox
                        canAssign
                        canRemove
                        translate={this.props.translate}
                        checked={this.props.form.attributes.copyFromLast}
                        onChange={(e) =>
                          this.changeAttribute("copyFromLast", e.target.checked)
                        }
                        canA
                        label={this.props.translate("copyFromLast")}
                      />
                      
                    </div>
                  </Tooltip>
                {/* </Grid> */}
              {/* </Grid> */}
            </Grid>

            <Grid item xs={12}>
              <FlexibleWidthXYPlot height={300}>
                <HorizontalGridLines />
                <VerticalGridLines />
                <ChartLabel
                  text={this.props.translate("sensorValue")}
                  className="alt-x-label"
                  includeMargin={false}
                  xPercent={0.45}
                  yPercent={1.01}
                />

                <ChartLabel
                  text={this.props.translate("quantity")}
                  className="alt-y-label"
                  includeMargin={false}
                  xPercent={0.025}
                  yPercent={0.45}
                  style={{
                    transform: "rotate(-90)",
                    textAnchor: "end",
                  }}
                />
                <LineSeries data={this.state.scale} />
                <XAxis />
                <YAxis />
              </FlexibleWidthXYPlot>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        </Grid> 
         {/* seperate portion */}
         <Grid item md={6} xs={12}>
         <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <div
            style={{
              background: this.props.themecolors.themeLightColor + "40",
              padding: 16,
              maxHeight: 575,
              overflow: "auto",
              boxSizing: "border-box",
            }}
          >
            <Grid container spacing={0}>
              <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                {this.props.translate("sensorValue")}
              </Grid>
              <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                {this.props.translate("quantity")}
                <a
                  href={emptyLink}
                  onClick={this.addMore}
                  className={"pull-right"}
                  style={{ color: "inherit" }}
                >
                  {this.props.translate("addMore")}
                </a>
              </Grid>
              {this.state.scale.map((row, index) => (
                <Grid
                  key={index}
                  container
                  spacing={0}
                  className="sensors-rows"
                >
                  <Grid item xs={this.state.scale.length > 1 ? 11 : 12}>
                    <Grid container spacing={0}>
                      <Grid item md={6} xs={12} style={{ padding: "0 8px" }}>
                        <TextField
                          type="number"
                          error={row.e}
                          value={row.x || ''}
                          onChange={(e) => this.handleChart("x", e, index)}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12} style={{ padding: "0 8px" }}>
                        <TextField
                          type="number"
                          value={row.y || ''}
                          onChange={(e) => this.handleChart("y", e, index)}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.scale.length > 1 ? (
                    <Grid
                      item
                      xs={1}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <DeleteIcon
                        onClick={(e) => this.deleteRow(row, index)}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid> 
        </Grid> 
        </Grid> 
      
      </Grid>
    );
  }
}
export default Sensors;
