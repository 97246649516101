import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchNotificationCount,
  getNotificationType,
} from "../../Actions/Notifications";
import isEqual from "react-fast-compare";
import { withLocalize } from "react-localize-redux";
import { UserEventDetails } from "./../../Components/Events/userEvents";
import Layout from "./../../Layout";
import MarkerMap from "../../Components/Maps";
import Loader from "../../Layout/Loader";
import "./Style.scss";
import { checkPrivileges, errorHandler } from "../../Helpers";
import moment from "moment";
import axios from "axios";
import instance from "../../axios";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisable: false,
      selecteditemId: "",
      selectitem: [],
      deviceData: {},
      itemDetails: "",
      eventEnableMap: false,
      showNotification: false,
      address: "",
      latitude: "",
      longitude: "",
      loading: false,
      applied: false,
      validatedForm: false,
      notificationId: [],
      notificationType: [],
      alreadySubmit: false,
      from: "",
      to: "",
      selectedDate: "",
      showAllNotification: false,
      isProcessCompleted: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      showFilterForm: false,
    };
    this.onCloseModal = this.onCloseModal.bind(this);
    this.selecteItem = this.selecteItem.bind(this);
    this.setPageLoader = this.setPageLoader.bind(this);
    this.selectedItem = this.selectedItem.bind(this);
    this.detailsDiv = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  toggleFilterForm = () => {
    if (this.state.showFilterForm === false) {
      this.setState({
        selectitem: [],
        notificationId: [],
        notificationType: [],
        from: "",
        to: "",
        showFilterForm: true,
      });
    } else if (this.state.showFilterForm === true) {
      this.setState(
        {
          selectitem: [],
          notificationId: [],
          notificationType: [],
          from: "",
          to: "",
          showFilterForm: false,
        },
        () => {
          this.fetchMoreItems(true);
        }
      );
    }
    // this.setState({showFilterForm: !this.state.showFilterForm})
  };

  toggleFilterForm2 = () => {
    this.setState({ showFilterForm: !this.state.showFilterForm });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }

    this.setState({
      isVisable: false,
      selecteditemId: "",
      selectitem: [],
      itemDetails: "",
      eventEnableMap: false,
      showNotification: false,
      address: "",
      latitude: "",
      longitude: "",
      assignDriver: "",
      loading: false,
      applied: false,
      validatedForm: false,
      notificationId: [],
      alreadySubmit: false,
      from: "",
      to: "",
      selectedDate: "",
      showAllNotification: false,
      isProcessCompleted: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.logInUser && n.logInUser.id && this.state.alreadySubmit === false) {
      if (n.match.params.id) {
        this.setState({
          selectitem: [...this.state.selectitem, n.match.params.id],
        });
      }
      this.setState(
        {
          alreadySubmit: true,
        },
        () => {
          this.fetchMoreItems(true);
        }
      );
    }
  }

  fetchMoreItems = (reset, cb) => {
    if (source) {
      source.cancel();
    }
    this.fetchData(
      this.props.logInUser.id,
      reset ? 1 : this.state.currentPage,
      this.state.pagesize,
      reset || null,
      cb
    );
  };

  fetchData = (userId, page, perPage, reset, cb) => {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
    const from = this.state.from;
    const to = this.state.to;
    let items = this.state.itemPagination.items;
    if (reset) {
      items = [];
    }
    source = CancelToken.source();
    let method = "POST";
    let url = `/api/events/get`;

    instance({
      method,
      url,
      data: {
        userId: userId,
        deviceIds: this.state.selectitem,
        types: this.state.notificationType,
        notificationIds: this.state.notificationId,
        fromDate: from,
        toDate: to,
        search: "",
        page: page,
        limit: perPage,
      },
      cancelToken: source.token,
    })
      .then((response) => {
        // if (response.status === 200) {
        const res = response;
        this.setState(
          {
            showNotification: true,
            itemPagination: {
              ...res,
              items: items.concat(res.data),
            },
            currentPage: res.hasNext ? res.page + 1 : res.page,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        this.setState({ showNotification: false });
      });
  };

  componentWillMount() {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.alreadySubmit === false
    ) {
      if (this.props.match.params.id) {
        this.setState({
          selectitem: [...this.state.selectitem, this.props.match.params.id],
        });
      }
      this.setState(
        {
          alreadySubmit: true,
        },
        () => {
          this.fetchMoreItems(true);
        }
      );
    }
    const urls = []; // a list of urls to fetch in parallel
    if (!this.props.notificationType.length) {
      urls.push("/api/notifications/types/");
    }
    source = CancelToken.source();

    urls.map((url) =>
      instance({
        url: url,
        method: `GET`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // params: {
        //   id: id,
        // },
      })
        // axios
        //   .get(url, {
        //     cancelToken: source.token,
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json",
        //     },
        //   })
        .then((res) => {
          // if (res.status === 200) {
          // if (url === "/api/notifications/types/") {
          this.props.dispatch(getNotificationType(res));
          // }
          // }
        })
    );
  }

  setPageLoader(value) {
    this.setState({ loading: value });
  }

  selecteItem(item) {
    this.setState({
      selectitem: { ...item, label: item.label || item.name, value: item.id },
      isVisable: true,
      showNotification: false,
      eventEnableMap: false,
      /*notificationId: [],
            from: '',
            to: '',
            selectedDate: '',*/
    });
  }

  makeRead = (item) => {
    let items = this.state.itemPagination.items.map((row) => {
      if (item.id === row.id) {
        row.read = true;
      }
      return row;
    });
    this.setState(
      {
        itemPagination: {
          ...this.state.itemPagination,
          items,
        },
      },
      () => {
        instance({
          url: `/api/events/read/${item.id}`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          // params: {
          //   id: id,
          // },
        })
          // axios.get(`/api/events/read/${item.id}`)
          .then((response) => {
            // if (response && response.status === 200) {
            //   if (response.data && response.data.status === "success") {
            fetchNotificationCount(this.props.dispatch, this.props.logInUser);
            // }
            // }
          });
      }
    );
  };

  selectedItem(selectitem) {
    this.setState({
      selectitem,
      isVisable: true,
      showNotification: false,
      eventEnableMap: false,
    });
  }

  showNotification = (obj) => {
    this.setState(
      { ...obj, showNotification: false, alreadySubmit: true },
      () => {
        this.fetchMoreItems(true, this.toggleFilterForm2);
      }
    );
  };

  onCloseModal() {
    this.setState({
      isVisable: false,
      selectitem: [],
    });
  }

  

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  getNotificationType = () => {
    let array = [];
    if (this.props.notificationType.length) {
      this.props.notificationType.map((item, i) => {
        array.push({
          name: this.props.translate("notification." + item.type),
          id: item.type,
        });
        return null;
      });
      return array;
    } else {
      return array;
    }
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      validatedForm: value,
      showAllNotification: value,
      showNotification: false,
      eventEnableMap: false,
    });
    if (value === true) {
      this.setState({
        notificationId: [],
        to: "",
        from: "",
      });
    }
  };

  disableShowBtn = () => {
    this.setState({
      validatedForm: false,
    });
  };

  setSelectedDevice = () => {
    let item = this.props.devices.find(
      (item) => parseInt(this.props.match.params.id) === parseInt(item.id)
    );
    if (item) {
      this.setState({
        selecteditem: { ...item, label: item.name, value: item.id },
      });
    }
  };

  fetchNotifications = () => {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }

    let serverTimeZone = moment.tz(serverTimeZoneName).utcOffset() / 60;
    let clientTimezone = moment().utcOffset() / 60;

    const f = this.state.from || "";
    const t = this.state.to || "";

    let from = this.calcTime(
      moment(f).toISOString(),
      clientTimezone - serverTimeZone + clientTimezone
    );
    let to = this.calcTime(
      moment(t).toISOString(),
      clientTimezone - serverTimeZone + clientTimezone
    );

    let eventId = "";
    if (this.state.notificationId.length) {
      this.state.notificationId.map((id) => {
        eventId += "&type=" + id;
        return null;
      });
    }

    let id = this.state.selecteditem.value;
    this.setState(
      {
        totalEvents: [],
        currentPage: 1,
        hasMore: true,
      },
      () => {
        instance({
          url: `/api/events/device/${id}`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            page: this.state.currentPage,
            limit: 20,
            from: from,
            to: to,
            type: eventId,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((res) => {
            this.setState({
              ...res,
              totalEvents: this.state.totalEvents.concat(res.data),
              currentPage: res.page + 1,
              hasMore: res.hasNext,
            });
            //   })
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
    );
  };

  screenVaildation = (isValidet, num, value) => {
    let { validateValue } = this.state;
    if (isValidet) {
      for (let i = 0; i < validateValue.length; i++) {
        if (validateValue[i] === num) {
          validateValue.splice(i, 1);
        }
      }
    } else {
      validateValue.push(num);
      validateValue = [...new Set(validateValue)];
    }
    this.setState({
      validateValue: validateValue,
      wizardData: {
        ...this.state.wizardData,
        [`form${num}`]: value,
      },
    });
  };

  markAsReadAll = () => {
    if (window.confirm("Are you sure? Do you want to mark all as read?")) {
      instance({
        method: "GET",
        url: "/api/events/readall",
      })
        .then((response) => {
          this.fetchMoreItems(true, () =>
            fetchNotificationCount(this.props.dispatch, this.props.logInUser)
          );
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };
  calcTime = (date, offset) => {
    let d = new Date(date);

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);

    return nd.toISOString();
  };

  render() {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }

    let deviceCategory = "";
    if (
      this.state.itemDetails &&
      this.state.itemDetails.deviceId &&
      this.props.devices
    ) {
      const d = this.props.devices.find(
        (dev) => dev.id === this.state.itemDetails.deviceId
      )
      if (d) {
        deviceCategory = d.category || "default"
      }
    }

    return (
      <>
        <Layout
          {...this.props}
          selectedItem={this.selectedItem}
          classFromChildren="notification-page"
          showNotification={this.showNotification}
          toggleFilterForm={this.toggleFilterForm}
          showFilterForm={this.state.showFilterForm}
          showDetail={this.props.showDetail}
          userEvents={{
            markAsReadAll: this.markAsReadAll,
            setPageLoader: this.setPageLoader,
            className: "DeviceWiseEvents",
            showDetail: this.props.showDetail,
            itemDetails: this.state.selectitem,
            devices: this.props.devices,
            onCloseModal: this.onCloseModal,
            loading: this.state.loading,
            notificationId: this.state.notificationId,
            disableShowBtn: this.disableShowBtn,
            validatedForm: this.state.validatedForm,
            itemPagination: this.state.itemPagination,
            fetchMoreItems: this.fetchMoreItems,
            serverTimeZone: serverTimeZoneName,
            translate: this.props.translate,
          }}
        >
          {checkPrivileges("device") && (
            <div style={{ minHeight: "100%" }}>
              <MarkerMap
                eventMarker={
                  deviceCategory
                    ? `/assets/category/default/${deviceCategory}top.svg`
                    : ``
                }
                showMarker={this.state.eventEnableMap}
                zoom={this.state.eventEnableMap ? 15 : 3}
                lat={this.state.latitude}
                lng={this.state.longitude}
                height={"100%"}
              />
            </div>
          )}
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  geofences: state.geoFence,
  devices: state.devices3,
  notificationType: state.notificationType,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors,
});

export default connect(mapStateToProps)(withLocalize(Events));
