import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";
import Moment from "moment";
import { getTrash } from "../../Actions/Trash";
import {
  fetchGeofences,
  fetchDrivers,
  fetchDevices,
  fetchUsers,
} from "./../../Actions/Devices/index";
import { fetchTrailers } from "./../../Actions/Trailer";
import Table from "../common/tableWithBackEndPagination";
import { withLocalize } from "react-localize-redux";
import { checkPrivileges, errorHandler, PaginationConfig } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import Loader from "../../Layout/Loader";
import instance from "../../axios";

let source;
class usersModal extends Component {
  constructor() {
    super();
    this.state = {
      ...PaginationConfig,
      selectedUser: "",
      defaultItemsList: false,
      isSelectedUser: false,
      onDeleteConfirmation: false,
      onRestoreConfirmation: false,
      onRestoreForce: false,
      confirmationText: "",
      isArchived: true,
      tableData: [],
      pagData: "",
      multiChecked: false,
      hasToCheckAll: false,
      pageSize: 20,
    };

    this.handleAllCheckboxes = this.handleAllCheckboxes.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.dispatch(Notifications.removeAll());
    this.getTrashData(this.props);
  }
  UNSAFE_componentWillReceiveProps(n) {
    if (this.state.isArchived) {
      this.getTrashData(n);
    }
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  getTrashData = (n) => {
    let { page, pageSize, itemSearch, userTypes } = this.state;

    if (n.logInUser && n.logInUser.id) {
      this.setState(
        {
          isArchived: false,
        },
        () => {
          let apiCall = `api/archives/get?page=${page}&limit=${pageSize}&search=${itemSearch}${
            userTypes || ""
          }`;
          source = axios.CancelToken.source();
          axios
            .get(apiCall, {
              cancelToken: source.token,
            })
            .then((response) => {
              if (
                response &&
                response.status === 200 &&
                response.data &&
                response.data.status === "success"
              ) {
                let udata = response.data.data.data;
                n.dispatch(getTrash(udata));
                let users = udata.map((u) => {
                  if (u.timeStamp) {
                    return {
                      ...u,
                      timeStamp:
                        n.logInUser && n.logInUser.twelveHourFormat
                          ? Moment(u.timeStamp).format("YYYY-MM-DD hh:mm A")
                          : Moment(u.timeStamp).format("YYYY-MM-DD HH:mm"),
                    };
                  } else {
                    return { ...u };
                  }
                });
                let currentDateTime = Date();
                let current =
                  Moment(currentDateTime).format("YYYY-MM-DD hh:mm A");
                const settingExpire = users.map((data) => {
                  const duration = Moment.duration(
                    Moment(current).diff(data.timeStamp)
                  );
                  let diff = 30 - duration._data.days;
                  return {
                    ...data,
                    expire: "Auto delete in " + diff + " days",
                    checked: false,
                  };
                });
                this.setState({
                  tableData: settingExpire,
                  defaultItemsList: true,
                  pagData: response.data && response.data.data,
                });
              } else {
                this.setState({
                  // tableData: [],
                  pagData: "",
                });
              }
            })
            .catch((e) => {
              // if (axios.isCancel(e)) {
              console.log("Request canceled = ", e);
              // }
            });
        }
      );
    }
  };

  removeItem = (selected) => {
    if (selected && selected.length) {
      this.setState({
        onDeleteConfirmation: true,
        selectedUser: selected[0],
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        directDelete: false,
        selectedUser: selected,
      });
    }
  };
  restoreItem = (selected) => {
    if (selected && selected.length) {
      this.setState({
        selectedUser: selected[0],
        onRestoreConfirmation: true,
      });
    } else {
      this.setState({
        directDelete: false,
        selectedUser: selected,
        onRestoreConfirmation: true,
      });
    }
  };

  deleteItem = () => {
    let { selectedUser } = this.state;
    if (this.state.multiChecked) {
      instance({
        url: "api/archives/deleteall",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        // .then(response => {
        //     if (response.status === 200) {
        //         if (response.ok) {
        //             response.json()
        .then((dele) => {
          this.setState({
            multiChecked: false,
          });
          this.getTrashData(this.props);
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("allDeletedSuccessfully"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        })
        //         }
        //     }
        //     else {
        //         this.props.dispatch(
        //             Notifications.error({
        //                 message: 'some thing went wrong',
        //                 autoDismiss: 5,
        //                 location: this.props.location
        //             })
        //         )
        //     }
        // })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
      // .catch(e => {
      //     if (e && e.text) {
      //         e.text().then(err => {
      //             this.props.dispatch(
      //                 Notifications.error({
      //                     message: 'some thing went wrong',
      //                     autoDismiss: 5,
      //                     location: this.props.location
      //                 })
      //             )
      //         })
      //     }
      // })
    } else {
      if (selectedUser.id) {
        instance({
          url: `api/archives/delete/${selectedUser.id}`,
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          // .then(response => {
          //     if (response&&response.status === 200) {
          //         if (response.ok) {
          //             response.json()
          .then((user) => {
            this.getTrashData(this.props);
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("deleteSuccessfully"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch, this.errorCallBack);
          })
          //         }
          //     }
          //     else {
          //         this.props.dispatch(
          //             Notifications.error({
          //                 message: 'some thing went wrong',
          //                 autoDismiss: 5,
          //                 location: this.props.location
          //             })
          //         )
          //     }
          // })
          .catch((e) => {
            if (e && e.text) {
              e.text().then((err) => {
                this.props.dispatch(
                  Notifications.error({
                    message: "some thing went wrong",
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
              });
            }
          });
      }
    }
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  // restoring user
  restoreSelectedItem = (isForceRestore) => {
    let { selectedUser } = this.state;
    if (selectedUser && selectedUser.id) {
      axios({
        method: "PUT",
        url: `api/archives/unarchive/${selectedUser.id}?forceRestore=${isForceRestore}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response && response.data && response.data.status === "success") {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("restoreSuccessfully"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
            this.getTrashData(this.props);
            this.fetchData(selectedUser.itemType);
          } else if (
            response &&
            response.data &&
            response.data.statusCode === "422"
          ) {
            this.setState({
              onRestoreForce: true,
              confirmationText: `Not a ${selectedUser.itemType} creator ? ${selectedUser.itemType} parent user not found. Please activate ${selectedUser.itemType} parent user first OR click 'RESTORE UNDER MY ACCOUNT' button to restore ${selectedUser.itemType} under your account.`,
            });
          } else {
            let array =
              response && response.data && response.data.message.split("HINT:");
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(array[1].replace(")", "")),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response && error.response.data.includes("-")) {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(
                    error.response.data.split("-")[0]
                  ),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate("somthingWentWrongMessage"),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          } else if (error.request) {
            console.log("error.request =", error.request);
          } else {
            console.log("error.message =", error.message);
          }
        });
      this.setState({
        // selectedUser: '',
        onRestoreConfirmation: false,
      });
    }
  };
  fetchData = (type) => {
    if (type === "User") {
      fetchUsers(this.props.dispatch, this.props.logInUser);
    } else if (type === "Device") {
      this.props.dispatch(fetchDevices(this.props.logInUser, true));
    } else if (type === "Driver") {
      fetchDrivers(this.props.dispatch, this.props.logInUser);
    } else if (type === "Trailer") {
      fetchTrailers(this.props.dispatch, this.props.logInUser);
    } else if (type === "Geofence") {
      fetchGeofences(this.props.dispatch, this.props.logInUser);
    }
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      onRestoreConfirmation: false,
      onRestoreForce: false,
    });
  };
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        pageSize: value,
      },
      () => this.getTrashData(this.props)
    );
  };
  handleChangePage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: value,
        //defaultItemsList: false
      },
      () => this.getTrashData(this.props)
    );
  };
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        page: 1,
        itemSearch: searchValue,
      },
      () => this.getTrashData(this.props)
    );
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  setPagination = (user) => {
    if (this.state.pagData) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };
  // multihandleChange = e => {
  //   this.setState({
  //     multiChecked:e.target.checked
  //   })
  // }
  // singlehandleChange = e => {
  // }
  handleAllCheckboxes = (e) => {
    const hasToCheckAll = !this.state.hasToCheckAll;
    const updatedArray = this.state.tableData.map((item) => {
      item.checked = hasToCheckAll;
      return item;
    });

    this.setState({
      ...this.state,
      tableData: updatedArray,
      hasToCheckAll: e.target.checked,
      multiChecked: true,
    });
  };

  handleCheckboxClick = (clickedItem) => {
    const updatedArray = this.state.tableData.map((item) => {
      item.checked = item.id === clickedItem.id ? !item.checked : item.checked;
      return item;
    });
    this.setState({
      ...this.state,
      tableData: updatedArray,
    });
  };
  render() {
    return (
      <div style={{ position: "relative" }}>
        {/* <Style>{`
                .custom-tab-button {
                    min-height: 29px;
                    border-radius: 6px 6px 0 0;
                    margin: 0 2px;
                    background: ${this.props.themecolors['600']};
                    color: ${this.props.themecolors['themeInverse']};
                }
                .custom-tabs {
                    min-height: 30px;
                    margin: 10px 6px 0;
                }

                .custom-tab-button-selected {
                    background: ${this.props.themecolors['500']};
                    color: ${this.props.themecolors['themeInverse']};
                }`}</Style> */}

        {this.state.defaultItemsList ? (
          <>
            <Table
              rows={this.state.tableData || []}
              pagination={this.setPagination(this.state.pagData)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              SearchItem={this.SearchItem}
              onDelete={this.removeItem}
              onRestore={this.restoreItem}
              isEditable
              restore
              trashLabel
              handleCheckboxClick={(e) => this.handleCheckboxClick(e)}
              handleAllCheckboxes={(e) => this.handleAllCheckboxes(e)}
              // hasToCheckAll = {this.state.hasToCheckAll}
              // multiChecked={this.state.multiChecked}
              searchSelectedId={this.state && this.state.searchSelectedId}
              // showCheckbox={true}
              //   searchSelectedId={this.state.searchSelectedId}
              ServerSetting={this.props.ServerSetting}
              logInUser={this.props.logInUser}
              themecolors={this.props.themecolors}
              isRowIcons={false}
              searchable
              isCursoPointer
              filterDropdown
              setSerialNo
              translate={this.props.translate}
              // multiSelect
              hasAccessOfDelete={checkPrivileges("userDelete")}
              // title={this.props.translate('trash')}
              rowDefinition={rowDefinitionR(this.props.translate)}
            />
          </>
        ) : (
          <Loader />
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteItem}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selectedUser.label}
          />
        )}
        {this.state.onRestoreConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={(e) => this.restoreSelectedItem(false)}
            title={this.props.translate("areYouWantToRestore")}
            children={this.state.selectedUser.label}
          />
        )}
        {this.state.onRestoreForce && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={(e) => this.restoreSelectedItem(true)}
            title={this.props.translate(this.state.confirmationText)}
            OkText={this.props.translate("restoreForcefully")}
            // children={this.state.selectedUser.label}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    ServerSetting: state.ServerSetting,
    trash: state.trash,
  };
};
const mapStateToProps = connect(mapState);
export const ShowReports = mapStateToProps(withLocalize(usersModal));

const rowDefinitionR = (translate) => {
  if (localStorage.getItem("adminToken")) {
    return [
      {
        id: "label",
        numeric: false,
        disablePadding: false,
        label: translate("sharedDescription"),
      },
      {
        id: "itemType",
        numeric: false,
        disablePadding: false,
        label: translate("sharedType"),
      },
      {
        id: "timeStamp",
        numeric: false,
        disablePadding: false,
        label: translate("delete"),
      },
      {
        id: "expire",
        numeric: false,
        disablePadding: false,
        label: translate("userExpirationTime"),
      },
    ];
  } else {
    return [
      {
        id: "label",
        numeric: false,
        disablePadding: false,
        label: translate("sharedDescription"),
      },
      {
        id: "itemType",
        numeric: false,
        disablePadding: false,
        label: translate("sharedType"),
      },
      {
        id: "timeStamp",
        numeric: false,
        disablePadding: false,
        label: translate("delete"),
      },
      {
        id: "expire",
        numeric: false,
        disablePadding: false,
        label: translate("userExpirationTime"),
      },
    ];
  }
};
