import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import { checkPrivileges, isOwner, errorHandler } from "../../Helpers";
import Layout from "./../../Layout";
import TextField from "../../Components/common/TextField";
import Button from "../../Components/common/Button";
import { addNotification, getNotification } from "../../Actions/Notifications";
import Notifications from "react-notification-system-redux";
import EmptyState from "../../Components/common/EmptyState";
import Dialog from "../../Components/common/Dialog";
import SearchItems from "../../Components/Devices/searchItems";
import { NotificationModal } from "../../Components/Notifications/Wizard";
import { updateNotification } from "../../Actions/Notifications";
import axios from "axios";
import instance from "../../axios";
const CancelToken = axios.CancelToken;
let source;

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: {},
      mode: "create",
      waitForNotificationData: false,
      model: false,
      assignMode: "",
      currentNotification: "",
      entityId: 0,

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      deviceIds:[],
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    };
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
    this.setState({
      itemData: {},
      mode: "",
      model: false,
      assignMode: "",
      currentNotification: "",
      entityId: 0,

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  UNSAFE_componentWillMount() {
     if (this.props.location.pathname === "/notificationCreate") {
      if (checkPrivileges("notificationCreate")) {
        // this.openCreateFrom();
      } else {
        //display privilege error message
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate("lackOfPrivileges"),
            autoDismiss: 10,
          })
        );
      }
    }
    if (this.props.location.pathname.indexOf("/notificationUpdate") !== -1) {
      let { notification } = this.props;
      if (checkPrivileges("notificationUpdate")) {
        if (notification.length) {
          this.openNotificationEditForm();
        } else {
          this.setState({
            waitForNotificationData: true,
          });
        }
      } else {
        //display privilege error message
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate("lackOfPrivileges"),
            autoDismiss: 10,
          })
        );
      }
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    }
    let mode = new URLSearchParams(this.props.location.search).get("mode");
    // if (mode === "create") {
    //   this.setState({
    //     mode,
    //   });
    // }
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          // this.fetchData(
          //   n.logInUser,
          //   this.state.currentPage,
          //   this.state.pagesize
          // );
        }
      );
    }
  }
  openNotificationEditForm = () => {
    let { notification } = this.props;
    notification.map((item) => {
      if (parseInt(this.props.match.params.id) === item.id) {
        this.editNotificationForm(item);
        this.setState({
          waitForNotificationData: false,
        });
      }
    });
  };
  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    );
  };

  searchItems = (text) => {
    if (source) {
      source.cancel();
    }
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchData(this.props.logInUser, 1, this.state.pagesize, true);
      }
    );
  };

  fetchData = (logInUser, page, perPage, reset) => {
    source = CancelToken.source();

    let searchText = this.state.searchText;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }

    let items = this.state.itemPagination.items;
    if (reset) {
      items = [];
    }

    instance({
      method: "GET",
      url: `/api/notifications/get?page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status === 200) {
        this.setState(
          {
            itemPagination: {
              ...response,
              items: items.concat(response.data),
            },
            currentPage: response.hasNext ? response.page + 1 : response.page,
          },
          () => {
            this.props.dispatch(getNotification(items.concat(response.data)));
          }
        );
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  assignModal = (assignMode, currentNotification) => {
    this.setState({
      assignMode,
      currentNotification,
      selectEntity: "",
      model: true,
    });
  };

  selectEntity = (e) => {
    this.setState({
      selectEntity: e,
    });
  };

  submitAssignModel = () => {
    if (this.state.assignMode && this.state.assignMode === "unit") {
      let obj = {
        deviceId: this.state.selectEntity.id,
        notificationId: this.state.currentNotification.id,
      };
      this.assignNotification(obj);
    } else if (this.state.assignMode && this.state.assignMode === "user") {
      let obj = {
        userId: this.state.selectEntity.id,
        notificationId: this.state.currentNotification.id,
      };
      this.assignNotification(obj);
    } else if (this.state.assignMode && this.state.assignMode === "group") {
      let obj = {
        groupId: this.state.selectEntity.id,
        notificationId: this.state.currentNotification.id,
      };
      this.assignNotification(obj);
    }
  };

  assignNotification = (obj) => {
    instance({
      url: `/api/permissions/`,
      method: `POST`,
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.ok) {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("notificationAssignedSuccessfully"),
            autoDismiss: 10,
          })
        );
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   if (e && e.text) {
    //     e.text().then(err => {
    //       if (err && err.includes('Duplicate entry')) {
    //         this.props.dispatch(
    //           Notifications.error({
    //             message: this.props.translate('alreadyAssigned'),
    //             autoDismiss: 10
    //           })
    //         )
    //       } else {
    //         this.props.dispatch(
    //           Notifications.error({
    //             message: this.props.translate('somthingWentWrongMessage'),
    //             autoDismiss: 10
    //           })
    //         )
    //       }
    //     })
    //   }
    // })
  };

  closeAssignModal = () => {
    this.setState({
      model: false,
      assignMode: "",
      commandTitle: "",
      currentNotification: "",
    });
  };

  openCreateFrom = () => {
     this.setState({ mode: "", itemData: {} }, () => {
      this.setState({
        mode: "create",
        itemData: {},
      });
    });
  };

  editNotificationForm = (itemData) => {
     this.setState({ mode: "", itemData: {} }, () => {
       this.setState({
        mode: "update",
        deviceIds:itemData?.attributes?.deviceIds || [],
        itemData: {
          ...itemData,
        },
      });
    });
  };
  closeCreateFrom = () => {
    if (
      this.props.location.pathname === "/notificationCreate" ||
      this.props.location.pathname.indexOf("/notificationUpdate") !== -1
    ) {
      this.props.history.push("/notifications");
    }
    this.setState({
      mode: "",
      itemData: "",
      model: false,
    });
  };

  updateNotification = (form) => {
    const updatedItemData = {
      ...form,
      attributes: {
        ...form.attributes,
        suspend: !form.attributes.suspend,
      },
    };
  
    this.setState(
      {
        itemData: updatedItemData,
      },
      () => {
        this.ac_submit(updatedItemData);
      }
    );
  };
  
  ac_submit = (itemData) => {
    instance({
      url: `/api/notifications/wizard/${itemData.id}`,
      method: `PUT`,
      data: {
        ...itemData,
      },
    })
      .then((notifications) => {
        this.props.dispatch(updateNotification(notifications));
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("notificationIsUpdated"),
            autoDismiss: 10,
          })
        );
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        this.setState({ loader: false });
      });
  };
  
  submitForm = () => {
    let { attributes, id, description, type, textChannel, deviceId } =
      this.state.itemData;

    let obj = {
      attributes: attributes || {},
      id: id || 0,
      description,
      type,
      deviceId: deviceId || 0,
      textChannel,
    };

    let params = "";
    if (this.state.mode === "update") {
      params = id;
    }

    if (obj) {
      instance({
        url: `/api/notifications/${params}`,
        method: `${this.state.mode === "update" ? "PUT" : "POST"}`,
        data: {
          ...obj,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((notification) => {
          if (this.state.mode === "update") {
            this.props.dispatch(addNotification(notification));
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("savedCommandsIsUpdated"),
                autoDismiss: 10,
              })
            );
          } else {
            this.props.dispatch(addNotification(notification));
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("savedCommandsIsCreated"),
                autoDismiss: 10,
              })
            );
          }
          this.closeCreateFrom();
          //   })
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  onChangedDevice = item => {
     this.setState({deviceIds: item})
  }

  render() {
    let { notification } = this.props;
    if (
      notification.length &&
      this.state.waitForNotificationData &&
      this.props.location.pathname.indexOf("/notificationUpdate") !== -1
    ) {
      this.openNotificationEditForm();
    }
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")} --{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          {this.props.translate("notFound")}{" "}
        </p>
      );

    if (checkPrivileges("notification")) {
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            classFromChildren="has-padding"
            editNotificationForm={this.editNotificationForm}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            updateNotification={this.updateNotification}
            onChangedDevice={this.onChangedDevice}
            deviceIds={this.state.deviceIds}
            {...this.state}
            mode={this.state.mode}
          >
            <div className="main-content-page">
              <div
                className="notification-page-wrapper"
                style={{
                   color: this.props.themecolors.textColor,
                  borderRadius: 6,
                }}
              >
                {(this.state.mode === "create" ||
                  this.state.mode === "update") && (
                  <NotificationModal
                    {...this.state}
                    onBack={this.closeCreateFrom}
                    translate={this.props.translate}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    commands={this.props.commands}
                    dispatch={this.props.dispatch}
                    assignModal={this.assignModal}
                  />
                )}
                {this.state.model ? (
                  <Dialog
                    isVisableBtn={true}
                    headerActions={
                      <Button
                        onClick={this.submitAssignModel}
                        variant="outlined"
                        size="small"
                      >
                        {this.props.translate("assignNotification")}
                      </Button>
                    }
                    open={this.state.model}
                    onClose={this.closeAssignModal}
                    title={this.props.translate("assignNotification")}
                  >
                    <div style={{ height: 250, padding: 16 }}>
                      <TextField
                        id="name"
                        margin="dense"
                        label={this.props.translate("sharedName")}
                        variant="outlined"
                        fullWidth
                        readOnly
                        value={
                          this.state.currentNotification &&
                          this.state.currentNotification.name
                        }
                      />

                      {this.state.assignMode === "unit" ? (
                        <SearchItems
                          api="devices"
                          fill
                          isMulti={false}
                          onChange={this.selectEntity}
                          value={this.state.selectEntity}
                          placeholder={this.props.translate("selectedTrackers")}
                        />
                      ) : null}

                      {this.state.assignMode === "user" ? (
                        <SearchItems
                          api="users"
                          fill
                          isMulti={false}
                          onChange={this.selectEntity}
                          value={this.state.selectEntity}
                          placeholder={this.props.translate("searchUsers")}
                        />
                      ) : null}

                      {this.state.assignMode === "group" ? (
                        <SearchItems
                          api="groups"
                          fill
                          isMulti={false}
                          onChange={this.selectEntity}
                          value={this.state.selectEntity}
                          placeholder={this.props.translate("searchGroup")}
                        />
                      ) : null}
                    </div>
                  </Dialog>
                ) : null}
                {this.state.mode === "" ? (
                  <EmptyState
                    text={this.props.translate("noNotificationSelected")}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  commands: state.commands,
});
export default connect(mapStateToProps)(
  withSnackbar(withLocalize(Notification))
);
