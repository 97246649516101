import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import { withLocalize } from "react-localize-redux";
import withResources from "../HOCRecources";
import ResourceModal from "../../Components/Recources/resourceModal";
import {
  Map,
  TileLayer,
  Tooltip,
  Marker,
  LayersControl,
} from "react-leaflet";
import { addGeoFence, updateGeoFence } from "./../../Actions/Devices";
import Layout from "./../../Layout";
import L from "leaflet";
 import { convertSpeedUnits, errorHandler } from "../../Helpers";
import GeofenceModal from "../../Components/Geofence/GeofenceModal";
 import CustomMarker from "./../../Components/Maps/CustomMarker";
import { MapTooltip } from "./../../Components/Maps/MapTooltip";
import { MapTileLayers } from "../../Reducers/Maps";
import moment from "moment";
import instance from "../../axios";
import ReactHtmlParser from "react-html-parser";

//import { checkPrivileges } from '../../Helpers/index'
let geo = {};
class Geofences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minZoom: 3,
      lat: 25.097919,
      lng: 55.236683,
      zoom: 3,
      bounds: null,
      update: true,
      EditControlObject: {},
      newVector: {},
      updatedGeo: {},
      updateVisible: this.updateVisible.bind(this),  
      DrawControl: {},
      Drawing: "",
      EditMode: false,
      fitBounds: {},
      resourceList: false,
      linkResource: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMaoData: {},
      currentLayer: null,
    };  
    this.setEditControlValue = this.setEditControlValue.bind(this);
    this.reactECref = {};
    this.markers = null;
  }

  componentWillUnmount() {
    this.setState({
      minZoom: 3,
      lat: 25.097919,
      lng: 55.236683,
      zoom: 3,
      bounds: null,
      update: true,
      EditControlObject: {},
      newVector: {},
      updatedGeo: {},
      DrawControl: {},
      Drawing: "",
      EditMode: false,
      fitBounds: {},

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMaoData: {},
    });
  }

  setEditControlValue(obj) {
    if (obj && obj.id) {
      this.setState({
        EditControlObject: { ...this.state.EditControlObject, [obj.id]: obj },
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

 

 
  UNSAFE_componentWillMount() {
    if (this.props.ServerSetting && this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude,
      });
    }
    if (this.props.logInUser && this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.props.geoFence.length &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            this.props.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          );
          this.showUnits(this.props);
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.ServerSetting.zoom) {
      this.setState({
        zoom: n.ServerSetting.zoom,
        lat: n.ServerSetting.latitude,
        lng: n.ServerSetting.longitude,
      });
    }
    if (n.logInUser.zoom) {
      this.setState({
        zoom: n.logInUser.zoom,
        lat: n.logInUser.latitude,
        lng: n.logInUser.longitude,
      });
    }

    if (n.logInUser && n.logInUser.id && n.geoFence.length) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            n.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    }
    this.showUnits(n);
  }
  showResources = (type) => {
    this.setState({
      resourceList: type,
    });
  };
  addResource = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "addResource",
      selecteditem: "",
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onEditResource = (item) => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "editResource",
      selecteditem: item,
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onLinkResource = (item) => {
    this.setState(
      {
        linkResource: true,
        selecteditem: item,
      },
      () => {
        this.props.fetchNestedItems(item.id, 1);
      }
    );
  };
  onCloseResource = () => {
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  };
  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: "",
      activeOperation: "",
      onDeleteConfirmation: false,
    });
  };
  showUnits = (n) => {
    if (n.deviceRelatedData && Object.keys(n.deviceRelatedData).length) {
      this.markers = [];
      n.devices.data &&
        n.devices.data.map((deviceData, index) => {
          const position = n.deviceRelatedData[deviceData.id];
          const driver =
            n.drivers &&
            n.drivers.find(
              (dr) =>
                position &&
                position.exists &&
                position.driverId &&
                position.driverId === dr.id
            );
          const trailer =
            n.trailers &&
            n.trailers.find(
              (t) =>
                position &&
                position.exists &&
                position.attributes.trailerUniqueId &&
                position.attributes.trailerUniqueId === t.uniqueId
            );
          if (
            position &&
            position.exists &&
            deviceData &&
            deviceData.visible === true
          ) {
            this.markers.push(
              <CustomMarker
                key={deviceData.id}
                position={{
                  lat: position.latitude,
                  lng: position.longitude,
                  updated: moment(position.serverTime),
                }}
                rotationAngle={0}
                rotationOrigin="center"
                animationTime={
                  deviceData.id === this.props.trackId &&
                  this.state.animCount === 1
                    ? position.animationTime
                    : 0.5
                }
                tracking={this.props.trackId}
                icon={L.divIcon({
                  iconUrl:
                    "/assets/category/default/" +
                    (deviceData.category || "default") +
                    "top.svg",
                  iconSize: [50, 50],
                  iconAnchor: [25, 25],
                  tooltipAnchor: [0, -20],
                  className: "custom-marker",
                  html: `<img
                  style="transform: rotate(${position.course}deg)"
                    src=
                      '/assets/category/default/${
                        deviceData.category || "default"
                      }top.svg'
                    
                    alt=''
                  />`,
                })}
                iconSize={[50, 50]}
              >
                <Tooltip direction={"top"}>
                  <MapTooltip
                    trailer={trailer}
                    themecolors={this.props.themecolors}
                    position={position}
                    driver={driver}
                    device={deviceData}
                    logInUser={this.props.logInUser}
                    translate={this.props.translate}
                  />
                </Tooltip>
              </CustomMarker>
            );
          }
          return null;
        });
    } else {
      this.markers = null;
    }
  };

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser.id,
      this.state.currentPage,
      this.state.pagesize
    );
  };

  searchItems = (text) => {
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true);
      }
    );
  };

  fetchData = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText;
    let items = this.props.geoFence.filter((e) =>
      e.name.toLowerCase().includes((searchText || "").toLowerCase())
    );
    this.setState({
      itemPagination: {
        items: items.map((e) => ({
          ...e,
          name: ReactHtmlParser(e.name)[0],
          description: ReactHtmlParser(e.description)[0],
        })),
        total: items.length,
        currentPage: 1,
        hasNext: false,
      },
    });
  };
  onDelete = () => {
    this.fetchData();
  };

  openGeofenceModal = (data) => {
 
      this.setState({
        geofenceModal: true,
        geofenceModalData: data,
       })
   };

  closeGeofenceModal = (data) => {
    this.setState({
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMaoData: {},
    });
  };

  addShape(type) {
    if (this.state.EditMode === false) {
      if (this.state.DrawControl) {
        this.setState({ Drawing: type }, () => {
          if (type === "circle") {
            this.state.DrawControl.leafletElement._toolbars.draw._toolbarContainer.children[2].click();
          } else if (type === "polygon") {
            this.state.DrawControl.leafletElement._toolbars.draw._toolbarContainer.children[1].click();
          } else if (type === "polyline") {
            this.state.DrawControl.leafletElement._toolbars.draw._toolbarContainer.children[0].click();
          }
        });
      }
    } else {
      alert("Please finish your modification first!");
    }
  }

  toggleGeofences = (checked) => {
    if (!checked) {
      this.setState({ fitBounds: {} });
      geo = {};
    } else {
      this.updateVisible();
    }
  };

  updateVisible() {
    setTimeout(() => {
      let bounds = [];
      this.props.geoFence.map((g) => {
        if (g.visible === true && this.state.fitBounds[g.id]) {
          bounds.push(this.state.fitBounds[g.id]);
        }
        return null;
      });
      if (bounds.length) {
        this.map.fitBounds(bounds);
      }
    }, 50);
  }

   

  render() {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")} --{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          {this.props.translate("notFound")}{" "}
        </p>
      );
    if (this.props.mapLayer) {
      return (
        <div className="no-map-controls no-widgets">
          {this.state.geofenceModal && (
            <GeofenceModal
              geofence={this.state.geofenceModalData}
              {...this.state.geofenceModalMaoData}
              onClose={this.closeGeofenceModal}
              resourceList={this.state.resourceList}
            />
          )}
          <Layout
            {...this.props}
            geofenceData={this.state}
            toggleGeofences={this.toggleGeofences}
            fetchMoreItems={this.fetchMoreItems}
            itemPagination={{ ...this.state.itemPagination, endMessage }}
            searchItems={this.searchItems}
            openGeofenceModal={this.openGeofenceModal}
            onDelete={this.onDelete}
            showResources={this.showResources}
            onEditResource={this.onEditResource}
            onLinkResource={this.onLinkResource}
            addResource={this.addResource}
            resourceList={this.state.resourceList}
          >
             
            <button
              style={{
                position: "absolute",
                left: "-10000px",
                top: "-10000px",
              }}
              onClick={(e) => this.addShape("circle")}
              id="addCircle"
            ></button>
            <button
              style={{
                position: "absolute",
                left: "-10000px",
                top: "-10000px",
              }}
              onClick={(e) => this.addShape("polygon")}
              id="addPolygon"
            ></button>
            <button
              style={{
                position: "absolute",
                left: "-10000px",
                top: "-10000px",
              }}
              onClick={(e) => this.addShape("polyline")}
              id="addPolyline"
            ></button>
            <div className="has-padding">
              <ResourceModal
                changeResource={this.props.changeResource}
                selectedResourse={this.state.selecteditem}
                activeOperation={this.state.activeOperation}
                onCloseResource={this.onCloseResource}
                itemPagination={this.state.itemPagination && this.state.itemPagination.items}
                assignItem={this.props.assignItem}
                unassignItem={this.props.unassignItem}
                fetchNestedItems={this.props.fetchNestedItems}
                nestedResources={this.props.nestedResources}
                translate={this.props.translate}
                editResource={this.state.editResource}
                linkResource={this.state.linkResource}
                themecolors={this.props.themecolors}
                itemType="Geofence"
              />
            </div>
          </Layout>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  geoFence: state.geoFence,
  deviceRelatedData: state.deviceRelatedData,
  devices: state.devices,
  mapLayer: state.mapLayer,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
});

export default connect(mapStateToProps)(
  withLocalize(withResources(Geofences, "Geofence"))
);
