export const bounds = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'ADD_BOUNDS':
      return state.concat(action.payload.bounds)

    case 'SET_BOUNDS':
      return action.payload

    case 'RESET_BOUNDS':
      return []
    default:
      return state
  }
}

export const bounded = (state = false, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'APPLY_BOUNDED':
      return action.payload.apply

    default:
      return state
  }
}
export const markerEnabled = (state = false, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'MARKER_ENABLE':
      return action.payload

    default:
      return state
  }
}
export const insideGeofence = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}

    case 'UPDATE_INSIDE_GEO':
      return action.payload

    default:
      return state
  }
}

export const MapTileLayers = [
  {
    id: 'carto',
    name: 'CARTO',
    maxZoom: 22,
    reuseTiles: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    trafficUrl: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c', 'd'],
    attribution:
      '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
  },
  {
    id: 'yandexSat',
    name: 'Yandex Satellite',
    maxZoom: 19,
    url: '//sat{s}.maps.yandex.net/tiles?l=sat&z={z}&x={x}&y={y}&scale=2&lang=en_US',
    trafficUrl: '//sat{s}.maps.yandex.net/tiles?l=sat&z={z}&x={x}&y={y}&scale=2&lang=en_US',
    subdomains: ['01', '02', '03', '04'],
    attribution: '&copy; <a href="https://yandex.com/maps/">Yandex</a>'
  },
  {
    id: 'yandexMap',
    name: 'Yandex',
    maxZoom: 20,
    url: '//vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=en_US',
    trafficUrl: '//vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=en_US',
    subdomains: ['01', '02', '03', '04'],
    attribution: '&copy; <a href="https://yandex.com/maps/">Yandex</a>'
  },
  {
    id: 'googleTerrain',
    name: 'Google Terrain',
    maxZoom: 22,
    url: '//{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
    trafficUrl: '//{s}.google.com/vt/lyrs=p,traffic&x={x}&y={y}&z={z}',
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    attribution: 'Google Terrain'
  },
  {
    id: 'googleSatellite',
    name: 'Google Satellite',
    maxZoom: 22,
    url: '//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    trafficUrl: '//{s}.google.com/vt/lyrs=s,traffic&x={x}&y={y}&z={z}',
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    attribution: 'Google Satellite'
  },
  {
    id: 'googleHybrid',
    name: 'Google Hybrid',
    maxZoom: 22,
    url: '//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    trafficUrl: '//{s}.google.com/vt/lyrs=s,traffic,h&x={x}&y={y}&z={z}',
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    attribution: 'Google Hybrid'
  },
  {
    id: 'googleRoad',
    name: 'Google Road',
    maxZoom: 22,
    checked: true,
    url: '//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    trafficUrl: '//{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}',
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    attribution: 'Google Roadmap'
  },
  {
    id: 'osm',
    name: 'Open Street',
    maxZoom: 19,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    trafficUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: ['a', 'b', 'c']
  },
  {
    id: 'baidu',
    name: 'Baidu Map',
    maxZoom: 19,
    url: 'http://online{s}.map.bdimg.com/tile/?qt=tile&x={x}&y={y}&z={z}&styles=sl',
    trafficUrl: 'http://its.map.baidu.com:8002/traffic/TrafficTileService&level={z}&x={x}&y={y}?time='+new Date().getTime(),
    attribution:
      '© 2014 Baidu - GS(2012)6003;- Data © <a target="_blank" href="http://www.navinfo.com/">NavInfo</a> & <a target="_blank" href="http://www.cennavi.com.cn/">CenNavi</a> & <a target="_blank" href="http://www.365ditu.com/">DaoDaoTong</a>',
    subdomains: ['0','1','2','3','4','5','6','7','8','9']
  }
]

/* 
  Notes For Google Tiles

  Note the difference in the "lyrs" parameter in the URL:
  Hybrid: s,h;
  Satellite: s;
  Streets: m;
  Terrain: p; */

export const mapTraffic = (state = false, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return false
    case 'UPDATE_TRAFFIC':
      return !state
    default: 
      return state
  }
}

export const mapLayer = (
  state = {
    id: 'osm',
    maxZoom: 16,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: ['a', 'b', 'c']
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_LAYER':
    case 'GET_LAYER':
      switch (action.payload.id) {
        case 'carto':
        case 'yandexSat':
        case 'yandexMap':
        case 'googleTerrain':
        case 'googleSatellite':
        case 'googleHybrid':
        case 'googleRoad':
        // case 'baidu':
          return MapTileLayers.find(layer => layer.id === action.payload.id)
        case 'osm':
          return MapTileLayers.find(layer => layer.id === 'osm')
        default:
          return MapTileLayers.find(layer => layer.id === 'osm')
      }
    default:
      return state
  }
}
