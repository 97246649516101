import React, { Component } from "react";
import TextField from "./../../Components/common/TextFieldLg";
import Button from "./../../Components/common/Button";
import Loader from "./../../Layout/Loader";
import { logInUserInfo, getRoles } from "./../../Actions/Users";
import { ServerInfo } from "./../../Actions/serverSetting";
import { getLayer } from "./../../Actions/Maps";
import { withLocalize } from "react-localize-redux";
import { fetchDevicesList, fetchDevices } from "./../../Actions/Devices";
// import {
//   displayErrorMessages,
//   checkedDevelopmentType,
//   getCookie,
// } from "../../Helpers/index";
import {
  displayErrorMessages,
  checkedDevelopmentType,
  getCookie,
  errorHandler,
  checkPrivileges,
} from "../../Helpers/index";
import Checkbox from "../../Components/common/Checkbox";
import SocketController from "../../SocketController";
import { connect } from "react-redux";
import Style from "style-it";
import Notifications from "react-notification-system-redux";
import { NavLink } from "react-router-dom";
import InfoIcon from "./../../assets/monitoring/info.svg";
import instance from "../../axios";
const mapState = (state) => {
  return {
    devices: state.devices.data,
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
  };
};

class LoginForm1 extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, loginErrorMessage: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state !== ""
    ) {
      this.setState({
        failed: true,
        loginErrorMessage: this.props.location.state,
      });
    }
    this.setState({
      email: this.getCookie("userName"),
      password: this.getCookie("pass"),
    });
  }
  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleLogin(event) {
    event.preventDefault();
    const { email, password } = this.state;
    this.setState({
      failed: false,
      loading: true,
      loginErrorMessage: "",
    });
    let fetchUrl = checkedDevelopmentType();

    instance({
      url: `/api/session`,
      method: "POST",
      data: new URLSearchParams({
        email: email,
        password: password,
        app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
        host: fetchUrl,
      }),
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((userInfoFront) => {
        // fetch call for Api  privileges
        let lang = userInfoFront.attributes.lang;
        this.props.setActiveLanguage(lang || "en");

        instance({
          url: `/api/privileges`,
          method: "GET",
          params: {
            roleId: userInfoFront.roleId,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((privileges) => {
            let privilegeKeys = [];
            privileges.map((item) => {
              privilegeKeys.push(item.key);
              return null;
            });
            localStorage.setItem(
              "frontPrivileges",
              JSON.stringify({ ...privilegeKeys })
            );
            if (privileges.length) {
              this.props.dispatch(logInUserInfo(userInfoFront));
              checkPrivileges("device") &&
                this.props.dispatch(fetchDevicesList(userInfoFront));
              checkPrivileges("device") &&
                this.props.dispatch(fetchDevices(userInfoFront));

              if (userInfoFront.userType === -1) {
                instance({
                  url: `/api/roles`,
                  method: "GET",
                  params: {
                    all: true,
                  },
                })
                  // .then(response => {
                  //   if (response.ok) {
                  //     response.json()
                  .then((role) => {
                    this.props.dispatch(getRoles(role));
                    //   })
                    // }
                    // else{
                    //   throw response
                    // }
                  })
                  .catch((error) => {
                    // errorHandler(error, this.props.dispatch)
                  });
              } else if (
                userInfoFront.userType !== 1 &&
                checkPrivileges("role")
              ) {
                instance({
                  url: `/api/roles`,
                  method: "GET",
                  params: {
                    userId: userInfoFront.id,
                  },
                })
                  // .then(response => {
                  //     if (response.ok) {
                  //       response.json()
                  .then((role) => {
                    this.props.dispatch(getRoles(role));
                    //   })
                    // }
                    // else{
                    //   throw response
                    // }
                  })
                  .catch((error) => {
                    // errorHandler(error, this.props.dispatch)
                  });
              }

              instance({
                url: `/api/accounts`,
                method: "GET",
                params: {
                  accountId: userInfoFront.accountId,
                },
              })
                // .then(response => {
                //   if (response.ok) {
                //     response.json()
                .then((server) => {
                  if (server[0]) {
                    //implatation of whitelabeling to replace data
                    this.props.dispatch(ServerInfo(server[0]));
                    this.props.dispatch(
                      getLayer({
                        id:
                          userInfoFront.map ||
                          (server && server[0].map) ||
                          "osm",
                      })
                    );
                    if (this.props.location && this.props.location.state) {
                      this.props.history.push("/dashboard");
                    } else {
                      this.props.history.push("/");
                    }
                  } else {
                    this.someThingWendWrong("Account Data Not Found");
                  }
                })
                //   } else {
                //     throw response
                //   }
                // })
                .catch((error) => {
                  // errorHandler(error, this.props.dispatch, this.errorCallBack)
                  this.setState({
                    failed: true,
                    password: "",
                    loading: false,
                    loginErrorMessage: displayErrorMessages(error.message),
                  });
                });
            } else {
              this.setState({
                failed: true,
                password: "",
                loading: false,
                loginErrorMessage: "Lack Of Privileges",
              });
            }
          })
          //   } else {
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch, this.errorCallBack)
            this.setState({
              failed: true,
              password: "",
              loading: false,
              loginErrorMessage: displayErrorMessages(error.message),
            });
          });
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        this.setState({loading:false})
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
  }
  errorMessage = (e) => {
    if (e && e.text) {
      e.text().then((err) => {
        this.setState({
          failed: true,
          password: "",
          loading: false,
          loginErrorMessage: displayErrorMessages(err),
        });
      });
    }
  };
  handleClick = (e) => {
    let { email, password } = this.state;
    if (e.target.checked && email && password) {
      this.setCookie("userName", email);
      this.setCookie("pass", password);
    } else {
      this.setCookie("userName", "");
      this.setCookie("pass", "");
    }
  };
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  setCookie(name, value, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      name + "= " + encodeURIComponent(value) + ";" + expires + ";path=/login;";
  }
  someThingWendWrong = (mgs) => {
    this.setState({
      failed: true,
      password: "",
      loading: false,
      loginErrorMessage: this.props.translate(mgs),
    });
  };
  render() {
    if (this.state.loading) {
      return (
        <div className="loginLoaderWrapper">
          <Loader />
        </div>
      );
    } else {
      let e = this.props.translate("userEmail") + " *";
      return (
        <form
          className="loginForm"
          onSubmit={this.handleLogin}
          autoComplete="off"
        >
          <div className="login-logo-wrapper">
            <img
              className="login-logo"
              src={this.props.whiteLabling&&this.props.whiteLabling.logo}
              alt="logo"
            />
          </div>
          {this.state.failed && (
            <div
              className="alert alert-danger"
              style={{
                color: this.props.themecolors["error"],
              }}
            >
              <svg fill="currentColor" width={16} height={16}>
                <use xlinkHref={`${InfoIcon}#icon`} />
              </svg>
              {this.props.translate(this.state.loginErrorMessage)}
            </div>
          )}
          <div className="form-group">
            <TextField
              themecolors={this.props.themecolors}
              id="email"
              variant="outlined"
              label={e}
              type="text"
              value={this.state.email || ""}
              onChange={this.handleChange}
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="form-group">
            <TextField
              themecolors={this.props.themecolors}
              id="password"
              variant="outlined"
              label={this.props.translate("userPassword") + " *"}
              type="password"
              value={this.state.password || ""}
              onChange={this.handleChange}
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              style={{
                color: this.props.themecolors.textColor,
                marginRight: "auto",
              }}
            >
              <Checkbox
                disableRipple
                onChange={(event) => this.handleClick(event)}
                label={this.props.translate("rememberme")}
              />
            </div>
            <NavLink
              to={"/login"}
              onClick={(e) => this.props.switchView("forgot")}
            >
              {this.props.translate("forgotPassword")}
            </NavLink>
          </div>
          <p style={{ textAlign: "center" }}>
            <Button
              themecolors={this.props.themecolors}
              type="submit"
              className="btn-submit"
              style={{background:"#ec1c24",color:"#fff"}}
            >
              {this.props.translate("loginLogin")}
            </Button>
          </p>

          {this.props.logInUser && this.props.logInUser.id ? (
            <SocketController />
          ) : null}
          <div className="login-footer">
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate("about")}
            </a>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate("termsAndConditions")}
            </a>
            <a
              href={
                this.props.whiteLabling &&
                this.props.whiteLabling.privacyPolicyLink
              }
            >
              {this.props.translate("privacy")}
            </a>
          </div>
        </form>
      );
    }
  }
}

const mapStateToProps = connect(mapState);

export const LoginForm = mapStateToProps(withLocalize(LoginForm1));

class ForgotForm1 extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", errorMessage: "", loading: false };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    this.setState(
      {
        loading: true,
        errorMessage: "",
      },
      () => {
        instance({
          url: `/api/password/forget`,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
          data: `email= ${email}`,
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((res) => {
            // if (res.status === 'success') {
            this.setState({
              loading: false,
              errorMessage: this.props.translate("emailSentSuccessfully"),
            });

            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("emailSentSuccessfully"),
                autoDismiss: 10,
              })
            );
            // } else {
            //   this.setState({
            //     loading: false,
            //     errorMessage: this.props.translate('invalidEmailAddress')
            //   })
            // }
            //   })
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch, this.errorCallBack);
          });
      }
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  render() {
    console.log('all props ====', this.props)
    console.log('all props ====', this.state)
    let { loading, errorMessage } = this.state;
    if (loading) {
      return (
        <div className="loginLoaderWrapper">
          <Loader />
        </div>
      );
    } else {
      return (
        <form
          className="loginForm"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Style>
            {`
            a {
              color: ${this.props.themecolors.textColor}
            }
             
            .login-footer {
              border-top: 1px solid ${this.props.themecolors.themeLightColor};
            }
            `}
          </Style>
          <div className="login-logo-wrapper">
            <img
              className="login-logo"
              src={"./../../assets/images/common/logo.png"}

              alt="logo"
            />
          </div>
          <p
            style={{
              marginTop: 0,
              marginBottom: 30,
              fontSize: 12,
              color: this.props.themecolors.textColor,
            }}
          >
            {this.props.translate("resetPasswordMessage")}
          </p>
          {errorMessage && (
            <div
              className="alert alert-danger"
              style={{
                color:
                  errorMessage === "Email sent successfully"
                    ? this.props.themecolors["success"]
                    : this.props.themecolors["error"],
                background: this.props.themecolors.textColor,
              }}
            >
              <svg fill="currentColor" width={16} height={16}>
                <use xlinkHref={`${InfoIcon}#icon`} />
              </svg>
              {errorMessage}
            </div>
          )}
          <TextField
            themecolors={this.props.themecolors}
            id="email"
            label={this.props.translate("userEmail") + " *"}
            type="text"
            value={this.state.email || ""}
            onChange={this.handleChange}
            margin="dense"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <p style={{ textAlign: "center", marginTop: 0 }}>
            <Button
              themecolors={this.props.themecolors}
              type="submit"
              className="btn-submit"
            >
              {this.props.translate("submit")}
            </Button>
          </p>
          <p style={{ textAlign: "center" }}>
            <NavLink
              to={"/login"}
              onClick={(e) => this.props.switchView("login")}
            >
              {this.props.translate("loginTitle")}
            </NavLink>
          </p>

          <div className="login-footer">
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate("about")}
            </a>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate("termsAndConditions")}
            </a>
            <a
              href={
                this.props.whiteLabling &&
                this.props.whiteLabling.privacyPolicyLink
              }
            >
              {this.props.translate("privacy")}
            </a>
          </div>
        </form>
      );
    }
  }
}

export const ForgotForm = mapStateToProps(withLocalize(ForgotForm1));
