  import React, { Component, Fragment } from "react";
  import SearchField from "../../../Components/common/SearchField";
  import { connect } from "react-redux";
  import { withLocalize } from "react-localize-redux";
  import isEqual from "react-fast-compare";
  import Icon from "@material-ui/core/Icon";
  import AddIcon from "@material-ui/icons/Add";
  import DeleteIcon from "@material-ui/icons/Delete";
  import { checkPrivileges, errorHandler } from "../../../Helpers";
  import ConfirmDialoag from "../../../Components/common/ConfirmDialoag";
  import Style from "style-it";
  import Scrollbar from "react-scrollbars-custom";
  import InfiniteScroll from "react-infinite-scroll-component";
  import Loader from "../../Loader";
  import { removedNotification } from "../../../Actions/Notifications";
  import { ReactComponent as NotificationOff } from "../../../assets/notification-off.svg";
  import { ReactComponent as NotificationOn } from "../../../assets/notification-on.svg";
  import Notifications from "react-notification-system-redux";
  import ArrowBackIcon from "@material-ui/icons/ArrowBack";
  import Tooltip from "@material-ui/core/Tooltip";
  import IconButton from "@material-ui/core/IconButton";
  import instance from "../../../axios";
  import Modal from "../../../Components/Notifications/Wizard/Screen1/modal";
  class Notification extends Component {
    constructor(props) {
      super(props);
      this.state = { searchRecently: "" };
    }

    search(e) {
      this.props.searchItems(e.target.value);
    }

    render() {
      const { notification } = this.props;
  let notificationList;
  if(notification.length){
    notificationList = notification
  }else{
    notificationList = notification.data
  }
      const notification1 = notificationList && notificationList.map((g) => (
        <ListRow
          key={g.id}
          item={g}
          editNotificationForm={this.props.editNotificationForm}
          updateNotification={this.props.updateNotification}
        />
      ));

      return (
        <Style>
          {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
          <div className="fms-sidebar sidebar-bg-layer">
            {checkPrivileges("notification") && (
              <div>
                <div className="section-head section-head-filter">
                  <ul className="filter-row">
                    <li style={{ flex: 0.01, marginTop:5 }}>
                      <IconButton
                        color="inherit"
                        onClick={(e) => this.props.history.push("/events")}
                        style={{ padding: 0 }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </li>
                    <li style={{margin:"0 5px"}}>
                      <SearchField
                        label={this.props.translate("searchNotification")}
                        fullWidth
                        onChange={(e) => this.search(e)}
                      />
                    </li>
                    <li
                      style={{
                        paddingTop: 8,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        flex: 0.01,
                        lineHeight:1.5
                      }}
                    >
                      {checkPrivileges("notificationCreate") && (
                        <div style={{display:"flex",alignItems:"center", border:"1px solid",borderColor:this.props.themecolors.menuActiveBackground, borderRadius:8, cursor:"pointer"}} 
                            onClick={(e) => this.props.openCreateFrom()} >
                                <span style={{background:this.props.themecolors.menuActiveBackground, borderRadius: "4px 0px 0px 4px",padding:"3px 8px", display:"flex", flexDirection:"column", color:"#ffffff", fontWeight:"500"}}>+</span>
                                <span style={{color:'#141313', fontSize:12, padding:"0 12px", fontWeight:"500"}}>{this.props.translate("sharedCreate")}</span>
                          </div>
                      )}
                    </li>
                  </ul>
                </div>
                  
                {notificationList && notificationList.length ? <>
                  <ul className="list-view with-padding-right sidebar-devices-list">
                  <Scrollbar
                    disableTracksWidthCompensation
                    scrollerProps={{
                      renderer: (props) => {
                        const { elementRef, ...restProps } = props;
                        return (
                          <div
                            {...restProps}
                            ref={elementRef}
                            id="scrollableDiv"
                          />
                        );
                      },
                    }}
                  >
                    {this.props.itemPagination ? (
                      <InfiniteScroll
                        dataLength={this.props.itemPagination.items.length}
                        next={this.props.fetchMoreItems}
                        hasMore={this.props.itemPagination.hasNext}
                        loader={<Loader defaultStyle={true} imgSize={50} />}
                        scrollableTarget="scrollableDiv"
                        endMessage={this.props.endMessage}
                      >
                        {notification1}
                      </InfiniteScroll>
                    ) : null}
                  </Scrollbar>
                  </ul></>
                  : <><p style={{textAlign:"center"}}>Not Found</p> </>
                }
              </div>
            )}
          </div>
        </Style>
      );
    }
  }

  class ListRow1 extends Component {
    constructor(props) {
      super(props);
      this.state = {
        onDeleteConfirmation: false,
      };
    }

    onDelete = () => {
      if (this.props.item.id) {
        instance({
          url: `api/notifications/${this.props.item.id}`,
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            // if (response.ok) {
            this.props.dispatch(removedNotification({ id: this.props.item.id }));
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("notificationIsDeleted"),
                autoDismiss: 10,
              })
            );
            this.setState({ item: {} });
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch);
          });
      }
    };

    shouldComponentUpdate(nextProps, nextState) {
      return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    onRemovedItem = () => {
      this.setState({
        onDeleteConfirmation: true,
      });
    };
    onCancel = () => {
      this.setState({
        onDeleteConfirmation: false,
      });
    };

    render() {
      return (
        <li className={"list-row clearfix"} key={this.props.item.id}
        onClick={
          checkPrivileges("notificationUpdate")
            ? (e) => this.props.editNotificationForm(this.props.item)
            : null
        }
        
        >
          {checkPrivileges("notificationUpdate") && (
            <label
              className="checkbox"
            >
              <span className="unit-name">{this.props.item.name}</span>
            </label>
          )}
          <div className="callout-actions">
            {checkPrivileges("notificationDelete") && (
              <span className="action" onClick={this.onRemovedItem}>
                <DeleteIcon className="material-icons" />
              </span>
            )}
            {checkPrivileges("notificationUpdate") && (
              <Tooltip
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
                title={
                  this.props.item.attributes.suspend
                    ? "Click here to turn on notification"
                    : "Click here to turn off notification"
                }
              >
                <span
                  className="action"
                  onClick={(e) => this.props.updateNotification(this.props.item)}
                >
                  <Icon className="material-icons">
                    {this.props.item.attributes.suspend ? (
                      <NotificationOff />
                    ) : (
                      <NotificationOn />
                    )}
                  </Icon>
                </span>
              </Tooltip>
            )}
          </div>

          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.onDelete}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.item.name}
            />
          )}
        </li>
      );
    }
  }

  const mapState = (state) => ({
    themecolors: state.themeColors,
  });

  const mapStateToProps = connect(mapState);
  export const ListRow = mapStateToProps(withLocalize(ListRow1));
  export default mapStateToProps(Notification);
