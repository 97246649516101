import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import moment from "moment";
import { withLocalize } from "react-localize-redux";
import Layout from "./../../Layout";
import Loader from "./../../Layout/Loader";
import Tabs from "./../../Components/common/Tabs";
import EmptyState from "./../../Components/common/EmptyState";
import { removeTemplate } from "./../../Actions/Templates";
import { withSnackbar } from "notistack";
import { checkPrivileges, errorHandler } from "../../Helpers";
import Notifications from "react-notification-system-redux";
import instance from "../../axios";
const reportTypes = [
  { id: "tripssummary", label: "Trips", type: "reportTrip" },
  { id: "stopssummary", label: "Stops", type: "reportStop" },
  { id: "eventssummary", label: "Events", type: "reportEvent" },
  { id: "enginehourssummary", label: "Engine Hours", type: "reportEngineHour" },
  { id: "geosummary", label: "Geofence", type: "reportGeo" },
  { id: "fuelsummary", label: "Fuel", type: "reportFuel" },
];

const itemGroupTypes = {
  device: "Device",
  driver: "Driver",
  vehicle: "Vehicle",
};

function checkReportTypes() {
  let array = reportTypes;
  array.map((item, index) => {
    if (!checkPrivileges(item.type)) {
      array.splice(index);
    }
    return null;
  });
}
let columns = [];
let source;
const ReportData = {};

class Reports extends Component {
  constructor(props) {
    super(props);
    checkReportTypes();
    this.state = {
      disableSubmit:true,
      templatesCall: false,
      view: "displayForm",
      from: "",
      to: "",
      selectedDateLabel: "Choose Date",
      selectedDate: "",
      reportType: 0,
      reportType1: 0,
      deviceId: [],
      vehicleId: [],
      groupId: [],
      driverId: [],
      geofenceId: [],
      sensorId: [],
      sensorValues: [{ input: "", index: "", value: "" }],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      groupView: 1,
      reportUnits: "units",
      notificationIds: [],
      types: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      processInvalidPositions: false,
      useIgnition: false,
      controlDays: [1, 2, 3, 4, 5, 6, 7],
      controlTimeStart: "00:00",
      controlTimeEnd: "23:59",
      controlTime: [0, 1439],
      geoTimeThreshold: 1,
      isSensorTracing: false,
      timeError: false,
      isSubmit: false,
      errorMsg: null,
      errorStep: 0,
      showReportData:false,
      tripsColumns: {
        averageSpeed: { checked: true },
        distance: { checked: false },
        driverName: { checked: false },
        driverUniqueId: { checked: false },
        duration: { checked: true },
        maxSpeed: { checked: true },
        spentFuel: { checked: false },
        endAddress: { checked: true },
        endOdometer: { checked: false },
        endPositionId: { checked: false },
        endLat: { checked: false },
        endLon: { checked: false },
        startAddress: { checked: true },
        startOdometer: { checked: false },
        startPositionId: { checked: false },
        startLat: { checked: false },
        startLon: { checked: false },
        endTime: { checked: false },
        startTime: { checked: false },
      },
      reportDevices: [],
      advanceSettingsForm: {
        // minimalNoDataDuration: 0,
        // minimalParkingDuration: 0,
        // minimalTripDuration: 0,
        // minimalTripDistance: 0,
        // speedThreshold: 0.01,
        // advanceSettings: false
        // processInvalidPositions: false,
        // useIgnition: true
      },
      allowFields: [],
      eventType: "notification",
      steps: [{ id: 1 }, { id: 2 }],
      activeStep: 0,
      allSensors: false,
      selectedReportsUnits:[]
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.selectedDevices = this.selectedDevices.bind(this);
    this.selectedGroups = this.selectedGroups.bind(this);
    this.reportTypeChange = this.reportTypeChange.bind(this);
    this.advanceFormChange = this.advanceFormChange.bind(this);
    this.childRendered = this.childRendered.bind(this);
    this.saveData = this.saveData.bind(this);
    this.reportDetail = this.reportDetail.bind(this);
    this.reportTypeSelection = this.reportTypeSelection.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  switchGroupView = (type) => {
    this.setState({
      groupView: type,
      groupId: [],
      vehicleId: [],
      deviceId: [],
      driverId: [],
    });
  };

  advanceFormChange(event, name) {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      advanceSettingsForm: { ...this.state.advanceSettingsForm, [name]: value },
    });
  }

  handleChange = (name, event) => {
    let v = event.target.value;
    if (name === "geoTimeThreshold")
      v = event.target.value < 0 ? 0 : event.target.value;
    this.setState({
      [name]: v,
    });
  };

  reportTypeSelection(type) {
    this.setState({ reportUnits: "" }, () => {
      this.setState({
        reportUnits: type,
        vehicleId: [],
        deviceId: [],
        groupId: [],
        driverId: [],
      });
    });
  }

  onCloseModal(e, name) {
    this.setState({ [name]: false });
  }

  saveData =(blob, filename)=> {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.setState({ loading: false });
      }, 0);
    }
    this.setState({ loading: false });
  }

  calcTime = (date, offset) => {
    let d = new Date(date);

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);

    return nd.toISOString();
  };

  createReport =(type)=> {
    let fileName = "download";
    const duration = moment.duration(
      moment(this.state.to).diff(this.state.from)
    );
    const hours = duration.asHours();
    this.setState({
      isSubmit: true,
    });
    if (!(this.state.isSensorTracing && hours >= 24)) {
      let header;
      let { reportType } = this.state;

      this.setState({
        detail1:
          reportType === 1 ||
          reportType === 2 ||
          reportType === 3 ||
          reportType === 4 ||
          reportType === 5 ||
          reportType === 6 ||
          reportType === 13
            ? true
            : false,
      });

      let form = {
        rpTmplId: this.state.reportType,
        to: this.state.to,
        from: this.state.from,
        controlDays: this.state.controlDays,
        controlTimeStart: moment()
          .startOf("day")
          .add(this.state.controlTime[0], "minutes")
          .format("HH:mm"),
        controlTimeEnd: moment()
          .startOf("day")
          .add(this.state.controlTime[1], "minutes")
          .format("HH:mm"),
        deviceIds: this.state.deviceId || [],
        vehicleIds: this.state.vehicleId || [],
        itemGroupIds: this.state.groupId || [],
        types: this.state.types,
        itemGroupType:
          this.state.groupView === 2
            ? itemGroupTypes[this.state.reportUnits]
            : "",
        notificationIds: this.state.notificationIds,
        geofenceIds: this.state.geofenceId || [],
        sensors: this.state.sensorId || [],
        detail: false,
        driverIds: this.state.driverId || [],
        mail: false,
        geoTimeThreshold: (this.state.geoTimeThreshold || 0) * 60000,
      };

      let temp = "";

      this.props.templates.map((t) => {
        if (form.rpTmplId === t.rpTmpl.id) {
          temp = t.rpTmpl.name;
        }
        return null;
      });
      let accept = "application/pdf";
      let fileName = "report";
      if (type === "downloadPdf" || type === "downloadExcel") {
        if (type === "downloadPdf") {
          accept = "application/pdf";
        } else {
          accept =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        }
        // if (type === 'downloadPdf') {
        // header = {
        //   headers: new Headers({
        //     'Content-Type': 'application/json',
        //     Accept:
        //       'application/pdf'
        //   }),
        //   method: 'POST',
        //   responseType: 'blob',
        //   data: {
        //     ...form
        //   }
        // }
        header = {
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: accept,
          }),
          method: "POST",
          body: JSON.stringify(form),
        };

        this.setState({ loading: true, reportsData: {} }, () => {
          fetch("/api/reports/dynamic", { ...header })
            .then((response1) => {
              if (response1.status === 200) {
                response1.blob().then((response) => {
                  if (type === "downloadPdf") {
                    this.saveData(
                      response,
                      (temp || this.state.reportType) + "-reports.pdf"
                    );
                  } else {
                    this.saveData(
                      response,
                      (temp || this.state.reportType) + "-reports.xlsx"
                    );
                  }
                  this.setState({ loading: false, detail: this.state.detail1 });
                });
              } else {
                throw response1;
              }
            })
            // .then(url => {
            // this.setState({ loading: false, detail: this.state.detail1 })
            // })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
              if (error && error.text) {
                error.text().then((err) => {
                  let message = err.split("-")[0];
                  if (message) {
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(message),
                        autoDismiss: 10,
                      })
                    );
                  }
                });
              }
              this.setState({ loading: false });
            });
        });
      }
      //  else if (type === 'downloadExcel') {
      // header = {
      //   headers: new Headers({
      //     'Content-Type': 'application/json',
      //     Accept:
      //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      //   }),
      //   method: 'POST',
      //   body: JSON.stringify(form)
      // }

      //   this.setState({ loading: true, reportsData: {} }, () => {
      //     // fecxtch('/api/reports/dynamic', { ...header })
      //     instance({
      //       url: `/api/reports/dynamic`,
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         Accept:
      //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      //       },
      //       responseType: 'blob',
      //       data: {
      //         ...form
      //       }
      //     })
      //       // .then(response => response.blob())
      //       .then(response =>
      //         this.saveData(
      //           response,
      //           (temp || this.state.reportType) + '-reports.xlsx'
      //         )
      //       )
      //       .then(url => {
      //         this.setState({ loading: false, detail: this.state.detail1 })
      //       })
      //       .catch(error => {errorHandler(error,this.props.dispatch)})
      //   })
      // }
      else if (type === "email") {
        form.mail = true;

        // header = {
        //   headers: new Headers({
        //     "Content-Type": "application/json",
        //     Accept:
        //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //   }),
        //   method: "POST",
        //   body: JSON.stringify(form),
        // };

        this.setState({ loading: true, reportsData: {} }, () => {
          // instance({
          //   url: `/api/reports/dynamic`,
          //   method: "POST",
          //   headers: {
          //     Accept: "application/json",
          //     "Content-Type": "application/json",
          //   },
          //   data: {
          //     ...form,
          //   },
          // })
          fetch("/api/reports/dynamic", { ...header })
            .then((response) => {
              this.setState({ loading: false });
              if (response.ok) {
                this.setState({ detail: this.state.detail1 });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate("emailSentSuccessfully"),
                    autoDismiss: 10,
                  })
                );
              } else {
                throw response;
              }
            })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
              if (error && error.text) {
                error.text().then((err) => {
                  let message = err.split("-")[0];
                  if (message) {
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(message),
                        autoDismiss: 10,
                      })
                    );
                  }
                });
              }
              this.setState({ loading: false });
            });
        });
      } else {
        // header = {
        //   headers: new Headers({
        //     'Content-Type': 'application/json',
        //     Accept: 'application/json'
        //   }),
        //   method: 'POST',
        //   body: JSON.stringify(form)
        // }
        this.setState(
          { loading: true, reportsData: {}, reportType1: "", detail: false },
          () => {
            instance({
              url: `/api/reports/dynamic`,
              method: "POST",
              data: {
                ...form,
              },
            })
 
              .then((reports) => {
                if (reports.success) {
                  this.setState({
                    reportsData: reports.report,
                    reportType1: this.state.reportType,
                    detail: this.state.detail1,
                    loading:false,
                    // to:"",
                    // from:"",
                    // reportType:"",
                    // deviceId:[],
                    controlDays: [1, 2, 3, 4, 5, 6, 7],
                    // selectedDateLabel: "Choose Date",
                    // selectedDate: "",
                    showReportData:true,
                    // disableSubmit:true
                  })
                } 
              })
              .catch((error) => {
                errorHandler(error, this.props.dispatch);
                this.setState({ loading: false });
              });
          }
        );
      }
    } else {
      this.validatedCurrentStep(1, "", -1);
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("sensorTracingLimitError"),
          autoDismiss: 10,
        })
      );
      this.setState({
        timeError: true,
      });
    }
  }

  showDates = (date) => {
    const duration = moment.duration(
      moment(this.state.to).diff(this.state.from)
    );
    const hours = duration.asHours();
    if (this.state.isSubmit && this.state.isSensorTracing && hours >= 24) {
      this.setState({
        timeError: true,
      });
    } else {
      this.setState({
        timeError: false,
      });
    }
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
    };

    let timeFormat = "HH:mm";

    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }

    this.validatedStep(1);
    this.setState(
      {
        selectedDateLabel: date.chosenLabel,
        selectedDate:
          moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
          " - " +
          moment(date.endDate).format("YYYY-MM-DD " + timeFormat),
        ...formatedDate,
        from:formatedDate.from,
        to:formatedDate.to,

      },
      () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.reportType &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
        this.checkReqFields()
      }
    );
  };

  onDismiss() {}

  selectedNotifications = (types) => {
    this.setState({ types }, () => {
      this.validatedStep(3);

      if (
        (this.state.groupId.length ||
          this.state.vehicleId.length ||
          this.state.deviceId.length ||
          this.state.driverId.length) &&
        this.state.reportType &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  };

  selectedEvents = (notificationIds) => {
    this.setState({ notificationIds }, () => {
      this.validatedStep(3);
      if (
        (this.state.groupId.length ||
          this.state.vehicleId.length ||
          this.state.deviceId.length ||
          this.state.driverId.length) &&
        this.state.reportType &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  };

  selectedDevices(deviceId) {

    if (deviceId && deviceId.length) {
      this.setState({ deviceId }, () => {
        this.validatedStep(21);
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({selectedReportsUnits:deviceId, validatedForm: true },()=> this.checkReqFields());
        } else {
          this.setState({ selectedReportsUnits:deviceId,validatedForm: false },()=> this.checkReqFields());
        }
      });
    } else {
      this.setState({ deviceId: [] , selectedReportsUnits:[]},()=> this.checkReqFields());
    }
  }
  checkReqFields = ()=>{
    const {controlDays,from,to,selectedDate,deviceId, reportType, selectedReportsUnits} = this.state
    if((controlDays&&controlDays.length) && from && to && reportType && (selectedReportsUnits && selectedReportsUnits.length>0) ) this.setState({disableSubmit:false})
    else this.setState({disableSubmit:true})
  }

  validatedStep = (step) => {
    if (this.state.errorStep === step) {
      this.setState({
        errorStep: 0,
        errorMsg: null,
      });
    }
  };

  selectedGroups(groupId) {
    if (groupId && groupId.length) {
      this.setState({ groupId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      });
    } else {
      this.setState({ groupId: [] });
    }
  }

  selectedVehicles = (vehicleId) => {
    if (vehicleId && vehicleId.length) {
      this.setState({ vehicleId }, () => {
        this.validatedStep(21);
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      });
    } else {
      this.setState({ vehicleId: [] });
    }
  };

  selectedDrivers = (driverId) => {
    if (driverId && driverId.length) {
      this.setState({ driverId }, () => {
        this.validatedStep(21);
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      });
    } else {
      this.setState({ driverId: [] });
    }
  };

  selectedGeofences = (geofenceId) => {
    if (geofenceId && geofenceId.length) {
      this.setState({ geofenceId }, () => {
        this.validatedStep(4);
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      });
    } else {
      this.setState({ geofenceId: [] });
    }
  };

  selectedSensors = (sensorId) => {
    if (sensorId && sensorId.length) {
      this.validatedStep(5);
      if (
        sensorId.length === 1 &&
        sensorId[0] &&
        sensorId[0].value === "allSensors"
      ) {
        this.setState({ allSensors: true });
      } else {
        this.setState({ allSensors: false });
      }
      this.setState(
        { sensorId: sensorId.map((e) => e.value), sensorValues: sensorId },
        () => {
          if (
            (this.state.groupId.length ||
              this.state.vehicleId.length ||
              this.state.deviceId.length ||
              this.state.driverId.length) &&
            this.state.selectedDate
          ) {
            this.setState({ validatedForm: true });
          } else {
            this.setState({ validatedForm: false });
          }
        }
      );
    } else {
      this.setState({ sensorId: [] });
    }
  };

  reportTypeChange(value) {
    // console.log("===, value", this.state.selectedReportsUnits);
    let tpl = this.props.templates.find(
      (temp) => temp.rpTmpl.id === value.rpTmpl.id
    );
    let allowFields = [];
     if (tpl &&
      tpl.rpTmpl &&
      tpl.rpTmpl.attributes &&
      tpl.rpTmpl.attributes.allowFields
    ) {
      allowFields = tpl.rpTmpl.attributes.allowFields;
    }

    this.validatedStep(2);

    this.setState(
      { 
        selectedReportsUnits:[],
        reportType: value&&value.rpTmpl&&value.rpTmpl.id,
        reportUnits: allowFields[0],
        allowFields,
        eventType: allowFields.includes(this.state.eventType)
          ? this.state.eventType
          : allowFields.includes("notification")
          ? "notification"
          : allowFields.includes("eventType")
          ? "eventType"
          : "",
      },
      () => {
        let currentSteps = this.state.steps.filter((step) => step.id < 3);
        if (
          allowFields.some((r) => ["eventType", "notification"].includes(r))
        ) {
          // add notification step
          currentSteps.push({ id: 3, title: "selectEventOrNotification" });
        } else if (allowFields.some((r) => ["geofence"].includes(r))) {
          // add notification step
          currentSteps.push({ id: 4, title: "selectGeofence" });
        } else if (
          allowFields.some((r) => ["sensor", "digitalSensor"].includes(r))
        ) {
          // add notification step
          currentSteps.push({ id: 5, title: "selectSensor" });
        }

        this.reportTypeSelection(this.state.reportUnits);
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.reportType &&
          this.state.selectedDate
        ) {
          this.setState({selectedReportsUnits:[], validatedForm: true, steps: currentSteps });
        } else {
          this.setState({ selectedReportsUnits:[], validatedForm: false, steps: currentSteps });
        }
        this.checkReqFields()
      }
    );
    tpl&&tpl.rpTmplTblWrappers&&tpl.rpTmplTblWrappers.map((i) => {
      if (i.rpTmplTbl.rpId === 8) {
        this.setState({
          isSensorTracing: true,
        });
      } else {
        this.setState({
          isSensorTracing: false,
        });
      }
      return null;
    });
  }

  reportDetail(checked) {
    this.setState({ detail1: checked });
  }

  handleChangeType = (eventType) => {
    this.setState(
      {
        eventType,
      },
      () => {
        this.selectedNotifications([]);
        this.selectedEvents([]);
      }
    );
  };

  childRendered() {
    if (this.state.loading === true && this.state.reportsData.head) {
      this.setState({ loading: false });
    }
  }

  deleteTemplate = (id) => {
    // fetch('/api/report/templates/' + id, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/report/templates/${id}`,
      method: "DELETE",
    })
      .then((response) => {
        // if (response.ok) {
        this.props.dispatch(removeTemplate({ id }));
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  onDayChange = (checked, obj) => {
    let dayArry = [];
    if (checked) {
      dayArry = (this.state.controlDays || []).concat(parseInt(obj.id));
    } else {
      dayArry = (this.state.controlDays || []).filter(
        (day) => day !== parseInt(obj.id)
      );
    }
    this.setState({
      controlDays: dayArry,
    }, ()=>{this.checkReqFields()});
  };

  handleTimeControl = (event, controlTime) => {
    this.setState({ controlTime });
  };

  goToNextStep = (type) => {
    this.validatedCurrentStep(type);
  };
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
    this.setState({ activeStep: currentStep - 1 });
  };

  validatedCurrentStep = (type) => {
    let validated = true;
    let errorMsg = null;
    let errorStep = 1;
    // check datetime
    if (!this.state.from && !this.state.to) {
      validated = false;
      errorStep = 1;
      errorMsg = (
        <small className="text-danger" style={{color:this.props.themecolors.textColor}}>
          Please select{" "}
          <strong>'{this.props.translate("selectReportTimeControl")}'</strong>
        </small>
      );
    }
    // check template and unit, group or staff selected
    else if (!this.state.reportType) {
      validated = false;
      errorMsg = (
        <small className="text-danger" style={{color:this.props.themecolors.textColor}}>
          Please select a{" "}
          <strong>'{this.props.translate("selectReportTemplate")}'</strong>
        </small>
      );
      errorStep = 2;
    } else if (
      this.state.allowFields.some((r) =>
        ["device", "driver", "vehicle"].includes(r)
      ) &&
      !this.state.groupId.length &&
      !this.state.deviceId.length &&
      !this.state.driverId.length &&
      !this.state.vehicleId.length
    ) {
      validated = false;
      errorMsg = (
        <small className="text-danger">
          Please must select anything from the following{" "}
          <strong>list section</strong>
        </small>
      );
      errorStep = 21;
    } else if (
      this.state.allowFields.some((r) =>
        ["eventType", "notification"].includes(r)
      ) &&
      !this.state.notificationIds.length &&
      !this.state.types.length
    ) {
      validated = false;
      errorMsg = (
        <small className="text-danger">
          Please must select anything from the following{" "}
          <strong>list section</strong>
        </small>
      );
      errorStep = 3;
    } else if (
      this.state.allowFields.includes("geofence") &&
      !this.state.geofenceId.length
    ) {
      validated = false;
      errorMsg = (
        <small className="text-danger">
          Please select a <strong>geofence</strong> from the following{" "}
          <strong>list section</strong>
        </small>
      );
      errorStep = 4;
    } else if (
      (this.state.allowFields.includes("sensor") ||
        this.state.allowFields.includes("digitalSensor")) &&
      !this.state.sensorId.length
    ) {
      // allow fields containing sensor, types make sure select any of.
      validated = false;
      errorMsg = (
        <small className="text-danger">
          Please select a <strong>sensor</strong> from the following{" "}
          <strong>list section</strong>
        </small>
      );
      errorStep = 5;
    }

    this.setState({ errorMsg, errorStep });

    if (validated === true) {
      this.createReport(type);
    }
  };

  render() {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }

    return (
      <div>
        <Layout
          {...this.props}
          {...this.state}
          themecolors={this.props.themecolors}
          switchGroupView={this.switchGroupView}
          handleChange={this.handleChange}
          goToNextStep={this.goToNextStep}
          goToBackStep={this.goToBackStep}
          deleteTemplate={this.deleteTemplate}
          handleChangeType={this.handleChangeType}
          reportTypeChange={this.reportTypeChange}
          createReport={this.createReport}
          advanceSettings={this.state.advanceSettingsForm.advanceSettings}
          advanceSettingsForm={this.state.advanceSettingsForm}
          advanceFormChange={this.advanceFormChange}
          reportTypeSelection={this.reportTypeSelection}
          showDates={this.showDates}
          selectedDevices={this.selectedDevices}
          eventType={this.state.eventType}
          selectedNotifications={this.selectedNotifications}
          selectedEvents={this.selectedEvents}
          selectedGroups={this.selectedGroups}
          selectedDrivers={this.selectedDrivers}
          selectedVehicles={this.selectedVehicles}
          selectedGeofences={this.selectedGeofences}
          selectedSensors={this.selectedSensors}
          handleTimeControl={this.handleTimeControl}
          reportTypes={reportTypes}
          reportDetail={this.reportDetail}
          validatedForm={this.state.validatedForm}
          reportUnits={this.state.reportUnits}
          showReportData={this.state.showReportData}
          onDayChange={this.onDayChange}
        >
          {this.state.showReportData && (
            <div className="main-content-page">
            {!this.state.loading && !this.state.reportsData.head ? (
              <div
                style={{
                  margin: 16,
                  background: this.props.themecolors.backgroundColor,
                  borderRadius: 6,
                  color: this.props.themecolors.textColor,
                }}
              >
                <EmptyState text={this.props.translate("noReportSelected")} />
              </div>
            ) : null}
              <Tabs
              {...this.props}
              heading={this.state.reportsData.head}
              onRender={this.childRendered}
              tabs={this.state.reportsData.sheets}
              columns={columns}
              reportType={this.state.reportType1}
              detail={this.state.detail}
              themecolors={this.props.themecolors}
              serverTimeZone={serverTimeZoneName}
              fetching={this.state.loading}
              translate={this.props.translate}
            />
            <div
              style={{
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor,
              }}
            >
              {this.state.loading ? <Loader /> : null}
            </div>
          </div>
          )}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state.devices.data,
  logInUser: state.logInUsers,
  groups: state.groups,
  templates: state.templates,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
});

export default connect(mapStateToProps)(withSnackbar(withLocalize(Reports)));
