import React, { Component } from 'react'

import './Style.scss'

import { LoginForm, ForgotForm } from './Component'
import { connect } from 'react-redux'
import Style from 'style-it'
class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      view: 'login'
    }
  }
  switchView = view => {
    this.setState({ view })
  }
  render () {
    const { view } = this.state
    return (<React.Fragment>
          <Style>
          {`
          .theme-label.Mui-focused,
          .theme-label {
            margin-top: -2px;
            font-size: 14px;
            color: ${this.props.themecolors.textColor}
          }

          .text-field-helper-text {
            color: inherit;
          }
          
          .theme-label-shrink {
            margin-top: 0;
          }
          .theme-label-focused {
            margin-top: 0;
          }
          .theme-textarea {
            font-size: 14px;
            padding: 0
          }
          .theme-label-error {
            color: ${this.props.themecolors.error}
          }
          .theme-notchedOutline-multiline,
          .theme-notchedOutline {
            border-color:  ${this.props.themecolors.InputBorderColor} !important;
            border-radius: 4px;
          }
          .theme-input-error .theme-notchedOutline {
            border-color:  ${this.props.themecolors.error} !important;
          }
          .theme-cssFocused .theme-notchedOutline {
            border-color:  ${this.props.themecolors.InputBorderColor} !important;
          }
          .theme-input-select {
            border-color: ${this.props.themecolors.InputBorderColor};
            color: ${this.props.themecolors.textColor};
            padding: 0;
            display: flex;
            height: auto
          }
          .theme-textarea,
          .theme-input {
            border-color: ${this.props.themecolors.InputBorderColor};
            color: ${this.props.themecolors.textColor};
            font-size: 11px;
            height: 1em;
          }
          .custom-theme-label {
            font-size: 12px;
            display: block;
            margin-bottom: 5px;
          }
          .custom-theme-input:focus {
            outline: none
          }
          .custom-theme-input {
            color: ${this.props.themecolors.textColor};
            background: ${this.props.themecolors.backgroundColor};
            border-color: ${this.props.themecolors.themeLightColor};
            border-radius: 4px;
            font-size: 12px;
            padding: 5px 14px;
            font-family: inherit;
          }
          a {
            color: ${this.props.themecolors.textColor}
          }
          
          .loginLoaderWrapper {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
          .login-footer {
            border-top: 1px solid #7d8691;
          }
          `}
          
        </Style>
        <div className='loginWrapper'>
          <div className='loginWrapperInner'>
            {view === 'login' ? (
              <LoginForm {...this.props} switchView={this.switchView} />
              ) : null}
            {view === 'forgot' ? (
              <ForgotForm {...this.props} switchView={this.switchView} />
              ) : null}
          </div>
          {/* <div
            className='white-label-bg'
            style={{ backgroundImage: `url("${this.props.whiteLabling.logInPageImage}")` }}
            ></div> */}
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  themecolors: state.themeColors,
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(Login);