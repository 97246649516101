import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
class NotificationTypeSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      displayList: [],
      search: '',
      isChecked: false
    }
  }

  UNSAFE_componentWillMount () {
    let list = {}
    if (this.props.value && this.props.value.length) {
      this.props.value.map(d => (list[d] = true))
      this.setState({ list })
    }

    this.prepareNotifications(this.props)
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      displayList: [],
      isChecked: false
    })
  }
  UNSAFE_componentWillReceiveProps (n) {
    this.prepareNotifications(n)
  }

  prepareNotifications = n => {
    let displayList = n.notificationList.map(({type}) => ({type, label: n.translate('notification.'+type)}))
    this.setState({ displayList })
  }

  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list))
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.type] = true
    } else {
      delete list[v.type]
    }
    this.setState(
      {
        list,
        isChecked:
          this.props.notificationList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }

  searchItem = text => {
    this.setState({ search: text.target.value })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      this.props.notificationList.map(d => (list[d.type] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  render () {
    let list = this.state.displayList.filter(
      item => (item.type+item.label).toLowerCase().indexOf(this.state.search) !== -1
    )
    return (
      <Style>
        {`
          .ScrollbarsCustom-Wrapper {
            margin: 0 -15px;
          }`}
        <div>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, }}
          >
            <ul className='filter-row pull-left'>
              <li style={{width:300}}>
                <SearchField
                  label={this.props.translate('searchNotification')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3, paddingLeft: 6 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className='selector-count'>
              {' '}
              {Object.keys(this.state.list).length}{' '}
              {this.props.translate('NotificationSelected')}
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 32) *
                (this.props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDivNotification'
                  />
                )
              }
            }}
          >
            <ul className='list-view' style={{margin:'0 6px'}}>
              {list.map(row => (
                <li key={row.type} className={"list-row "+(this.state.list[row.type] ? 'active' : '')}>
                  <label className="unit-name">
                    {!this.props.readOnly ? (
                      <span style={{marginRight: 6}}>
                        <Checkbox
                          checked={this.state.list[row.type]}
                          value={row}
                          onChange={this.onChange}
                        />
                      </span>
                    ) : null}
                    <span className='unit-name-text'>{row.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  notificationList: state.notificationType,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(NotificationTypeSelector))
