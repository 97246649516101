import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { markerEnable, applyDevicesFilter, setTrackId, resetBounds, updateDeviceVisible } from './../../Actions/Devices'
import Icon from '@material-ui/core/Icon'
import './MapFilters.scss'
import sheet from './icons/sheet.svg'
import { Callout, CalloutPOI } from './../Features/Callout'
import OptionMenu from './MapOptionMenu'
import OptionGroupMenu from './MapOptionGroupMenu'
import { checkPrivileges } from '../../Helpers'
import Tooltip from '@material-ui/core/Tooltip'
import Style from 'style-it'
import PaginationMenu from './PaginationMenu'
import { ReactComponent as OverspeedIcon } from '../../assets/monitoring/menu/overspeed.svg'
import { ReactComponent as IdlingIcon } from '../../assets/monitoring/menu/idling.svg'
import { ReactComponent as StopIcon } from '../../assets/monitoring/menu/stop.svg'
import { ReactComponent as MovingIcon } from '../../assets/monitoring/menu/moving.svg'
import { ReactComponent as TowingIcon } from '../../assets/monitoring/menu/towing.svg'
import { ReactComponent as OnlineIcon } from '../../assets/monitoring/menu/online.svg'
import { ReactComponent as OfflineIcon } from '../../assets/monitoring/menu/offline.svg'
import { ReactComponent as ParkingIcon } from '../../assets/monitoring/menu/parking.svg'
import { ReactComponent as GeoIcon } from '../../assets/monitoring/menu/geo.svg'
import { ReactComponent as BatteryIcon } from '../../assets/monitoring/menu/battery.svg'
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import PinDropIcon from '@material-ui/icons/PinDrop';
import TrafficMenu from './TrafficMenu'

const mapStateToProps = (state, ownProps) => {
  return {
    notificationSettings: state.notificationSettings,
    trackId: state.trackId
  }
}

let notificationSettings = []


class MapFilter2 extends React.PureComponent {
  constructor (props) {
    super(props)
    this.openCallout = this.openCallout.bind(this)
    this.markerEnabled = this.markerEnabled.bind(this)
    this.markerDisabled = this.markerDisabled.bind(this)
    this.tiggerEvent = this.tiggerEvent.bind(this)
    this.setCallout = this.setCallout.bind(this)
    this.addShape = this.addShape.bind(this)
    this.state = {
      callout: 0,
      open: true,
      setCallout: this.setCallout.bind(this)
    }
  }

  markerEnabled () {
    this.props.dispatch(markerEnable({ enable: true }))
  }

  markerDisabled () {
    this.props.dispatch(markerEnable({ enable: false }))
  }

  UNSAFE_componentWillReceiveProps (n) {
    if (n.poiForm && n.poiForm.description && this.state.callout !== 2) {
      if (!isEqual(n.poiForm.description, this.props.poiForm.description)) {
        this.setState({
          callout: 2
        })
      }
    }
  }

  tiggerEvent (event) {
    return event || false
  }

  setCallout (id) {
    this.setState({
      callout: id !== this.state.callout ? id : 0
    })
  }

  openCallout (id) {
    this.setCallout(id)
  }

  addShape (type) {
    let element
    if (type === 'polygon') {
      element = document.getElementById('addMeasurePolygon')
    } else if (type === 'polyline') {
      element = document.getElementById('addMeasurePolyline')
    }
    if (element) {
      element.click()
    }
  }
  render () {
    const emptyLinks = null
    const buttons = (
      <>
        {!this.props.disableClusterLayer ? <li className='has-menu'>
          <OptionGroupMenu themecolors={this.props.themecolors} />
        </li> : null}
        {/* {!this.props.disableSettingsLayer ? <li className='has-menu'>
          <OptionMenu themecolors={this.props.themecolors} />
        </li> : null} */}
        {!this.props.disableMapLayer ? <li className='has-menu'>
          <PaginationMenu
            updateLayer={this.props.updateLayer}
            themecolors={this.props.themecolors}
          />
        </li> : null}
        {!this.props.disableTrafficLayer && checkPrivileges('trafficLayer') ? <TrafficMenu
            themecolors={this.props.themecolors}
          /> : null }
        {/* {!this.props.disableBottomRightFilters && (<>
          <li className='has-callout'>
            <a
              href={emptyLinks}
              title={this.props.translate('sharedGeofence')}
              onClick={this.openCallout.bind(this, 1)}
            >
              <span>
                <svg fill='currentColor' width={18} height={18}>
                  <use xlinkHref={`${sheet}#icon-geofence`} />
                </svg>
              </span>
            </a>
            {this.state.callout === 1 ? (
              <Callout
                key={1}
                className={
                  this.state.callout === 1 ? 'callout-open' : ''
                }
                {...this.props}
                {...this.state}
                title={this.props.translate('sharedGeofence')}
                tiggerEvent={this.tiggerEvent}
              />
            ) : null}
          </li>
          {!this.props.disablePOIFilters &&
          <li className='has-callout'>
            <a
              href={emptyLinks}
              title={this.props.translate('POI')}
              onClick={this.openCallout.bind(this, 2)}
            >
              <PinDropIcon style={{fontSize: 18}} />
            </a>
            {this.state.callout === 2 ? (
              <CalloutPOI
                key={2}
                className={
                  this.state.callout === 2 ? 'callout-open' : ''
                }
                {...this.props}
                {...this.state}
                title={this.props.translate('POI')}
                tiggerEvent={this.tiggerEvent}
              />
            ) : null}
          </li>
          }
          
          {!this.props.disableBottomLeftFilters && (
            <li className='has-menu'>
              <a href={emptyLinks}>
                <LinearScaleIcon/>
              </a>
              <ul className='sub-menu right-top'>
                <li>
                  <a
                    href={emptyLinks}
                    onClick={e => this.addShape('polygon')}
                  >
                    {this.props.translate('areaCalculator')}
                  </a>
                </li>
                <li>
                  <a
                    href={emptyLinks}
                    onClick={e => this.addShape('polyline')}
                  >
                    {this.props.translate('measureDistance')}
                  </a>
                </li>
              </ul>
            </li>)}
          </>
          
        )} */}
      </>
    )
    return (
      <Style>
        {`
          .sub-menu a:hover,
          .sub-menu label:hover,
          .sub-menu .active a,
          .material-icons {vertical-align: middle; color: inherit}
          .filter-actions-list > li:hover > a {
            color: ${this.props.themecolors.themeLightColor}
          }
          .map-filters-bar {
            color: #222;
            background: #fff;
          }
          .material-icons {vertical-align: middle; color: inherit}
          .map-filters-bar-vertical .filter-actions-list li.active > a {
            background-color: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse}
          }
          .map-filters-bar-vertical { color: ${this.props.themecolors.themeInverse}; background-color: ${this.props.themecolors.themeDarkColor}}
				`}

        <div
          className={
            'map-filters-bar clearfix ' +
            (this.state.open ? 'collapse-open' : '')
          }
        >
          {checkPrivileges('device') && (
            <div className='map-actions-left'>
              <ul className='filter-actions-list'>
                {buttons}
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

class DrawIcon extends React.PureComponent {
  render () {
    const { item, themecolors, emptyLinks, count, isActive } = this.props
    let CurrentIcon = null;
    if(item.icon === 'overspeed') {
      CurrentIcon = OverspeedIcon;
    }
    else if(item.icon === 'idling') {
      CurrentIcon = IdlingIcon
    }
    else if(item.icon === 'harshbreak') {
      CurrentIcon = StopIcon
    }
    else if(item.icon === 'deviceMoving') {
      CurrentIcon = MovingIcon
    }
    else if(item.icon === 'towing') {
      CurrentIcon = TowingIcon
    }
    else if(item.icon === 'statusOnline') {
      CurrentIcon = OnlineIcon
    }
    else if(item.icon === 'offline') {
      CurrentIcon = OfflineIcon
    }
    else if(item.icon === 'parking') {
      CurrentIcon = ParkingIcon
    }
    else if(item.icon === 'geofence') {
      CurrentIcon = GeoIcon
    }
    else if(item.icon === 'lowBattery') {
      CurrentIcon = BatteryIcon
    }

    return (
      <Tooltip title={this.props.translate(item.label)} placement='top'>
        <li className={isActive ? 'active' : ''}>
          <a
            href={emptyLinks}
            title=''
            className={item.class}
            onClick={() => this.props.showSelectedUnits(item.notificationKey)}
          >
            <span className={'rel-icons ' + item.notificationKey}>
              <CurrentIcon fill='currentColor' width={24} height={24} />
            </span>
            <span
              className='counter'
              style={{
                color: themecolors.themeInverse
              }}
            >
              {count}
            </span>
          </a>
        </li>
      </Tooltip>
    )
  }
}

export const MapFiltersVerticle = connect(mapStateToProps)(MapFilter2)
