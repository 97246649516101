import React from "react";
import { connect } from "react-redux";
import {
  setTrackId,
  updateDeviceVisible,
  toggleGroupDevices,
  setDeviceId,
  unsetDeviceId,
  fetchMoreDevices,
  resetGroupBy,
  updateGroupNames,
  // applyGroupByFilter
} from "./../../Actions/Devices";
import InfiniteScroll from "react-infinite-scroll-component";
import { updateEvents } from "./../../Actions/Notifications";
import Icon from "@material-ui/core/Icon";
import UnitMenu from "./../Menu/UnitMenu";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import "./index.scss";
import { CheckIcon } from "../common/CheckIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ReactComponent as InfoIcon } from "./../../assets/monitoring/info.svg";
import { ReactComponent as PlayIcon } from "../../assets/monitoring/icons/moving.svg";
import { ReactComponent as StopIcon } from "../../assets/monitoring/stop.svg";
import { ReactComponent as IgnitionIcon } from "../../assets/monitoring/icons/idling.svg";
import { ReactComponent as TowingIcon } from "../../assets/monitoring/towing.svg";
import { ReactComponent as ParkingIcon } from "../../assets/monitoring/icons/parking.svg";
import { ReactComponent as QuestionIcon } from "../../assets/monitoring/question.svg";
import { ReactComponent as OnlineIcon } from "../../assets/monitoring/online.svg";
import { ReactComponent as OfflineIcon } from "../../assets/monitoring/wifi-off.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/monitoring/delivery.svg";
import { ReactComponent as EventIcon } from "../../assets/monitoring/events.svg";
import { ReactComponent as GroupIcon } from "../../assets/monitoring/Group.svg";
import { ReactComponent as Group2Icon } from "../../assets/monitoring/Group-2.svg";
import { ReactComponent as ElogicIcon } from "../../assets/monitoring/elogics.svg";
import { ReactComponent as UnitIcon } from "../../assets/monitoring/units-01.svg";
import { ReactComponent as PinLocationIcon } from "../../assets/monitoring/pin-location.svg";
import { ReactComponent as SendCommandIcon } from "../../assets/monitoring/sendCommand.svg";
import { ReactComponent as ArrowDropDownIcon } from "../../assets/monitoring/arrowdown.svg";
import { ReactComponent as ArrowDropUpIcon } from "../../assets/monitoring/arrowup.svg";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import { getComputedAttributes } from "../../Actions/ComputedAttributes";
import Scrollbar from "react-scrollbars-custom";
import Loader from "./../../Layout/Loader";
import { setIconOnList } from "../../Helpers";
import instance from "../../axios";
import { setAttributeFormat } from "../../Helpers";
import IconButton from "@material-ui/core/IconButton"; 
import moment from "moment";
import { Link } from 'react-router-dom'
import MenuItem from "../common/MenuItem";
import Dialog from "../common/Dialog";
import { SendCommand } from "../../Pages/Units/Components/sendCommand";

class Devices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: "",
    };
  }
  componentWillUnmount() {
    this.setState(
      {
        setNull: true,
      },
      () => {
        this.state = null;
      }
    );
  }

  selectGroup = (id) => {
    if (this.state.selectedId === id) {
      // reset
      this.setState(
        {
          selectedId: "",
        },
        () => {
          this.props.dispatch(resetGroupBy());
        }
      );
    } else {
      this.setState(
        {
          selectedId: id,
        },
        () => {
          // this.props.dispatch(applyGroupByFilter(id))
        }
      );
    }
  };

  groupCollapse = (event) => {
    event.preventDefault();
  };

  render() {
     if (this.state.setNull) {
      return null;
    }
    if (this.props.switchView === "units") {
      const data = this.props.devices.map((device) => {
        return (
          <>
          <ListRow
            sensorData={this.props.sensorData}
            monitoringMap={this.props.monitoringMap}
            openDeviceSettings={this.props.openDeviceSettings}
            collapseSidebar={this.props.collapseSidebar}
            key={device.id}
            item={device}
            item1={device.id}
            trackId={this.props.trackId}
            deviceId={this.props.deviceId}
            dispatch={this.props.dispatch}
            showBox={this.props.showBox}
            relatedData={this.props.deviceRelatedData[device.id]}
            themecolors={this.props.themecolors}
            translate={this.props.translate}
            viewOnMap={this.props.viewOnMap}
            logInUser={this.props.logInUser}
          />
          </>
        );
      });
      return (
        <div className="individual-view list-row-condest">
          <ul className="list-view list-view-children" style={{marginBottom:40}}>{data}</ul>
        </div>
      );
    } else {
      return (
        <DrawGroups
          sensorData={this.props.sensorData}
          selectGroup={this.selectGroup}
          {...this.props}
          selectedId={this.state.selectedId}
          groupCollapse={this.groupCollapse}
        />
      );
    }
  }
}

class DrawGroups1 extends React.PureComponent {
  render() {
    return (
      <div className="group-view list-row-condest">
        <ul className="list-view-group">
          {this.props.groups.map((group, index) => (
            <DrawGroupRow key={index} group={group} {...this.props} />
          ))}
          <DrawGroupRow
            key="empty"
            group={{ id: "nogroup", name: "No Group" }}
            {...this.props}
          />
        </ul>
      </div>
    );
  }
}

export class DrawGroupRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
    };
  }

  toggleGroupDevices = (event) => {
    this.setState(
      {
        checked: event.target.checked,
      },
      () => {
        this.props.dispatch(
          toggleGroupDevices({
            id: this.props.group.id,
            checked: this.state.checked,
            ids: Object.keys(this.props.visibleGroups),
          })
        );
      }
    );
  };

  UNSAFE_componentWillReceiveProps(n) {
    if (n.isChecked !== this.props.isChecked) {
      this.setState({
        checked: n.isChecked,
      });
    }
    if (
      this.props.visibleGroups &&
      n.visibleGroups &&
      n.visibleGroups[n.group.id] !== this.props.visibleGroups[n.group.id]
    ) {
      this.setState({
        checked: n.visibleGroups[n.group.id],
      });
    }
  }

  render() {
    const emptyLinks = null;
    const groups = this.props.groupWiseDevices[this.props.group.id];
    const checked = this.props.selectedId === this.props.group.id;

    if (groups && groups.length) {
      return (
        <li key={this.props.group.id}>
          <div className="clearfix group-list-row list-row">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={this.state.checked}
                onChange={(e) => this.toggleGroupDevices(e)}
              />
              <CheckIcon />
              <span className="unit-name">
                <span className="unit-name-text">{this.props.group.name}</span>
              </span>
            </label>
            <div
              className="pull-right"
              onClick={(e) => this.props.selectGroup(this.props.group.id)}
            >
              <span className="action-item action-additional-menu">
                <a
                  href={emptyLinks}
                  onClick={(e) =>
                    this.props.groupCollapse(e, this.props.group.id)
                  }
                  title="Additional Menu"
                >
                  {checked ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </a>
              </span>
            </div>
          </div>
          <Collapse in={checked} timeout="auto" unmountOnExit>
            <ListRows child={this.props.devicesData.data} {...this.props} />
          </Collapse>
        </li>
      );
    } else {
      return (
        <li key={this.props.group.id}>
          <div className="clearfix group-list-row list-row">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={this.state.checked}
                onChange={(e) => this.toggleGroupDevices(e)}
              />
              <CheckIcon />
              <span className="unit-name">
                <span className="unit-name-text">{this.props.group.name}</span>
              </span>
            </label>
            <div
              className="pull-right"
              onClick={(e) => this.props.selectGroup(this.props.group.id)}
            >
              <span className="action-item action-additional-menu">
                <a
                  href={emptyLinks}
                  onClick={(e) => this.props.groupCollapse(e)}
                  title="Additional Menu"
                >
                  {checked ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </a>
              </span>
            </div>
          </div>

          <Collapse in={checked} timeout="auto" unmountOnExit>
            <ListRows child={[]} {...this.props} />
          </Collapse>
        </li>
      );
    }
  }
}

export class ListRows extends React.Component {
  fetchNext = () => {
    this.props.dispatch(fetchMoreDevices(false, 0, this.props.group.id));
  };
  render() {
    if (this.props.child.length) {
      return (
        <div style={{ height: 250 }}>
          <Scrollbar
            disableTracksWidthCompensation
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id={"scrollableDivDevices" + this.props.group.id}
                  />
                );
              },
            }}
          >
            <InfiniteScroll
              dataLength={this.props.devicesData.data.length}
              next={this.fetchNext}
              hasMore={this.props.devicesData.hasNext}
              loader={<Loader defaultStyle={true} />}
              endMessage={this.props.endMessage}
              scrollableTarget={"scrollableDivDevices" + this.props.group.id}
            >
       
              <ul className="list-view list-view-children">
                {this.props.devicesData.data.map((device, i) => {
                  return (
                    <ListRow
                      sensorData={this.props.sensorData}
                      monitoringMap={this.props.monitoringMap}
                      openDeviceSettings={this.props.openDeviceSettings}
                      collapseSidebar={this.props.collapseSidebar}
                      key={device.id}
                      item={device}
                      item1={device.id}
                      relatedData={this.props.deviceRelatedData[device.id]}
                      dispatch={this.props.dispatch}
                      showBox={this.props.showBox}
                      themecolors={this.props.themecolors}
                      translate={this.props.translate}
                      isCheckedFn={this.props.isCheckedFn}
                      trackId={this.props.trackId}
                      deviceId={this.props.deviceId}
                    />
                  );
                })}
              </ul>
            </InfiniteScroll>
          </Scrollbar>
        </div>
      );
    } else {
      return (
        <p style={{ textAlign: "center", color: "rgb(204, 204, 204)" }}>
          empty
        </p>
      );
    }
  }
}

export const ListRowAlter1 = React.memo(({ deviceRelatedData, ...others }) =>
  others.item ? (
    <ListRow1 {...others} relatedData={deviceRelatedData[others.item.id]} 
    
    openDeviceSettings={this.props.openDeviceSettings}/>
  ) : null
);

export class ListRow1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      expanded:false,
      sendcommandDialog:false,
      list:[]
    };
  }

  handleChange = (event) => {
    const { target } = event;

    this.props.dispatch(
      updateDeviceVisible({ checked: target.checked, id: this.props.item.id })
    );
  };
  viewOnMap = () => {
      this.props.viewOnMap(this.props.item)
    // if (this.props.relatedData && this.props.relatedData.exists) {
    //   this.props.dispatch( updateDeviceVisible({ checked: true, id: this.props.item.id }) );
    //   this.props.dispatch(setTrackId(this.props.item.id));
    // }

    // if (this.props.deviceId !== this.props.item.id) {
    //   this.props.dispatch(unsetDeviceId({ id: this.props.item.id }));
    //   document.body.classList.remove("shown-widgets");
    // }

    // this.props.collapseSidebar();
  };

  addComputedAttributes = (id) => {
    instance({
      url: `/api/attributes/computed`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        deviceId: id,
        all: true,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((Attribute) => {
        this.props.dispatch(getComputedAttributes(Attribute));
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  viewDetails = () => {
    if (window.innerWidth <= 959) {
      this.props.collapseSidebar();
    }
    if (this.props.deviceId === this.props.item.id) {
      this.props.dispatch(unsetDeviceId({ id: this.props.item.id }));
      document.body.classList.remove("shown-widgets");
    } else {
      this.props.dispatch(setDeviceId({ id: this.props.item.id }));
      document.body.classList.add("shown-widgets");

      // this.addComputedAttributes(this.props.item.id)
      instance({
        url: `/api/events/get`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          deviceId: this.props.item.id,
          limit: 4,
        },
      })
        // .then(
        //   response => {
        //     if (response.ok) {
        //       response.json()
        .then((events) => {
          this.props.dispatch(updateEvents(events.data));
          //   })
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
      this.props.dispatch(updateGroupNames([]));
      instance({
        url: `/api/itemgroups/names`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          itemId: this.props.item.id,
          itemType: "Device",
        },
      })
        // .then(response => {
        //     if (response.ok) {
        //       response.json()
        .then((res) => {
          this.props.dispatch(updateGroupNames(res.data));
          //   })
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  openDeviceSettings = () => this.props.openDeviceSettings(this.props.item,"update");
  
  sendCommandFun = () =>  {
    this.setState({
      sendcommandDialog:true
    })
    if(!!this.props.item.id){
      instance({
        url: `/api/commands/send`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          deviceId: this.props.item.id,
        },
      })
   
        .then((commands) => {
          const list = [];
          if (commands.length) {
            commands.map((item) => {
              list.push({ ...item, key: item.id, name: item.description });
              return null;
            });
          }
          this.setState({list}) 
        })
        .catch((error) => { console.log('error ====', error)  })
    }
  }

  onCloseModal = ()=> this.setState({sendcommandDialog:false})

  onClose = () => {
    this.setState({ anchorEl: null });
  };

  // const [expanded, setExpanded] = useState(false);

   handleExpandClick = () => {
    // setExpanded(!expanded);
     this.setState({ expanded: !this.state.expanded });

  };
  /* shouldComponentUpdate (next, state) {
    
    return (
      !isEqual(next.data, this.props.item) ||
      !isEqual(next.relatedData, this.props.relatedData) ||
      (!isEqual(next.trackId, this.props.trackId) &&
        (this.props.trackId === next.data.id ||
          next.trackId === next.data.id)) ||
      !isEqual(next.deviceId, this.props.deviceId) ||
      !isEqual(state, this.state)
    )
  } */

  render() {
    let serverTimeZoneName = 'Asia/Dubai'
    let vehicle = 'N/A'
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }
    const emptyLinks = null;
    const {
      statusText,
      statusClass,
      exists,
      icons,
      speed,
      attributes,
      deviceAttributes,
      status,
    } = this.props.relatedData || {};
     let deviceStatus =
      status === "online"
        ? "#00BF4E"
        : status === "offline"
        ? "#FF0000"
        : status === "gpsNotUpdated"
        ? "#1976d2"
        : status === "notRegisteredYet"
        ? "#100909"
        : "#00BF4E";
    const icon = icons && icons.playicon && icons.playicon.icon ? icons.playicon.icon : "";

    let RelatedIcon = null;
    if (icon === "play") {
      RelatedIcon = PlayIcon;
    } else if (icon === "ignition") {
      RelatedIcon = IgnitionIcon;
    } else if (icon === "towing") {
      RelatedIcon = TowingIcon;
    } else if (icon === "parking") {
      RelatedIcon = ParkingIcon;
    } else if (icon === "stop") {
      RelatedIcon = StopIcon;
    } else if (icon === "unknown") {
      RelatedIcon = QuestionIcon;
    }

    let relatedInfoHTML = null;

    let parking = 0;
    let stopTime = 0;
    let idling = 0;

    if (
      attributes &&
      deviceAttributes &&
      attributes.stopTime &&
      deviceAttributes.minimalParkingDuration
    ) {
      if (
        parseInt(attributes.stopTime) >
        parseInt(deviceAttributes.minimalParkingDuration) * 1000
      ) {
        if (attributes.parking) {
          const ms =
            attributes.stopTime -
            parseInt(deviceAttributes.minimalParkingDuration) * 1000;
          parking = ms > 0 ? ms : 0;
          stopTime = ms < 0 ? attributes.stopTime : 0;
        } else {
          const ms =
            attributes.idlTime -
            parseInt(deviceAttributes.minimalParkingDuration) * 1000;
          idling = ms > 0 ? ms : 0;
          stopTime = ms < 0 ? attributes.idlTime : 0;
        }
      } else {
        stopTime = attributes.stopTime;
      }
    }

    if (icon === "play") {
      relatedInfoHTML = speed ? (
        <span className="action-related-info">
          <span className="action-related-info-value">
            {setAttributeFormat("speed", speed)}
          </span>
        </span>
      ) : null;
    }

    if (parking) {
      relatedInfoHTML = (
        <span className="action-related-info">
          <span className="action-related-info-value">
            {setAttributeFormat("hours", parking)}
          </span>
        </span>
      );
    }
    if (idling) {
      relatedInfoHTML = (
        <span className="action-related-info">
          <span className="action-related-info-value">
            {setAttributeFormat("hours", idling)}
          </span>
        </span>
      );
    }
    if (stopTime) {
      relatedInfoHTML = (
        <span className="action-related-info">
          <span className="action-related-info-value">
            {setAttributeFormat("hours", stopTime)}
          </span>
        </span>
      );
    }

    let extractedDate ='', time='', timeToShow ='';
    // const extractedDate = moment(this.props.item.lastUpdate).utc().format('YYYY-MM-DD')
    // const time =  moment(this.props.item.lastUpdate).utc().format('HH-MM-SS A')
    if(this.props.item.lastUpdate && this.props.item.lastUpdate !== null) {
          extractedDate = moment(this.props.item.lastUpdate).utc().format('DD-MM-YYYY')
          // time =  moment(this.props.item.lastUpdate).utc().format('HH-MM-SS A')
          time = moment.tz(this.props.item.lastUpdate, serverTimeZoneName).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
          timeToShow = moment.tz(this.props.item.lastUpdate, serverTimeZoneName).format('HH:mm:ss');
      } 
        const currentDateTime = moment().tz(serverTimeZoneName).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const diffInHours = moment(currentDateTime).diff(moment(time), 'hours');
        let diff = diffInHours > 2

    return (  
      <>
        <li style={{padding:"2px 12px"}}>
            <div style={{background:"#FFFFFF", 
                border:"1px solid #CFCFCF", 
                borderTop: this.state.expanded ? `3px solid ${this.props.themecolors.menuActiveBackground}`:"1px solid #CFCFCF",
                borderRadius:8, 
                padding:"6px 5px 6px 10px",  
          }}>
          
              <div style={{display:'flex', justifyContent:"space-between"  }}>
              <div style={{ marginTop:5,marginLeft:5, cursor:"pointer", maxWidth:"80%",}} onClick={()=>this.viewOnMap()}>
                    <div 
                      style={{
                        fontSize:14,
                        fontWeight:600,
                        // maxWidth:"90%",  
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                        lineHeight: "17px", 
                        // color:this.props.themecolors?.menuActiveBackground || "#000"
                        color: this.props?.relatedData?.statusText === 'online' ? "green": 
                               this.props?.relatedData?.statusText === 'offline' ? "red" : 
                               this.props?.relatedData?.statusText === 'GPS not updated' ? "blue" : null
                        }}
                        > 
                      {this.props.item.name}
                      </div>
                      {timeToShow && extractedDate && (
                        <span style={{fontSize:10, fontWeight:400,fontStyle:"italic"}}>
                          {timeToShow?timeToShow:"---"} | {extractedDate?extractedDate:"---"}
                        </span>)}
                </div>

                <span style={{ alignItems:"top" }}>
                  {!this.state.expanded ?
                      <IconButton aria-label="Show more"  onClick={()=>this.handleExpandClick()}  >
                        <ArrowDropDownIcon width={12} height={12}/>
                      </IconButton>
                      :
                      <IconButton  onClick={()=>this.handleExpandClick()}>
                        <ArrowDropUpIcon width={12} height={12} />
                      </IconButton>
                  }
                </span>
              </div>
               <div style={{display:'flex', marginTop:5, justifyContent:"space-between" }}>
                <div style={{display:'flex'}}>
                  {/* {this.props.item.status === 'online' ? <OnlineIcon fill="currentColor" width={18} height={18} />  : <OfflineIcon fill="currentColor" width={18} height={18} />  }
                  <span style={{fontSize:12,marginLeft:2,
                                fontWeight:600,fontStyle:"italic",
                                color:this.props.themecolors?.menuTextColor||"#000"}}>
                                  {this.props.item.status === "online" ? this.props.translate("online")
                                  : this.props.translate("offline") }</span> */}

                                   <span style={{fontSize:12,marginLeft:2,
                                        fontWeight:600,fontStyle:"italic",
                                        color:this.props.themecolors?.menuTextColor||"#000"}}>
                                          {/* {this.props.item.status === "online" ? this.props.translate("online")
                                          : this.props.translate("offline") } */}
                                          {this.props?.relatedData?.statusText}
                                          </span>

                  {!diff ? <>
                    <span style={{margin:0, marginLeft:12, marginRight:4}}>{RelatedIcon && <RelatedIcon fill={icon === 'play'?"#28a745"  :  icon === 'parking' ? "#3083EA"  :  icon === 'stop' ? "currentColor" :  icon === 'ignition' ? "#ffbb54eb"  : icon === 'towing' ? "#ffc107":"currentColor"} width={18} height={18} />}</span>
                      <span style={{color:icon === 'play'? "#35B369" :icon === 'parking' ? "#3083EA" :icon === 'stop' ? "#3A3A39" :icon === 'ignition' ? "#B71E4F":icon === 'immbolizer' ? "#FF700A":"#3A3A39",
                        fontSize:12, margin:0, padding:0, marginRight:5}}>{icon === 'play' ? "Moving" :icon === 'parking' ? "Parking":icon === 'ignition'? "Idling" : icon === 'stop' ? "Stop":icon}</span>
                     {timeToShow && extractedDate && ( "|" )}
                          <span style={{marginLeft:5,color:this.props.themecolors?.menuTextColor||"#000",fontSize:12,maxWidth:"70px", whiteSpace: "nowrap", overflow: "hidden",  textOverflow: "ellipsis"}}>
                          {icon === 'play'? setAttributeFormat("speed", speed) 
                            :icon === 'parking' ?  setAttributeFormat('hours', parking)
                            :icon === 'stop' ?  setAttributeFormat('hours', stopTime)
                            :icon === 'ignition' ?  setAttributeFormat('hours', idling)
                            :icon === 'towing' ?  setAttributeFormat('speed', speed)
                            :""}
                           </span>
                      </> : <>
                          <span style={{margin:0, marginLeft:12, marginRight:4}}> {this.props.translate("Unknown")}</span>
                          <span style={{fontSize:12, margin:0, padding:0, marginRight:5}}></span>
                          </> }
                  </div>
                  <div style={{display:'flex'}}>
                  <span 
                    style={{padding:"0 10px",cursor: "pointer", background:"transparent", color:"red"}}
                     onClick={()=>{this.openDeviceSettings()}}
                     >
                    <Tooltip
                          title={this.props.translate("viewDetails")}
                          placement="right-start" //hang issue fixed, tooltip come on right side, infinite scroll have height auto, so hang with tooltip default padding
                          classes={{
                            popper: "menu-popper",
                            tooltip: "menu-popper-tooltip",
                          }}
                        >
                      <UnitIcon  fill="#A7A7A7" width={18} height={18} />
                      </Tooltip>
                      </span>
                  <span
                   style={{  marginRight:10,  cursor: "pointer"}}
                     onClick={this.viewDetails} >
                        <InfoIcon fill="#A7A7A7"  width={18} height={18} />
                        </span>
              </div>
              </div>

              <Collapse in={this.state.expanded} >
                <>
                  <div className="units-collapse"  >
                    <MenuItem style={{ background:"transparent"}} component={Link} to={'/events/' + this.props.item?.id}>
                        <span><EventIcon fill="#A7A7A7"  width={18} height={18}  /></span>
                        <span   style={{ fontSize:11, fontWeight:600,marginLeft:"2px",cursor: "pointer",  color:this.props.themecolors?.menuTextColor||"#000"}}>{this.props.translate("Events")} </span>
                    </MenuItem>
                
                    <MenuItem style={{ background:"transparent"}}  component={Link}  to={'/tracks/' + this.props.item?.id}>
                      <span> <ElogicIcon fill="#A7A7A7"   width={18} height={18} /></span>
                      <span style={{fontSize:11,fontWeight:600,marginLeft:"2px",cursor: "pointer",   color:this.props.themecolors?.menuTextColor||"#000"}} >{this.props.translate("Tracks")}</span>
                      </MenuItem>
                      <MenuItem  style={{  background:"transparent"}} onClick={()=>this.sendCommandFun()}>
                        <span style={{marginRight:3, marginTop:2}}><SendCommandIcon fill="#A7A7A7" width={18} height={18} /></span>
                          <span  style={{ fontSize:11, fontWeight:600, cursor: "pointer", color:this.props.themecolors?.menuTextColor||"#000"}}>
                            {this.props.translate("sendCommands")}
                          </span>
                      </MenuItem>
                      
                  </div>
                  {this.props.relatedData&&this.props.relatedData.address &&(
                    <div style={{ display:'flex',alignItems:"center" }}>
                          <span style={{ padding:"0 5px",}} > <PinLocationIcon minWidth={"15.17px"} height={"21px"}/></span>
                      <p  style={{  color:this.props.themecolors?.menuTextColor||"#000"  }} className="montoring-addres">
                          {this.props?.relatedData?.address || "-----"} 
                        </p>
                  </div>
                  )}
                </>
              </Collapse>
            </div>
        
          {/* <div
            className={
              "clearfix list-row" +
              (this.props.item.id === this.props.trackId ? " active " : "") +
              " " +
              (!exists
                ? "disabled-row"
                : statusClass === "not-updated"
                ? "not-updated"
                : "")
            }
            style={{ paddingLeft: 10 }}
            >
              <Tooltip
                title={this.props.item.name}
                placement="right-start" //hang issue fixed, tooltip come on right side, infinite scroll have height auto, so hang with tooltip default padding
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
              >
                <a
                  onClick={()=>this.viewOnMap()}
                  href={emptyLinks}
                  className="unit-name"
                  style={{ textDecoration: "none", marginBottom: 10 }}
                >
                  <span className="unit-img">
                    {setIconOnList(this.props.item.category, deviceStatus)}
                  </span>
                  <span style={{ minWidth: 0 }}>
                    <span className="unit-name-text">{this.props.item.name}</span>
                    <span className={"unit-status-text " + icons?.play}>
                      <span className="unit-status-label">
                        {icons && RelatedIcon && (
                          <RelatedIcon fill="currentColor" width={16} height={16} />
                        )}{" "}
                        {this.props.translate(icons?.playicon?.label || "")}
                      </span>
                      {relatedInfoHTML}
                    </span>
                  </span>

                </a>
              </Tooltip>
              <div className="pull-right list-action-bar">
                <span
                  className={
                    "action-item action-view " +
                    (this.props.deviceId === this.props.item.id ? "view" : "")
                  }
                >
                  <a
                    href={emptyLinks}
                    onClick={this.viewDetails}
                    title={this.props.translate("viewDetails")}
                    style={{ cursor: "pointer" }}
                  >
                    <InfoIcon fill="currentColor" width={14} height={14} />
                  </a>
                </span>
                <span className={"action-item action-connection " + statusClass}>
                  <a
                    href={emptyLinks}
                    title={this.props.translate("tracker." + statusText)}
                  >
                    <i className="circle"></i>
                  </a>
                </span>

                <span className="action-item action-ellipsis has-menu">
                  <a
                    href={emptyLinks}
                    onClick={this.openMenu}
                    title={this.props.translate("monitoringPanelCustomizer")}
                  >
                    <MoreVertIcon />
                  </a>
                  {this.state.anchorEl && (
                    <UnitMenu
                      id={this.props.item.id}
                      onClose={this.onClose}
                      anchorEl={this.state.anchorEl}
                      menuClass="level-right"
                      removeDevice={this.onRemovedItem}
                      editDevice={this.editDevice}
                      relatedData={this.props.relatedData}
                      data={this.props.item}
                      openDeviceSettings={this.openDeviceSettings}
                      themecolors={this.props.themecolors}
                      translate={this.props.translate}
                      dispatch={this.props.dispatch}
                    />
                  )}
                </span>
              </div>
          </div> */}
                
        
        </li>
          {this.state.sendcommandDialog ? (
            <Dialog
             sendCommand
              open
              title={this.props.translate("sendCommand")}
              themeColors={this.props.themecolors}
              cancelText= {this.props.translate('sharedCancel')}
              // disableFooter
              onClose={this.onCloseModal}
              dialogHeight={250}
              draggable
              bodyPadding={0}
            >
              <SendCommand
                id={this.props.item.id}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                descriptionList={this.state.list}
                sendCommandFun={this.sendCommandFun}
              />
            </Dialog>
          ) : null}
      
      </>
    );
  }
}

const mapStateToProps2 = (state) => ({

  deviceRelatedData: state.deviceRelatedData,
});
const mapStateToProps = (state) => {
  let visibleGroupsArr = {};
  let visibleGroups = {};
  const ids = state.groups.map((g) => g.id);
  state.devices3.map((d) => {
    const gid = ids.includes(d.groupId) ? d.groupId : "nogroup";
    visibleGroupsArr[gid] = visibleGroupsArr[gid] || [];
    visibleGroupsArr[gid].push(d.visible);
    return null;
  });
  ids.push("nogroup");
  ids.map((e) => {
    visibleGroups[e] =
      visibleGroupsArr[e] && visibleGroupsArr[e].includes(false) ? false : true;
    return null;
  });

  return {
    groups: state.groups,
    groupWiseDevices: state.groupWiseDevices,
    visibleGroups,
    devices3: state.devices3,
  };
};
export const DrawGroups = connect(mapStateToProps)(DrawGroups1);
export const ListRow = ListRow1;
export const ListRowAlter = connect(mapStateToProps2)(ListRowAlter1);
export const DevicesList = connect(mapStateToProps2)(Devices);
