import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
class ItemGroupSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      search: '',
      isChecked: false
    }
  }
  UNSAFE_componentWillMount () {
    let list = {}
    if (this.props.value && this.props.value.length) {
      this.props.value.map(d => (list[d] = true))
      this.setState({ list })
    }
  }

  UNSAFE_componentWillReceiveProps (nextprops) {
    let list = {}
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map(d => (list[d] = true))
    }
    this.setState({ list })
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      isChecked: false
    })
  }
  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list))
    }
  }
  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    this.setState(
      {
        list,
        isChecked: this.props.groupList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }
  searchItem = text => {
    this.setState({ search: text.target.value })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      this.props.groupList.map(d => (list[d.id] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  render () {
    let list = this.props.groupList.filter(
      item => item.name && item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    )
    return (
      <Style>
        {`.ScrollbarsCustom-Wrapper {
          margin: 0 -15px;
        }`}
        <div>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={this.props.translate('searchGroup')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              <li style={{ paddingRight: 0, paddingTop: 3, paddingLeft: 6 }}>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate('selectAll')}
                />
              </li>
            </ul>
          </div>
          <div className='selector-count'>
            {' '}
            {Object.keys(this.state.list).length}{' '}
            {this.props.translate('GroupsSelected')}
          </div>
          <Scrollbar
            disableTracksWidthCompensation
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 32) *
                (this.props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div {...restProps} ref={elementRef} id='scrollableDiv' />
                )
              }
            }}
          >
            <ul className='list-view'>
              {list.map(row => (
                <li key={row.id} className={"list-row "+(this.state.list[row.id] ? 'active' : '')}>
                  <label className="unit-name">
                    <span style={{marginRight: 6}}>
                      <Checkbox
                        checked={this.state.list[row.id]}
                        value={row}
                        onChange={this.onChange}
                      />
                    </span>
                    <span className='unit-name-text'>{row.name}</span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    )
  }
}

export default withLocalize(ItemGroupSelector)
